import css from 'styled-jsx/css';

const CmrOptionLabelStyle = css`
  .new-cmr-points-container {
    width: 198px;
    height: 48px;
    background: #f9f9f9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @mixin mobileToTablet {
      width: 211px;
    }
    @mixin smallerMobile1 {
      justify-content: flex-start;
    }
    .box-text {
      display: flex;
      align-items: end;
      @mixin smallerMobile1 {
        display: block;
      }
      .accum-text {
        font-size: 12px;
        line-height: 14.4px;
        color: #4a4a4a;
        min-width: 80px;
        @mixin mobileToTablet {
          font-size: 14px;
          line-height: 16.4px;
        }
        @mixin smallerMobile1 {
          display: block;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
      .box-points {
        display: flex;
        align-items: center;
        .cmr-points-text {
          font-size: 16px;
          line-height: 19.2px;
          font-weight: 700;
          @mixin mobileToTablet {
            margin-left: 4px;
          }
          @mixin smallerMobile1 {
            margin-left: 0;
          }
        }
      }
    }

    .cmr-icon {
      margin: 8px;
    }
    .cmr-text-icon {
      height: 11.46px;
      width: 24px;
      margin-left: 4px;
    }
  }
`;

export { CmrOptionLabelStyle };
