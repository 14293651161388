import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import cls from 'classnames';
import { SliderDotsStyles } from './SliderDots.style';

const SliderDots = ({ maxDots, selectedIndex, allDots, onDotClick }) => {
  const [shownDots, setShownDots] = useState(allDots);
  const [previousIndex, setPreviousIndex] = useState(selectedIndex);
  const filterArray = useCallback(
    (allElements, indiceDivision, limit, dir) => {
      const firstSubarray = allElements.slice(0, indiceDivision);
      const secondSubarray = allElements.slice(indiceDivision + 1);
      while (firstSubarray.length + secondSubarray.length >= limit) {
        if (dir === 'r') {
          if (secondSubarray.length > 1) {
            secondSubarray.pop();
          } else {
            firstSubarray.shift();
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (firstSubarray.length > 1) {
            firstSubarray.shift();
          } else {
            secondSubarray.pop();
          }
        }
      }
      return [
        ...firstSubarray,
        ...[allElements[indiceDivision]],
        ...secondSubarray
      ];
    },
    [maxDots, selectedIndex, allDots]
  );
  const parsedIndex = useMemo(() => {
    if (selectedIndex > allDots.length - 1) return 0;
    if (selectedIndex < 0) return allDots.length - 1;
    return selectedIndex;
  }, [selectedIndex, allDots]);

  const shouldCreate = (i) => {
    if (!previousIndex) return true;
    const shownDotsSliced = [...shownDots].slice(1, shownDots.length - 1);
    return !shownDotsSliced.some((e) => e.index === i);
  };
  useEffect(() => {
    const direction = selectedIndex >= previousIndex ? 'r' : 'l';
    if (shouldCreate(parsedIndex)) {
      const data = shouldCreate(parsedIndex)
        ? filterArray(allDots, parsedIndex, maxDots, direction)
        : shownDots;
      const mappedDots = data.map((e) => ({ index: allDots[e] }));
      setShownDots(mappedDots);
    }
    if (previousIndex !== selectedIndex) {
      setPreviousIndex(parsedIndex);
    }
  }, [selectedIndex, allDots]);

  return (
    <div>
      <div className="dot-container">
        {shownDots.map((e) => (
          <div
            key={e.index}
            role="button"
            tabIndex="0"
            className={cls(
              'dot',
              {
                full:
                  allDots.length > maxDots &&
                  parsedIndex !== e.index &&
                  e.index !== 0 &&
                  e.index !== allDots.length - 1
              },
              { active: parsedIndex === e.index }
            )}
            onClick={() => onDotClick(e.index)}
            onKeyDown={noop}
          />
        ))}
      </div>
      <style jsx>{SliderDotsStyles}</style>
    </div>
  );
};

SliderDots.propTypes = {
  maxDots: PropTypes.number.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  allDots: PropTypes.array.isRequired,
  onDotClick: PropTypes.func.isRequired
};

export default SliderDots;
