import css from 'styled-jsx/css';
import { colors, typography } from '../../config/styles';

const ProductTopSpecificationsStyle = css`
  .specifications-container {
    background-color: ${colors.gray.shade32};
    padding: 16px;
    margin-bottom: 20px;
    @mixin tabletToDesktop {
      margin-bottom: 0;
      padding: 18px 27px;
    }
  }
  .specifications-title {
    color: ${colors.black.shade1};
    font-weight: bold;
    font-size: 16px;
    @mixin tabletToDesktop {
      font-size: 14px;
    }
  }
  .specifications-divider {
    border-bottom: 1.1px solid ${colors.gray.shade14};
    @mixin tabletToDesktop {
      border-bottom: 1px solid ${colors.gray.shade3};
      margin-top: 20px;
    }
  }
  .specifications-list {
    font-size: 16px;
    margin: 10px 0;
    line-height: 1.5;
    @mixin tabletToDesktop {
      font-size: 12px;
      margin: 10px 0 17px;
    }
  }

  .specifications-list li {
    position: relative;
    padding-left: 23px;
    @mixin tabletToDesktop {
      padding-left: 17px;
    }
  }

  .specifications-list li::before {
    position: absolute;
    content: '◇';
    left: 0;
    color: ${colors.black.shade1};
    font-size: ${typography.sm2};
  }

  .specifications-list li strong {
    font-weight: bold;
  }
  .view-more {
    color: #68717d;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 112.5% */
    border-bottom: 1px solid #68717d;
  }
`;

export { ProductTopSpecificationsStyle };
