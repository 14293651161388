import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { VariantHeaderStyles } from './VariantHeader.style';
import Prices from '../Prices/Prices';
import Heading from '../ui/Heading/Heading';
import BodyCopy from '../ui/BodyCopy/BodyCopy';
import constants from '../../config/constants';
import ProductColorSwatches from '../ProductColorSwatches/ProductColorSwatches';
import noop from '../../utils/noop';
import { withLabels } from '../../utils/LabelsContext';
import { LinkLabel } from '../ui/LinkButton/LinkButton';
import _ from '../../utils/LodashImports';
import { withApplicationContext } from '../../utils/ApplicationContext';

const VariantHeader = ({
  brandName,
  variantName,
  prices,
  colorVariants,
  variant,
  selectedColorSwatchIndex,
  colorSwatchHandler,
  productId,
  layout,
  variantUrl,
  isRebrandingEnabled,
  appCtx
}) => {
  const isNewPriceApplicable = _.get(
    appCtx,
    'siteConfig.toggles.isNewPriceApplicable',
    false
  );
  const isForCart = variant === 'cart';

  const renderVariantName = () =>
    layout === 'typeC' ? (
      <LinkLabel type="default" href={variantUrl}>
        {isForCart ? (
          <span className="variantName">{variantName}</span>
        ) : (
          variantName
        )}
      </LinkLabel>
    ) : (
      variantName
    );

  return (
    <div className={`variantHeader ${variant}`}>
      <div className={`variantHeader-text${isRebrandingEnabled ? ' mkp' : ''}`}>
        <div className="brandName">
          {isForCart ? (
            <BodyCopy type="title17" size="primary">
              {brandName.toUpperCase()}
            </BodyCopy>
          ) : (
            <Heading level={2} variation="title9">
              {brandName.toUpperCase()}
            </Heading>
          )}
        </div>
        {isForCart ? (
          <BodyCopy type="title19" size="primary">
            {renderVariantName()}
          </BodyCopy>
        ) : (
          <h3
            className={classNames('variantName', {
              'mb-2px': isNewPriceApplicable,
              'ajust-mb': isNewPriceApplicable,
              [`variantName-${layout}`]: layout
            })}
          >
            {renderVariantName()}
          </h3>
        )}

        {!isForCart && prices.length > 0 && (
          <Prices
            priceList={prices}
            deviceType="desktop"
            page="PDP"
            priceCount={prices.length}
            variant="TYPE_C"
          />
        )}

        {isForCart && colorVariants[selectedColorSwatchIndex] && (
          <div className="color-swatch-container">
            <ProductColorSwatches
              colorVariants={colorVariants}
              selectedColorSwatchIndex={selectedColorSwatchIndex}
              colorSwatchHandler={colorSwatchHandler}
              type={constants.VIEW_TEMPLATE.TYPE_C}
              size="small"
              appCtx={appCtx}
              productId={productId}
            />
          </div>
        )}
      </div>

      <style jsx>{VariantHeaderStyles}</style>
    </div>
  );
};

VariantHeader.defaultProps = {
  brandName: '',
  prices: [],
  layout: undefined,
  variant: '',
  colorVariants: [],
  selectedColorSwatchIndex: -1,
  colorSwatchHandler: noop,
  productId: '',
  variantUrl: '',
  isRebrandingEnabled: false
};

VariantHeader.propTypes = {
  variantName: PropTypes.string.isRequired,
  brandName: PropTypes.string,
  prices: PropTypes.arrayOf(PropTypes.object),
  layout: PropTypes.string,
  variant: PropTypes.string,
  colorVariants: PropTypes.arrayOf(PropTypes.object),
  selectedColorSwatchIndex: PropTypes.number,
  colorSwatchHandler: PropTypes.func,
  productId: PropTypes.string,
  variantUrl: PropTypes.string,
  isRebrandingEnabled: PropTypes.bool,
  appCtx: PropTypes.object.isRequired
};

export default withApplicationContext(withLabels(VariantHeader));
export { VariantHeader };
