import { getCFDefaultImage } from '../../components/ImageGallery/ImageGalleryHelper';

export const getBackgroundCSS = ({
  value,
  sellerName,
  regionCode,
  isCfMediasEnabled
}) => {
  if (/^[0-9A-Fa-f]{6}$/i.test(value)) {
    return {
      backgroundColor: `#${value}`
    };
  }
  if (value && value.indexOf('http') === 0) {
    return {
      backgroundImage: `url(${value})`,
      backgroundSize: 'cover'
    };
  }
  if (value && value.indexOf('http') === -1) {
    if (value.length < 10) {
      return {
        backgroundColor: value
      };
    }
    const baseUrl = 'https://falabella.scene7.com';
    const mediaUrl = isCfMediasEnabled
      ? getCFDefaultImage({ sellerName, regionCode })
      : baseUrl.concat(`${value}`);
    return {
      backgroundImage: `url(${mediaUrl})`,
      backgroundSize: 'cover'
    };
  }
  return {
    backgroundColor: `undefined`
  };
};
