import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import surveyBoxStyles from './SurveyBox.style';
import noop from '../../../utils/noop';

const SurveyBox = ({ icon, content, onClickHandler, type, url }) => {
  const InnerContent = () => (
    <Fragment>
      <i className={`icon-medium ${icon}`} />
      {content()}
      <i className="icon-small csicon-arrow" />
      <style jsx>{surveyBoxStyles}</style>
    </Fragment>
  );
  const Button = () => (
    <button type="button" className="survey-box" onClick={onClickHandler}>
      <InnerContent />
      <style jsx>{surveyBoxStyles}</style>
    </button>
  );
  const Anchor = () => (
    <a
      className="survey-box"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <InnerContent />
      <style jsx>{surveyBoxStyles}</style>
    </a>
  );
  return type === 'link' ? <Anchor /> : <Button />;
};

SurveyBox.defaultProps = {
  url: '',
  type: 'button',
  onClickHandler: noop
};

SurveyBox.propTypes = {
  icon: PropTypes.string.isRequired,
  content: PropTypes.func.isRequired,
  onClickHandler: PropTypes.func,
  type: PropTypes.oneOf(['button', 'link']),
  url: PropTypes.string
};

export default SurveyBox;
