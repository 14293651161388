import { useState, useEffect } from 'react';
import _ from './LodashImports';
import { svgToDataUri } from './styles/svgMixins';
import { icons } from '../config/styles';

export const getStoreIconPathFromStoreName = (storeName) => {
  let name = storeName;
  name = (name && name.toLowerCase()) || '';
  // eslint-disable-next-line no-nested-ternary
  const storePath = name.includes('tottus')
    ? 'Tottus'
    : name.includes('sodimac')
    ? 'Sodimac'
    : 'Falabella';
  return storePath;
};

const useGoogleMap = (mapRef, selectedStore, hideMap = false) => {
  const [map, setMap] = useState({});
  const [marker, setMarker] = useState({});
  const [isMapVisible, setMapVisible] = useState(!hideMap);

  const initMap = () => {
    if (mapRef && mapRef.current) {
      const latLng = {
        lat: _.get(selectedStore, 'address.latitude', 0),
        lng: _.get(selectedStore, 'address.longitude', 0)
      };
      const storePath = getStoreIconPathFromStoreName(
        _.get(selectedStore, 'storeName', '')
      );
      const initialMap = new window.google.maps.Map(mapRef.current, {
        zoom: 12,
        center: latLng,
        disableDefaultUI: true,
        zoomControl: true
      });

      const currentMarker = new window.google.maps.Marker({
        position: latLng,
        map: initialMap,
        icon: svgToDataUri(icons[`mapMarker${storePath}`])
      });
      setMap(initialMap);
      setMarker(currentMarker);
    }
  };
  useEffect(() => {
    if (!hideMap) {
      initMap();
    }
  }, []);
  const showMap = () => {
    setMapVisible(true);
    initMap();
  };
  return {
    marker,
    map,
    mapRef,
    isMapVisible,
    showMap
  };
};

export default useGoogleMap;
