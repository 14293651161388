import _ from '../LodashImports';
import { getPriceToNumber } from '../PriceHelpers';

const isStringEqual = (first, second) => {
  return first.toLowerCase() === second.toLowerCase();
};

const isAllAttributesMatching = (attributes, variant) => {
  return Object.keys(attributes).every((attribute) => {
    if (!attributes[attribute]) return true;
    if (!variant.attributes[attribute]) return false;
    return isStringEqual(attributes[attribute], variant.attributes[attribute]);
  });
};

const filterVariants = (variants, attributes) => {
  return variants.reduce((acc, variant) => {
    const { isPurchaseable } = variant;
    if (isPurchaseable && isAllAttributesMatching(attributes, variant)) {
      return [...acc, variant];
    }
    return acc;
  }, []);
};

const getFallback = (variants, colorName) => {
  return filterVariants(variants, { colorName });
};

const getMatchingVariants = (variants, attributes, fallback = false) => {
  const matchingVariants = filterVariants(variants, attributes);

  if (!matchingVariants.length && fallback) {
    return getFallback(variants, attributes.colorName);
  }

  return matchingVariants;
};

const getFirstMatchingVariantId = (variants, attributes) => {
  return _.get(
    getMatchingVariants(variants, attributes, true),
    '[0].id',
    undefined
  );
};

const getCheapestVariantId = (variants, operators, tenant) => {
  const operatorIds = operators.map((op) => op.id);

  const availableVariants = variants
    .map((v) => {
      if (
        operatorIds.includes(v.id) &&
        v.prices &&
        v.prices.length !== 0 &&
        v.isPurchaseable
      )
        return v;
      return false;
    })
    .filter(Boolean);

  if (!availableVariants.length) return null;

  const priceValues = availableVariants.map((v) => v.prices[0].price[0]);
  const isEveryPriceSame = priceValues.every(
    (p) => priceValues.indexOf(p) === 0
  );

  if (isEveryPriceSame) return null;

  const cheapestVariant = availableVariants.sort(
    (a, b) =>
      getPriceToNumber(a.prices[0].price[0], tenant) -
      getPriceToNumber(b.prices[0].price[0], tenant)
  );

  return cheapestVariant[0].id;
};
export { getMatchingVariants, getFirstMatchingVariantId, getCheapestVariantId };
