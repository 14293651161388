import css from 'styled-jsx/css';
import { colors } from '../../../config/styles';

export const AdditionalInformationDesktopCss = css`
  .productInfoContainer {
    display: flex;
    padding-bottom: 30px;
    background-color: ${colors.white.shade1};

    .specification {
      box-sizing: border-box;
      padding-left: 60px;

      &-2-column {
        width: 37%;
      }
      &-1-column {
        width: 100%;
        padding-right: 60px;
      }
    }

    .description {
      box-sizing: border-box;
      padding: 0 60px 0 70px;

      &-1-column {
        width: 100%;
      }
      &-2-column {
        width: 63%;
      }
    }
  }
`;
