import css from 'styled-jsx/css';
import { colors } from '../../../config/styles';

const GetSharedDetailsStyles = css`
  .delivery-options-wrapper {
    display: flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 2px 2px 0 rgba(29, 29, 29, 0.1);
  }
  .dlv-opt-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
  }
  .so-border {
    border: 1px solid ${colors.white.shade4};
  }
  .mkp-border {
    border: 1px solid ${colors.green.shade24};
  }
  .marginWrapper {
    padding-top: 16px;
    position: relative;
    margin: 0 -3.7vw;
  }
`;
export { GetSharedDetailsStyles };
