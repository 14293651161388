import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import httpService from '../../../../utils/httpService';
import { withLabels } from '../../../../utils/LabelsContext';
import _ from '../../../../utils/LodashImports';
import PickupInStoreAvailabilityOptionItem from './PickupInStoreItem';
import { ModalContentCss } from '../../ModalContent/ModalContent.style';
import noop from '../../../../utils/noop';
import apiConfig from '../../../../config/api/apiConfig';
import WebStorage from '../../../../utils/WebStorage';
import constants from '../../../../config/constants';
import { errorMessageHandler } from '../../../../utils/errorHandler';
import { getCommerceHeaders } from '../../../../utils/tenantHelper';
import { withApplicationContext } from '../../../../utils/ApplicationContext';
import { changeTextCase } from '../../../../utils/jsUtils';
import CookieStorage from '../../../../utils/CookieStorage';

const PickupInStoreModal = ({
  labels,
  onClose,
  regionCode,
  selection,
  setLoading,
  setError,
  appCtx,
  offeringId
}) => {
  const { state, municipal, lat, long } = selection;
  const [data, setData] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const commerceHeaders = getCommerceHeaders(regionCode);
  const { isRebrandingEnabled, siteConfig } = appCtx;
  useEffect(() => {
    setError('');
    setLoading(true);
    httpService()
      .get(
        apiConfig.getPickupFromStoreShippingUrl(
          regionCode,
          state,
          municipal,
          lat,
          long,
          offeringId
        ),
        {
          headers: commerceHeaders
        }
      )
      .then((res) => {
        setLoading(false);
        const stores = _.get(res, 'data.data.storePickUp.listOfStores', []);
        setData(stores);
        if (res.statusCode !== 200 && res.error) {
          const resp = JSON.parse(res.data);
          const error = errorMessageHandler(resp.errors);
          setError(error ? error[0].message : '');
        } else if (!stores || stores.length === 0) {
          setError(
            _.get(
              siteConfig,
              `configurations.commerceApiAlerts[CHECKOUT_SLOT_NOT_AVAILABLE]`,
              ''
            )
          );
        }
      });
  }, [selection]);

  useEffect(() => {
    try {
      const perviousState = JSON.parse(
        WebStorage.getItem({
          name: constants.LS_STORE_PICKUP_KEY,
          fromPersistence: true
        })
      );

      setActiveId(perviousState.storeId);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, []);

  const handleSelection = (id) => {
    setActiveId(id);
  };

  const handleOnClick = () => {
    const store = data.find(({ storeId }) => activeId === storeId);
    // Set Availability_CC cookie
    CookieStorage.setCookie({
      name: constants.COOKIE_STORE_PICKUP_KEY,
      value: {
        ...selection,
        displayName: `En ${store.storeName}`,
        storeId: activeId
      },
      regionCode
    });
    // Save CC information to local storage
    WebStorage.setItem({
      name: constants.LS_STORE_PICKUP_KEY,
      value: JSON.stringify({
        ...selection,
        displayName: `En ${store.storeName}`,
        storeId: activeId
      }),
      shouldPersist: true
    });

    onClose();
  };

  return (
    <Fragment>
      {data.length !== 0 && (
        <div
          className={`resultActive hasBottomOption ${isRebrandingEnabled &&
            'rebranded'}`}
        >
          <div className="heading">
            <span>
              {data.length} {labels.PICKUP_INSTORE_STORES_AVAILABLE}
            </span>
          </div>

          <div className="optionContainer">
            {data.map((store) => (
              <PickupInStoreAvailabilityOptionItem
                key={store.storeId}
                store={store}
                onChange={handleSelection}
                selected={activeId === store.storeId}
                isRebrandingEnabled={isRebrandingEnabled}
              />
            ))}
          </div>

          <div className="bottomContainer">
            <div className="bottomContainer-item left">
              <div className="icon">
                <i className="csicon-alert" />
              </div>
              <span className="quinary">
                {labels.PICKUP_INSTORE_INFO_CAN_VARY}
              </span>
            </div>
            <div className="bottomContainer-item right">
              <button
                id="testId-PickupInStoreModalContent-clearselection"
                type="button"
                onClick={onClose}
                className="button delete"
              >
                {labels.CANCEL}
              </button>
              <button
                id="testId-PickupInStoreModalContent-saveselection"
                type="button"
                onClick={handleOnClick}
                className={`button delivery ${!activeId && 'disabled'}`}
              >
                {changeTextCase(
                  labels.PICKUP_INSTORE_SAVESTORE_BUTTON,
                  isRebrandingEnabled
                )}
              </button>
            </div>
          </div>

          <style jsx>{ModalContentCss}</style>
        </div>
      )}
    </Fragment>
  );
};

PickupInStoreModal.propTypes = {
  labels: PropTypes.object,
  onClose: PropTypes.func,
  regionCode: PropTypes.string,
  selection: PropTypes.shape({
    state: PropTypes.string,
    municipal: PropTypes.string,
    lat: PropTypes.string,
    long: PropTypes.string
  }),
  setLoading: PropTypes.func,
  setError: PropTypes.func,
  appCtx: PropTypes.object,
  offeringId: PropTypes.string
};

PickupInStoreModal.defaultProps = {
  labels: {},
  onClose: noop,
  regionCode: '',
  selection: { state: '', municipal: '', lat: '', long: '' },
  setLoading: noop,
  setError: noop,
  appCtx: {},
  offeringId: ''
};

export { PickupInStoreModal };
export default withApplicationContext(withLabels(PickupInStoreModal));
