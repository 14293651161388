import css from 'styled-jsx/css';
import { colors } from '../../../../config/styles';

const priceCalendarStyle = css`
  .deliverySlot-container {
    border: 1.1px solid ${colors.gray.shade37};
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    margin: 10px 0;
    padding: 15px 0 0;

    &.bottom-pad {
      padding: 15px 0;
    }
  }

  .calendar-description {
    padding: 0 0 15px 20px;
  }

  .calendar-month {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px 10px;
    .backward-icon {
      cursor: pointer;
      outline: none;
    }
    .show-icon {
      visibility: show;
    }
    .hide-icon {
      visibility: hidden;
    }
    .forward-icon {
      cursor: pointer;
      outline: none;
    }
    .calendar-month-name {
      font-size: 16px;
      font-weight: 700;
    }
  }

  .calendar-single {
    margin-left: 20px;
    h4 {
      font-size: 1.5rem;
      line-height: 2.1rem;
      font-weight: bold;
    }
    .delivery-data {
      margin-top: 11px;
      padding-right: 20px;
      font-size: 1.4rem;
      display: flex;
      justify-content: space-between;
      padding-bottom: 5px;
      div {
        width: 50%;
      }
    }
  }

  .calender-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    height: 33px;
    width: 100%;
    text-align: center;
    padding: 5%;

    @mixin phabletToDesktop {
      height: 43px;
    }
  }

  .calendar {
    display: flex;
    .calendar-side-menu {
      display: none;
      @mixin phabletToDesktop {
        width: 20%;
        display: flex;
        font-size: 14px;
        color: ${colors.black.shade1};
        flex-direction: column;
        justify-content: center;
        align-items: center;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          height: 33px;
          width: 100%;
          text-align: center;
          padding: 5%;

          @mixin phabletToDesktop {
            height: 43px;
          }
        }
        .row-1 {
          border-top: 1px solid ${colors.gray.shade6};
          border-bottom: 1px solid ${colors.gray.shade6};
          background-color: ${colors.white.shade2};
        }
      }
    }

    .calendar-date-price {
      width: auto;
      overflow-y: auto;
      display: flex;
      font-size: 14px;
      color: ${colors.black.shade1};
      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        div {
          text-align: center;
          height: 33px;
          padding: 12px 10%;
          width: 100%;

          @mixin phabletToDesktop {
            height: 43px;
          }
        }
        .row-1 {
          border-top: 1px solid ${colors.gray.shade6};
          border-bottom: 1px solid ${colors.gray.shade6};
          width: 100px;
          font-weight: 700;
          background-color: ${colors.white.shade2};
          @mixin phabletToDesktop {
            width: auto;
          }
        }
      }

      @mixin phabletToDesktop {
        width: 80%;
      }
    }
  }
`;

export { priceCalendarStyle };
