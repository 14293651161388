const zIndex = {
  typeAhead: {
    overlay: 12,
    searchBar: 20,
    searchResults: 20,
    searchBarMobile: 10
  },
  pod: {
    badges: 1
  },
  taxonomy: {
    menu: 11,
    overlay: 101
  },
  dialog: {
    root: 10000,
    footer: 101
  },
  factesMobileMenu: {
    child: 90
  },
  facet: {
    icon: 10
  },
  popover: {
    content: 9000
  },
  backToTop: 5,
  loader: {
    overlay: 9999
  },
  facets: {
    header: 20
  },
  scanner: {
    video: 99990,
    canvas: 99992,
    overlay: 99994,
    button: 99996,
    modal: 99998
  },
  imageGallery: {
    button: 4
  },
  availability: {
    radioInput: 2,
    radioInputOverlay: 3
  },
  input: {
    prependIcon: 1
  },
  pdp: {
    addToCartButton: 19
  },
  xlp: {
    myListsIcon: 120
  },
  drawer: {
    container: 999
  },
  sizeInfo: 99,
  tooltip: {
    pointer: 9001
  }
};

export default zIndex;
