import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { LocationIcon } from '../../ui/LocationIcon/LocationIcon';
import { AvailabilityLocationStyles } from './AvailabilityLocation.style';
import { capitalizeText } from '../../Pod/podUtils';

export const AvailabilityLocation = ({ comunaName }) => {
  return (
    <Fragment>
      <div className="delivery-option-location">
        <LocationIcon />
        <p className="delivery-location-text">
          Entrega en <span>{capitalizeText(comunaName)}</span>
        </p>
      </div>
      <style jsx>{AvailabilityLocationStyles}</style>
    </Fragment>
  );
};

AvailabilityLocation.propTypes = {
  comunaName: PropTypes.string.isRequired
};
