// 1rem =  10px

const typography = {
  xxs3: '0.4rem',
  xxs2: '0.6rem',
  xxs1: '0.7rem',
  xxs: '0.8rem',
  xs1: '0.9rem',
  xs: '1rem',
  xs2: '1.1rem',
  sm: '1.2rem',
  sm1: '1.3rem',
  sm2: '1.1rem',
  base: '1.4rem',
  lg: '1.5rem',
  lg1: '1.6rem',
  lg2: '1.7rem',
  xl: '1.8rem',
  xla: '1.9rem',
  xl2: '2rem',
  xl2b: '2.2rem',
  xl2c: '2.3rem',
  xl3: '2.4rem',
  xl3a: '2.5rem',
  xl3c: '2.6rem',
  xl3b: '2.7rem',
  xl4: '2.8rem',
  xl5: '3.2rem',
  xl5a: '3.3rem',
  xl6: '3.5rem',
  xl7: '3.6rem',
  xl11: '4rem',
  xxl: '6rem'
};

export default typography;
