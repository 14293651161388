/* stylelint-disable no-descending-specificity */
import css from 'styled-jsx/css';
import { typography, colors, icons } from '../../config/styles';
import { svgToDataUri } from '../../utils/styles/svgMixins';

const ProductBundleStyles = css`
  .bundle-wrapper {
    height: auto;
    background: ${colors.white.shade1};
    margin: 24px auto;
    padding: 30px 15px;
    display: flex;
    flex-direction: column;
    @mixin tabletToDesktop {
      padding: 17px 25px;
      min-height: 210px;
    }
    @mixin desktop {
      padding: 17px 60px;
      min-height: 210px;
    }
    &.recommendation {
      padding: 0;

      .pod-wrapper {
        padding: 10px 34px 1px;

      &.single-product {
        padding-bottom: 34px;
        @mixin mobileToTablet {
          padding-bottom: 32px;
        }
        .price-box > .reco-add-to-cart {
          @mixin tabletToDesktop {
            margin-right: 0;
            margin-left: auto;
          }
          @mixin mobileToTablet {
            width: 100%;
          }
        }
        .price-box > .prices {
            flex: 0;
            @mixin mobileToTablet {
              margin-bottom: 13px;
              padding-bottom: 0;
            }
            @mixin tabletToDesktop {
              margin-left: auto;
            }
        }
        .bundles-box {
          width: auto;
          flex-grow: 0;
        }
        .price-box {
          @mixin tabletToDesktop {
            margin-left: 27px;
          }
        }
        .price-box > .prices > .price-total {
          align-self: center;
          margin-right: 20px;
        }
      }
      @mixin mobileToTablet {
        padding: 12px 17px 0;
      }
      }
      .price-box {
        padding: 0 34px 20px 0;
        @mixin mobileToTablet {
          padding: 0 17px 34px 17px;
          margin-top: 14px;
        }
        @mixin tabletToDesktop {
          align-self: end;
        }
      }
    }
  }
  .bundle-heading {
    display: flex;
    margin-bottom: 27px;
    align-items: center;
    i {
      font-size: ${typography.xl3b};
      margin-right: 10px;
    }
    .csicon-mkp-bundleTitle {
      content: url("${svgToDataUri(icons.bundleTitleRebranded)}");
      width: 25.51px;
      height: 25.51px;
    }
    &.rebranding-header {
      line-height: 30px;
      padding: 10px 40px;
      margin-bottom: 0;
      border-bottom: thin solid #f6f6f6;
      @mixin mobileToTablet {
        padding: 1rem 0 1rem 1.5rem;
      }
    }
  }
  .pod-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    @mixin tabletToDesktop {
      flex-direction: row;
    }

    .bundles-box {
      width: 100%;
      overflow: hidden;
      @mixin tabletToDesktop {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
      }
      &.rebranding-bundles {
        &-box {
          justify-content: start;
        }
        &-boxes {
          justify-content: space-around;
        }
      }
    }
    .price-box {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 1em;
      @mixin tabletToDesktop {
        width: 25%;
        min-width: 240px;
        margin-top: 0;
      }
      @mixin desktop {
        min-width: 280px;
      }
    }

    .price-box.mkp {
      align-items: center;
      padding: 0 5.5px;
      button {
        margin-top: 29px;
        @mixin tabletToDesktop {
          margin-top : 40px;
        }
      }
    }
    
    .prices {
      flex-grow: 1;
      height: 60px;
      display: flex;
      padding: 1em 0;
      margin-bottom: 21px;
      @mixin tabletToDesktop {
        padding: 0;
        margin-bottom: 13px;
      }
    }
    .price-total {
      font-size: ${typography.lg1};
      font-weight: bold;
      margin-right: 45px;
      @mixin tabletToDesktop {
        font-size: ${typography.base};
        margin-right: 20px;
      }
    }
  }

  .cart.bundle-wrapper {
    margin: 0;
    padding: 22px 21px;

    @mixin tabletToDesktop {
      padding: 22px 0;
    }
  }
  .reco-prices {
    display: flex;
    margin: 19px 0 21px;
    .price-total {
      margin-right: 18px;
      align-self: center;
      width: 48%;
      font-weight: 700;
      text-align: right;
      color: ${colors.black.shade1};
      @mixin tabletToDesktop {
        margin-right: 20px;
        width: 58%;
      }
    }
  }
  .reco-add-to-cart {
    @mixin tabletToDesktop {
      width: 280px;
      margin-right: 40px;
    }
  }
  .prices.cart {
    margin-bottom: 10px;
  }
`;

export default ProductBundleStyles;
