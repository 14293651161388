import React, { useContext, useState } from 'react';
import createContainer from 'constate';
import _ from 'lodash';
import CookieStorage from './CookieStorage';
import { isWindow, isMobile, isIpadPotrait } from './deviceChecker';
import { nameHOC } from './hocutils';
import { reloadWithParams, isReloadRequiredOnZoneChange } from './RouteHandler';

const logoutUser = ({ appCtx, setAppCtx }) => () => {
  const userCookie = CookieStorage.getUserDataCookieName();
  CookieStorage.removeCookie({ name: userCookie });
  const { USER_DATA_COOKIE, ...rest } = appCtx;
  setAppCtx(rest);
};

const setBusy = ({ appCtx, setAppCtx }) => (busy) => {
  const updatedBusy = busy ? appCtx.busy + 1 : appCtx.busy - 1;
  setAppCtx({
    ...appCtx,
    busy: updatedBusy > 0 ? updatedBusy : 0
  });
};

const reloadOnZoneChange = ({ appCtx, setAppCtx }) => (zoneData = {}) => {
  const politicalId = _.get(zoneData, 'politicalId', '');
  const pid = politicalId;
  const priceGroupId = _.get(zoneData, 'priceGroupId', '');
  const zoneName = _.get(zoneData, 'name', '');
  const zones = _.get(zoneData, 'zones', '');
  if (!_.isEmpty(zoneData) && politicalId && priceGroupId && zones) {
    const isReloadRequired = isReloadRequiredOnZoneChange();
    setAppCtx({
      ...appCtx,
      politicalId,
      pid,
      priceGroupId,
      zoneName,
      zones: zones.join(),
      busy: isReloadRequired,
      isDefaultComuna: false,
      comuna: { data: zoneData, default: false }
    });
    const newParams = `&pid=${politicalId}&zones=${zones.join()}&pgid=${priceGroupId}&exp=${
      appCtx.store
    }`;
    const ignoreQuery = ['pid', 'pgid', 'zones'];
    if (isReloadRequired) {
      reloadWithParams({ newParams, ignoreQuery, tenant: appCtx.tenant });
    }
  }
};

const setLocation = ({ appCtx, setAppCtx }) => (location) => {
  if (!location) return;
  CookieStorage.setCookie({
    name: 'zoneData',
    value: location,
    regionCode: appCtx.regionCode
  });

  const locationData = CookieStorage.parseCookieValue(
    'locationData',
    CookieStorage.getCookie('locationData').replace(/"/g, '')
  );

  if (locationData) {
    locationData.availableZone = `${location.zoneID}`;
    CookieStorage.setCookie({
      name: 'locationData',
      value: locationData,
      addQuotes: true,
      regionCode: appCtx.regionCode
    });
  }

  const newAppCtx = _.set(appCtx, 'zoneData', location);
  setAppCtx({ ...newAppCtx });
};

const changeCartCount = ({ appCtx, setAppCtx }) => (count) => {
  const userCookie = CookieStorage.getUserDataCookieName();
  const dynamicDataCookie = CookieStorage.getCookie(userCookie).split('*');
  dynamicDataCookie[1] = `${count}`;
  const userDataCookieValue = dynamicDataCookie.join('*');

  CookieStorage.setCookie({
    name: userCookie,
    value: userDataCookieValue,
    regionCode: appCtx.regionCode,
    expiryDays: 356
  });

  setAppCtx({
    ...appCtx,
    USER_DATA_COOKIE: userDataCookieValue
  });
};

const attachResizeListener = ({ appCtx, setAppCtx }) => () => {
  const onResize = () => {
    let newDeviceType = 'desktop';

    if (isMobile()) {
      newDeviceType = 'mobile';
    } else if (isIpadPotrait()) {
      newDeviceType = 'tablet';
    } else {
      newDeviceType = 'desktop';
    }

    if (newDeviceType !== appCtx.deviceType) {
      setAppCtx({
        ...appCtx,
        deviceType: newDeviceType
      });
    }
  };

  if (isWindow()) {
    onResize();
    window.onresize = onResize;
  }
};

function useApplicationContext({ appCtx: initialAppCtx }) {
  const [appCtx, setAppCtx] = useState(initialAppCtx);

  return {
    appCtx: {
      ...appCtx,
      logoutUser: logoutUser({ appCtx, setAppCtx }),
      setBusy: setBusy({ appCtx, setAppCtx }),
      // logInUser: logInUser({ appCtx, setAppCtx }),
      setLocation: setLocation({ appCtx, setAppCtx }),
      changeCartCount: changeCartCount({ appCtx, setAppCtx }),
      reloadOnZoneChange: reloadOnZoneChange({ appCtx, setAppCtx }),
      attachResizeListener: attachResizeListener({ appCtx, setAppCtx })
    },
    setAppCtx
  };
}

const ApplicationContext = createContainer(useApplicationContext);

const withApplicationContext = (WrappedComponent) => {
  const { getInitialProps } = WrappedComponent;
  const ComponentWithAppCtx = (props) => {
    const { appCtx, setAppCtx } = useContext(ApplicationContext.Context);
    const appCtxWithState = {
      ...appCtx,
      setAppCtx
    };
    return <WrappedComponent {...props} appCtx={appCtxWithState} />;
  };
  if (typeof getInitialProps === 'function') {
    ComponentWithAppCtx.getInitialProps = getInitialProps;
  }
  ComponentWithAppCtx.originalName = nameHOC(WrappedComponent);
  ComponentWithAppCtx.displayName = nameHOC(WrappedComponent, 'WithAppContext');

  return ComponentWithAppCtx;
};

export default ApplicationContext;
export { withApplicationContext };
