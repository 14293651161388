import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { loaderStyle } from './Loader.style';
import Spinner from '../ui/Spinner/Spinner';
import MarketplaceSpinner from '../ui/Spinner/MarketplaceSpinner';
import { withApplicationContext } from '../../utils/ApplicationContext';

const Loader = ({ appCtx, size }) => {
  const { isRebrandingEnabled } = appCtx || {};
  return (
    <div className="loader">
      {isRebrandingEnabled ? (
        <MarketplaceSpinner size={size} />
      ) : (
        <Spinner size={size} />
      )}
      <style jsx>{loaderStyle}</style>
    </div>
  );
};

const withLoader = (WrappedComponent) => {
  const { getInitialProps } = WrappedComponent;
  const ComponentWithLoader = (props) => {
    const [busy, setBusy] = useState(false);

    return (
      <Fragment>
        {busy && <Loader />}
        <WrappedComponent {...props} setBusy={setBusy} />
      </Fragment>
    );
  };
  if (typeof getInitialProps === 'function') {
    ComponentWithLoader.getInitialProps = getInitialProps;
  }
  return ComponentWithLoader;
};

Loader.defaultProps = {
  size: 'medium'
};

Loader.propTypes = {
  appCtx: PropTypes.object.isRequired,
  size: PropTypes.string
};

export default withApplicationContext(Loader);

export { withLoader };
