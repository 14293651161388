import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';

const ratingsNumericStyle = css`
  .ratingsNumeric {
    display: flex;
    align-items: center;
    color: ${colors.black.shade3};
    .count {
      font-size: ${typography.base};
      margin-left: 2px;
    }
    .maximumRatings {
      display: inline-flex;
      padding-left: 2px;
    }
  }
`;

export default ratingsNumericStyle;
