import React from 'react';
import PropTypes from 'prop-types';
import VariantAvailabilityOptions from '../../VariantAvailabilityOptions/VariantAvailabilityOptions';
import Button from '../../ui/Button/Button';
import VariantHeaderMobile from '../../VariantHeader/VariantHeaderMobile';
import {
  TypeCProductMobileStyles,
  TypeCMobileCartStyles
} from './TypeCProductMobile.style';
import noop from '../../../utils/noop';
import constants from '../../../config/constants';
import { withLabels } from '../../../utils/LabelsContext';
import ProductColorSwatches from '../../ProductColorSwatches/ProductColorSwatches';
import Size from '../../Size/Size';
import BodyCopy from '../../ui/BodyCopy/BodyCopy';
import Image from '../../ui/Image/Image';
import Prices from '../../Prices/Prices';
import Warning from '../../Warning/Warning';
import { LinkLabel } from '../../ui/LinkButton/LinkButton';
import _ from '../../../utils/LodashImports';

const TypeCProductMobile = ({
  brandName,
  variantName,
  prices,
  variantId,
  productId,
  labels,
  colorVariants,
  selectedColorSwatchIndex,
  colorSwatchHandler,
  sizes,
  selectedSize,
  sizeChangeHandler,
  addToCartLabel,
  isAddToCartDisabled,
  variant,
  handleAddToCart,
  errorAddingToCart,
  variantUrl,
  isHomeDeliveryAvailable,
  currentVariant,
  isPickFromStoreAvailable,
  isRebrandingEnabled,
  appCtx,
  colorMetrics
}) => {
  const isForCart = variant === 'cart';
  const firstImage = _.get(currentVariant, 'medias[0].url', undefined);
  const renderVariantImage = () => (
    <LinkLabel type="default" href={variantUrl}>
      <Image
        id={variantId}
        alt=""
        width={150}
        height={150}
        lazyLoad={false}
        inlineDimensions={false}
        imageUrl={firstImage}
      />
    </LinkLabel>
  );

  const renderNormalView = () => (
    <div className={`typeCProduct ${isRebrandingEnabled ? 'mkp' : ''}`}>
      <div>
        <div className="variant-image">{renderVariantImage()}</div>
        {!isForCart && (
          <div className="availability-options mobile">
            <VariantAvailabilityOptions
              deviceType="mobile"
              isHomeDeliveryAvailable={isHomeDeliveryAvailable}
              isPickFromStoreAvailable={isPickFromStoreAvailable}
              isRebrandingEnabled={isRebrandingEnabled}
            />
          </div>
        )}
      </div>
      <div className="typeCProduct-variants">
        <VariantHeaderMobile
          variant={variant}
          variantId={variantId}
          brandName={brandName}
          variantName={variantName}
          prices={prices}
          layout="typeC"
          variantUrl={variantUrl}
          isRebrandingEnabled={isRebrandingEnabled}
        />
        <div
          className={`typeCProduct-variants-select${
            isRebrandingEnabled ? ' mkp' : ''
          }`}
        >
          {colorVariants[selectedColorSwatchIndex] && (
            <div className="color-swatch-container fa--color-swatches-type-c__mobile">
              <div className="colorName">
                <span className="colorLabel">{`${labels.COLOR}: `}</span>
                {colorVariants[selectedColorSwatchIndex].label}
              </div>
              <ProductColorSwatches
                colorVariants={colorVariants}
                selectedColorSwatchIndex={selectedColorSwatchIndex}
                colorSwatchHandler={colorSwatchHandler}
                type={constants.VIEW_TEMPLATE.TYPE_C}
                size="medium"
                productId={productId}
                appCtx={appCtx}
              />
            </div>
          )}
          {sizes.length > 0 && (
            <Size
              title={labels.SELECTION_SIZE}
              sizes={sizes}
              selectedSize={selectedSize}
              onSizeChange={sizeChangeHandler}
              type="dropdown"
            />
          )}
          <div
            className={`addToCart-button ${isRebrandingEnabled ? 'mkp' : ''}`}
          >
            <Button
              size={isRebrandingEnabled ? 'medium-extend' : 'medium'}
              type={isRebrandingEnabled ? 'mkp-secondary' : 'secondary'}
              onClick={handleAddToCart}
              disabled={isAddToCartDisabled}
            >
              <span className="addToCart-button-text">{addToCartLabel}</span>
            </Button>
            {errorAddingToCart && (
              <Warning
                label={errorAddingToCart}
                iconSize="copy10"
                labelSize="copy3"
                forPDP
              />
            )}
          </div>
        </div>
      </div>
      <style jsx>{TypeCProductMobileStyles}</style>
    </div>
  );
  const renderCartView = () => (
    <div className={`typeCProduct cart ${isRebrandingEnabled ? 'mkp' : ''}`}>
      <div className="variant-image">{renderVariantImage()}</div>
      <div className="variant-details">
        <BodyCopy size="primary" type="title18">
          <span className="brandName">{brandName.toUpperCase()}</span>
        </BodyCopy>

        <BodyCopy type="title20" size="primary">
          <LinkLabel type="default" href={variantUrl}>
            <span className="variantName">{variantName}</span>
          </LinkLabel>
        </BodyCopy>

        <Prices
          priceList={prices}
          deviceType="desktop"
          page={!isForCart && 'PDP'}
          priceCount={prices.length}
          variant="TYPE_C"
        />

        {colorVariants[selectedColorSwatchIndex] && (
          <div className="color-swatch-container">
            <div className="colorName">
              <span className="colorLabel">{`${labels.COLOR}: `}</span>
              {colorVariants[selectedColorSwatchIndex].label}
            </div>
            <ProductColorSwatches
              colorVariants={colorVariants}
              selectedColorSwatchIndex={selectedColorSwatchIndex}
              colorSwatchHandler={colorSwatchHandler}
              type={constants.VIEW_TEMPLATE.TYPE_C}
              size="medium"
              productId={productId}
              appCtx={appCtx}
              colorMetrics={colorMetrics}
            />
          </div>
        )}

        <div className="dropdown">
          {sizes.length > 0 && (
            <Size
              title={labels.SELECTION_SIZE}
              sizes={sizes}
              selectedSize={selectedSize}
              onSizeChange={sizeChangeHandler}
              type="dropdown"
            />
          )}
        </div>

        <div className={`addToCart-button ${isRebrandingEnabled ? 'mkp' : ''}`}>
          <Button
            size="small"
            type={isRebrandingEnabled ? 'mkp-secondary' : 'secondary'}
            onClick={handleAddToCart}
            disabled={isAddToCartDisabled}
            strech={isRebrandingEnabled && !isAddToCartDisabled ? `auto` : ''}
          >
            <span>{addToCartLabel}</span>
          </Button>
        </div>
      </div>

      <style jsx>{TypeCMobileCartStyles}</style>
    </div>
  );

  return isForCart ? renderCartView() : renderNormalView();
};

TypeCProductMobile.defaultProps = {
  brandName: '',
  variantName: '',
  prices: [],
  variantId: '',
  productId: '',
  colorVariants: [],
  selectedColorSwatchIndex: 0,
  colorSwatchHandler: noop,
  sizes: [],
  selectedSize: '',
  sizeChangeHandler: noop,
  addToCartLabel: '',
  isAddToCartDisabled: false,
  variant: '',
  currentVariant: {},
  variantUrl: '',
  isHomeDeliveryAvailable: false,
  isPickFromStoreAvailable: false,
  isRebrandingEnabled: false
};

TypeCProductMobile.propTypes = {
  labels: PropTypes.object.isRequired,
  currentVariantL: PropTypes.object,
  brandName: PropTypes.string,
  variantName: PropTypes.string,
  prices: PropTypes.arrayOf(PropTypes.object),
  variantId: PropTypes.string,
  isHomeDeliveryAvailable: PropTypes.bool,
  isPickFromStoreAvailable: PropTypes.bool,
  productId: PropTypes.string,
  colorVariants: PropTypes.arrayOf(PropTypes.object),
  selectedColorSwatchIndex: PropTypes.number,
  colorSwatchHandler: PropTypes.func,
  sizes: PropTypes.arrayOf(PropTypes.object),
  selectedSize: PropTypes.string,
  sizeChangeHandler: PropTypes.func,
  addToCartLabel: PropTypes.string,
  isAddToCartDisabled: PropTypes.bool,
  variant: PropTypes.string,
  errorAddingToCart: PropTypes.PropTypes.isRequired,
  variantUrl: PropTypes.string,
  isRebrandingEnabled: PropTypes.bool,
  appCtx: PropTypes.object.isRequired,
  colorMetrics: PropTypes.object.isRequired
};

export default withLabels(TypeCProductMobile);
