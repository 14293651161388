import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import { OperatorStyles } from './Operator.style';
import { withLabels } from '../../utils/LabelsContext';
import noop from '../../utils/noop';

const Operator = ({
  operators,
  labels,
  operatorChangeHandler,
  isDesktop,
  hideNotifications,
  hasNoMargin,
  isConnectProduct,
  selectedOperatorId,
  isRebrandingEnabled,
  theme
}) => {
  const themedStyles = OperatorStyles(theme);
  const [selectedOperator, setSelectedOperatorId] = useState(
    selectedOperatorId
  );
  const handleOperatorSelection = (operator, id, available) => {
    if (id === selectedOperator) {
      setSelectedOperatorId(null);
      operatorChangeHandler(null);
    } else {
      if (!available) return;
      setSelectedOperatorId(id);
      operatorChangeHandler(operator);
    }
  };

  useEffect(() => {
    setSelectedOperatorId(selectedOperatorId);
  }, [selectedOperatorId]);

  const classes = isDesktop
    ? `container fa--operator__desktop operator-container ${
        isRebrandingEnabled ? 'mkp' : ''
      }`
    : `container fa--operator__mobile operator-container ${
        isRebrandingEnabled ? 'mkp' : ''
      }`;

  return (
    <div className={classes} id="testId-Operator-container">
      <p className={`selection-message ${!hasNoMargin ? 'with-margin' : ''}`}>
        {labels.SELECT_OPERATOR_LABEL}
      </p>
      {!hideNotifications && (
        <span className="price-alert">
          <i
            className={`csicon-alert ${
              isRebrandingEnabled ? 'rebrand-alert' : ''
            }`}
          />
          <p>{labels.SELECT_OPERATOR_ALERT_LABEL}</p>
        </span>
      )}
      <div className={`operators ${!hasNoMargin ? 'with-margin' : ''}`}>
        {operators.map(({ name, id, available }) => {
          return (
            <button
              key={id}
              className={`operator ${isRebrandingEnabled ? 'mkp' : ''} ${
                id === selectedOperator ? 'selected-operator' : ''
              } ${!available ? 'operator-unavailable' : ''} ${
                isConnectProduct && isRebrandingEnabled
                  ? 'rebranding-connect'
                  : ''
              }`}
              type="button"
              onClick={handleOperatorSelection.bind(null, name, id, available)}
              id={`testId-Operator-${name}`}
            >
              {name}
            </button>
          );
        })}
      </div>
      <style jsx>{themedStyles}</style>
    </div>
  );
};

Operator.defaultProps = {
  operators: [],
  labels: {},
  operatorChangeHandler: noop,
  isDesktop: false,
  hideNotifications: false,
  isConnectProduct: false,
  hasNoMargin: false,
  selectedOperatorId: undefined,
  isRebrandingEnabled: false
};

Operator.propTypes = {
  operators: PropTypes.arrayOf(PropTypes.object),
  labels: PropTypes.object,
  operatorChangeHandler: PropTypes.func,
  isDesktop: PropTypes.bool,
  isConnectProduct: PropTypes.bool,
  hideNotifications: PropTypes.bool,
  hasNoMargin: PropTypes.bool,
  selectedOperatorId: PropTypes.string,
  isRebrandingEnabled: PropTypes.bool,
  theme: PropTypes.object.isRequired
};

export { Operator };
export default withTheme(withLabels(Operator));
