import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs } from '@digital-retail/falabella-ui-cross-components';
import HardLineStyle from './HardLineMobile.style';
import ImageGallery from '../../../components/ImageGallery/ImageGallery';
import { withProduct } from '../../../utils/ProductContext';
import { withLabels } from '../../../utils/LabelsContext';
import PDPBasicInfoMobile from './containers/PDPBasicInfoMobile/PDPBasicInfoMobileHardline';
import ProductSpecifications from '../../ProdctSpecifications/ProductSpecifications';
import ProductOutOfStock from '../../../components/ProductOutOfStock/ProductOutOfStock';
import ProductSpecificationConnect from '../../../components/ProductSpecificationConnect/ProductSpecificationConnect';
import ConnectTab from '../ConnectTab/ConnectTab';
import ProductTopSpecifications from '../../../components/ProductTopSpecifications/ProductTopSpecifications';
import * as productUtils from '../../../utils/product/productUtils';
import Helpline from '../../Helpline/HelpLine';
import { withUser } from '../../../utils/UserContext';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import ComboItemsMobile from '../../../components/ComboSection/ComboItemsMobile';
import _ from '../../../utils/LodashImports';
import { getVariant } from '../../../utils/variant';
import { RatingAndReviews } from '../../../external_dependencies/ratingAndReviews/RatingAndReviews';
import CMRApertura from '../../../components/CMRApertura/CMRApertura';
import constants from '../../../config/constants';
import SellerRatings from '../../../components/SellerRatings/SellerRatings';

const PDPMobile = ({ labels, product, isVendorLogin, appCtx }) => {
  const {
    isOutOfStock,
    breadCrumb,
    name: productName,
    variants,
    currentVariant: currentVariantId,
    cmrBannerActive = true
  } = product;
  const isSoCom = appCtx.store === constants.STORES.so_com;
  const showCMRApertura = isSoCom
    ? _.get(appCtx, 'siteConfig.toggles.isCMRAperturaEnabledForSoCom', false)
    : _.get(appCtx, 'siteConfig.toggles.isCMRAperturaEnabled', false);

  const isSellerRatingsEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isSellerRatingsEnabled',
    true
  );

  const currentVariant = getVariant(variants, currentVariantId);
  const comboData = _.get(currentVariant, 'comboData', null);
  const { prices, cmrSavings } = currentVariant;

  const isConnectProduct = productUtils.isConnectProduct(product);
  const tabData = [
    {
      title: () => labels.TEAM,
      content: () => <ProductSpecifications />
    },
    {
      title: () => labels.PLANES,
      content: () => <ProductSpecificationConnect />
    }
  ];
  const renderAgentLoginView = () => {
    const connectTabData = [
      {
        title: (flag) => (
          <div className="flex">
            {!flag ? (
              <span className="icon internet-icon-active" />
            ) : (
              <span className="icon internet-icon-disabled" />
            )}
            <p>INTERNET</p>
            <style jsx>{HardLineStyle}</style>
          </div>
        ),
        content: () => <ConnectTab variant="secondary" tabData={tabData} />
      },
      {
        title: (flag) => (
          <div className="flex">
            {!flag ? (
              <span className="icon tienda-active" />
            ) : (
              <span className="icon tienda-disabled" />
            )}
            <p>TIENDA</p>
            <style jsx>{HardLineStyle}</style>
          </div>
        ),
        content: () => <ConnectTab variant="secondary" tabData={tabData} />
      }
    ];
    const normalTabData = [
      {
        title: (flag) => (
          <div className="flex">
            {!flag ? (
              <span className="icon internet-icon-active" />
            ) : (
              <span className="icon internet-icon-disabled" />
            )}
            <p>INTERNET</p>
            <style jsx>{HardLineStyle}</style>
          </div>
        ),
        content: () => <ProductSpecifications />
      },
      {
        title: (flag) => (
          <div className="flex">
            {!flag ? (
              <span className="icon tienda-active" />
            ) : (
              <span className="icon tienda-disabled" />
            )}
            <p>TIENDA</p>
            <style jsx>{HardLineStyle}</style>
          </div>
        ),
        content: () => <ProductSpecifications currentTab="Tienda" />
      }
    ];
    return (
      <div>
        {isConnectProduct ? (
          <Fragment>
            <ProductTopSpecifications scollToElementId="productInfoContainer" />
            <ConnectTab tabData={connectTabData} />
          </Fragment>
        ) : (
          <ConnectTab tabData={normalTabData} />
        )}
      </div>
    );
  };
  const renderProductSpecifications = () => {
    return (
      <div>
        {isConnectProduct ? (
          <Fragment>
            <ProductTopSpecifications
              isRebrandingEnabled={appCtx.isRebrandingEnabled}
              scollToElementId="productInfoContainer"
            />
            <ConnectTab
              variant={appCtx.isRebrandingEnabled ? 'rebranding' : 'primary'}
              tabData={tabData}
            />
          </Fragment>
        ) : (
          <ProductSpecifications />
        )}
      </div>
    );
  };
  const productSpecifications = () => {
    if (isOutOfStock) return <ProductOutOfStock />;
    if (!isVendorLogin) return renderProductSpecifications();
    if (isVendorLogin) return renderAgentLoginView();
    return null;
  };

  if (isOutOfStock) {
    return (
      <Fragment>
        <div className="pdp-body">
          <div className="pdp-body-container">
            <Breadcrumbs
              items={breadCrumb}
              productName={productName}
              isPdpPage
              tenant={appCtx.regionCode}
              host={appCtx.host}
              path={appCtx.url}
            />
            <div className="pdp-container out-of-stock">
              <Fragment>
                <section className="pdp-detail-section oos-wrapper">
                  <ProductOutOfStock />
                </section>
                <div className="headline-image-and-details">
                  <section className="pdp-image-section">
                    {/* modify this component for PDP image view */}
                    <ImageGallery />
                  </section>
                  <section className="pdp-detail-section">
                    <PDPBasicInfoMobile product={product} labels={labels} />
                    {renderProductSpecifications()}
                  </section>
                </div>
                <div className="rating_summary-wrapper">
                  <RatingAndReviews />
                </div>
                {comboData && (
                  <div className="combo-wrapper">
                    <ComboItemsMobile comboData={comboData} />
                  </div>
                )}
              </Fragment>
            </div>
          </div>
        </div>
        <style jsx>{HardLineStyle}</style>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className="pdp-body">
        <div className="pdp-body-container">
          <Breadcrumbs
            items={breadCrumb}
            productName={productName}
            isPdpPage
            tenant={appCtx.regionCode}
            host={appCtx.host}
            path={appCtx.url}
          />
          <div className="pdp-container">
            <Fragment>
              <section className="pdp-detail-section">
                <PDPBasicInfoMobile product={product} labels={labels} />
              </section>
              <section className="pdp-image-section">
                {/* modify this component for PDP image view */}
                <ImageGallery />
              </section>
              <section className="pdp-detail-section">
                {productSpecifications()}
              </section>
              <Helpline product={product} />
              {showCMRApertura && cmrBannerActive && (
                <CMRApertura prices={prices} cmrSavings={cmrSavings} />
              )}
              {isSellerRatingsEnabled && product.sellerInfo?.rating && (
                <SellerRatings sellerInfo={product.sellerInfo} />
              )}
            </Fragment>
          </div>
        </div>
      </div>
      <style jsx>{HardLineStyle}</style>
    </Fragment>
  );
};

PDPMobile.propTypes = {
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  appCtx: PropTypes.object,
  isVendorLogin: PropTypes.object.isRequired
};

PDPMobile.defaultProps = {
  appCtx: {}
};

export default withUser(
  withProduct(withLabels(withApplicationContext(PDPMobile)))
);
export { PDPMobile };
