import { useEffect, useState } from 'react';
import constants from '../../config/constants';
import { isWindow } from '../../utils/deviceChecker';

const useMyListsMetrics = () => {
  const {
    WITHOUT_ML,
    SAVE_IN_L2,
    SAVE_IN_L3,
    SAVE_IN_L4,
    SAVE_IN_L5,
    SAVE_IN_MYFAVS,
    NOT_ADDED,
    NOT_CLICK_ML,
    NOT_CLICK_CREATE_LIST,
    NOT_SAVE_NEW_LIST,
    DO_NOT_SAVE_MYFAVS,
    NOT_SAVE_IN_L2,
    NOT_SAVE_IN_L3,
    NOT_SAVE_IN_L4,
    NOT_SAVE_IN_L5
  } = constants.ML_METRICS.METRICS_DATA;

  const [product, setProduct] = useState({});
  const [isWithMyLists, setIsWithMyLists] = useState(WITHOUT_ML);
  const [isAlreadyAdded, setIsAlreadyAdded] = useState(NOT_ADDED);
  const [isModalClicked, setIsModalClicked] = useState(NOT_CLICK_ML);
  const [isSavedNewList, setIsSavedNewList] = useState(NOT_SAVE_NEW_LIST);
  const [isCreateListClicked, setIsCreateListClicked] = useState(
    NOT_CLICK_CREATE_LIST
  );
  const [isInMyFavs, setIsInMyFavs] = useState(DO_NOT_SAVE_MYFAVS);
  const [isInL2, setIsInL2] = useState(NOT_SAVE_IN_L2);
  const [isInL3, setIsInL3] = useState(NOT_SAVE_IN_L3);
  const [isInL4, setIsInL4] = useState(NOT_SAVE_IN_L4);
  const [isInL5, setIsInL5] = useState(NOT_SAVE_IN_L5);
  const [isEvaluated, setIsEvaluated] = useState(false);

  const handleMyListsMetrics = (myListsData) => {
    if (isWindow()) {
      const eventToDigitalData = new CustomEvent('DigitalDataMyListsMetrics', {
        bubbles: true,
        detail: {
          payload: {
            myListsData
          }
        }
      });
      window.dispatchEvent(eventToDigitalData);
      setProduct({});
      setIsWithMyLists(WITHOUT_ML);
      setIsAlreadyAdded(NOT_ADDED);
      setIsModalClicked(NOT_CLICK_ML);
      setIsSavedNewList(NOT_SAVE_NEW_LIST);
      setIsCreateListClicked(NOT_CLICK_CREATE_LIST);
      setIsInMyFavs(DO_NOT_SAVE_MYFAVS);
      setIsInL2(NOT_SAVE_IN_L2);
      setIsInL3(NOT_SAVE_IN_L3);
      setIsInL4(NOT_SAVE_IN_L4);
      setIsInL5(NOT_SAVE_IN_L5);
      setIsEvaluated(false);
    }
  };

  const handleUnload = () => {
    const { id } = product;
    const myListsData = `${id}|${isWithMyLists}|${isAlreadyAdded}|${isModalClicked}|${isInMyFavs}|${isInL2}|${isInL3}|${isInL4}|${isInL5}|${isCreateListClicked}|${isSavedNewList}`;
    handleMyListsMetrics(myListsData);
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      handleUnload();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [
    product,
    isWithMyLists,
    isAlreadyAdded,
    isModalClicked,
    isInMyFavs,
    isInL2,
    isInL3,
    isInL4,
    isInL5,
    isCreateListClicked,
    isSavedNewList
  ]);

  const setProdInLists = (index) => {
    const listPosition = index + 1;
    if (listPosition === 2) {
      setIsInL2(SAVE_IN_L2);
    } else if (listPosition === 3) {
      setIsInL3(SAVE_IN_L3);
    } else if (listPosition === 4) {
      setIsInL4(SAVE_IN_L4);
    } else if (listPosition === 5) {
      setIsInL5(SAVE_IN_L5);
    }
  };

  const getListTypeSaved = (response, myListData) => {
    response.forEach(({ data }) => {
      if (data.data.list) {
        const { list } = data.data;

        const foundIndex = myListData.findIndex(
          (l) => l.tagName === list.tagName
        );
        if (list.type === 'MY_FAVORITES') {
          setIsInMyFavs(SAVE_IN_MYFAVS);
        } else if (list.type === 'WISH_LIST') {
          if (foundIndex !== -1) {
            setProdInLists(foundIndex);
          }
        } else {
          setIsInMyFavs(DO_NOT_SAVE_MYFAVS);
        }
      }
    });
  };

  return {
    setProduct,
    setIsWithMyLists,
    setIsAlreadyAdded,
    setIsModalClicked,
    setIsCreateListClicked,
    setIsSavedNewList,
    setIsInMyFavs,
    setIsInL2,
    setIsInL3,
    setIsInL4,
    setIsInL5,
    setIsEvaluated,
    getListTypeSaved,
    product,
    isWithMyLists,
    isAlreadyAdded,
    isModalClicked,
    isSavedNewList,
    isCreateListClicked,
    isInMyFavs,
    isInL2,
    isInL3,
    isInL4,
    isInL5,
    isEvaluated
  };
};

export default useMyListsMetrics;
