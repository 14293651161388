import { useEffect } from 'react';
import useIntersection from './useIntersection';

const useLazyLoadImage = ({ productRef, setLoadImage }) => {
  const isSlightlyInView = useIntersection(productRef, '0px', '0.1', false);
  useEffect(() => {
    if (isSlightlyInView) {
      setLoadImage(true);
    }
  }, [isSlightlyInView]);
};

export default useLazyLoadImage;
