/* stylelint-disable no-descending-specificity */
import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';

const BundlePodStyles = css`
  .bundle-pod {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1 1 auto;
    flex-basis: 0;
    @mixin tabletToDesktop {
      flex-direction: row;
      max-width: 294px;
    }

    .bundle {
      display: flex;
    }

    .image-wrapper {
      width: 75px;
      height: 75px;
      font-size: ${typography.xxs2};
      border: 1.2px solid #ededed;
      @mixin tabletToDesktop {
        width: 46px;
        height: 46px;
      }
    }
    div.anchor-div {
      cursor: pointer;
      color: inherit;
    }
    .bundle-pod-body {
      display: flex;
      flex-direction: column;
      padding: 0 21px 0 10px;
      @mixin tabletToDesktop {
        padding: 0 10px;
      }
    }
    .selection-box {
      width: 21px;
      margin: 25px 18px 27px 0;
      @mixin tabletToDesktop {
        margin: 5px 0 0 0;
      }
      &.fade {
        @mixin mobileToTablet {
          opacity: 0.6;
        }
      }
    }
    .bundle-pod-head {
      display: flex;
      flex-direction: row-reverse;
      align-items: start;
      margin-right: 15px;
      @mixin tabletToDesktop {
        flex-direction: column;
        margin-right: 0;
        align-items: center;
      }
    }
    .current-viewing {
      margin-top: 5px;
      font-size: ${typography.xs2};
      font-weight: bold;
      @mixin tabletToDesktop {
        margin-top: 15px;
      }
    }
    .product-name {
      font-size: ${typography.base};
      padding: 0 0 10px;
      white-space: nowrap;
      width: 99%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      @mixin tabletToDesktop {
        width: 140px;
      }
      @mixin desktop {
        width: 160px;
      }
    }
    &::after {
      color: #888;
      content: '+';
      display: block;
      margin: 20px 0;
      width: 50px;
      height: 30px;
      font-size: ${typography.xl4};
      text-align: center;
      position: relative;
      left: 50px;
      @mixin tabletToDesktop {
        left: 0;
      }
    }
    &:last-child::after {
      opacity: 0;
      margin: 0;
      display: none;
    }
  }
  .price.cart {
    margin-top: 5px;
  }

  .cart.bundle-pod {
    .bundle-pod-body {
      padding-right: 0;
    }
    .bundle-pod-body:last-child {
      height: auto;
    }
    @mixin desktop {
      .bundle-pod-body::after {
        width: 24px;
      }
    }
    :global(.description) {
      display: block;
      line-height: 19px;
    }
  }
  .recommendation {
    .bundle {
      background: ${colors.gray.shade32};
      padding: 11px 10px 6px 10px;
      width: 100%;
      @mixin desktop {
        min-height: 155px;
        min-width: 320px;
      }
      @mixin mobileToTablet {
        padding: 6px 9px;
      }
    }
    &.bundle-pod {
      width: 100%;
      max-width: 320px;
      .selection-box {
        margin: 25px 18px 0 0;
      }
      @mixin mobileToTablet {
        max-width: 100%;
      }
      &::after {
        color: #888;
        content: '+';
        display: block;
        margin: 20px 0;
        width: 50px;
        height: 30px;
        font-size: 30px;
        text-align: center;
        position: relative;
        left: 50px;
        @mixin tabletToDesktop {
          left: 35px;
          top: 22%;
        }
        @mixin mobileToTablet {
          left: 60px;
          top: 2px;
          height: 38px;
          margin: 4.5px 0;
        }
      }
    }
    &.bundle-pod-single {
      width: 100%;
      max-width: 320px;
      @mixin mobileToTablet {
        max-width: 100%;
      }
      @mixin tabletToDesktop {
        margin-right: 86px;
      }
      &::after {
        color: #888;
        content: '+';
        display: block;
        margin: 20px 0;
        width: 50px;
        height: 30px;
        font-size: 30px;
        text-align: center;
        position: relative;
        left: 50px;
        @mixin tabletToDesktop {
          left: 35px;
          top: 54%;
        }
        @mixin mobileToTablet {
          left: 22px;
          top: 2px;
          height: 38px;
          margin: 4.5px 0;
        }
      }
    }
    .selected-product {
      border: 1px solid ${colors.gray.shade36};
      background: none;
    }
    .brand-name {
      line-height: 14.4px;
      letter-spacing: 0.11px;
      color: ${colors.gray.shade44};
      font-size: ${typography.sm};
      font-weight: 700;
      @mixin mobileToTablet {
        font-size: ${typography.sm1};
        line-height: 15.6px;
        letter-spacing: 0.95px;
        color: ${colors.gray.shade19};
      }
    }
    .bundle-pod-body {
      padding-right: 0;
      width: 100%;
      div.anchor-div {
        height: 100%;
      }
    }
    .product-name {
      font-size: ${typography.base};
      color: ${colors.gray.shade40};
      line-height: 16.8px;
      letter-spacing: 0.12px;
      font-weight: 700;
      @mixin mobileToTablet {
        font-weight: 400;
        font-size: ${typography.lg1};
        line-height: 19.2px;
        letter-spacing: 0.3px;
        color: ${colors.black.shade1};
        width: 185px;
      }
    }
    .image-wrapper {
      border: none;
      width: 96px;
      height: 96px;
      @mixin mobileToTablet {
        width: 75px;
        height: 75px;
      }
    }
    .rebranding-current {
      font-weight: 400;
      font-size: ${typography.sm};
      color: ${colors.gray.shade53};
      line-height: 14px;
      text-align: right;
      margin-right: 7px;
    }
    .reco-checkbox {
      display: flex;
      align-items: end;
      justify-content: end;

      .checkbox {
        width: min-content;
        height: 20px;
      }
    }
  }
  .cart.bundle-pod:last-child {
    min-height: 90px;
  }
`;

export default BundlePodStyles;
