import React from 'react';
import PropTypes from 'prop-types';
import SingleChipletSelect from '../ui/SingleChipletSelect/SingleChipletSelect';
import { withDigitalData } from '../../utils/DigitalDataContext';

const ProductServices = ({
  serviceOptions,
  setServiceOption,
  label,
  digitalData
}) => {
  const handleServiceChange = (option) => {
    setServiceOption(option);
  };

  if (serviceOptions.services && serviceOptions.services.length) {
    digitalData.pdp.setAdditionalServicesAvailableQty(
      serviceOptions.services.length
    );
    return (
      <div className="fa--additional-services">
        <SingleChipletSelect
          options={serviceOptions.services}
          label={label}
          onOptionSelected={handleServiceChange}
          secondaryLabelIcon="csicon-alert"
          icon="csicon-preferences"
          shouldHaveBackground={false}
          type="medium"
          withPadding={false}
          forPDP
          highLightHeading
          clickableChiplet
          serviceType="additional-service"
          hideSubtitle
        />
      </div>
    );
  }
  return null;
};

ProductServices.defaultProps = {
  setServiceOption: () => {},
  serviceOptions: {
    services: [],
    header: ''
  },
  label: 'Selecciona servicios adicionales'
};

ProductServices.propTypes = {
  serviceOptions: PropTypes.object,
  setServiceOption: PropTypes.func,
  label: PropTypes.string,
  digitalData: PropTypes.object.isRequired
};

export default withDigitalData(ProductServices);
