import React from 'react';
import PropTypes from 'prop-types';
import { CountTextStyles } from './CountText.style';
import { useProductCounterContext } from '../ProductCounterContext';

const CountText = ({ className, type }) => {
  const { count } = useProductCounterContext();

  return (
    <div className={`product-count-value value-${type} ${className}`}>
      {count}

      <style jsx>{CountTextStyles}</style>
    </div>
  );
};

CountText.defaultProps = {
  className: '',
  type: 'primary'
};

CountText.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary'])
};

export { CountText };
