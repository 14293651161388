import _ from '../LodashImports';

const getUniqueOperators = (variants = [], currentColorName) => {
  const operators = {};
  variants.forEach((variant) => {
    const operator = _.get(variant, 'attributes.connectOperator', undefined);
    const colorName = _.get(variant, 'attributes.colorName', undefined);
    if (operator && currentColorName === colorName) {
      operators[operator] = {
        name: operator,
        id: variant.id,
        available: variant.isPurchaseable
      };
    }
  });
  return Object.values(operators);
};

export { getUniqueOperators };
