import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import { ComparativeTableTabStyles } from './ComparativeTableTab.style';
import noop from '../../../utils/noop';
import _ from '../../../utils/LodashImports';
import Carousel from '../../ui/Carousel/Carousel';
import Table from '../Table';

const ComparativeTableTab = ({
  deviceType,
  setHeadingRawTabSelected,
  appCtx,
  carouselPosition,
  heading,
  similarProducts,
  sameSellerProducts,
  attrRowsData,
  theme
}) => {
  const themedStyles = ComparativeTableTabStyles(theme);
  const [activeTab, setActiveTab] = useState(0);

  const tabsData = [
    {
      heading: 'Productos similares'
    },
    {
      heading: 'Productos del mismo vendedor'
    }
  ];

  const onToggleTab = (i) => {
    setActiveTab(i);
    setHeadingRawTabSelected({ index: i, text: tabsData[i].heading });
  };
  const isComparativeTableSameSellerEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isComparativeTableSameSellerEnabled',
    false
  );

  if (!isComparativeTableSameSellerEnabled) {
    const i = 0;
    return (
      <div className={`raw-tab ${deviceType} just-first-tab-enabled`}>
        <ul>
          <li key={`raw-tab-${i}`} className="active">
            <button
              className={classNames('tab-button', {
                'tab-active': activeTab === i
              })}
              type="button"
            >
              {heading}
            </button>
          </li>
        </ul>
        <div className="raw-tab-content">
          <Table
            heading={heading}
            mainInfoRow={similarProducts}
            attrRowsData={attrRowsData}
            deviceType={deviceType}
          />
        </div>

        <style jsx>{themedStyles}</style>
      </div>
    );
  }

  return (
    <div className={`raw-tab ${deviceType}`}>
      <ul>
        <li key={`raw-tab-${0}`} className={activeTab === 0 ? 'active' : ''}>
          <button
            className={classNames('tab-button', {
              'tab-active': activeTab === 0
            })}
            type="button"
            onClick={() => onToggleTab(0)}
          >
            {tabsData[0].heading}
          </button>
        </li>
        <li key={`raw-tab-${1}`} className={activeTab === 1 ? 'active' : ''}>
          <button
            className={classNames('tab-button', {
              'tab-active': activeTab === 1
            })}
            type="button"
            onClick={() => onToggleTab(1)}
          >
            {tabsData[1].heading}
          </button>
        </li>
      </ul>
      <div className="raw-tab-content">
        <div
          className={classNames('similar', 'carousel', {
            hidden: activeTab !== 0
          })}
        >
          <Carousel
            items={similarProducts}
            itemsPerSlide={0}
            navButtonsVisible={false}
            heading="Productos similares"
            carouselPosition={carouselPosition}
            extraClasses={['comparative-table']}
          />
        </div>
        <div
          className={classNames('same-seller', 'carousel', {
            hidden: activeTab !== 1
          })}
        >
          <Carousel
            items={sameSellerProducts}
            itemsPerSlide={0}
            navButtonsVisible={false}
            heading="Productos del mismo vendedor"
            carouselPosition={carouselPosition}
            extraClasses={['comparative-table']}
          />
        </div>
      </div>
      <style jsx>{ComparativeTableTabStyles}</style>
    </div>
  );
};

ComparativeTableTab.defaultProps = {
  deviceType: 'mobile',
  setHeadingRawTabSelected: noop,
  similarProducts: [],
  sameSellerProducts: [],
  attrRowsData: {},
  theme: {}
};

ComparativeTableTab.propTypes = {
  deviceType: PropTypes.string,
  setHeadingRawTabSelected: PropTypes.func,
  appCtx: PropTypes.object.isRequired,
  carouselPosition: PropTypes.number.isRequired,
  heading: PropTypes.string.isRequired,
  similarProducts: PropTypes.array,
  sameSellerProducts: PropTypes.array,
  attrRowsData: PropTypes.object,
  theme: PropTypes.object
};

export default React.memo(withTheme(ComparativeTableTab));
