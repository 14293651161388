import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import styles from './Alert.style';
import WithTimeout from '../../../utils/WithTimeout';

const TIMEOUT_MS = 10000;
const Alert = ({ content, toggleState, type, timer }) => {
  const { visible } = toggleState;

  useEffect(() => {
    if (visible) {
      timer.setToggle(true);
    }
  }, [visible]);

  if (!visible || !timer.toggle) return null;

  return (
    <div className={`alert-container ${type}`}>
      <i className="csicon-icon-success-circle" /> {content()}
      <style jsx>{styles}</style>
    </div>
  );
};

Alert.propTypes = {
  toggleState: PropTypes.object,
  timer: PropTypes.object,
  content: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['success', 'warning'])
};
Alert.defaultProps = {
  timer: { setToggle: noop, toggle: true },
  toggleState: null,
  type: 'success'
};
const AlertWithTimeout = WithTimeout(Alert, TIMEOUT_MS);
export { AlertWithTimeout };
export default Alert;
