import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { AvailabilityMobileStyles } from './AvailabilityMobile.style';
import Accordion from '../../ui/Accordion/Accordion';
import { withLabels } from '../../../utils/LabelsContext';
import constants from '../../../config/constants';
import { withProduct } from '../../../utils/ProductContext';
import AvailabilityMobileItemDownloadable from './AvailabilityMobileItemDownloadable';
import AvailabilityMobileItem from './AvailabilityMobileItem';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import _ from '../../../utils/LodashImports';

const AvailabilityMobileContainer = withProduct(
  ({ availabilityList, labels, product, sizes, colorVariants }) => {
    const isSoftProduct =
      product.productType === constants.PRODUCT_TYPE.SOFT_GOOD;
    const isGiftCard = product.productType === constants.PRODUCT_TYPE.GIFTCARD;

    return (
      <div className="availability">
        <div className="availability-container fa--availability-info__mobile">
          {isSoftProduct || isGiftCard ? (
            <AvailabilityMobileItemDownloadable />
          ) : (
            availabilityList.map((item, index) => {
              return (
                <Fragment>
                  <AvailabilityMobileItem
                    key={item.shippingOptionType}
                    labels={labels}
                    availability={item}
                    isLast={false}
                    hasModal={index !== 2}
                    sizes={sizes}
                    colorVariants={colorVariants}
                  />
                  {index === 1 && availabilityList.length > 2 && (
                    <div className="availability-item title lastItem">
                      <span>{labels.AVAILABILITY_PDP}</span>
                    </div>
                  )}
                </Fragment>
              );
            })
          )}
        </div>
        <style jsx>{AvailabilityMobileStyles}</style>
      </div>
    );
  }
);

AvailabilityMobileContainer.propTypes = {
  availabilityList: PropTypes.arrayOf(PropTypes.object).isRequired,
  labels: PropTypes.object.isRequired,
  product: PropTypes.object,
  sizes: PropTypes.array,
  colorVariants: PropTypes.array
};

AvailabilityMobileContainer.defaultProps = {
  sizes: [],
  colorVariants: []
};

const AvailabilityMobile = ({
  availabilityList,
  labels,
  appCtx,
  sizes,
  colorVariants
}) => {
  const { isRebrandingEnabled } = appCtx;
  const isNewDeliveryOptionsEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isNewDeliveryOptionsEnabled',
    false
  );
  return (
    <Fragment>
      {isNewDeliveryOptionsEnabled ? (
        <AvailabilityMobileContainer
          labels={labels}
          availabilityList={availabilityList}
          sizes={sizes}
          colorVariants={colorVariants}
        />
      ) : (
        <Accordion
          items={[
            {
              title: labels.AVAILABILITY_DELIVERY_TYPE,
              logo: 'csicon-disponibilidad',
              content: () => (
                <AvailabilityMobileContainer
                  labels={labels}
                  availabilityList={availabilityList}
                  sizes={sizes}
                  colorVariants={colorVariants}
                />
              ),
              iconOptions: {
                up: 'csicon-arrow_up_small',
                down: 'csicon-arrow_down_small',
                styles: {
                  fontSize: '1rem',
                  paddingRight: '9px'
                }
              },
              state: true,
              border: true,
              withPadding: false,
              isRebrandingEnabled
            }
          ]}
          state
          hasCustomVisualsForItem={false}
        />
      )}
    </Fragment>
  );
};

AvailabilityMobile.propTypes = {
  availabilityList: PropTypes.arrayOf(PropTypes.object).isRequired,
  labels: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  sizes: PropTypes.array,
  colorVariants: PropTypes.array
};

AvailabilityMobile.defaultProps = {
  sizes: [],
  colorVariants: []
};

export default withLabels(withApplicationContext(AvailabilityMobile));
