module.exports = {
  searchPlaceholder: '¿Qué buscas?',
  relatedCategories: 'CATEGORÍAS RELACIONADAS',
  relatedBrands: 'MARCAS RELACIONADAS',
  product: 'Produict1',
  CATEGORIES: 'Categorías',
  MENU: 'Menu',
  FILTERS_TITLE: 'Filtrar por:',
  ADD_TO_CART: 'VER PRODUCTO',
  MY_ACCOUNT: 'Mis ordenes',
  MY_ORDERS: 'Mis órdenes',
  MY_ORDERS_DESC: 'Aquí podrás revisar el estado de tus órdenes.',
  TRACK_ORDER: 'Seguimiento de mi orden',
  BAG: 'Bolsa',
  HELP: 'ayuda',
  ORDER_BY: 'Commandé par',
  CLEAR_FILTERS: 'Quitar filtros',
  SELECTED_FILTERS: 'Filtro seleccionado',
  SAVE: 'Guardar',
  CANCEL: 'Cancelar',
  CHOOSE_LOCATION: 'Selecciona tu regió',
  /* This is a generic name but in some point we need to consider setting
  the value according to the tenant */
  LOADER_MESSAGE:
    'Nous traitons votre demande. Sil vous plaît attendez un moment.',
  SEARCH_WITHOUT_RESULT_TITLE:
    'Désolé, nous avons pas trouvé de résultats pour',
  SEARCH_WITHOUT_RESULT_DESCRIPTION:
    'Peut-être que votre recherche était trop spécifique, essayez de rechercher un terme plus général.'
};
