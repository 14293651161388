module.exports = {
  searchPlaceholder: '¿Qué buscas?',
  relatedCategories: 'CATEGORÍAS RELACIONADAS',
  relatedBrands: 'MARCAS RELACIONADAS',
  product: 'Product',
  CATEGORIES: 'Categorías',
  WELCOME_LABEL: 'Bienvenid@,',
  MENU: 'Menu',
  FILTERS_TITLE: 'Filtrar por:',
  ADD_TO_CART: 'VER PRODUCTO',
  FILTERS_TITLE_MOBILE: 'Filtrar',
  FILTERS_AVAILABILITY_TITLE_MOBILE: 'Tipo de entrega',
  CLEAR: 'Cerrar',
  CLEAN: 'Limpiar',
  READY: 'Listo',
  HOME: 'Home',
  LOCATION_DIALOG_HEADER: 'Cambia tu región',
  LOCATION_DIALOG_BODY:
    'Selecciona la región donde quieres ver la disponibilidad de productos.',
  REGION: 'Región',
  SEARCH_RESULTS_FOR: 'Resultados de la búsqueda',
  OF: 'de',
  RESULTS: 'Resultados',
  NEXT: 'Siguiente',
  PREVIOUS: 'Anterior',
  LOGIN_LABEL: 'Inicia Sesión',
  MY_ACCOUNT_LABEL: 'Mi Cuenta',
  SUBSCRIPTION_EMPTY_ERROR: 'Por favor ingresa un mail',
  SUBSCRIPTION_INVALID_EMAIL_ERROR: 'Debes ingresar un mail valido',
  SUBSCRIPTION_ACCEPT_TERMS: 'Debes aceptar los terminos y condiciones',
  MY_ACCOUNT: 'Mis ordenes',
  MY_ORDERS: 'Mis órdenes',
  MY_ORDERS_DESC: 'Aquí podrás revisar el estado de tus órdenes.',
  TRACK_ORDER: 'Seguimiento de mi orden',
  BAG: 'Bolsa',
  HELP: 'ayuda',
  A11Y_LOCATION_LABEL: 'location icon',
  VIEW_PREPAID: 'Ver Prepago',
  VIEW_PLAN: 'Ver Plan',
  CHOOSE_VARIANTS: 'ELIGE TUS OPCIONES',
  NO_ACCOUNT_LABEL: '¿No tienes cuenta?',
  REGISTER_LABEL: 'Registrate',
  A11Y_USER_LABEL: 'user info',
  LOG_OUT_LABEL: 'Cerrar sesión',
  ORDER_BY_DESKTOP: 'Ordenar por',
  ORDER_BY_MOBILE: 'Ordenar',
  CLEAR_FILTERS: 'Quitar filtros',
  SELECTED_FILTERS: 'Filtro seleccionado',
  SAVE: 'Guardar',
  CANCEL: 'Cancelar',
  CHOOSE_LOCATION: 'Selecciona tu regió',
  ENTER_VALID_PRICE_ERROR_LABEL: 'Ingresa un rango de precios válido.',
  /* This is a generic name but in some point we need to consider setting
  the value according to the tenant */
  LOADER_MESSAGE:
    'Estamos procesando tu solicitud. Por favor espera un momento',
  NO_RESULT_LABEL: 'Lo sentimos, no encontramos resultados para',
  SHOWING_RESULT_FOR_LABEL: 'Mostrando resultados para',
  SEARCH_WITHOUT_RESULT_TITLE: 'Lo sentimos, no encontramos resultados para',
  SEARCH_WITHOUT_RESULT_DESCRIPTION:
    'Tal vez su búsqueda fue demasiado específica, intente buscar con un término más general.',
  POD_BOOM_SHIPPING_LABEL: 'Boom',
  PAGES_LABEL: 'Páginas'
};
