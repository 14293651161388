import css from 'styled-jsx/css';

export const ShippingLocationSelectionStyle = css`
  .formContainer {
    padding: 0 35px;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 210px;
    @mixin tabletToDesktop {
      flex-direction: row;
      justify-content: space-between;
      min-height: 0;
    }
  }
`;
