import React from 'react';
import PropTypes from 'prop-types';
import { withLabels } from '../../utils/LabelsContext';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withProduct } from '../../utils/ProductContext';
import { svgToDataUri } from '../../utils/styles/svgMixins';
import { CmrListItemStyle } from './CmrListItem.style';

const CmrListItem = ({
  title,
  cmrPoints,
  cmrPuntosIcon,
  cmrPuntosTextIcon,
  subTitle,
  bottomLine
}) => {
  return (
    <div className={`cmr-points-list-item ${bottomLine && 'bottom-line'}`}>
      <span className="cmr-icon-container">
        {cmrPuntosIcon && (
          <img
            alt="cmr-icon"
            className="cmr-icon"
            src={svgToDataUri(cmrPuntosIcon)}
          />
        )}
      </span>
      <span className="accum-text">
        <span className="cmr-text-title">{title}</span>
        {!!subTitle && <span className="cmr-text-subtitle">{subTitle}</span>}
      </span>
      <span className="cmr-points-group">
        <span className="cmr-points-text">{cmrPoints}</span>
        {cmrPuntosTextIcon && (
          <img
            alt="cmr-text-icon"
            className="cmr-text-icon"
            src={svgToDataUri(cmrPuntosTextIcon)}
          />
        )}
      </span>
      <style jsx>{CmrListItemStyle}</style>
    </div>
  );
};

CmrListItem.defaultProps = {
  subTitle: '',
  cmrPoints: '',
  bottomLine: '',
  cmrPuntosIcon: '',
  cmrPuntosTextIcon: ''
};

CmrListItem.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  cmrPoints: PropTypes.string,
  cmrPuntosIcon: PropTypes.string,
  cmrPuntosTextIcon: PropTypes.string,
  bottomLine: PropTypes.string
};

export default withApplicationContext(withProduct(withLabels(CmrListItem)));
