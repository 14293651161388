import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../ui/Button/Button';
import noop from '../../../utils/noop';
import VariantHeader from '../../VariantHeader/VariantHeader';
import { TypeAVariantMobileStyles } from './TypeAVariantMobile.style';
import VariantAvailabilityOptions from '../../VariantAvailabilityOptions/VariantAvailabilityOptions';
import Stepper from '../../ui/Stepper/Stepper';
import { withLabels } from '../../../utils/LabelsContext';
import Warning from '../../Warning/Warning';

const TypeAVariantMobile = ({
  variantId,
  brandName,
  variantName,
  prices,
  addToCart,
  labels,
  count,
  incrementCount,
  decrementCount,
  maxForStepper,
  showAlert,
  isHomeDeliveryAvailable,
  isPickFromStoreAvailable,
  isRebrandingEnabled
}) => {
  const buttonType = isRebrandingEnabled ? 'mkp-secondary' : 'secondary';
  return (
    <div className={`typeAVariant${isRebrandingEnabled ? ' mkp' : ''}`}>
      <VariantHeader
        variantId={variantId}
        brandName={brandName}
        variantName={variantName}
        prices={prices}
      />

      <div className="typeAVariant-info">
        <div className="availability-options">
          <VariantAvailabilityOptions
            deviceType="mobile"
            isHomeDeliveryAvailable={isHomeDeliveryAvailable}
            isPickFromStoreAvailable={isPickFromStoreAvailable}
            isRebrandingEnabled={isRebrandingEnabled}
          />
        </div>
        <div className="addToCart">
          <Stepper
            count={count}
            onIncrement={incrementCount}
            onDecrement={decrementCount}
            minimum={0}
            maximum={maxForStepper}
          />
          <div
            className={`addToCart-button${isRebrandingEnabled ? ' mkp' : ''}`}
          >
            {!showAlert ? (
              <Button
                size={isRebrandingEnabled ? 'medium-extend' : 'medium'}
                type={buttonType}
                onClick={addToCart}
              >
                <span
                  className={`addToCart-button-text${
                    isRebrandingEnabled ? ' mkp' : ''
                  }`}
                >
                  {isRebrandingEnabled
                    ? labels.ADD_TO_BAG.toLowerCase()
                    : labels.ADD_TO_BAG}
                </span>
              </Button>
            ) : (
              <Warning
                label={labels.MAXIMUM_QUANTITY_LIMIT_LABEL}
                iconSize="copy2"
                labelSize="copy5"
              />
            )}
          </div>
        </div>
      </div>
      <style jsx>{TypeAVariantMobileStyles}</style>
    </div>
  );
};

TypeAVariantMobile.defaultProps = {
  brandName: '',
  prices: [],
  addToCart: noop,
  showAlert: false,
  isHomeDeliveryAvailable: false,
  isPickFromStoreAvailable: false,
  isRebrandingEnabled: false
};

TypeAVariantMobile.propTypes = {
  brandName: PropTypes.string,
  variantName: PropTypes.string.isRequired,
  variantId: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  incrementCount: PropTypes.func.isRequired,
  decrementCount: PropTypes.func.isRequired,
  prices: PropTypes.arrayOf(PropTypes.object),
  addToCart: PropTypes.func,
  maxForStepper: PropTypes.number.isRequired,
  showAlert: PropTypes.bool,
  isHomeDeliveryAvailable: PropTypes.bool,
  isPickFromStoreAvailable: PropTypes.bool,
  isRebrandingEnabled: PropTypes.bool
};

export default withLabels(TypeAVariantMobile);
