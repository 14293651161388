import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';

const Styles = css`
  .cmr-modal {
    :global(.finance-calculator-label-text) {
      font-size: ${typography.xl};
      color: ${colors.gray.shade15};
      padding: 22px;
    }
  }
`;

export default Styles;
