import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from '../../../utils/LodashImports';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import DemoUpButtonContainerStyles from './DemoUpButtonContainer.style';

const DemoUp = require('demoup-react');

const DemoUpButtonContainer = ({ isConnect, appCtx }) => {
  const { store } = appCtx;
  const fallBackDemoUpStoreKeyMap = {
    falabella: 'falabella.com',
    sodimac: 'sodimac.falabella.com',
    tottus: 'tottus.falabella.com'
  };

  const demoUpStoreKeyMap = _.get(
    appCtx,
    'siteConfig.configurations.demoUpStoreKeyMap',
    fallBackDemoUpStoreKeyMap
  );

  const [DemoUpButton, setDemoUpButton] = useState(null);

  const isDemoUpVideoEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isDemoUpVideoEnabled',
    true
  );
  useEffect(() => {
    const loadDemoUpButton = async () => {
      const demoUpStoreKey = demoUpStoreKeyMap[store || 'falabella'];
      if (demoUpStoreKey) {
        const { button } = DemoUp(demoUpStoreKey);
        setDemoUpButton(() => button);
      }
    };
    if (isDemoUpVideoEnabled) {
      loadDemoUpButton();
    }
  }, [store]);

  const demoUpImageSize = isConnect ? 20 : 45;
  const demoUpButtonSize = isConnect ? 15 : 28;
  const resolveScopedStyles = (scope) => {
    return {
      styles: () => scope.props.children
    };
  };
  const style = resolveScopedStyles(
    <Fragment>
      <style>{`
        .demoup-trigger > div {
          display: flex;
          gap: 8px;
        }
      `}</style>
    </Fragment>
  );
  return (
    DemoUpButton && (
      <div className="demo-up-wrapper">
        <DemoUpButton
          buttonheight={demoUpButtonSize}
          buttonwidth={demoUpButtonSize}
          height={demoUpImageSize}
          width={demoUpImageSize}
          color="rgba(0, 0, 0, 0.5)"
          threed="enabled"
          multiButton
        />
        <style.styles />
        <style jsx>{DemoUpButtonContainerStyles}</style>
      </div>
    )
  );
};

DemoUpButtonContainer.defaultProps = {
  isConnect: false
};

DemoUpButtonContainer.propTypes = {
  isConnect: PropTypes.bool,
  appCtx: PropTypes.object.isRequired
};
export default withApplicationContext(DemoUpButtonContainer);
export { DemoUpButtonContainer };
