import css from 'styled-jsx/css';

const AvailabilityLocationStyles = css`
  .delivery-option-location {
    display: flex;
    flex-direction: row;
    gap: 4px;
    .delivery-location-text {
      font-size: 14px;
      color: #343e49;
      .location-text {
        font-weight: 700;
        text-decoration-line: underline;
        text-underline-offset: 2px;
      }
    }
  }
`;

export { AvailabilityLocationStyles };
