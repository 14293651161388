import {
  sponsoredClickService,
  sponsoredImpressionService,
  sponsoredVisitService
} from '../../services/sponsoredService';
import sponsoredEvents from '../../utils/SponsoredHelper';

const getFuntionAnalyticsSponsored = (isSponsoredService) => {
  if (isSponsoredService)
    return {
      sponsoredClickService,
      sponsoredImpressionService,
      sponsoredVisitService
    };

  return {
    sponsoredClickService: sponsoredEvents.click,
    sponsoredImpressionService: sponsoredEvents.impression,
    sponsoredVisitService: sponsoredEvents.visit
  };
};

export { getFuntionAnalyticsSponsored };
