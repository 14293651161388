import css from 'styled-jsx/css';
import { colors, typography } from '../../config/styles';

const ProductOutOfStockStyle = css`
  .outofstock-container {
    .warning-box {
      @mixin tabletToDesktop {
        width: 600px;
      }
      height: 54px;
      background-color: #fff9e9;
      border-radius: 4px;
      display: flex;
      align-items: center;
    }
    @mixin desktop {
      margin: 0;
    }
    .heading {
      display: flex;
      line-height: 17px;
      .warning-icon {
        margin-left: 9.25px;
        margin-right: 14.5px;
        background: url(/a/fa/product/static/styles/svg/warning_icon.svg);
        display: block;
        width: 18.5px;
        height: 18.5px;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .body {
      .category {
        padding-top: 10px;
        paddint-bottom: 10px;
      }
      .title {
        font-weight: bold;
        font-size: ${typography.base};
        letter-spacing: 0.14px;
        line-height: 17px;
      }
      .category {
        .link {
          text-decoration: none;
          color: inherit;
          padding-bottom: 2px;
          box-sizing: border-box;
          color: ${colors.gray.shade40};
          font-size: ${typography.sm};
          letter-spacing: 0.15px;
          text-decoration: none;
          line-height: 15px;
          border-bottom: 0.5px solid ${colors.gray.shade40};
          @mixin mobileToTablet {
            font-size: ${typography.lg1};
          }
        }
      }
    }
  }
`;

export default ProductOutOfStockStyle;
