import css from 'styled-jsx/css';
import { colors, typography } from '../../config/styles';

export const AddtionInformationCardCss = css`
  .container {
    width: 100%;
    .headerContainer {
      padding: 20px 0 10px;
      border-bottom: 2px solid ${colors.green.shade14};
      margin: 0 17px;
      @mixin tabletToDesktop {
        margin: 0;
      }

      span {
        font-family: inherit;
        font-size: ${typography.xl};
        line-height: 30px;
        color: ${colors.black.shade1};
      }
    }
    .mkp-headerContainer {
      padding: 20px 0 10px;
      border-bottom: 2px solid ${colors.gray.shade63};
      margin: 0 17px;
      @mixin tabletToDesktop {
        margin: 0;
      }

      span {
        font-family: inherit;
        font-size: ${typography.xl};
        line-height: 30px;
        color: ${colors.black.shade1};
      }
    }

    .bodyContainer {
      margin-top: 23px;
    }
  }
`;
