import constants from '../../../config/constants';
import _ from '../../../utils/LodashImports';

const extractInternationalModalLabels = ({ labels, endpoints, store }) => {
  return {
    title: _.get(labels, 'INTERNATIONAL_SHIPPING_XLP', 'Compra Internacional'),
    descriptionPara1Bold: _.get(
      labels,
      'INTERNATIONAL_SHIPPING_PARA_1_DESC_BOLD',
      `El precio publicado incluye el envío internacional y pago de
    aduana.`
    ),
    descriptionPara1Light: _.get(
      labels,
      'INTERNATIONAL_SHIPPING_PARA_1_DESC_LIGHT',
      `Si llegaran a fiscalizar tu producto en el aeropuerto, no tendrás que
      correr con ningún gasto.`
    ),
    descriptionPara2Light: _.get(
      labels,
      'INTERNATIONAL_SHIPPING_PARA_2_DESC_LIGHT',
      `Al ser una compra internacional,`
    ),
    descriptionPara2Bold: _.get(
      labels,
      'INTERNATIONAL_SHIPPING_PARA_2_DESC_BOLD',
      `te ayudaremos a registrar el celular para que funcione en tu país
        cuando lo recibas.`
    ),
    bulletPointsTitle: _.get(
      labels,
      'INTERNATIONAL_SHIPPING_BULLET_TITLE',
      'Notificaciones en momentos claves'
    ),
    bulletPointsDescription: _.get(
      labels,
      'INTERNATIONAL_SHIPPING_BULLET_DESC',
      'Te mantendremos al día con los avances de tu pedido cuando:'
    ),
    bulletPoints: _.get(
      labels,
      'INTERNATIONAL_SHIPPING_BULLETS',
      'El vendedor envíe tu producto desde el país de origen.*Tu producto llegue a Chile.*Cuando vaya en camino a tu domicilio.'
    ),
    bulletPoints1: _.get(labels, 'INTERNATIONAL_SHIPPING_BULLETS_1', ''),
    modalFooter: _.get(
      labels,
      'INTERNATIONAL_SHIPPING_FOOTER',
      'Para más información, revisa nuestro'
    ),
    modalFooterUnderline: _.get(
      labels,
      'INTERNATIONAL_SHIPPING_FOOTER_UNDERLINE_TEXT',
      'Centro de ayuda'
    ),
    footerLink:
      store === constants.STORES.so_com
        ? _.get(endpoints, 'SO_COM_INTERNATIONAL_SHIPPING_LINK', '')
        : _.get(
            endpoints,
            'INTERNATIONAL_SHIPPING_LINK',
            'https://www.falabella.com/falabella-cl/page/compra-internacional'
          )
  };
};
export { extractInternationalModalLabels };
