import React from 'react';
import PropTypes from 'prop-types';
import { SpecificationTableStyle } from './SpecificationTable.style';

const SpecificationTable = ({ specifications, type }) => {
  return (
    <table className={`specification-table ${type}`}>
      <tbody>
        {specifications.map(({ name, value }) => {
          return (
            <tr key={name}>
              <td className="property-name">{name}</td>
              <td className="property-value">{value}</td>
            </tr>
          );
        })}
      </tbody>
      <style jsx>{SpecificationTableStyle}</style>
    </table>
  );
};

SpecificationTable.defaultProps = {
  type: ''
};
SpecificationTable.propTypes = {
  specifications: PropTypes.array.isRequired,
  type: PropTypes.string
};

export default SpecificationTable;
