import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import noop from '../../../utils/noop';
import {
  SingleChipletSelectionStyles,
  OptionsStyles
} from './SingleChipletSelect.style';
import SingleChipletSelectCheckbox from './SingleChipletSelectCheckbox/SingleChipletSelectCheckbox';
import Accordion from '../Accordion/Accordion';
import MatchMedia from '../MatchMedia/MatchMedia';
import BodyCopy from '../BodyCopy/BodyCopy';
import { LinkLabel } from '../LinkButton/LinkButton';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { findIndex } from '../../../utils/jsUtils';
import Dropdown from '../Dropdown/Dropdown';
import Warning from '../../Warning/Warning';
import Tooltip, { TooltipText } from '../Tooltip/Tooltip';
import AdditionalPDPLabel from '../../AdditionalPDPLabel/AdditionalPDPLabel';
import { ExpansionIcon } from './ExpansionIcon';
import _ from '../../../utils/LodashImports';
import { getAdditionalServicesUrl } from './helpers';
import { withProduct } from '../../../utils/ProductContext';
import constants from '../../../config/constants';

const additionalServiceTypes = [
  'InCart-inPDP-ServiceOption',
  'InCart-inXLP-ServiceOption',
  'additional-service'
];

const SingleChipletSelect = ({
  icon,
  tooltipContent,
  cheapestOption,
  options,
  label,
  startsFromLabel,
  tooltipToggle,
  onOptionSelected,
  shouldHaveBackground,
  type,
  variant,
  showAsDropdown,
  withPadding,
  highLightHeading,
  defaultSelectedIndex,
  errorMessage,
  clickableChiplet,
  disableAll,
  additionalWarrantyLabel,
  appCtx,
  isModalServices,
  isModal,
  isNewWarrantyUIEnabled,
  serviceType,
  product,
  hideSubtitle
}) => {
  const [collapseState, setcollapseState] = useState(false);
  const [cheapestOptionPrice, setCheapestPrice] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(defaultSelectedIndex);
  const [serviceOptions, setServiceOptions] = useState(options);
  const isCart = variant === 'CART';
  const { isRebrandingEnabled, store } = appCtx;
  const isTooltipDataValid =
    Object.entries(tooltipContent).length &&
    (tooltipContent.title.text ||
      tooltipContent.bulletPoints.text.length ||
      tooltipContent.link.text);
  const isAdditionalService = additionalServiceTypes.includes(serviceType);
  const tooltipText = (isMobile) =>
    isTooltipDataValid ? (
      <div
        className={`tooltip-text ${isMobile && !isCart ? 'pdp-mobile' : ''}`}
      >
        {tooltipContent.title.text ? (
          <p
            style={{
              fontWeight: tooltipContent.title.bold ? 'bold' : 'normal'
            }}
            className="bold-text"
          >
            {tooltipContent.title.text}
          </p>
        ) : (
          ''
        )}
        {tooltipContent.bulletPoints.text.length ? (
          <ul className="tooltip-list">
            {tooltipContent.bulletPoints.text.map((i) => (
              <li
                style={{
                  fontWeight: tooltipContent.bulletPoints.bold
                    ? 'bold'
                    : 'normal'
                }}
              >
                {i}
              </li>
            ))}
          </ul>
        ) : (
          ''
        )}
        {tooltipContent.link.text && tooltipContent.link.href ? (
          <div className="tooltip-link-wrapper">
            <LinkLabel
              type="quaternary"
              size="small"
              href={tooltipContent.link.href}
              target="_blank"
            >
              <p
                style={{
                  fontWeight: tooltipContent.link.bold ? 'bold' : 'normal'
                }}
              >
                {tooltipContent.link.text}
              </p>
            </LinkLabel>
            <span>
              <i className="csicon-right arrow" />
            </span>
          </div>
        ) : (
          ''
        )}
        <style jsx>{SingleChipletSelectionStyles}</style>
      </div>
    ) : (
      ''
    );

  useEffect(() => {
    const newSelectedIndex = findIndex(options, (option) => option.selected);
    setSelectedIndex(newSelectedIndex);

    if (cheapestOption && options.length) {
      const price = cheapestOption.split(' ')[1];
      const cheapestPriceValue = options.find(
        (i) => i.label.indexOf(price) > 0
      );
      setCheapestPrice(cheapestPriceValue ? cheapestOption : '');
    }
    if (isCart && !isRebrandingEnabled) {
      setcollapseState(!tooltipContent.openInCartDesktop);
    } else {
      setcollapseState(!tooltipContent.openInPDPDesktop);
    }
  }, [options, cheapestOption]);

  const handleRadioButtonGroupChange = (i) => {
    if (i === selectedIndex) {
      setSelectedIndex(-1);
      onOptionSelected();
    } else {
      setSelectedIndex(i);
      onOptionSelected(options[i]);
    }
  };

  const handleCheckboxGroupChange = (i) => {
    let serviceOptionsCopy = JSON.parse(JSON.stringify(serviceOptions));
    const noThanksLabel = _.get(
      appCtx,
      'siteConfig.textDictionary.PDP_NO_THANKS',
      'No, gracias'
    );
    if (
      serviceOptionsCopy[i].label === noThanksLabel &&
      !serviceOptionsCopy[i].selected
    ) {
      serviceOptionsCopy = serviceOptionsCopy.map((s, index) => ({
        ...s,
        selected: index === i
      }));
      setServiceOptions(serviceOptionsCopy);
      onOptionSelected(serviceOptionsCopy, i);
      return null;
    }
    if (serviceOptionsCopy[i].selected) {
      serviceOptionsCopy[i].selected = false;
    } else {
      serviceOptionsCopy[i].selected = true;
      const noThanksIndex = serviceOptionsCopy.findIndex(
        (s) => s.label === noThanksLabel
      );
      if (noThanksIndex) {
        _.set(serviceOptionsCopy[noThanksIndex], 'selected', false);
      }
    }
    setServiceOptions(serviceOptionsCopy);
    onOptionSelected(serviceOptionsCopy, i);
    return null;
  };
  const handleDropdownOptionChange = (__, index) => {
    setSelectedIndex(index);
    onOptionSelected(options[index]);
  };

  const renderOptionAsDropdown = () => {
    return (
      <div className="dropdown-options">
        <Dropdown
          options={options.map((option, i) => ({
            ...option,
            selected: selectedIndex === i && !disableAll
          }))}
          variant={variant}
          onOptionSelected={handleDropdownOptionChange}
          showDesktopViewInMobile
        />
        <style jsx>{SingleChipletSelectionStyles}</style>
      </div>
    );
  };
  const checkToggleStatus = () => {
    if (isCart) {
      return tooltipContent.openInCartMobile;
    }
    return tooltipContent.openInPDPMobile;
  };
  const renderOptions = (viewType, isMobile) => {
    if (showAsDropdown) {
      return renderOptionAsDropdown();
    }
    return (
      <div
        id={`test-id-${serviceType}`}
        className={`${!isMobile ? 'options' : 'options-mobile'} ${
          isModal && isNewWarrantyUIEnabled ? 'options-services' : ''
        }`}
      >
        {options.map(({ label: optionLabel }, index) => (
          <SingleChipletSelectCheckbox
            key={optionLabel}
            label={optionLabel}
            itemId={index}
            selected={selectedIndex === index && !disableAll}
            onChange={handleRadioButtonGroupChange}
            type={type}
            variant={viewType}
            clickableChiplet={clickableChiplet}
            isCart={isCart}
            isMobile={isMobile}
            disabled={disableAll}
            isRebrandingEnabled={isRebrandingEnabled}
            serviceType={serviceType}
          />
        ))}
        {isMobile && isTooltipDataValid && tooltipToggle
          ? tooltipText(isMobile)
          : ''}
        <style jsx>{OptionsStyles}</style>
      </div>
    );
  };
  const renderAdditionalServices = (viewType, isMobile) => {
    if (showAsDropdown) {
      return renderOptionAsDropdown();
    }
    const sellerName =
      _.get(product, 'sellerName', '') ||
      _.get(product, 'variants[0].offerings[0].sellerName', '');
    const isSodimacStandAlone = store === constants.STORES.so_com;
    const moreInfoUrl = getAdditionalServicesUrl(
      sellerName.toLowerCase(),
      appCtx.regionCode,
      _.get(appCtx, 'siteConfig.endpoints', {}),
      isSodimacStandAlone
    );
    return (
      <React.Fragment>
        <div
          id={`test-id-${serviceType}`}
          className={`${!isMobile ? 'options' : 'options-mobile'} ${
            isModal && isNewWarrantyUIEnabled ? 'options-services' : ''
          }`}
        >
          {serviceOptions.map(({ label: optionLabel, selected }, index) => (
            <SingleChipletSelectCheckbox
              key={optionLabel}
              label={optionLabel}
              itemId={index}
              selected={selected && !disableAll}
              onChange={handleCheckboxGroupChange}
              type={type}
              variant={viewType}
              clickableChiplet={clickableChiplet}
              isCart={isCart}
              isMobile={isMobile}
              disabled={disableAll}
              isRebrandingEnabled={isRebrandingEnabled}
              serviceType={serviceType}
              isAdditionalService
            />
          ))}
          {moreInfoUrl && isMobile && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={moreInfoUrl}
              className="link-wrapper"
              tabIndex={0}
            >
              <span className="more-information">
                {_.get(
                  appCtx,
                  'siteConfig.textDictionary.MORE_INFORMATION',
                  'Más información'
                )}
              </span>
              <i className="csicon-right" />
            </a>
          )}
        </div>
        {moreInfoUrl && !isMobile && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={moreInfoUrl}
            className={`link-wrapper ${isCart ? 'cart' : ''}`}
            tabIndex={0}
          >
            <span className="more-information">
              {_.get(
                appCtx,
                'siteConfig.textDictionary.MORE_INFORMATION',
                'Más información'
              )}
            </span>
            <i className="csicon-right" />
          </a>
        )}
        <style jsx>{OptionsStyles}</style>
      </React.Fragment>
    );
  };

  const renderContent = (viewType, isMobile) => {
    return (
      <Fragment>
        {isAdditionalService
          ? renderAdditionalServices(viewType, isMobile)
          : renderOptions(viewType, isMobile)}
        {additionalWarrantyLabel && (
          <AdditionalPDPLabel
            label={additionalWarrantyLabel}
            type="warranty"
            productType="hardline"
          />
        )}
      </Fragment>
    );
  };
  const getSubTitle = () => {
    if (hideSubtitle) {
      return '';
    }
    if (cheapestOptionPrice) {
      return `${startsFromLabel}${cheapestOptionPrice}`;
    }
    if (isAdditionalService) {
      return _.get(
        appCtx,
        'siteConfig.textDictionary.ADDITIONAL_SERVICE_SUBTEXT',
        'Realizados por técnicos certificados'
      );
    }
    return '';
  };
  const renderSingleChipletMobile = () => {
    return (
      <Accordion
        items={[
          {
            title: label,
            subTitle: getSubTitle(),
            logo: icon,
            content: () => renderContent('', true),
            iconOptions: {
              up: 'csicon-arrow_up_small',
              down: 'csicon-arrow_down_small',
              styles: {
                fontSize: '1rem',
                paddingRight: '9px'
              }
            },
            state: checkToggleStatus(),
            border: true,
            withPadding,
            highLightHeading,
            isRebrandingEnabled,
            isCart,
            idCollapse: serviceType,
            collapseIds: {
              open: `${serviceType}_down_id`,
              close: `${serviceType}_up_id`
            }
          }
        ]}
        state={false}
        hasCustomVisualsForItem={false}
      >
        <style jsx>{SingleChipletSelectionStyles}</style>
      </Accordion>
    );
  };

  const renderSingleChipletDesktop = () => {
    return (
      <div
        className={`chiplet-wrapper ${
          isRebrandingEnabled ? 'mkp' : ''
        } desktop ${
          shouldHaveBackground ? 'withBackground' : 'withoutBackground'
        } ${type}
        ${isRebrandingEnabled && 'rebranded'}`}
      >
        <div className="heading">
          <i className={`${icon} primary-icon`} />
          <BodyCopy isHighlighted size="copy3">
            <span>{label}</span>
          </BodyCopy>
          {isModalServices && <i className="csicon-alert service-icon" />}
          {isTooltipDataValid && tooltipToggle ? (
            <div className="tooltip-wrapper">
              <Tooltip
                position={isCart ? 'bottom' : 'bottom-left'}
                hasCaret
                tooltipContent={
                  <TooltipText text={tooltipText(false)} viewType="medium" />
                }
              >
                <i className="csicon-alert secondary-icon" />
              </Tooltip>
            </div>
          ) : (
            ''
          )}
          <ExpansionIcon
            setcollapseState={setcollapseState}
            isRebrandingEnabled={isRebrandingEnabled}
            collapseState={collapseState}
            collapseClass={{ up: 'csicon-arrow_up', down: 'csicon-arrow_down' }}
            collapseIds={{
              open: `${serviceType}_down_id`,
              close: `${serviceType}_up_id`
            }}
          />
        </div>
        {!collapseState && renderContent()}
        {errorMessage && (
          <Warning label={errorMessage} iconSize="copy2" labelSize="copy5" />
        )}
        <style jsx>{SingleChipletSelectionStyles}</style>
      </div>
    );
  };

  return (
    <MatchMedia
      content={{
        desktop: () => renderSingleChipletDesktop(),
        mobile: () => renderSingleChipletMobile(),
        tablet: () => renderSingleChipletDesktop()
      }}
    />
  );
};

SingleChipletSelect.defaultProps = {
  icon: '',
  options: [],
  label: '',
  startsFromLabel: 'A partir de: ',
  tooltipContent: {
    openInPDPDesktop: true,
    openInCartDesktop: true,
    openInPDPMobile: true,
    openInCartMobile: true,
    title: {
      text: '',
      bold: true
    },
    link: {
      text: '',
      href: '',
      bold: false
    },
    bulletPoints: {
      text: [],
      bold: false
    }
  },
  onOptionSelected: noop,
  shouldHaveBackground: false,
  type: 'small',
  variant: '',
  cheapestOption: '',
  showAsDropdown: false,
  tooltipToggle: false,
  withPadding: false,
  highLightHeading: false,
  defaultSelectedIndex: -1,
  errorMessage: null,
  clickableChiplet: false,
  disableAll: false,
  isModal: false,
  isModalServices: false,
  additionalWarrantyLabel: {},
  isNewWarrantyUIEnabled: false,
  hideSubtitle: false
};

SingleChipletSelect.propTypes = {
  icon: PropTypes.string,
  tooltipContent: PropTypes.object,
  options: PropTypes.array,
  label: PropTypes.string,
  startsFromLabel: PropTypes.string,
  onOptionSelected: PropTypes.func,
  shouldHaveBackground: PropTypes.bool,
  type: PropTypes.oneOf(['small', 'medium']),
  variant: PropTypes.oneOf(['', 'CART']),
  showAsDropdown: PropTypes.bool,
  withPadding: PropTypes.bool,
  tooltipToggle: PropTypes.bool,
  highLightHeading: PropTypes.bool,
  defaultSelectedIndex: PropTypes.number,
  errorMessage: PropTypes.string,
  clickableChiplet: PropTypes.bool,
  cheapestOption: PropTypes.string,
  disableAll: PropTypes.bool,
  isModal: PropTypes.bool,
  isModalServices: PropTypes.bool,
  additionalWarrantyLabel: PropTypes.object,
  appCtx: PropTypes.object.isRequired,
  isNewWarrantyUIEnabled: PropTypes.bool,
  serviceType: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  hideSubtitle: PropTypes.bool
};

export default withApplicationContext(withProduct(SingleChipletSelect));

export { SingleChipletSelect };
