import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';

const Styles = css`
  .title-text {
    color: ${colors.black.shade1};
    font-size: ${typography.base};
    font-weight: 600;
    letter-spacing: 0.14px;
  }
  .cmr-calculator-hardline {
    padding: 16px 0 16px 12px;
  }

  .cmr-modal {
    :global(.finance-calculator-label-text) {
      font-size: ${typography.xl2};
      color: ${colors.gray.shade15};
    }
  }

  .icon.csicon-calculator {
    padding-right: 16px;
    color: ${colors.green.shade1};
  }

  .cmr-calculator-container {
    &.rebranded {
      .icon.csicon-calculator {
        color: ${colors.gray.shade45};
      }
    }
  }

  .calculator-popup-button {
    cursor: pointer;
    font-size: ${typography.sm};
    letter-spacing: 0.4px;
    line-height: 18px;
    outline: none;
    display: flex;
    span {
      border-bottom: thin solid ${colors.gray.shade35};
      color: ${colors.gray.shade40};
    }
  }

  .csicon-arrow_right {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0;
    &::before {
      font-size: ${typography.xxs};
    }
  }

  .flex-top {
    display: flex;
    align-items: flex-start;
  }

  .flex-middle {
    display: flex;
    align-items: center;
  }
`;
export default Styles;
