import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';

const ScrollToStyles = css`
  .specifications-action {
    color: ${colors.green.shade10};
    font-weight: bold;
    font-size: ${typography.lg1};
    display: inline-block;
    position: relative;
    height: 24px;
    cursor: pointer;
    outline: none;
    width: fit-content;
    font-family: inherit;
    @mixin tabletToDesktop {
      font-size: ${typography.sm1};
      height: auto;
      &.rebranded {
        margin-left: 0;
      }
    }
    &.rebranded.softline {
      font-weight: bold;
    }
    &.rebranded {
      color: ${colors.gray.shade48};
      font-weight: 400;
      @mixin tabletToDesktop {
        text-decoration: underline;
      }
      &::after {
        background-color: ${colors.gray.shade48};
      }
    }
  }

  .specifications-action::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${colors.green.shade10};
    @mixin tabletToDesktop {
      content: none;
    }
  }
`;

export { ScrollToStyles };
