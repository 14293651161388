import css from 'styled-jsx/css';
import { colors } from '../../../config/styles';

const AdditionalInformationStyleCss = css`
  .productInfoContainer {
    background-color: transparent;
  }
  .specification {
    background-color: ${colors.white.shade1};
  }

  .specification-header {
    padding: 20px 17px;
  }

  .productInfo {
    margin-top: 30px;
    background-color: ${colors.white.shade1};
  }
  .htmlBox {
    width: 100%;
    overflow: hidden;
  }
  .htmlBox-container {
    padding: 16px;
  }
`;

export { AdditionalInformationStyleCss };
