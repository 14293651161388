import React from 'react';
import PropTypes from 'prop-types';
import _ from '../../utils/LodashImports';
import { RatingAndReviews } from '../../external_dependencies/ratingAndReviews/RatingAndReviews';
import {
  BrandName,
  ProductName,
  ProductShareIcon,
  SellerSkuId
} from '../../components/PDP';
import noop from '../../utils/noop';
import { getVariant } from '../../utils/variant';
import constants from '../../config/constants';
import Badges from '../../components/Badges/Badges';
import PDPBasicInfoSharedStyle from './PDPBasicInfoShared.style';

const SKUIdSeller = ({ appCtx, offerings, labels, regionCode, product }) => {
  const isColombiaRegion = regionCode === 'co';

  const isSellerSkuIdColombiaEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isSellerSkuIdColombiaEnabled',
    false
  );
  const sellerSkuId = (offerings.length && offerings[0].sellerSkuId) || null;

  const sellerId = _.get(product, 'variants[0].offerings[0].sellerId', '');
  const is3pSeller = !constants.FIRST_PARTY_SELLER_REGEX.test(
    sellerId.toLowerCase()
  );

  const skuIdSellerHtml = () => {
    if (sellerSkuId !== null && Number(sellerSkuId) !== 0 && !is3pSeller) {
      return (
        <React.Fragment>
          <div className="vertical-separator" />
          <SellerSkuId sellerSkuId={sellerSkuId} labels={labels} />
          <style jsx>{PDPBasicInfoSharedStyle}</style>
        </React.Fragment>
      );
    }
    return null;
  };

  if (!isColombiaRegion) {
    return skuIdSellerHtml();
  }
  if (isColombiaRegion && isSellerSkuIdColombiaEnabled) {
    return skuIdSellerHtml();
  }
  return null;
};

const PDPBasicInfoShared = ({ appCtx, product, ratings, template }) => {
  const { badges = [], meatStickers = [], promotions } = getVariant(
    product.variants,
    product.currentVariant
  );
  const rcClass = `reviews-column${ratings ? '' : '-hidden'}`; // Don't change this logic, in order to update reviews we need the section to render so data loads
  const { isRebrandingEnabled } = appCtx;
  const isMeatStickerApplicableInPDP = _.get(
    appCtx,
    'siteConfig.toggles.isMeatStickerApplicableInPDP',
    false
  );
  const freeShippingPromotionFor3p = promotions?.find(
    (promotion) => promotion.campaignName === 'ENVIO GRATIS 3P'
  );

  const hasStickers =
    (isMeatStickerApplicableInPDP && meatStickers.length > 0) ||
    freeShippingPromotionFor3p;

  const { isOutOfStock } = product;

  if (isOutOfStock) {
    return (
      <div
        className={`basic-details-Mobile ${isRebrandingEnabled && 'rebranded'}`}
      >
        <BrandName brandName={product.brandName} />
        <div className="corner-details">
          <ProductName productName={product.name} />
        </div>
        <style jsx>{PDPBasicInfoSharedStyle}</style>
      </div>
    );
  }

  return (
    <div
      className={`basic-details-Mobile ${isRebrandingEnabled && 'rebranded'}`}
    >
      <div className="brand-and-rating">
        <BrandName brandName={product.brandName} />
        <div className={`${rcClass} fa--reviews-${template}__mobile`}>
          <RatingAndReviews />
        </div>
      </div>
      <div className="corner-details">
        <ProductName productName={product.name} />
        <ProductShareIcon
          onClickHandler={noop}
          isRebrandingEnabled={isRebrandingEnabled}
        />
      </div>
      <div className={template === 'softline' ? 'reviews-column' : 'badges'}>
        {(badges || meatStickers || freeShippingPromotionFor3p) && (
          <Badges
            deviceType="mobile"
            badgeItems={isMeatStickerApplicableInPDP ? meatStickers : badges}
            layout={template === 'softline' ? 'PDP SOFTLINE' : 'PDP'}
            isSticker={hasStickers}
            promotionBadge={freeShippingPromotionFor3p?.badge}
          />
        )}
      </div>
      <style jsx>{PDPBasicInfoSharedStyle}</style>
    </div>
  );
};

PDPBasicInfoShared.defaultProps = {
  template: 'softline'
};

PDPBasicInfoShared.propTypes = {
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  ratings: PropTypes.object.isRequired,
  template: PropTypes.string
};

export { PDPBasicInfoShared, SKUIdSeller };
