/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import HardlineProductSpecifications from './Hardline/HardlineProductSpecifications';
import SoftlineProductSpecifications from './Softline/SoftlineProductSpecifications';
import { withProduct } from '../../utils/ProductContext';
import {
  getUniqueColorVariants,
  getSelectedColorSwatchIndex,
  getUniqueSizes,
  getFirstMatchingVariantId,
  getUniqueOperators,
  getVariant,
  getCheapestVariantId
} from '../../utils/variant';
import { getErrorMessage } from '../../utils/errorHandler';
import { withApplicationContext } from '../../utils/ApplicationContext';
import constants from '../../config/constants';
import { withCart } from '../../utils/CartContext';
import _ from '../../utils/LodashImports';
import { withUser } from '../../utils/UserContext';
import { requestSkuStocksFromStore, requestProductFromStore } from './helpers';
import { track } from '../../external_dependencies/analytic/index';
import * as productUtils from '../../utils/product/productUtils';
import { withDigitalData } from '../../utils/DigitalDataContext';
import useInput from './useInput';

const ProductSpecifications = ({
  layoutType,
  product,
  appCtx,
  cart,
  isVendorLogin,
  currentTab,
  vendorData,
  digitalData
}) => {
  const {
    variants,
    currentVariant: currentVariantId,
    attributes,
    changeVariant,
    isOutOfStock,
    maxOnCartAmount,
    colorMetrics
  } = product;

  const { sendIsColorPurshased, setColorSwatch } = colorMetrics;

  const {
    isSizeSelected,
    setSizeSelection,
    setShowCart,
    setVariantSelectionVisible,
    setQuantityWhileVariantSelectionVisible,
    selectedSize,
    setSelectedSize,
    selectedOperatorId,
    setSelectedOperatorId,
    warrantyOptionCurrent,
    setWarrantyOptionCurrent
  } = cart;

  const currentVariant = getVariant(variants, currentVariantId);
  const { id: productId } = product;
  const { id: skuId } = currentVariant;
  const { tenant, regionCode, store } = appCtx;
  const offeringId = _.get(currentVariant, 'offerings[0]offeringId', '');
  const { inputVal } = useInput(maxOnCartAmount);
  const isPumAvailable =
    product.attributes && product.attributes.isPumAvailable;
  const pumQuantity = isPumAvailable ? product.attributes.pumQuantity : 1;

  const primeShipping =
    product.attributes && product.attributes.isPrimeAvailable;

  const isConnectProduct = productUtils.isConnectProduct(product);

  const colorVariants = getUniqueColorVariants(
    product.variants,
    currentVariant
  );

  digitalData.pdp.setProductColorsQty(colorVariants.length);

  const [selectedColorSwatchIndex, setColorIndex] = useState(-1);
  const [errorAddingToCart, setErrorAddingToCart] = useState('');
  const [prevLength, setPrevLength] = useState(0);

  useEffect(() => {
    if (colorVariants.length !== prevLength) {
      const indices = Array.from(
        { length: colorVariants.length },
        (_, i) => colorVariants[i].label
      );
      setColorSwatch(indices);
      setPrevLength(colorVariants.length);
    }
  }, [colorVariants, prevLength]);

  const currentColorName = colorVariants[selectedColorSwatchIndex]
    ? colorVariants[selectedColorSwatchIndex].label
    : undefined;

  const sizes = getUniqueSizes(product.variants, currentColorName);

  // const [selectedSize, setSize] = useState(null);
  const [serviceOption, setServiceOption] = useState(null);

  const selectCheapestVariant = (colorName) => {
    const updatedOperators = getUniqueOperators(product.variants, colorName);
    const variantId = getCheapestVariantId(
      variants,
      updatedOperators,
      regionCode
    );
    if (!variantId) return;
    setSelectedOperatorId(variantId);
    setSizeSelection(true);
    setVariantSelectionVisible(false);
    changeVariant(tenant, variantId, store, regionCode);
  };

  const colorSwatchHandler = ({ label: colorName }) => {
    const variantId = getFirstMatchingVariantId(variants, {
      colorName,
      size: selectedSize
    });
    changeVariant(tenant, variantId, store, regionCode);
    if (isConnectProduct) {
      selectCheapestVariant(colorName);
    }
  };

  const sizeChangeHandler = (size) => {
    const variantId = getFirstMatchingVariantId(variants, {
      colorName: currentColorName,
      size
    });
    const newVariant = getVariant(variants, variantId);
    const currentSize = _.get(newVariant, 'attributes.size', '');

    const newSize =
      size.toUpperCase() === currentSize.toUpperCase() ? size : undefined;

    setSelectedSize(newSize);
    setSizeSelection(true);
    setVariantSelectionVisible(false);
    changeVariant(tenant, variantId, store, regionCode);
  };

  const operators = getUniqueOperators(product.variants, currentColorName);

  const operatorChangeHandler = (connectOperator) => {
    if (!connectOperator) {
      setSizeSelection(false);
      setVariantSelectionVisible(true);
      setSelectedOperatorId('');
      return;
    }
    const variantId = getFirstMatchingVariantId(variants, {
      colorName: currentColorName,
      size: selectedSize,
      connectOperator
    });
    setSelectedOperatorId(variantId);
    setSizeSelection(true);
    setVariantSelectionVisible(false);
    changeVariant(tenant, variantId, store, regionCode);
  };

  useEffect(() => {
    if (!selectedOperatorId && isConnectProduct) {
      selectCheapestVariant(currentColorName);
    }

    // [FAFB-886] : When variants has operators but viewTemplate !== 'CONNECT'
    if (!isConnectProduct && operators.length !== 0) {
      setSelectedOperatorId(operators[0].id);
      setSizeSelection(true);
      setVariantSelectionVisible(false);
    }
  }, [operators]);

  const [storeSkuData, setStoreSkuData] = useState({});
  const vendorDataExists = Object.entries(vendorData).length > 0;

  const fetchDataForAgent = async () => {
    const response = await requestSkuStocksFromStore(
      currentVariant,
      vendorData,
      regionCode
    );
    if (response && response.success && response.state.SRXProduct) {
      setStoreSkuData(response.state.SRXProduct);
    }
    return null;
  };

  const isSizeAvailable = (sizeList) => {
    const selected = sizeList.find((size) => size.value === selectedSize);
    return selected && selected.available;
  };

  const validateSelectedSize = () => {
    if (!selectedSize) {
      return;
    }
    if (!isSizeAvailable(sizes)) {
      setSelectedSize(null);
      setVariantSelectionVisible(true);
      setSizeSelection(false);
    }
  };

  const m2AttributeValue = productUtils.getM2ArrtibuteValue(attributes);

  const [m2Quantity, setM2Quantity] = useState(1);
  const [m2ResultValue, setM2ResultValue] = useState(0);
  const [m2Value, setM2Value] = useState('');
  const [isExtraM2Checked, setIsExtraM2Checked] = useState(true);

  const handleM2ValueChange = (evt) => {
    const re = /(^[0-9\b]+$|^\d*,{1}(\d)?$)/;
    if (evt.target.value === '' || re.test(evt.target.value)) {
      setM2Value(evt.target.value);
    }
  };

  const handleExtraM2Change = (isSelected) => {
    setIsExtraM2Checked(isSelected);
  };

  const handleM2ResultChange = (value) => {
    if (!Number.isNaN(value)) {
      setM2ResultValue(value);
      return;
    }
    setM2ResultValue(0);
  };
  const handleM2QuantityChange = (quantity) => {
    if (quantity && !Number.isNaN(quantity)) {
      setM2Quantity(quantity);
      return;
    }
    setM2Quantity(1);
  };

  useEffect(() => {
    validateSelectedSize();
  }, [sizes]);

  useEffect(() => {
    if (sizes.length === 1 && sizes[0].available) {
      setSelectedSize(sizes[0].value);
      setSizeSelection(true);
    } else {
      setSelectedSize(null);
      setSizeSelection(false);
    }
  }, [selectedColorSwatchIndex]);

  useEffect(() => {
    if (!isOutOfStock) {
      setColorIndex(getSelectedColorSwatchIndex(colorVariants, currentVariant));
    }
  }, [currentVariant, product]);
  const handleUnload = () => {
    const eventToDigitalData = new CustomEvent('DDPDPSetFinalAddToCartMetric', {
      bubbles: true
    });
    window.dispatchEvent(eventToDigitalData);
  };
  useEffect(() => {
    if (isVendorLogin && vendorDataExists) fetchDataForAgent();
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);
  const [isAddToCartInProgress, setIsAddToCartInProgress] = useState(false);

  const addToCartButtonVisible =
    attributes && attributes.viewTemplate !== 'TYPE_A';
  const showCollectionButtonVisible = !addToCartButtonVisible;
  const timeputForLoginModalOn401 = _.get(
    appCtx,
    'siteConfig.textDictionary.TIMEOUT_FOR_LOGIN_MODAL',
    1000
  );
  const isFloorCalculatorEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isFloorcalculatorEnabled',
    true
  );

  const handleAddToCart = (quantity) => {
    sendIsColorPurshased(currentColorName);
    if (isAddToCartInProgress) {
      return;
    }

    if (showCollectionButtonVisible) {
      const typeAElement = document.getElementById('typeA-variants');
      if (!typeAElement) return;
      typeAElement.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    const sellerId = _.get(product, 'variants[0].offerings[0].sellerId', '');
    let products = {
      skuId,
      productId,
      quantity:
        m2AttributeValue > 0 && isFloorCalculatorEnabled
          ? m2Quantity
          : quantity,
      offeringId,
      isInternational: _.get(product, 'internationalShipping.applicable', false)
    };

    if (warrantyOptionCurrent) {
      products = {
        ...products,
        warrantySkuId: warrantyOptionCurrent.value,
        warrantyOfferingId: warrantyOptionCurrent.offeringId || ''
      };
    }
    if (serviceOption && serviceOption.length) {
      products = {
        ...products,
        serviceOptions: serviceOption
      };
    }

    setIsAddToCartInProgress(true);
    setErrorAddingToCart('');

    cart
      .addProduct({
        appCtx,
        replaceCartItems: true,
        products: [products],
        isBuyNowApplicable: cart.isBuyNowApplicable(
          _.get(appCtx, 'siteConfig.toggles'),
          sellerId
        )
      })
      .then((res) => {
        if (res && res.length) {
          const errorMessage = getErrorMessage(res, appCtx.siteConfig);
          const errorCode = _.get(res, '[0].code', '');
          setErrorAddingToCart(errorMessage);
          if (errorCode === constants.ATC_SESSION_EXPIRED_CODE) {
            setIsAddToCartInProgress(false);
            setTimeout(() => {
              cart.setShowLoginForm(true);
            }, timeputForLoginModalOn401);
          }
        } else {
          track('addToCart');
          const eventToDigitalData = new CustomEvent(
            'DDPDPATCQuantitySelector',
            {
              detail: {
                maxCartQuantity: maxOnCartAmount,
                value: inputVal
              },
              bubbles: true
            }
          );
          window.dispatchEvent(eventToDigitalData);
        }
        setIsAddToCartInProgress(false);
      });
  };

  const checkForSelectedVariant = (quantity) => {
    const isVariantSelectionApplicable =
      (sizes && sizes.length > 0) || (operators && operators.length > 0);
    if (
      !showCollectionButtonVisible &&
      isVariantSelectionApplicable &&
      !isSizeSelected
    ) {
      setQuantityWhileVariantSelectionVisible(quantity);
      setShowCart(true);
      setVariantSelectionVisible(true);
    } else {
      setQuantityWhileVariantSelectionVisible(null);
      handleAddToCart(quantity);
      setVariantSelectionVisible(false);
    }
  };

  const productSpecificationsProps = {
    isPumAvailable,
    pumQuantity,
    primeShipping,
    colorVariants,
    selectedColorSwatchIndex,
    colorSwatchHandler,
    selectedSize,
    m2AttributeValue,
    handleM2QuantityChange,
    m2Quantity,
    m2ResultValue,
    handleM2ResultChange,
    m2Value,
    handleM2ValueChange,
    isExtraM2Checked,
    handleExtraM2Change,
    sizes,
    sizeChangeHandler,
    handleAddToCart: checkForSelectedVariant,
    isAddToCartInProgress,
    operators,
    operatorChangeHandler,
    addToCartButtonVisible,
    showCollectionButtonVisible,
    setWarrantyOption: setWarrantyOptionCurrent,
    setServiceOption,
    isVendorLogin,
    storeSkuData,
    currentTab,
    selectedOperatorId,
    errorAddingToCart,
    requestProductFromStore,
    setShowLoginForm: cart.setShowLoginForm,
    showLoginForm: cart.showLoginForm,
    setErrorAddingToCart,
    colorMetrics
  };

  return layoutType === constants.PRODUCT_CONSTANTS.HARDLINE ? (
    <HardlineProductSpecifications {...productSpecificationsProps} />
  ) : (
    <SoftlineProductSpecifications {...productSpecificationsProps} />
  );
};

ProductSpecifications.defaultProps = {
  layoutType: constants.PRODUCT_CONSTANTS.HARDLINE,
  currentTab: '',
  vendorData: {}
};

ProductSpecifications.propTypes = {
  layoutType: PropTypes.string,
  currentTab: PropTypes.string,
  vendorData: PropTypes.object,
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  isVendorLogin: PropTypes.bool.isRequired,
  digitalData: PropTypes.object.isRequired
};

export { ProductSpecifications };
export default withUser(
  withCart(
    withProduct(withApplicationContext(withDigitalData(ProductSpecifications)))
  )
);
