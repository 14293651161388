import css from 'styled-jsx/css';

const CmrOptionListStyle = css`
  .cmrOptionsContainer {
    display: flex;
    flex-direction: column;
    max-height: 315px;
  }
  .cmr-line-divider {
    width: 100%;
    height: 1px;
    background: #f0f0f0;
  }

  .cmr-text-icon {
    margin-left: 1.25%;
  }
  .cmr-al-pagar {
    margin-left: 1.25%;
  }
`;

const CmrOptionsHeaderStyle = css`
  .cmr-list-title {
    height: 72px;
    padding-left: 32px;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    width: 480px;
    box-sizing: border-box;
    color: #333;
    @mixin mobileToTablet {
      width: 100%;
      padding-left: 25px;
      font-size: 18px;
    }
    @mixin smallerMobile {
      padding-left: 17px;
    }
    .cmr-al-pagar {
      margin-left: 6px;
    }
    .cmr-text-icon {
      width: 54px;
      height: 26px;
      margin-left: 6px;
    }
    .close-btn-container {
      display: flex;
      margin-left: auto;
      margin-right: 28px;
      justify-content: end;
      .close-btn {
        width: 17px;
        height: 17px;
        cursor: pointer;
        z-index: 20;
      }
    }
  }
`;
export { CmrOptionListStyle, CmrOptionsHeaderStyle };
