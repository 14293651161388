import React from 'react';
import PropTypes from 'prop-types';
import styles from './InternationalBuyModal.style';
import { withProduct } from '../../../utils/ProductContext';
import { isAnInternationalPhone } from '../../../utils/product/productUtils';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { extractInternationalModalLabels } from './InternationalBuyModalConfig';
import _ from '../../../utils/LodashImports';

const InternationalBuyModal = ({ actions, product, appCtx }) => {
  const labels = _.get(appCtx, 'siteConfig.textDictionary', {});
  const endpoints = _.get(appCtx, 'siteConfig.endpoints', {});
  const modalLabels = extractInternationalModalLabels({
    labels,
    endpoints,
    store: appCtx.store
  });
  const hideFooter = !modalLabels.footerLink;
  React.useEffect(() => {
    actions.setCustomHeader(
      <React.Fragment>
        <div className="modal-header">
          <p className="modal-title">{modalLabels.title}</p>
        </div>
        <style jsx>{styles}</style>
      </React.Fragment>
    );
  }, []);

  return (
    <div className={`modal-container ${hideFooter ? 'hide-footer' : ''}`}>
      <div className="paragraphs">
        {(modalLabels.descriptionPara1Bold ||
          modalLabels.descriptionPara1Light) && (
          <p className="paragraph">
            <span className="bold">{modalLabels.descriptionPara1Bold} </span>
            {modalLabels.descriptionPara1Light}
          </p>
        )}
        {modalLabels.bulletPoints1 && (
          <div className="notifications-section no-margin">
            <div className="list">
              <ul>
                {modalLabels.bulletPoints1.split('*').map((b) => (
                  <li key={b}>{b}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {isAnInternationalPhone(product, labels) && (
          <p className="paragraph second-paragraph">
            {modalLabels.descriptionPara2Light}{' '}
            <span className="bold">{modalLabels.descriptionPara2Bold}</span>
          </p>
        )}
      </div>
      <div className="notifications-section">
        <p className="title">{modalLabels.bulletPointsTitle}</p>
        <div className="list">
          <p>{modalLabels.bulletPointsDescription}</p>
          <ul>
            {modalLabels.bulletPoints.split('*').map((b) => (
              <li key={b}>{b}</li>
            ))}
          </ul>
        </div>
      </div>
      {modalLabels.footerLink && (
        <div className="rp-modal-footer">
          <div className="divider-line" />
          <div className="footer-wrapper">
            <i className="csicon-alert alertIcon" />
            <p>
              {modalLabels.modalFooter}{' '}
              <a
                id="testId-help-center-btn"
                href={modalLabels.footerLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>{modalLabels.modalFooterUnderline}</b>.
              </a>
            </p>
          </div>
        </div>
      )}
      <style jsx>{styles}</style>
    </div>
  );
};

InternationalBuyModal.propTypes = {
  actions: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
};

export default withProduct(withApplicationContext(InternationalBuyModal));
