import css from 'styled-jsx/css';
import { typography } from '../../../config/styles';

const TypeAVariantDesktopStyles = css`
  .typeAVariant {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &-count {
      margin-top: 30px;
    }

    .addToCart-button {
      &-wrapper {
        margin: 30px 0 0 95px;
        width: 300px;
      }
      &-text {
        font-size: ${typography.sm1};
        letter-spacing: 1.08px;
        line-height: 16px;
        text-transform: uppercase;
        font-weight: bold;
        padding: 0 72px;
        &.mkp {
          font-weight: 400;
          letter-spacing: 0.5px;
          line-height: 23px;
          font-size: 19px;
          padding: 9px 24px;
          text-align: center;
          text-transform: none;
        }
      }
    }
  }
`;

export { TypeAVariantDesktopStyles };
