/* stylelint-disable no-descending-specificity */
import css from 'styled-jsx/css';
import { colors, typography } from '../../config/styles';
import { svgUrl } from '../../utils/staticUrls';

const pricesStyles = css`
  .prices {
    &.isOutOfStock {
      width: 258px;
      @mixin mobileToTablet {
        width: 100%;
        padding-right: 16px;
      }
    }
    .unica-cmr-icon {
      background-image: url(${svgUrl('cmrIcon.svg')});
      height: 15px;
      width: 65px;
      display: inline-block;
      background-repeat: no-repeat;
      &.cmr-icon-faco {
        background-image: url(${svgUrl('cmrIconFACO.svg')});
        background-size: contain;
      }
      &.big-icon {
        height: 17px;
        width: 74px;
      }
      &.cmr-icon-facl {
        height: 15px;
        width: 65px;
      }
    }
    .has-unit-price {
      .unica-cmr-icon {
        margin-bottom: 4px;
      }
    }
    .unica-cmr-icon.pdp-icon {
      height: 15px;
      width: 65px;
      background-size: 65px 15px;
      &.big-icon {
        height: 17px;
        width: 74px;
        background-size: 74px 17px;
        &.cmr-icon-faco {
          background-size: contain;
        }
      }
      &.cmr-icon-facl {
        height: 15px;
        width: 65px;
        background-size: 65px 15px;
      }
      &.pdp-connect-tab-plan {
        color: red;
        width: 65px;
        height: 15px;
        background-size: 65px 15px;
      }
    }
  }
  .prices-1_GRID {
    .unica-cmr-icon {
      background-size: contain;
      width: 100px;
      height: 20px;
    }
  }

  .cmr-icon-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    :global(span) {
      margin-right: 4px;
      &:not([class*='line-height']) {
        line-height: 20px;
      }
    }
  }

  .cart-prices {
    .cmr-icon-container {
      :global(span) {
        &:not([class*='line-height']) {
          line-height: 16px;
        }
      }
    }
  }

  .pdp-prices li {
    display: flex;
    flex-direction: column;
  }

  .ol-1_GRID {
    margin-top: 9px;
    li {
      padding-bottom: 4px;
    }
  }

  .ol-2_GRID {
    margin-top: 9px;
    @mixin desktop {
      li {
        padding-bottom: 4px;
      }
    }
  }

  .li-separation {
    &.pdp-prices {
      & > li:not(:last-child) {
        margin-bottom: 2px;
      }
    }
    &.pod-prices {
      & > li:not(:last-child) {
        margin-bottom: 1px;
      }
      & > li.mb-2px:not(:last-child) {
        margin-bottom: 2px;
      }
    }
    &.cart-prices {
      & > li.mb-2px:not(:last-child) {
        margin-bottom: 2px;
      }
    }
  }

  .pod-prices li,
  .cart-prices li {
    display: flex;
    line-height: 1;
    padding-bottom: 1px;
  }

  .pdp-prices {
    li:nth-child(1) {
      line-height: 29px;

      @mixin desktop {
        line-height: 24px;
        padding-bottom: 2px;
      }
    }

    li:nth-child(2) {
      line-height: 19px;
      @mixin desktop {
        padding-bottom: 2px;
      }
    }

    li:nth-child(3) {
      line-height: 17px;
    }
  }

  .pod-prices,
  .cart-prices {
    li:nth-child(1) {
      line-height: 22px;
    }
    li:nth-child(2) {
      line-height: 22px;
    }

    li:nth-child(3) {
      line-height: 17px;
    }
  }
  .price-extras {
    flex-basis: 100%;
    display: flex;
  }

  .pum-unitforsale {
    font-size: 14px;
    color: ${colors.gray.shade40};
  }

  .pum-price {
    font-size: ${typography.base};
    line-height: 18px;
    color: ${colors.gray.shade52};
  }
  .operator-name {
    color: ${colors.gray.shade19};
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
  }
  .margin-left-6 {
    margin-left: 6px;
  }
  .has-unit-price {
    .cmr-icon-container {
      margin-bottom: 2px;
      :global(span) {
        margin-right: 0;
      }
    }
  }
  .unica-cmr-icon.pdp-icon {
    margin-right: calc(100% - 65px);
    margin-bottom: 2px;
    &.big-icon {
      margin-right: calc(100% - 74px);
    }
    &.cmr-icon-facl {
      margin-right: calc(100% - 65px);
    }
  }
  .mb-18px {
    margin-bottom: 18px;
  }
`;

export { pricesStyles };
