import css from 'styled-jsx/css';
import { colors, icons } from '../../../../config/styles';
import { svgToDataUri } from '../../../../utils/styles/svgMixins';

const ProductSpecificationsMobileStyle = css`
  .product-specifications {
    display: flex;
    flex-direction: column;
    margin-top: 9px;
    &-column {
      width: 100%;
      height: auto;

      @mixin tabletToDesktop {
        height: 700px;
      }
      .stock-table {
        @mixin mobileToPhablet {
          margin-left: 25px;
        }
      }
      span {
        font-weight: bold;
      }
      .color-swatch-container {
        position: relative;
        @mixin mobileToTablet {
          margin: 15px 0;
        }
        .expand-swatch {
          position: absolute;
          right: 0;
          top: 13px;
          font-size: 10px;
        }
      }
    }
  }
  .flex {
    display: flex
  }
  .flex > * {
    flex: 1;
  }
  .prices {
    margin-bottom: 20px;
    &.withMultipurposeBadge {
      margin-bottom: 16px;
    }
  }
  .cmr-points {
    font-size: 11px;
    margin-top: 11px;
    color: ${colors.black.shade7};
  }

  .boomIcon{
    background-image: url("${svgToDataUri(icons.boomIcon)}");
    background-repeat: no-repeat;
    width: 60px;
    height: 13px;
    margin: 10px 0;
    display: block;
  }

  :global(.csicon-arrow_right) {
    margin-left: 10px;
  } 

  .add-to-cart-wrapper {
    display: flex;
  }


  .multipurpose-badge {
    width: 100%;
    margin-bottom: 18px;
  }
  .variant-seller-ids {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
`;

export { ProductSpecificationsMobileStyle };
