import apiConfig from '../../config/api/apiConfig';
import httpService from '../httpService';
import _ from '../LodashImports';
import Logger from '../Logger';
import CookieStorage from '../CookieStorage';
import {
  mapProduct,
  segregateProduct,
  cartAlertsAndErrorSeggregator
} from './helpers';
import { getCommerceHeaders } from '../tenantHelper';

const removeProduct = ({ cart, setCart }) => async ({
  appCtx,
  products = [],
  bundledProducts = []
}) => {
  const { regionCode, store } = appCtx;
  const isSoCom = store === 'so_com';
  const cartId = CookieStorage.getCookie('cartId');
  const { OFFER_PRICE_LABEL, NORMAL_PRICE_LABEL } = _.get(
    appCtx,
    'siteConfig.textDictionary',
    {}
  );
  const toggles = _.get(appCtx, 'siteConfig.toggles', {});
  const combinedProducts = [...products, ...bundledProducts];

  const patchCart = [];

  combinedProducts.forEach(
    ({
      cartLineId,
      totalQuantity,
      warrantycartLineId,
      servicecartLineId,
      quantity = 1
    }) => {
      patchCart.push({
        op: 'replace',
        path: `${cartLineId}/quantityNumber`,
        value: totalQuantity - quantity
      });
      if (warrantycartLineId && totalQuantity > 1) {
        patchCart.push({
          op: 'replace',
          path: `${warrantycartLineId}/quantityNumber`,
          value: totalQuantity - quantity
        });
      }
      if (servicecartLineId && totalQuantity > 1) {
        patchCart.push({
          op: 'replace',
          path: `${servicecartLineId}/quantityNumber`,
          value: totalQuantity - quantity
        });
      }
    }
  );
  const reqBody = {
    data: {
      cart: {
        patchCart
      }
    },
    metadata: {
      priceGroup: 'default'
    }
  };

  reqBody.metadata.politicalAreaId = appCtx.politicalId;
  reqBody.metadata.priceGroup = appCtx.priceGroupId;

  const additionalHeaders = getCommerceHeaders(regionCode, true);

  const patchCartLineUrl = apiConfig.patchCartlineCommerceUrl(
    cartId,
    regionCode
  );

  const { data: response, statusCode } = await httpService().patch(
    patchCartLineUrl,
    reqBody,
    {
      credentials: 'include'
    },
    additionalHeaders,
    'json'
  );

  const items = _.get(response, 'data.cart.cartLines', []);

  const alerts = _.get(response, 'data.data.cart.cartAlerts', []);
  const { limitAlerts } = cartAlertsAndErrorSeggregator(
    alerts,
    appCtx.siteConfig
  );
  const totalProductsQuantityCount = _.get(
    response,
    'data.cart.totalProductsQuantityCount',
    0
  );
  const totalProductsCount = totalProductsQuantityCount;

  if (statusCode === 200 && items) {
    const mappedProducts = await items.map((item) =>
      mapProduct(
        item,
        regionCode,
        { OFFER_PRICE_LABEL, NORMAL_PRICE_LABEL },
        toggles,
        isSoCom
      )
    );

    const mappedItem = segregateProduct({
      mappedProducts,
      products,
      action: 'REMOVE',
      bundledProducts,
      bundleItems: cart.bundleItems,
      cartItems: cart.cartItems
    });

    setCart({
      ...cart,
      ...mappedItem,
      totalProductsCount,
      isAtgPrice: false,
      limitAlerts
    });
  } else {
    const { errors } = response;
    Logger.error(`Failed to Remove Product ${JSON.stringify(products)}`);
    return errors || [];
  }
  return null;
};

export default removeProduct;
