import React from 'react';
import PropTypes from 'prop-types';
import { DecrementStyles } from './Decrement.style';
import { useProductCounterContext } from '../ProductCounterContext';

const Decrement = ({ className, minimum, onClick, style, type, id }) => {
  const { count } = useProductCounterContext();

  return (
    <>
      <button
        id={id}
        className={`decrement btn-${type} ${className}`}
        disabled={count <= minimum}
        onClick={() => onClick()}
        style={style}
        type="button"
      >
        <span>&#8722;</span>
      </button>

      <style jsx>{DecrementStyles}</style>
    </>
  );
};

Decrement.defaultProps = {
  className: '',
  style: {},
  type: 'primary',
  id: 'decrement-button'
};

Decrement.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  minimum: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  type: PropTypes.oneOf(['primary', 'secondary'])
};

export { Decrement };
