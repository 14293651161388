import css from 'styled-jsx/css';
import colors from '../../../../config/styles/colors';
import { svgToDataUri } from '../../../../utils/styles/svgMixins';
import { videoIcon } from '../../ImageGallery.style';

const ImageGalleryCarouselStyles = css`
  .carousel {
    overflow: hidden;
    margin: 0 10px;

    .carousel-wrapper {
      outline: none;
      display: flex;

      .image-wrapper {
        position: relative;
        outline: none;
        height: 74px;

        &.video {
          background: url("${svgToDataUri(videoIcon)}");
          background-size: 65px 65px;
          background-clip: border-box;
          background-repeat: no-repeat;
          background-position: 50% 50%;
        }

        &.active::before {
          content: '';
          position: absolute;
          height: 3px;
          left: 5px;
          right: 5px;
          background-color: ${colors.green.shade11};
          bottom: 0;
        }

        :global(img), .image-carousel {
          margin: 0 5px;
          cursor: pointer;
          height: 74px;
          width: 74px;
        }
      }
    }
    &.rebranded {
      .carousel-wrapper .image-wrapper.active::before {
        height: 5px;
        background-color: ${colors.secondary_color};
        z-index: 1;
      }
    }
  }

  .dark {
    background-color: black;
    opacity: 0.3;
  }
`;

export { ImageGalleryCarouselStyles };
