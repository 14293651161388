import css from 'styled-jsx/css';
import { colors, icons, typography, zIndex } from '../../../config/styles';
import { svgToDataUri, svgReplaceColor } from '../../../utils/styles/svgMixins';

const AddToCartButtonMobile = css`
  .add-quantity-and-add-to-cart-container {
    box-shadow: 0 -3px 26px rgba(0, 0, 0, 0.1);
    background-color: ${colors.white.shade1};
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px 16px;
    z-index: ${zIndex.pdp.addToCartButton};
    .add-quantity-container {
      .counter {
        width: 112px;
        margin-bottom: 10px;
      }
      .max-units-text {
        font-weight: 400;
        font-size: 12px;
        line-height: 14.4px;
        color: ${colors.gray.shade19};
        white-space: nowrap;
      }
    }
    .flex-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
    }
    .addToCart-container {
      width: 100%;
      height: 44.5px;
      margin-left: 10px;
      :global(.csicon-arrow_down_small) {
        margin-left: 9px;
        font-size: ${typography.xs2};
      }
      .add-to-cart-floor {
        display: flex;
      }
      .quantity-selector {
        margin-right: 18px;
      }
      .addToCart-button-container {
        :global(.button) {
          height: 45.5px;
          width: 182px;
        }
      }
      .add-to-cart-text {
        display: flex;
        align-items: center;
        justify-content: center;
        .cart-icon {
          margin-left: 7px;
          width: 20.33px;
          height: 18.21px;
          background-image: url("${svgToDataUri(
            svgReplaceColor(icons.cart, colors.white.shade1)
          )}");
          background-repeat: no-repeat;
          background-size: contain;
          background-position:center;
        }
      }
      &.quantity-input {
        width: 182px;
        height: 44.5px;
      }
    }
    .limit-execed-text {
      background-color: ${colors.yellow.shade4};
      padding: 4.75px 9.25px;
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 16px;
      .warning-icon {
          width: 18.5px;
          height: 18.5px;
          background-image: url('${svgToDataUri(icons.floorCalcWarningIcon)}');
          background-size: contain;
          background-repeat: no-repeat;
          margin-right: 9.25px;
        }
        p {
          color: ${colors.black.shade1};
          font-size: 12px;
        }
    }
  }
  .add-quantity-and-add-to-cart-container.with-buy-now {
    padding: 12px 16px;
    .flex-wrapper {
      display: block;
    }
    .add-quantity-container {
      display: block;
      .counter {
        display: inline-block;
      }
      .max-units-text {
        display: inline-block;
        margin-left: 8px;
      }
    }
    .addToCart-container {
      .add-to-cart-text {
        .cart-icon {
          width: 24px;
          height: 24px;
          background-image: url("${svgToDataUri(
            svgReplaceColor(icons.cart, colors.gray.shade62)
          )}");
        }
      }
    }
    .btns-action-wrapper {
      display: flex;
      justify-content: space-between;
      gap:8px;
      .addToCart-container {
        margin-left: 0;
      }
    }
  }
`;

export default AddToCartButtonMobile;
