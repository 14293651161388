const pumApplicableRegion = {
  co: true,
  cl: false,
  pe: false
};

const cmrApplicableRegion = {
  cl: true,
  co: true,
  pe: true
};

export { pumApplicableRegion, cmrApplicableRegion };
