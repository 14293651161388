import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import css from 'styled-jsx/css';

const defaultStyles = css``;

const WithOutsideClick = ({ children, onOutsideClick, className, styles }) => {
  const node = useRef();

  const handleClick = (e) => {
    if (!(node.current && node.current.contains(e.target))) {
      onOutsideClick(e);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, []);

  return (
    <div
      className={className || 'doc-click-overlay'}
      ref={(r) => {
        node.current = r;
      }}
    >
      {children}
      <style jsx>{styles}</style>
    </div>
  );
};

WithOutsideClick.defaultProps = {
  className: '',
  styles: defaultStyles
};

WithOutsideClick.propTypes = {
  children: PropTypes.any.isRequired,
  onOutsideClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  styles: PropTypes.string
};

export default WithOutsideClick;
