import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';
import ProductTopSpecifications from '../ProductTopSpecifications/ProductTopSpecifications';
import ProductPrices from '../ProductPrices/ProductPrices';
import { withProduct } from '../../utils/ProductContext';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { ProductSpecificationConnectStyles } from './ProductSpecificationConnect.style';
import { withLabels } from '../../utils/LabelsContext';
import LinkButton from '../ui/LinkButton/LinkButton';
import _ from '../../utils/LodashImports';
import constants from '../../config/constants';
import Heading from '../ui/Heading/Heading';
import { getUniqueOperators, getVariant } from '../../utils/variant';
import WithUrl from '../../utils/WithUrl';

const getMinimumProductPrice = (prices) => {
  return Array.isArray(prices) && prices.length ? [prices[0]] : [];
};

const ProductSpecificationConnect = ({ product, appCtx, labels }) => {
  const { regionCode, deviceType, isRebrandingEnabled = false } = appCtx;
  const { id: productId, name: productName } = product;
  const { currentVariant, variants } = product;
  const variant = getVariant(variants, currentVariant);

  const { deviceCost = [], deviceCmrPoints: cmrPoints, monthlyCost } = variant;

  const activeVariant = variants.find((v) => {
    return v.id === currentVariant;
  });

  const shouldRenderSpecifications = deviceType === constants.DEVICE_DESKTOP;
  const isMobile = deviceType === constants.DEVICE_MOBILE;
  const isMobileOrTablet = [
    constants.DEVICE_MOBILE,
    constants.DEVICE_TABLET
  ].includes(deviceType);
  const minProductPrice = getMinimumProductPrice(deviceCost);

  const productColorCode = _.get(activeVariant, 'attributes.colorName', '');
  const operators = getUniqueOperators(variants, productColorCode);
  const isViewPlanActive =
    operators.length > 0 && minProductPrice.length > 0 && monthlyCost;

  let displayPrice = [];
  if (minProductPrice && minProductPrice.length > 0) {
    displayPrice = minProductPrice;
  }
  const planUrl = WithUrl('PLAN_URL', regionCode, {
    custom: {
      productId,
      productName: productName.replace('/', '-'),
      variantId: activeVariant.id
    }
  });
  return (
    <div
      className="product-specifications"
      data-backend-category={product.merchantCategoryId}
      data-availability={
        product.attributes && product.attributes.isPrimeAvailable
      }
      data-published={product.isPublished}
      id="testId-ProductSpecificationConnect-container"
    >
      <div className="product-specifications-column">
        {shouldRenderSpecifications && (
          <ProductTopSpecifications
            isRebrandingEnabled={isRebrandingEnabled}
            scollToElementId="productInfoContainer"
          />
        )}
      </div>
      <div
        className={classname('product-specifications-column', {
          'product-specifications-column-panel': isMobile
        })}
      >
        {isViewPlanActive ? (
          <Fragment>
            {displayPrice && (
              <div className="connect-prices">
                <div className="info-message">{labels.TEAM_FROM_LABEL}</div>
                <ProductPrices
                  prices={displayPrice}
                  productId={productId}
                  regionCode={regionCode}
                  cmrPoints={cmrPoints}
                  isMobile={isMobile}
                  discountBadge={variant.discountBadge}
                />
              </div>
            )}
            <div className="plan-review-container">
              <div className="info-message">{labels.IDEAL_PLAN_LABEL}</div>
              <p className="plan-review-text">{labels.PLAN_REVIEW_LABEL}</p>
            </div>
            <div className={isMobileOrTablet ? 'viewPlans-container' : ''}>
              <LinkButton
                href={planUrl}
                type={isRebrandingEnabled ? 'rebranding' : 'primary'}
                size={
                  isRebrandingEnabled && !isMobileOrTablet ? 'large' : 'medium'
                }
                id="testId-ProductSpecificationConnect-viewPlan"
              >
                <span className={isRebrandingEnabled ? '' : 'panel-label'}>
                  {isRebrandingEnabled
                    ? labels.VIEW_PLANES_LABEL_REBRANDING
                    : labels.VIEW_PLANES_LABEL}
                </span>
              </LinkButton>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <Heading labels={3} variation="title8">
              {labels.NO_PLAN_TITLE}
            </Heading>
            <p className="no-plan-text">{labels.NO_PLAN_LABEL}</p>
          </Fragment>
        )}
      </div>
      <style jsx>{ProductSpecificationConnectStyles}</style>
    </div>
  );
};

ProductSpecificationConnect.propTypes = {
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired
};

export { ProductSpecificationConnect };
export default withProduct(
  withApplicationContext(withLabels(ProductSpecificationConnect))
);
