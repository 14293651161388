import css from 'styled-jsx/css';
import { icons, colors } from '../../../config/styles';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

export const CustomModalStyles = css`
  .modal-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    position: relative;
    padding: 20px 30px;
    border-bottom: 1px solid ${colors.gray.shade14};
    @mixin mobileToTablet {
      padding: 30px 0 21px 17px;
    }
    i {
      width: 32px;
      height: 32px;
      background-image: url(${svgToDataUri(icons.stockEnTienda)});
      background-repeat: no-repeat;
      background-size: contain;
    }
    p.modal-heading {
      margin-left: 16px;
      font-size: 2rem;
      line-height: 24px;
      @mixin tabletToDesktop {
        height: 24px;
      }
      @mixin mobileToTablet {
        font-size: 2.2rem;
        line-height: 26.4px;
      }
    }
  }
  .modal-content {
    padding: 16px 32px;

    @mixin mobileToTablet {
      padding: 16px 17px;
    }
  }
  .no-padding {
    padding: 0;
  }
  .modal-footer {
    position: absolute;
    bottom: 0;
    left: 32px;
    right: 32px;
    padding: 16px 0;
    border-top: 1px solid #f0f0f0;
    display: flex;
    z-index: 1;
    background: ${colors.white.shade1};
    @mixin mobileToTablet {
      /* stylelint-disable declaration-no-important, length-zero-no-unit */
      box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.05);
      width: 100%;
      left: 0;
      right: 0;
      padding-left: 16px;
      padding-right: 16px;
      align-items: center;
    }
  }
`;
