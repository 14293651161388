import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { cssTriangleStyles } from './SizeEquivalencies.style';
import MatchMedia from '../../../ui/MatchMedia/MatchMedia';
import SizeButton from '../SizeButton/SizeButton';
import EquilancyTable from './EquilancyTable/EquilancyTable';

const SizeEquivalencies = ({
  value,
  available,
  index,
  onSizeChange,
  selectedSize,
  sizeMap,
  regionCode,
  isRebrandingEnabled
}) => {
  const tenantSize = regionCode.toUpperCase();

  const EquilancyTableForMobile = () => (
    <Fragment>
      <div className={`pointer ${isRebrandingEnabled && 'rebranded'}`} />
      <EquilancyTable
        isMobile
        sizeMap={sizeMap}
        tenantValue={value}
        tenantSize={tenantSize}
        isRebrandingEnabled={isRebrandingEnabled}
      />
      <style jsx>{cssTriangleStyles}</style>
    </Fragment>
  );

  const isSelectedSize = (val) =>
    selectedSize && val.toUpperCase() === selectedSize.toUpperCase();

  return (
    <div className="button-wrapper">
      <SizeButton
        value={value}
        available={available}
        selectedSize={selectedSize}
        index={index}
        onSizeChange={onSizeChange}
        isRebrandingEnabled={isRebrandingEnabled}
      />
      {isSelectedSize(value) && sizeMap && (
        <MatchMedia
          content={{
            mobile: () => <EquilancyTableForMobile />,
            tablet: () => <EquilancyTableForMobile />,
            desktop: () => (
              <EquilancyTable
                sizeMap={sizeMap}
                tenantValue={value}
                tenantSize={tenantSize}
                isRebrandingEnabled={isRebrandingEnabled}
              />
            )
          }}
        />
      )}
    </div>
  );
};

SizeEquivalencies.defaultProps = {
  sizeMap: undefined,
  isRebrandingEnabled: false
};

SizeEquivalencies.propTypes = {
  value: PropTypes.string.isRequired,
  available: PropTypes.bool.isRequired,
  selectedSize: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  onSizeChange: PropTypes.func.isRequired,
  sizeMap: PropTypes.object,
  regionCode: PropTypes.object.isRequired,
  isRebrandingEnabled: PropTypes.bool
};

export default SizeEquivalencies;
