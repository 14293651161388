import css from 'styled-jsx/css';
import { colors } from '../../../config/styles';
import _ from '../../../utils/LodashImports';

const ComparativeTableTabStyles = (theme) => {
  const secondaryColor =
    _.get(theme, 'secondary_color', null) || colors.secondary_color;
  return css`
    iframe.sizechart-template {
      width: 100%;
      height: inherit;
      @mixin desktop {
        display: block;
        min-width: 400px;
        max-width: 760px;
      }
    }

    .sizechart-container {
      width: 100%;
      height: 80vh;
    }

    .raw-tab-content {
      width: 100%;
      overflow-anchor: none;
      overflow-x: scroll;
      @mixin desktop {
        overflow-x: unset;
        padding-bottom: 14px;
      }
      @mixin mobileToDesktop {
        padding-bottom: 14px;
      }
      @mixin mobileToTablet {
        padding-bottom: 7px;
      }
      .similar.hidden {
        display: none;
      }
      .same-seller.hidden {
        display: none;
      }
    }
    .raw-tab {
      width: 100%;
      background: ${colors.white.shade1};
      font-family: Lato, sans-serif;
      overflow-anchor: none;
    }
    ul {
      margin: auto;
      padding: 22px 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .tab-button {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      text-align: center;
      margin: 0 auto;
      color: ${colors.black.shade3};
    }
    li {
      flex: 1;
      align-self: flex-end;
      width: 100%;
      border-bottom: 1px solid ${colors.gray.shade61};
      padding-bottom: 8px;
      color: ${colors.black.shade1};
      &.active {
        border-bottom: 2px solid ${secondaryColor};
        @mixin tabletToDesktop {
          border-bottom: 3px solid ${secondaryColor};
        }
        .tab-button {
          color: ${secondaryColor};
        }
      }
    }
    .raw-tab.desktop {
      ul {
        padding: 30px 10px 51px 38px;
        .tab-button {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
  `;
};
export { ComparativeTableTabStyles };
