import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs } from '@digital-retail/falabella-ui-cross-components';
import SoftLineDesktopStyles from './SoftLineDesktop.style';
import PDPBasicInfoDesktop from './containers/PDPBasicInfoDesktop/PDPBasicInfoDesktop';
import ProductSpecifications from '../../ProdctSpecifications/ProductSpecifications';
import ComboItemsDesktop from '../../../components/ComboSection/ComboItemsDesktop';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withProduct } from '../../../utils/ProductContext';
import { withLabels } from '../../../utils/LabelsContext';
import { withUser } from '../../../utils/UserContext';
import ProductOutOfStock from '../../../components/ProductOutOfStock/ProductOutOfStock';
import constants from '../../../config/constants';
import ImageGallerySoftline from '../../../components/ImageGallery/ImageGallerySoftline';
import Helpline from '../../Helpline/HelpLine';
import ConnectTab from '../../HardLineProductContainer/ConnectTab/ConnectTab';
import ReturnPolicy from '../../ReturnPolicy/ReturnPolicy';
import { getVariant } from '../../../utils/variant';
import _ from '../../../utils/LodashImports';

const PDPDesktop = ({ product, labels, isVendorLogin, appCtx }) => {
  const {
    isOutOfStock,
    breadCrumb,
    name: productName,
    variants,
    currentVariant
  } = product;
  const variant = getVariant(variants, currentVariant);
  const comboData = _.get(variant, 'comboData', null);
  const productSpecification = (
    <ProductSpecifications layoutType={constants.PRODUCT_CONSTANTS.SOFTLINE} />
  );
  const productSpecificationView = () => {
    if (!isVendorLogin) return productSpecification;

    const tabData = [
      {
        title: (flag) => (
          <div className="flex">
            {!flag ? (
              <span className="icon internet-icon-active" />
            ) : (
              <span className="icon internet-icon-disabled" />
            )}
            <p>INTERNET</p>
            <style jsx>{SoftLineDesktopStyles}</style>
          </div>
        ),
        content: () => productSpecification
      },
      {
        title: (flag) => (
          <div className="flex">
            {!flag ? (
              <span className="icon tienda-active" />
            ) : (
              <span className="icon tienda-disabled" />
            )}
            <p>TIENDA</p>
            <style jsx>{SoftLineDesktopStyles}</style>
          </div>
        ),
        content: () => (
          <ProductSpecifications
            layoutType={constants.PRODUCT_CONSTANTS.SOFTLINE}
            currentTab="Tienda"
          />
        )
      }
    ];
    return <ConnectTab tabData={tabData} />;
  };

  if (isOutOfStock) {
    return (
      <Fragment>
        <div className="pdp-body">
          <div className="pdp-body-container container">
            <Breadcrumbs
              items={breadCrumb}
              productName={productName}
              isPdpPage
              tenant={appCtx.regionCode}
              host={appCtx.host}
              path={appCtx.url}
            />
            <div className="pdp-container container out-of-stock">
              <section className="pdp-image-section">
                <ImageGallerySoftline />
                {comboData && <ComboItemsDesktop comboData={comboData} />}
              </section>
              <section className="pdp-details-section">
                <div className="pdp-details-section-body">
                  <ProductOutOfStock />
                </div>
                <section className="pdp-details-section-header">
                  <PDPBasicInfoDesktop product={product} labels={labels} />
                  {productSpecificationView()}
                </section>
              </section>
            </div>
          </div>
        </div>
        <style jsx>{SoftLineDesktopStyles}</style>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className="pdp-body">
        <div className="pdp-body-container container">
          <Breadcrumbs
            items={breadCrumb}
            productName={productName}
            isPdpPage
            tenant={appCtx.regionCode}
            host={appCtx.host}
            path={appCtx.url}
          />
          <div className="pdp-container container">
            <section className="pdp-image-section">
              <ImageGallerySoftline />
              <Helpline product={product} />
              <ReturnPolicy returnPolicy={product.returnPolicy} />
              {comboData && <ComboItemsDesktop comboData={comboData} />}
            </section>
            <section className="pdp-details-section">
              <section className="pdp-details-section-header">
                <PDPBasicInfoDesktop product={product} labels={labels} />
              </section>
              <div className="pdp-details-section-body">
                {isOutOfStock ? (
                  <ProductOutOfStock />
                ) : (
                  productSpecificationView()
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
      <style jsx>{SoftLineDesktopStyles}</style>
    </Fragment>
  );
};

PDPDesktop.propTypes = {
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  isVendorLogin: PropTypes.bool.isRequired,
  appCtx: PropTypes.object.isRequired
};

export { PDPDesktop };
export default withUser(
  withProduct(withLabels(withApplicationContext(PDPDesktop)))
);
