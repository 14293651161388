import _ from '../../LodashImports';
import { findIndex } from '../../jsUtils';

const formatUpdatedWarrantyOptions = (warrantyId, productData) => {
  const product = Object.assign(productData);
  const warrantyOptions = _.get(product, 'warrantyOptions', {});
  const fieldOptions = _.get(product, 'warrantyOptions.fieldOptions', []);
  const newFieldOptions = fieldOptions.map((item) => {
    return {
      ...item,
      selected: Number(item.value) === Number(warrantyId)
    };
  });
  const newWarrantyOptions = {
    ...warrantyOptions,
    fieldOptions: newFieldOptions
  };
  const newProduct = { ...product, warrantyOptions: newWarrantyOptions };
  return newProduct;
};

const updateCartLineId = (
  cart = {},
  setCart,
  warranty,
  variantId,
  channel,
  childProducts,
  method = 'UPDATE',
  prevCartLineId,
  totalProductsCount
) => {
  const childProductIndex = findIndex(
    childProducts,
    (cp) => cp.variantId === warranty.skuId
  );

  const newCartLineId =
    childProductIndex >= 0
      ? _.get(childProducts[childProductIndex], 'cartLineId', '')
      : '';
  const { cartItems = {} } = cart;
  if (newCartLineId || method === 'DELETE') {
    const cartItemIndex = findIndex(
      cartItems,
      (item) => item.skuId === variantId
    );

    if (cartItemIndex >= 0) {
      const cartItem = { ...cartItems[cartItemIndex] };
      const { childProducts: cProducts = [] } = cartItem;

      if (channel === 'warranty') {
        if (method === 'DELETE') {
          cartItem.warrantyOptions.cartLineId = '';
        } else {
          cartItem.warrantyOptions.cartLineId = newCartLineId;
        }
      }

      if (channel === 'service') {
        const serviceOptionIndex = findIndex(
          _.get(cartItem, 'assemblyOptions.fieldOptions', []),
          (as) => as.variantId === warranty.skuId
        );
        if (method === 'DELETE') {
          _.set(
            cartItem,
            `assemblyOptions.fieldOptions[${serviceOptionIndex}].cartLineId`,
            ''
          );
        } else {
          _.set(
            cartItem,
            `assemblyOptions.fieldOptions[${serviceOptionIndex}].cartLineId`,
            newCartLineId
          );
        }
      }

      const mappedChildProducts =
        cProducts.length > 0
          ? cProducts.map((cp) => {
              return cp.cartLineId === prevCartLineId
                ? { ...childProducts[childProductIndex] }
                : { ...cp };
            })
          : childProducts;

      cartItem.childProducts = mappedChildProducts;

      cartItems[cartItemIndex] = cartItem;
    }
  }
  setCart({
    ...cart,
    cartItems,
    totalProductsCount
  });
};

const formatUpdatedServiceOptions = (serviceId, productData) => {
  const product = Object.assign(productData);
  const serviceOptions = _.get(product, 'serviceOptions', {});
  const fieldOptions = _.get(product, 'serviceOptions.services', []);
  const newFieldOptions = fieldOptions.map((item) => {
    return {
      ...item,
      selected: Number(item.value) === Number(serviceId)
    };
  });
  const newServiceOptions = {
    ...serviceOptions,
    services: newFieldOptions
  };
  const newProduct = { ...product, serviceOptions: newServiceOptions };
  return newProduct;
};

export {
  formatUpdatedWarrantyOptions,
  updateCartLineId,
  formatUpdatedServiceOptions
};
