import css from 'styled-jsx/css';
import { colors, typography, zIndex } from '../../../../../config/styles';

const SizeEquilancyStyles = css`
  .size-equilancy-wrapper {
    height: 43px;
    @mixin desktop {
      height: 40px;
    }
  }

  .align-center {
    text-align: center;
  }

  .z-top {
    z-index: ${zIndex.sizeInfo};
  }

  .size-equilancy-table-mobile {
    font-size: ${typography.lg};
    position: absolute;
    left: -15px;
    width: calc(100vw - 32px);
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.green.shade12};
    border-right: transparent;
    border-left: transparent;
    background-color: ${colors.green.shade13};
    margin-top: 3px;
  }

  .size-equilancy-table-desktop {
    font-size: ${typography.base};
    position: absolute;
    left: 0;
    width: 100%;
    background-color: ${colors.green.shade13};
    padding: 8px 0;
  }

  .tenant-size {
    color: ${colors.green.shade12};
    font-weight: bold;
    padding-right: 4px;
  }
  .country-size {
    padding: 0 4px;
    border-right: 1px solid;
    &:last-child {
      border-right: 0 solid;
    }
  }
  .size-equilancy-wrapper.rebranded {
    .size-equilancy-table-desktop,
    .size-equilancy-table-mobile {
      background-color: ${colors.white.shade1};
    }
    .size-equilancy-table-mobile {
      border: 1px solid ${colors.secondary_color};
      border-left: transparent;
      border-right: transparent;
    }
    .tenant-size {
      color: ${colors.secondary_color};
    }
  }
`;

export { SizeEquilancyStyles };
