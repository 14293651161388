import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import BodyCopy from '../../components/ui/BodyCopy/BodyCopy';
import ReturnPolicyStyles from './ReturnPolicy.style';
import MatchMedia from '../../components/ui/MatchMedia/MatchMedia';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withDigitalData } from '../../utils/DigitalDataContext';
import { Modal } from '../../components/ui/Modal/Modal';
import ReturnPolicyModalContent from './ReturnPolicyModal/ReturnPolicyModal';
import _ from '../../utils/LodashImports';

const ReturnPolicyDesktop = ({
  returnPolicy,
  forMobile,
  isRebrandingEnabled,
  deviceType,
  textDictionary,
  toggleState,
  setToggleState,
  regionCode
}) => {
  return (
    <div>
      <div className="return-policy-container" />
      <div className="return-policy-custom-container">
        <div className={`returnIcon ${isRebrandingEnabled && 'rebranded'}`} />
        <div className="return-policy-text">
          <Fragment>
            <BodyCopy size="copy3" type="senary">
              <span
                className="header"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: returnPolicy.headerLabel }}
              />
            </BodyCopy>
            <BodyCopy size={`${forMobile ? 'copy5' : 'copy3'}`} type="senary">
              <button
                type="button"
                id="testId-more-information-btn"
                className="open-modal-btn"
                onClick={() => setToggleState(!toggleState)}
              >
                <span>{returnPolicy.linkLabel}</span>
                <i className="csicon-right" />
              </button>
            </BodyCopy>
          </Fragment>
        </div>
      </div>
      <Modal
        content={(actions) => (
          <ReturnPolicyModalContent
            actions={actions}
            textDictionary={textDictionary}
            url={returnPolicy.linkURL}
            deviceType={deviceType}
            tenant={regionCode}
          />
        )}
        options={{
          height: 530,
          width: 500,
          overflow: 'hidden'
        }}
        withPadding={false}
        closeBtnStyles={{ top: '23px', right: '25px' }}
        deviceType={deviceType}
        toggleState={{
          visible: toggleState,
          setVisible: (bool) => setToggleState(bool)
        }}
        isRebrandingEnabled={isRebrandingEnabled}
      />
      <style jsx>{ReturnPolicyStyles}</style>
    </div>
  );
};

ReturnPolicyDesktop.defaultProps = {
  forMobile: false,
  isRebrandingEnabled: false,
  toggleState: false
};

ReturnPolicyDesktop.propTypes = {
  returnPolicy: PropTypes.string.isRequired,
  forMobile: PropTypes.bool,
  isRebrandingEnabled: PropTypes.bool,
  textDictionary: PropTypes.object.isRequired,
  deviceType: PropTypes.string.isRequired,
  toggleState: PropTypes.bool,
  setToggleState: PropTypes.func.isRequired,
  regionCode: PropTypes.string.isRequired
};

const ReturnPolicyMobile = ({
  returnPolicy,
  isRebrandingEnabled,
  deviceType,
  textDictionary,
  toggleState,
  setToggleState,
  regionCode
}) => (
  <div>
    <div className="return-policy-container" />
    <div className="return-policy-custom-container mobile-padding">
      <div className={`returnIcon ${isRebrandingEnabled && 'rebranded'}`}>
        <i
          className={`${
            isRebrandingEnabled
              ? 'return-icon-mkp'
              : 'csicon-return-icon mobile-size'
          }`}
        />
      </div>
      <div
        className={`return-policy-text ${isRebrandingEnabled && 'rebranded'}`}
      >
        <Fragment>
          <BodyCopy size="copy3" type="senary">
            <span
              className="header-mobile"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: returnPolicy.headerLabel }}
            />
          </BodyCopy>
          <BodyCopy size="copy3" type="senary">
            <button
              type="button"
              className="open-modal-btn"
              id="testId-more-information-btn"
              onClick={() => setToggleState(!toggleState)}
            >
              <span>{returnPolicy.linkLabel}</span>
              <i className="csicon-right" />
            </button>
          </BodyCopy>
        </Fragment>
      </div>
    </div>
    <Modal
      content={(actions) => (
        <ReturnPolicyModalContent
          actions={actions}
          textDictionary={textDictionary}
          url={returnPolicy.linkURL}
          deviceType={deviceType}
          tenant={regionCode}
        />
      )}
      withPadding={false}
      fullScreen
      stopScroll
      closeBtnStyles={{ top: '16px', right: '20px', fontSize: '16px' }}
      deviceType={deviceType}
      options={{ overflow: 'hidden', disableOutsideClick: true }}
      toggleState={{
        visible: toggleState,
        setVisible: (bool) => setToggleState(bool)
      }}
      isRebrandingEnabled={isRebrandingEnabled}
    />
    <style jsx>{ReturnPolicyStyles}</style>
  </div>
);

ReturnPolicyMobile.defaultProps = {
  isRebrandingEnabled: false,
  toggleState: false
};

ReturnPolicyMobile.propTypes = {
  returnPolicy: PropTypes.string.isRequired,
  isRebrandingEnabled: PropTypes.bool,
  textDictionary: PropTypes.object.isRequired,
  deviceType: PropTypes.string.isRequired,
  toggleState: PropTypes.bool,
  setToggleState: PropTypes.func.isRequired,
  regionCode: PropTypes.string.isRequired
};

const ReturnPolicy = ({ returnPolicy, appCtx, digitalData }) => {
  const { isRebrandingEnabled, deviceType, regionCode } = appCtx;
  const textDictionary = _.get(appCtx, 'siteConfig.textDictionary', {});
  digitalData.pdp.setFreeReturns(!!returnPolicy);
  const [toggleState, setToggleState] = React.useState(false);
  return (
    <Fragment>
      {returnPolicy && (
        <MatchMedia
          content={{
            desktop: () => (
              <ReturnPolicyDesktop
                returnPolicy={returnPolicy}
                isRebrandingEnabled={isRebrandingEnabled}
                deviceType={deviceType}
                textDictionary={textDictionary}
                toggleState={toggleState}
                setToggleState={setToggleState}
                regionCode={regionCode}
              />
            ),
            mobile: () => (
              <ReturnPolicyMobile
                returnPolicy={returnPolicy}
                isRebrandingEnabled={isRebrandingEnabled}
                deviceType={deviceType}
                textDictionary={textDictionary}
                toggleState={toggleState}
                setToggleState={setToggleState}
                regionCode={regionCode}
              />
            ),
            tablet: () => (
              <ReturnPolicyMobile
                returnPolicy={returnPolicy}
                isRebrandingEnabled={isRebrandingEnabled}
                deviceType={deviceType}
                textDictionary={textDictionary}
                toggleState={toggleState}
                setToggleState={setToggleState}
                regionCode={regionCode}
              />
            )
          }}
        />
      )}
    </Fragment>
  );
};

ReturnPolicy.propTypes = {
  returnPolicy: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  digitalData: PropTypes.object.isRequired
};

export default withApplicationContext(withDigitalData(ReturnPolicy));
