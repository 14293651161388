import { isMarketPlace, getSidCookie, isSodimacSite } from './urlHelper';
import storeMap from '../config/storeMap';

const tenants = {
  cl: 'FACL',
  co: 'FACO',
  pe: 'FAPE',
  ar: 'FAAR'
};

export const tenantNameSpace = {
  cl: 'falabella-cl',
  co: 'falabella-co',
  pe: 'falabella-pe',
  ar: 'falabella-ar'
};

const getTenant = (regionCode) => {
  return tenants[regionCode];
};

const getTenantNameSpace = (regionCode) => {
  return tenantNameSpace[regionCode];
};

const getCommerceHeaders = (regionCode, includeAuth = false) => {
  const label = isMarketPlace() ? 'marketplace' : 'falabella';
  const sid = getSidCookie();
  const sodimacLabel = isSodimacSite() ? 'sodimac' : '';
  const siteName = isSodimacSite() ? 'sodimac' : 'default';
  const headers = {
    'x-channel-id': 'WEB',
    'x-ecomm-name': `${label}${sodimacLabel}-${regionCode}`,
    'x-commerce': 'FCM',
    'x-origin-name': 'PDP',
    'x-origin-view': 'Product',
    'x-site-name': siteName
  };
  if (sid && includeAuth) {
    headers.Authorization = sid;
  }
  return headers;
};

const getStoreValue = (seller) => {
  return storeMap[seller] ? seller.toLowerCase() : 'falabella';
};

export { getTenant, getTenantNameSpace, getCommerceHeaders, getStoreValue };
