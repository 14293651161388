import getConfig from 'next/config';
import _ from './LodashImports';
import getImages from './GetImages';
import constants from '../config/constants';
import validStores from '../config/storeMap';
import { getBreadcrumbData } from './SEO/PDPSeo';

const getRegion = (regionCode) => {
  if (regionCode === 'cl' || !regionCode) {
    return '';
  }
  return `.${regionCode}`;
};

const getCanonicalUrl = (
  product,
  region = 'cl',
  store = null,
  storeName = ''
) => {
  const nextConfig = getConfig();
  let { env } = (nextConfig && nextConfig.publicRuntimeConfig) || {
    env: 'www'
  };
  if (env === 'uat') {
    env = 'beta';
  }

  let bu = 'falabella';
  let buSubDomain = env;
  let isMKTSubDomain = false;
  const mappedRegion = getRegion(region);
  const sellerId = _.get(
    product,
    'variants[0].offerings[0].sellerId',
    _.get(product, 'productSites[0]', '')
  );
  const is3pSeller = !constants.FIRST_PARTY_SELLER_REGEX.test(
    sellerId.toLowerCase()
  );
  const productUrl = `${product.id}/${product.slug}/${product.currentVariant}`;

  if (storeName === 'so_com') {
    const domainPerRegion = {
      default: '.cl',
      cl: '.cl',
      pe: '.com.pe'
    };
    return `https://${env}.sodimac${domainPerRegion[region] ??
      domainPerRegion.default}/sodimac-${region}/articulo/${productUrl}`;
  }

  if (store && !is3pSeller) {
    const isValidStore = validStores[store.toLowerCase()];
    if (isValidStore) {
      buSubDomain = env !== 'www' ? `${env}-${store}` : store;
      isMKTSubDomain = ['tienda', 'linio'].includes(store);
      if (!isMKTSubDomain) {
        bu = store;
      }
    }
  }

  return `https://${
    !isMKTSubDomain ? buSubDomain : 'www'
  }.falabella.com${mappedRegion}/${bu}-${region}/product/${productUrl}`;
};

const showStoreSeoTags = (store, page) => {
  return (
    !!store && Object.keys(constants.STORES).includes(store) && page === 'PDP'
  );
};

const getStoreKey = (store) => {
  const storeMap = {
    tottus: 'TOTTUS',
    sodimac: 'SODIMAC',
    linio: 'LINIO',
    tienda: 'TIENDA',
    homecenter: 'HOMECENTER',
    so_com: 'SO_COM'
  };
  return storeMap[store];
};

const getOpenGraphData = (product, appCtx, store = null, page = 'PDP') => {
  const { regionCode, store: storeName } = appCtx;
  const {
    currentVariant: currentVariantId,
    primaryVariantId,
    variants,
    breadCrumb
  } = product;
  const breadcrumbData = getBreadcrumbData(regionCode, store, breadCrumb);
  const lastBreadCrumb = breadcrumbData.reverse().slice(0, 2)[1];
  const category = lastBreadCrumb?.name || '';
  const primaryVariant = variants.find(({ id }) => {
    return id === primaryVariantId;
  });
  const currentVariant = variants.find(({ id }) => {
    return id === currentVariantId;
  });
  const selectedVariant = primaryVariant || currentVariant;
  const mediaUrl = _.get(selectedVariant, 'medias[0].url');
  const sellerName = _.get(
    product,
    'variants[0].offerings[0].sellerName',
    ''
  ).toLowerCase();
  const isCFMediasEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isCFMediasEnabled',
    false
  );
  const imageUrl = getImages({
    variantId: `${currentVariantId || primaryVariantId}_1`,
    isCFMediasEnabled,
    sellerName,
    regionCode,
    url: mediaUrl
  });
  let title = `${product.brandName} ${product.name}`;
  if (regionCode === 'pe' && page === 'PDP') {
    title = `${product.name} ${product.brandName}`;
  }
  const countryName = constants.COUNTRY[regionCode];
  const siteTitle = constants.META.OG[regionCode].TITLE;
  const isStoreMetaTags = showStoreSeoTags(store, page);
  const storeKey = getStoreKey(store);
  const descriptionExpression = isStoreMetaTags
    ? constants.META_SITE.DESCRIPTION[storeKey][regionCode][page]
    : constants.META.DESCRIPTION[regionCode][page];

  const url = getCanonicalUrl(product, regionCode, store, storeName);

  const result = {
    description: descriptionExpression
      .replace(/\$product_name\$/g, product.name)
      .replace(/\$brand_name\$/g, product.brandName)
      .replace(/\$country\$/g, countryName)
      .replace(/\$product_id\$/g, product.id)
      .replace(/\$category\$/g, category)
      .replace('Falabella Chile', 'Falabella.com'),
    canonicalUrl: url,
    url,
    site_name:
      storeName === 'so_com'
        ? constants.META.OG[regionCode].sodimac.SITENAME
        : constants.META.OG[regionCode].SITENAME,
    title: isStoreMetaTags
      ? constants.META_SITE.TITLE[storeKey][regionCode][page]
          .replace(/\$product_name\$/g, product.name)
          .replace(/\$country\$/g, countryName)
          .replace(/\$brand_name\$/g, product.brandName)
      : `${title} | ${siteTitle}`,
    images: [
      {
        url: imageUrl,
        alt: product.name
      }
    ]
  };
  return result;
};

export { getOpenGraphData, getCanonicalUrl, getRegion };
