import _ from './LodashImports';

const bvRef = { current: '' };

const updateBVReviews = (ratings) => {
  window.bvCallback = (BV) => {
    let tries = 0;
    bvRef.current = setInterval(() => {
      if (_.get(BV, 'rating_summary.data.length')) {
        clearInterval(bvRef.current);
        const totalReviews =
          BV &&
          BV.rating_summary &&
          BV.rating_summary.data &&
          BV.rating_summary.data[0] &&
          BV.rating_summary.data[0].data &&
          BV.rating_summary.data[0].data.reviewSummary &&
          BV.rating_summary.data[0].data.reviewSummary.numReviews;
        ratings.setRatings(totalReviews);
      } else if (tries === 20) {
        clearInterval(bvRef.current);
      } else {
        tries += 1;
      }
    }, 300);
  };
};

const abortBVInterval = () => {
  clearInterval(bvRef.current);
};

export { updateBVReviews, abortBVInterval };
