import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import bazaarVoice from './BazaarVoice.styles';
import bvRebrandingStyles from './BVRebranding.styles';
import WithUrl from '../../utils/WithUrl';

const BazaarVoiceHead = ({ country, isRebrandingEnabled }) => {
  return (
    <Head>
      <script
        type="text/javascript"
        async
        src={WithUrl('BAZAARVOICE_SCRIPT_URL', country)}
      />
      <style type="text/css">
        {isRebrandingEnabled ? bvRebrandingStyles : bazaarVoice}
      </style>
    </Head>
  );
};

BazaarVoiceHead.propTypes = {
  country: PropTypes.string.isRequired,
  isRebrandingEnabled: PropTypes.bool.isRequired
};

const BazaarVoiceComponent = ({ componentName, productId }) => {
  const [attributes, setAttributes] = useState({});
  useEffect(() => {
    setAttributes({
      'data-bv-show': componentName,
      'data-bv-product-id': productId
    });
  }, []);
  return <div {...attributes} />;
};

BazaarVoiceComponent.propTypes = {
  componentName: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired
};

export { BazaarVoiceComponent, BazaarVoiceHead };
