import css from 'styled-jsx/css';
import { typography, colors } from '../../config/styles';

const ImageGallerySoftlineStyles = css`
  .imageGallery {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
  }

  .imageHeadlineContainer {
    display: flex;
    position: relative;
    justify-content: space-between;
  }

  .left-image {
    height: 490px;
    width: 335px;
  }

  .right-image {
    height: 490px;
    width: 335px;
  }

  .control-section {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    gap: 32px;
  }

  .additional-info {
    display: flex;
    flex-direction: column;
    background: ${colors.gray.shade32};
    width: 330px;
    padding: 13px 13px 18px 13px;
  }

  .info-label {
    font-size: ${typography.sm};
    color: ${colors.black.shade1};
    line-height: 18px;
    padding: 10px 0;
  }
  .imageHeadlineContainer.out-of-stock {
    .left-image,
    .right-image {
      width: 214px;
      height: 321px;
    }
    .right-image {
      margin-left: 22px;
    }
  }
`;

export { ImageGallerySoftlineStyles };
