import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import colors from '../../../config/styles/colors';
import _ from '../../../utils/LodashImports';
import { spinnerVisualStyle } from './Spinner.style';

const MarketplaceSpinner = ({ size, color, theme }) => {
  const themeColor =
    _.get(theme, 'secondary_color', null) || colors.secondary_color;
  return (
    <div className={`spinner ${size} ${color}`}>
      <svg
        width="49"
        height="49"
        viewBox="0 0 49 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="spinner__svg"
      >
        <path
          d="M24.4378 2C36.7207 2.05798 46.66 12.0332 46.66 24.33C46.66 36.6628 36.6628 46.66 24.33 46.66C12.039 46.66 2.0661 36.7265 2 24.4494"
          stroke="#F0F0F0"
          strokeWidth="3"
        />
        <path
          d="M46.66 24.4378C46.602 36.7207 36.628 46.66 24.33 46.66C11.9972 46.66 2 36.6628 2 24.33C2 12.0378 11.9335 2.06494 24.2106 2"
          stroke={themeColor}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <style jsx>{spinnerVisualStyle}</style>
    </div>
  );
};

MarketplaceSpinner.propTypes = {
  size: PropTypes.oneOf(['medium', 'small']),
  color: PropTypes.oneOf(['orange', 'green']),
  theme: PropTypes.object
};

MarketplaceSpinner.defaultProps = {
  size: 'medium',
  color: 'orange',
  theme: null
};

export default withTheme(MarketplaceSpinner);
