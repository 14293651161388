import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import noop from '../../../utils/noop';
import { checkboxStyles } from './Checkbox.style';

const Checkbox = (props) => {
  const {
    isChecked,
    onChange,
    id,
    children,
    variant,
    disableClick,
    manageState,
    inConnect,
    isRebrandingEnabled,
    type,
    selectedBorderClass,
    normalBorderClass,
    inFloorCalculator,
    hasTimeConstraint,
    isShippingFacet,
    disabled,
    theme
  } = props;
  const themedStyles = checkboxStyles(theme);
  const [isSelected, setSelected] = useState(isChecked);
  const renderCheckIcon = () => {
    if (isRebrandingEnabled) {
      return (
        <React.Fragment>
          <i
            className={`rebrand-icon ${hasTimeConstraint &&
              'shipping-checkbox'}  ${isSelected ? '' : 'hide-icon'}`}
          />
          <style jsx>{themedStyles}</style>
        </React.Fragment>
      );
    }
    if (isSelected && !isRebrandingEnabled) {
      return (
        <React.Fragment>
          <i
            className={`csicon-${type} ${hasTimeConstraint &&
              'shipping-checkbox'} ${inFloorCalculator ? 'in-floor-calc' : ''}`}
          />
          <style jsx>{themedStyles}</style>
        </React.Fragment>
      );
    }
    return <React.Fragment />;
  };
  const handleOnChange = () => {
    if (manageState) {
      setSelected(!isSelected);
    }
    onChange({
      isSelected: !isSelected
    });
  };
  const titleClass = inConnect ? 'connect-title' : 'title';
  useEffect(() => {
    setSelected(isChecked);
  }, [props]);
  return (
    <label
      className={`checkbox ${
        isRebrandingEnabled ? 'checkbox-rebranding' : ''
      } ${isRebrandingEnabled && isChecked ? 'orange-border' : ''}${
        disabled ? ' disabled' : ''
      }`}
      htmlFor={id}
    >
      <input
        id={id}
        type="checkbox"
        checked={isSelected}
        onChange={disableClick ? noop : handleOnChange}
      />
      <span
        className={`${titleClass} ${isShippingFacet &&
          'hasTimeConstraint'} ${variant}${selectedBorderClass}${normalBorderClass} ${
          inFloorCalculator ? 'in-floor-calc' : ''
        }`}
      >
        {renderCheckIcon()}
        {children}
      </span>
      <style jsx>{themedStyles}</style>
    </label>
  );
};

Checkbox.defaultProps = {
  children: null,
  onChange: noop,
  isChecked: false,
  variant: 'secondary',
  disableClick: false,
  manageState: true,
  inConnect: false,
  isRebrandingEnabled: false,
  type: 'succes',
  selectedBorderClass: '',
  normalBorderClass: '',
  inFloorCalculator: false,
  hasTimeConstraint: false,
  isShippingFacet: false,
  disabled: false,
  theme: {}
};

Checkbox.propTypes = {
  children: PropTypes.node,
  isChecked: PropTypes.bool,
  isRebrandingEnabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'services']),
  disableClick: PropTypes.bool,
  manageState: PropTypes.bool,
  inConnect: PropTypes.bool,
  type: PropTypes.oneOf('succes', 'mkp-succes', 'mkp-bundle-succes'),
  selectedBorderClass: PropTypes.oneOf(' mkp-border', ' mkp-border-bundle', ''),
  normalBorderClass: PropTypes.oneOf(' mkp-normal-border', ''),
  inFloorCalculator: PropTypes.bool,
  hasTimeConstraint: PropTypes.bool,
  isShippingFacet: PropTypes.bool,
  disabled: PropTypes.bool,
  theme: PropTypes.object
};

export default withTheme(Checkbox);
