import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withProduct } from '../../utils/ProductContext';
import TypeCProductDesktop from './Desktop/TypeCProductDesktop';
import TypeCProductMobile from './Mobile/TypeCProductMobile';
import MatchMedia from '../ui/MatchMedia/MatchMedia';
import {
  getUniqueColorVariants,
  getSelectedColorSwatchIndex,
  getUniqueAvailableSizes,
  getFirstMatchingVariantId,
  getVariant
} from '../../utils/variant';
import { withLabels } from '../../utils/LabelsContext';
import _ from '../../utils/LodashImports';
import { withCart } from '../../utils/CartContext';
import { withApplicationContext } from '../../utils/ApplicationContext';
import noop from '../../utils/noop';
import cleanText from '../../utils/textHelper';
import { getErrorMessage } from '../../utils/errorHandler';

const TypeCProduct = ({
  productId,
  product,
  labels,
  variant,
  cart,
  appCtx,
  setBusy,
  isRebrandingEnabled
}) => {
  const { linkedProducts: productLinkedProducts, colorMetrics } = product;

  const { store, regionCode, subdomain } = appCtx;

  const [linkedProducts, setLinkedProduct] = useState(productLinkedProducts);
  const [errorAddingToCart, setErrorAddingToCart] = useState('');

  const linkedProductDetails = linkedProducts[productId];
  const { currentVariant: currentVariantId } = linkedProductDetails;

  const [currVariant, setCurrentVariant] = useState(currentVariantId);
  const { variants, brandName, name: variantName } = linkedProductDetails;

  const currentVariant = getVariant(variants, currVariant);

  const colorVariants = getUniqueColorVariants(variants, currentVariant);
  // const selectedColorVariant = colorVariants.find((cv) => cv.selected);

  // if (colorVariants.length && !selectedColorVariant) {
  //   colorVariants[0].selected = true;
  //   setCurrentVariant(colorVariants[0].extraInfo);
  //   currentVariant = getVariant(variants, colorVariants[0].extraInfo);
  // }

  const {
    prices,
    isPurchaseable,
    isHDAvailable,
    isCCAvailable
  } = currentVariant;

  const { attributes } = getVariant(variants, currVariant);
  const colorName = _.get(attributes, 'colorName', '');
  const sizes = getUniqueAvailableSizes(variants, colorName);

  const [selectedColorSwatchIndex, setColorIndex] = useState(-1);

  const currentColorName = _.get(
    colorVariants,
    `[${selectedColorSwatchIndex}].label`,
    undefined
  );

  const isForCart = variant === 'cart';

  const showLoader = (val) => {
    if (isForCart) {
      setBusy(val);
      return;
    }
    appCtx.setBusy(val);
  };

  const handleAddToCart = () => {
    const selectedVariantId = getVariant(variants, currVariant).id;
    const offeringId = _.get(currentVariant, 'offerings[0].offeringId', '');
    setErrorAddingToCart('');
    showLoader(true);
    if (
      isForCart ||
      (cart.cartItems &&
        !cart.cartItems.find((item) => item.skuId === selectedVariantId))
    ) {
      cart
        .addCollection({
          appCtx,
          isTypeC: true,
          products: [{ quantity: 1, skuId: selectedVariantId }],
          offeringId
        })
        .then((res) => {
          showLoader(false);
          if (res) {
            const errorMessage = getErrorMessage(res, appCtx.siteConfig);
            setErrorAddingToCart(errorMessage);
          }
        });
    } else {
      cart
        .addCollection({
          appCtx,
          isTypeC: true,
          products: [],
          mainProduct: [{ quantity: 1, skuId: selectedVariantId }],
          offeringId
        })
        .then((res) => {
          showLoader(false);
          if (res) {
            const errorMessage = getErrorMessage(res, appCtx.siteConfig);
            setErrorAddingToCart(errorMessage);
          }
        });
    }
  };

  const [selectedSize, setSelectedSize] = useState();

  const onSwatchChange = (newVariantId) => {
    const newLinkedProducts = JSON.parse(JSON.stringify(linkedProducts));

    if (!newLinkedProducts || !newLinkedProducts[productId]) {
      return;
    }
    newLinkedProducts[productId].currentVariant = newVariantId;

    setLinkedProduct(newLinkedProducts);
  };

  const colorSwatchHandler = ({ label: selectedColorName }) => {
    const matchingSize = variants.find(({ attributes: attr }) => {
      if (attr.colorCode !== colorName) {
        return false;
      }
      return (
        !attr.size ||
        (selectedSize && attr.size.toUpperCase() === selectedSize.toUpperCase())
      );
    });

    const newSize = matchingSize ? selectedSize : undefined;

    const size = _.get(
      getUniqueAvailableSizes(variants, selectedColorName),
      '[0].value',
      ''
    );
    const matchingVariantId = getFirstMatchingVariantId(variants, {
      colorName: selectedColorName,
      size: isForCart ? size : newSize
    });

    const { isPurchaseable: variantIsPurchaseable } = getVariant(
      variants,
      matchingVariantId
    );

    if (variantIsPurchaseable) {
      setSelectedSize(isForCart ? size : newSize);
    } else {
      setSelectedSize(undefined);
    }
    setCurrentVariant(matchingVariantId);
    onSwatchChange(matchingVariantId);
  };

  const sizeChangeHandler = (size) => {
    const matchingVariantId = getFirstMatchingVariantId(variants, {
      colorName: currentColorName,
      size
    });

    setSelectedSize(size);
    onSwatchChange(matchingVariantId);
    setCurrentVariant(matchingVariantId);
  };

  useEffect(() => {
    setColorIndex(
      getSelectedColorSwatchIndex(
        colorVariants,
        getVariant(variants, linkedProducts[productId].currentVariant)
      )
    );
  }, [currentVariant]);

  const isAddToCartDisabled =
    (sizes.length !== 0 && !selectedSize) || !isPurchaseable;

  const {
    CHOOSE_VARIANTS = 'ELIGE TUS OPCIONES',
    ADD_TO_BAG = 'AGREGAR AL CARRO',
    ADD_TO_BAG_MKP = 'Agregar al carro',
    CHOOSE_VARIANTS_MKP = 'Elige tus opciones'
  } = labels;

  const selectVariantLabel = isRebrandingEnabled
    ? CHOOSE_VARIANTS_MKP
    : CHOOSE_VARIANTS;
  const cartLabel = isRebrandingEnabled ? ADD_TO_BAG_MKP : ADD_TO_BAG;

  const addToCartLabel =
    sizes.length > 0 && isAddToCartDisabled ? selectVariantLabel : cartLabel;

  const buildProductUrl = () => {
    let env = process.env.ENVIRONMENT || 'www';
    let region = '';
    let storeName = 'falabella';

    if (env === 'production') {
      env = 'www';
    } else if (env === 'uat') {
      env = 'beta';
    }
    let buSubDomain = env;

    if (['sodimac', 'tottus', 'homecenter'].includes(subdomain)) {
      buSubDomain = env !== 'www' ? `${env}-${store}` : store;
      storeName = store;
    }
    if (regionCode !== 'cl') {
      region = `.${regionCode}`;
    }
    const exp = store ? `?exp=${store}` : '';

    return `https://${buSubDomain}.falabella.com${region}/${storeName}-${regionCode}/product/${productId}/${cleanText(
      variantName
    )}/${currVariant}${exp}`;
  };

  const typeCProps = {
    brandName,
    variantName,
    prices,
    variantId: getVariant(variants, linkedProducts[productId].currentVariant)
      .id,
    colorVariants,
    onSwatchChange,
    variants,
    currentVariant,
    isHomeDeliveryAvailable: isHDAvailable,
    isPickFromStoreAvailable: isCCAvailable,
    productId,
    sizes,
    sizeChangeHandler,
    selectedColorSwatchIndex,
    colorSwatchHandler,
    selectedSize,
    addToCartLabel,
    isAddToCartDisabled,
    variant,
    handleAddToCart,
    errorAddingToCart,
    variantUrl: buildProductUrl(),
    isRebrandingEnabled,
    appCtx,
    colorMetrics
  };

  return (
    <MatchMedia
      content={{
        desktop: () => <TypeCProductDesktop {...typeCProps} />,
        mobile: () => <TypeCProductMobile {...typeCProps} />,
        tablet: () => <TypeCProductMobile {...typeCProps} />
      }}
    />
  );
};

TypeCProduct.defaultProps = {
  variant: '',
  setBusy: noop,
  isRebrandingEnabled: false
};

TypeCProduct.propTypes = {
  productId: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  variant: PropTypes.string,
  cart: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  setBusy: PropTypes.func,
  isRebrandingEnabled: PropTypes.bool
};

export { TypeCProduct };

export default withApplicationContext(
  withCart(withLabels(withProduct(TypeCProduct)))
);
