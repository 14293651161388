import css from 'styled-jsx/css';

const ImageGalleryHeadlineMobileStyles = css`
  .headline-wrapper {
    text-align: center;
    margin-bottom: 15px;
    outline: none;
    position: relative;

    .image-slider {
      overflow: hidden;
      position: relative;
      touch-action: pan-y;
      user-select: none;
      -webkit-user-drag: none;
    }
    .image-wrapper {
      display: flex;
      align-items: center;
      width: 100vw;
      height: 100vw;
      max-height: 478px;
      &.out-of-stock {
        width: 100%;
        height: 84px;
      }
    }

    .image-headline {
      flex-shrink: 0;
      display: flex;
      max-height: 478px;
      width: 100%;
      height: 100%;
      justify-content: center;

      &.zoom {
        transform-origin: 0 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        max-width: none;
      }
    }

    .image-headline :global(img) {
      width: auto;
      height: 100%;
    }

    .image-zoom-container {
      visibility: hidden;
      touch-action: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;

      &.visible {
        visibility: visible;
      }
    }

    .image-zoom-container-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
    }
  }
`;

const ImageGalleryMobileVideoStyles = css`
  .video-headline {
    position: relative;
    flex-shrink: 0;
    width: 100vw;
    height: 100%;

    span.video-icon {
      position: absolute;
      width: 63px;
      height: 63px;
      border-radius: 100%;
      border: 3px solid white;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: black;
    }

    .video-thumbnail {
      height: 100%;
      width: 100%;
    }
  }

  .video-frame {
    width: 100%;
    height: 100%;
  }
`;

export { ImageGalleryHeadlineMobileStyles, ImageGalleryMobileVideoStyles };
