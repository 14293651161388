import React from 'react';
import PropTypes from 'prop-types';
import ExpandableSwatch from '../../ExpandableSwatch/ExpandableSwatch';
import SpecificationTable from '../../SpecificationTable/SpecificationTable';
import AddtionalInformationCard from '../../AdditionalInformationCard/AdditionaInformationCard';
import ComboSpecifications from '../../ComboSection/ComboSpecifications';
import { AdditionalInformationStyleCss } from './AdditionalInformationMobile.style';
import { withLabels } from '../../../utils/LabelsContext';
import { withProduct } from '../../../utils/ProductContext';
import ProductInfo from '../ProductInfo/ProductInfo';
import { getVariant } from '../../../utils/variant';
import _ from '../../../utils/LodashImports';

const AdditionalInformationMobile = ({
  specifications,
  description,
  topSpecifications,
  labels,
  isRebrandingEnabled,
  product,
  isMobile,
  appCtx
}) => {
  const headerType = isRebrandingEnabled
    ? 'mkp-headerContainer'
    : 'headerContainer';
  const swatchType = isRebrandingEnabled ? 'mkp-swatchButton' : 'swatchButton';
  const { currentVariant, variants } = product;
  const isSoCom = appCtx.store === 'so_com';
  const specificationsTitle = isSoCom
    ? _.get(labels, 'SO_COM_SPECIFICATIONS_LABEL', 'Ficha técnica')
    : labels.SPECIFICATIONS_LABEL;
  const productInformationLabel = isSoCom
    ? _.get(labels, 'SO_COM_PRODUCT_INFORMATION_LABEL', 'Descripción')
    : labels.PRODUCT_INFORMATION_LABEL;
  const comboData = _.get(
    getVariant(variants, currentVariant),
    'comboData',
    null
  );
  const showSpecifications =
    comboData?.hasSpecifications ||
    (specifications.length > 0 && !topSpecifications.length);

  return (
    <div className="productInfoContainer" id="productInfoContainer">
      {showSpecifications && (
        <div className="specifications-container">
          <ExpandableSwatch height={562} buttonType={swatchType}>
            <div className="specification">
              <AddtionalInformationCard
                headerText={specificationsTitle}
                headerClass="specification-header"
                headerType={headerType}
              >
                {comboData && comboData.hasSpecifications ? (
                  <ComboSpecifications comboItems={comboData.comboItems} />
                ) : (
                  <SpecificationTable specifications={specifications} />
                )}
              </AddtionalInformationCard>
            </div>
          </ExpandableSwatch>
        </div>
      )}

      {description && !isMobile && (
        <div className="productInfo-container">
          <ExpandableSwatch height={818} buttonType={swatchType}>
            <div className="productInfo">
              <AddtionalInformationCard
                headerText={productInformationLabel}
                headerType={headerType}
              >
                {/* eslint-disable react/no-danger */}
                <div className="htmlBox-container">
                  <ProductInfo description={description} />
                </div>
              </AddtionalInformationCard>
            </div>
          </ExpandableSwatch>
        </div>
      )}
      <style jsx>{AdditionalInformationStyleCss}</style>
    </div>
  );
};

AdditionalInformationMobile.defaultProps = {
  description: undefined,
  isRebrandingEnabled: false,
  topSpecifications: [],
  isMobile: false
};

AdditionalInformationMobile.propTypes = {
  specifications: PropTypes.array.isRequired,
  labels: PropTypes.object.isRequired,
  topSpecifications: PropTypes.array,
  description: PropTypes.string,
  isRebrandingEnabled: PropTypes.bool,
  isMobile: PropTypes.bool,
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired
};
export { AdditionalInformationMobile };
export default withProduct(withLabels(AdditionalInformationMobile));
