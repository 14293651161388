import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Heading from '../Heading/Heading';
import { accordionStyle, accordionItemStyle } from './Accordion.style';
import noop from '../../../utils/noop';

const AccordionItem = ({
  title,
  subTitle,
  logo,
  iconOptions,
  content,
  hasCustomVisualsForItem,
  state,
  border = false,
  withOutExpansion = false,
  clickEventHandler,
  withPadding,
  subTitleElement,
  highLightHeading,
  isRebrandingEnabled,
  isCart = false,
  badge,
  type = '',
  collapseIds,
  idItem
}) => {
  const [toggle, setToggle] = useState(state);
  const [idState, setIdState] = useState(idItem);

  const {
    up = 'csicon-arrow_up',
    down = 'csicon-arrow_down',
    styles = { fontSize: '0.8em' }
  } = iconOptions || {};
  const expandCollapse = () => {
    setToggle(!toggle);
    if (collapseIds) {
      if (idState === collapseIds.open) {
        setIdState(collapseIds.close);
      } else if (idState === collapseIds.close) {
        setIdState(collapseIds.open);
      }
    }
  };

  const handleClick = () => {
    if (withOutExpansion === false) {
      expandCollapse();
    } else {
      clickEventHandler();
    }
  };

  const getBorderClass = () => {
    const resClass = 'withBorder';
    if (!border) return '';
    if (toggle) return resClass;
    return `${resClass} bottomPadding`;
  };

  return (
    <li
      className={`${
        hasCustomVisualsForItem ? 'withBorderBottom' : ''
      } ${getBorderClass()} ${isCart ? 'isCart' : ''} ${
        toggle ? 'expanded' : ''
      }`}
      key={title}
    >
      <button type="button" className={type} onClick={handleClick} id={idState}>
        <div className="container">
          {logo ? (
            <div
              className={`logo ${
                withPadding ? 'withPadding' : 'withFixedWidth'
              } ${isRebrandingEnabled && 'rebranded'}`}
            >
              <i className={`${logo}`} />
            </div>
          ) : (
            ''
          )}

          <div className={`headingContainer ${badge && 'flexRow'}`}>
            <Heading
              isHighlighted={
                subTitle || toggle || subTitleElement || highLightHeading
              }
              level="2"
              variation={isRebrandingEnabled ? 'title13' : 'title1'}
              alignment="left"
            >
              {title}
            </Heading>
            {badge}
            {subTitle ? (
              <div>
                <Heading type="secondary" level="3" variation="base">
                  {subTitle}
                </Heading>
              </div>
            ) : (
              !subTitle &&
              subTitleElement && (
                <div>
                  <Heading type="secondary" level="3" variation="title7">
                    {subTitleElement()}
                  </Heading>
                </div>
              )
            )}
          </div>
        </div>
        <i className={`icon ${toggle ? up : down}`} style={styles}>
          <span className="sr-only">{title}</span>
        </i>
      </button>
      <div hidden={!toggle}>{content()}</div>
      <style jsx>{accordionItemStyle}</style>
    </li>
  );
};

const Accordion = ({ items, hasCustomVisualsForItem }) => {
  return (
    <ul className="accordion">
      {items.map(
        ({
          title,
          subTitle,
          subTitleElement,
          logo,
          iconOptions,
          content,
          state,
          border,
          withOutExpansion,
          clickEventHandler,
          withPadding,
          highLightHeading,
          isRebrandingEnabled,
          isCart,
          badge,
          idCollapse,
          collapseIds
        }) => {
          const idItem = idCollapse
            ? `${idCollapse}_up_id`
            : `testId-Accordion-${title}`;
          return (
            <>
              {// eslint-disable-next-line react/no-array-index-key
              title ? (
                <AccordionItem
                  state={state}
                  title={title}
                  subTitle={subTitle}
                  logo={logo}
                  iconOptions={iconOptions}
                  content={content}
                  hasCustomVisualsForItem={hasCustomVisualsForItem}
                  border={border}
                  withOutExpansion={withOutExpansion}
                  clickEventHandler={clickEventHandler}
                  withPadding={withPadding}
                  subTitleElement={subTitleElement}
                  highLightHeading={highLightHeading}
                  isRebrandingEnabled={isRebrandingEnabled}
                  isCart={isCart}
                  badge={badge}
                  idItem={idItem}
                  collapseIds={collapseIds}
                />
              ) : (
                <Fragment />
              )}
            </>
          );
        }
      )}

      <style jsx>{accordionStyle}</style>
    </ul>
  );
};

AccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  logo: PropTypes.string,
  iconOptions: PropTypes.object,
  content: PropTypes.func.isRequired,
  state: PropTypes.bool.isRequired,
  hasCustomVisualsForItem: PropTypes.bool,
  border: PropTypes.bool,
  withOutExpansion: PropTypes.bool,
  clickEventHandler: PropTypes.func,
  withPadding: PropTypes.bool,
  subTitleElement: PropTypes.func,
  highLightHeading: PropTypes.bool,
  isRebrandingEnabled: PropTypes.bool,
  isCart: PropTypes.bool,
  badge: PropTypes.element,
  type: PropTypes.string,
  idItem: PropTypes.string.isRequired,
  collapseIds: PropTypes.object
};

AccordionItem.defaultProps = {
  subTitle: null,
  logo: null,
  iconOptions: undefined,
  border: false,
  hasCustomVisualsForItem: false,
  withOutExpansion: false,
  clickEventHandler: noop,
  withPadding: true,
  subTitleElement: null,
  highLightHeading: false,
  isRebrandingEnabled: false,
  isCart: false,
  badge: null,
  type: '',
  collapseIds: {}
};

Accordion.defaultProps = {
  hasCustomVisualsForItem: false
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasCustomVisualsForItem: PropTypes.bool
};
export { AccordionItem };
export default Accordion;
