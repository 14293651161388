import css from 'styled-jsx/css';
import { typography, colors, zIndex } from '../../config/styles';

const ProductSpecificationConnectStyles = css`
  .product-specifications {
    display: flex;
    flex-direction: column;
    margin-top: 9px;
    &-column {
      width: 100%;
      height: auto;
      padding-left: 15px;
      &:first-child {
        padding-left: 0;
      }

      @mixin tabletToDesktop {
        padding-left: 30px;
        width: 50%;
        min-height: 500px;
      }
      &-panel {
        padding-left: 0;
      }
    }

    @mixin tabletToDesktop {
      flex-direction: row;
    }
  }

  .info-message {
    font-size: ${typography.lg1};
    margin-bottom: 10px;
    font-weight: 550;
    color: ${colors.gray.shade15};
    @mixin tabletToDesktop {
      font-size: ${typography.base};
    }
  }

  .connect-prices {
    padding-bottom: 20px;
    border-bottom: 1px solid ${colors.gray.shade3};

    @mixin tabletToDesktop {
      padding: 10px 0 10px 0;
    }
  }

  .plan-review-text {
    font-size: ${typography.base};
    line-height: 17px;
    font-weight: 400;
    color: ${colors.black.shade1};
  }

  .plan-review-container {
    padding-bottom: 15px;
  }

  .csicon-arrow_right {
    font-size: ${typography.sm};
    padding-left: 8px;
  }

  .no-plan-text {
    font-size: ${typography.base};
    line-height: 17px;

    @mixin tabletToDesktop {
      font-size: ${typography.sm1};
    }
  }

  .viewPlans-container {
    box-shadow: 0 -3px 26px rgba(0, 0, 0, 0.1);
    background-color: ${colors.white.shade1};
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 21px;
    z-index: ${zIndex.pdp.addToCartButton};

    :global(.csicon-arrow_down_small) {
      margin-left: 9px;
      font-size: ${typography.xs2};
    }
  }

  .panel-label {
    padding: 2px 0;
    display: inline-block;
    font-size: 1.4rem;
  }
`;

export { ProductSpecificationConnectStyles };
