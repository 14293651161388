/* stylelint-disable no-descending-specificity */
import css from 'styled-jsx/css';
import { colors, icons } from '../../../config/styles';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

const ReturnPolicyModalStyles = css`
  .title-wrapper {
    display: flex;
    align-items: center;
    &.main {
      @mixin mobileToTablet {
        border-bottom: 1px solid #f0f0f0;
      }
      p {
        line-height: 2.4rem;
        font-size: 2rem;
        margin: 27px auto 20px 32px;
        color: ${colors.black.shade1};
        @mixin mobileToTablet {
          line-height: 28px;
          font-size: 2.2rem;
          margin: 16px auto 21px 16px;
        }
      }
    }

    &.old {
      p {
        line-height: 2.4rem;
        font-size: 2rem;
        margin: 27px auto 20px 32px;
        @mixin mobileToTablet {
          line-height: 26.4px;
          font-size: 2.2rem;
          margin: 16px auto 21px 16px;
        }
      }
    }

    p {
      color: ${colors.gray.shade40};
      line-height: 2.2rem;
      font-size: 1.6rem;
      margin: 16px auto;

      @mixin mobileToTablet {
        /* stylelint-disable length-zero-no-unit */
        max-width: 314px;
        line-height: 2.2rem;
        font-size: 1.6rem;
        margin-top: 16px auto;
        margin-bottom: 0px;
      }
      @mixin smallerMobile1 {
        max-width: 270px;
      }
    }
  }

  .divider-line {
    border-top: 1px solid #f0f0f0;
  }

  .modal-container {
    .contents-container {
      max-height: 403px;
      overflow-y: scroll;
      padding: 0 32px;

      .title-wrapper {
        p {
          margin-left: 0;

          @mixin mobileToTablet {
            margin-left: 16px;
          }
        }
      }

      @mixin mobileToTablet {
        max-height: calc(100vh - 66px);
        padding: 0;
      }
    }

    .rp-modal-body {
      @mixin mobileToTablet {
        margin-left: 16px;
        margin-right: 20px;
      }

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
      }
      
      &::-webkit-scrollbar-thumb {
        background: #D9D9D9;
        border-radius: 100px;
      }
      @mixin smallerMobile {
        padding-bottom: 20px
      }
      .paragraph-wrapper {
        display: flex;
        align-items: start;
        &.center {
          align-items: center;
        }
        p,b {
          color: #4a4a4a;
        }
        @mixin mobileToTablet {
          align-items: flex-start;
        }
      }
      .no-refund-icon {
          margin-right: 9px;
          max-width: 32px;
          width: 100%;
          height: 32px;
          background-image: url("${svgToDataUri(icons.noRefund)}");
          background-repeat: no-repeat;
          background-size: contain;
          background-position:center;
          @mixin mobileToTablet {
            margin-right: 18px;
          }
        }
      p.paragraph {
        font-size: 1.6rem;
        line-height: 2.2rem;
        b {
          font-weight: 700;
        }
        @mixin mobileToTablet {
          line-height: 22px;
        }
        &.p-1 {
          margin: 11px auto 16px;
        }
        &.p1-new {
          margin-bottom: 16px;
          &.old {
            margin: 16px auto 10px;
          }
        }
        &.p-2 {
          margin: 16px auto 20px;
        }
        &.p2-new {
          margin-bottom: 16px;
        }
        &.margin-top-11 {
          margin-top: 11px;
          @mixin mobileToTablet {
            margin-top: 16px;
          }
        }
        &.margin-bot-27 {
          margin-bottom: 27px;
        }
      }
      p,
      li {
        line-height: 2rem;
        font-size: 14px;
        color: #4a4a4a;
        @mixin mobileToTablet {
          line-height: 22px;
          font-size: 16px;
        }
      }
      ul {
        @mixin mobileToTablet {
          padding: 0 9px;
        }
      }
      ul li::before {
        content: ${`"\\2022"`};
        font-size: 1.0em;
        position: absolute;
        top: 0;
        left: 0;
      }
      ul li {
        position: relative;
        padding-left: 17px;
        padding: 0 0 8px 17px;
      }
      &:last-child {
        ul:last-child {
          li:last-child {
            padding-bottom: 16px;
          }
        }
      }
      .new-ul-padding{
        padding-top: 6px;
        li:last-child {
          padding-bottom: 16px;
        }
      }
      .padding-top-0{
        padding-top: 0;
      }

      @mixin mobileToTablet {
        &:last-child{
          ul:last-child {
            li:last-child {
              padding-bottom: 24px;
            }
          }
        }
      }
    }

    .rp-modal-footer {
      padding: 0 32px;

      @mixin mobileToTablet {
        position: fixed;
        bottom: 0;
        background: #fff;
        padding: 0 16px;
        height: 66px;
      }

      .footer-wrapper {
        display: flex;
        padding: 12px 0 16px;
        align-items: center;
        @mixin mobileToTablet {
          align-items: flex-start;
        }
        a {
          text-decoration: none;
          p {
            color: #4a4a4a;
            text-decoration: none;
            line-height: 19px;
    
            b {
              position: relative;
              font-weight: bold;
              line-height: 19.2px;
              color: #495867;
              border-bottom: 1px solid #495867;
              text-decoration: none;

              @mixin mobileToTablet {
                white-space: pre;
              }
            }
          }
        }
      }

      .alertIcon {
        cursor: pointer;
        display: inline-block;
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
        color: #495867;
        font-size: 1.5rem;
        margin-right: 8px;
        @mixin mobileToTablet {
          margin-top: 4px;
        }
      }
      p {
        font-size: 16px;
        line-height: 19.2px;
        letter-spacing: 0.2px;
        color: #4A4A4A;
        b {
          font-weight: 700;
          color: #4A4A4A;
          text-decoration: underline;
        }
      }
    }
  }
`;

export default ReturnPolicyModalStyles;
