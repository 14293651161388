import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';
import { svgToDataUri, svgReplaceColor } from '../../../utils/styles/svgMixins';
import icons from '../../../config/styles/icons';

const StockTableStyle = css`
  .stock-container {
    &-connect {
      width: 250px;
      margin-top: 10px;
    }
    &-color,
    &-size {
      width: 302px;
      margin-top: 10px;
    }
    &-mobile {
      width: 100%;
      margin-top: 10px;
    }
  }
  .mobile-spacing {
    margin: 20px auto;
  }
  .desktop-spacing {
    margin: 10px 0 0 20px;
  }
  .stock-table {
    box-sizing: border-box;
    border: 1px solid #d5d5d5;
    font-size: 13px;
    color: ${colors.black.shade3};
    background-color: ${colors.white.shade1};
    margin-top: 13px;
  }
  .stock-table- {
    &connect {
      width: 250px;
    }
    &default {
      width: 83%;
    }
    &mobile {
      width: 100%;
    }
  }

  tr > th {
    border-bottom: 1.1px solid #d5d5d5;
    text-align: center;
    width: 112px;
  }
  tr > th:nth-child(odd) {
    background-color: #f6f6f6;
    color: ${colors.gray.shade40};
    line-height: 32px;
  }
  .request-product {
    &-wrapper {
      margin: 20px 0 0 24px;
      max-width: 224px;
    }
    &-text {
      font-size: ${typography.sm1};
      line-height: 16px;
      text-transform: uppercase;
      font-weight: bold;
      color: #fff;
    }
  }
  .stock-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px;
    color: ${colors.black.shade1};
  }
  .request-success-wrapper {
    padding: 1rem;

    .success-title {
      font-size: 16px;
      font-weight: 600;
      height: 24px;
    }
    .success-desc {
      font-weight: 600;
    }
  }
`;
const StockCellStyle = css`
  tr > td {
    border-bottom: 1.1px solid #d5d5d5;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
  }
  .selected {
    border: 2px solid #d3d3d3;
  }
  tr > td:nth-child(odd) {
    background-color: #f6f6f6;
    line-height: 32px;
  }
  .color {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    margin: 5px 10px;
  }
  .flex {
    display: flex;
  }
  .color-name {
    align-self: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .color-name- {
    &connect {
      width: 47px;
    }
    &default {
      max-width: 64px;
    }
  }
  .icon {
    min-width: 34px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: block;
    &.onlyInternet {
      background-image: url("${svgToDataUri(
        svgReplaceColor(icons.onlyInternet, colors.gray.shade40)
      )}");
      height: 30px;
    }
  }
`;
export { StockTableStyle, StockCellStyle };
