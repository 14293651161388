import fetch from 'isomorphic-unfetch';
import Logger from './Logger';
import { isWindow } from './deviceChecker';

const makeCall = async (
  url,
  opts,
  appCtx,
  errorFormat,
  fallback = {},
  isImage = false
) => {
  let tracingHeaders = {};
  if (opts.includeTracing && !isWindow() && appCtx) {
    // eslint-disable-next-line prefer-destructuring
    tracingHeaders = appCtx.tracingHeaders;
  }
  const defaultOptions = {};
  const {
    readBody = (body) => body.json(),
    readBodyAsText = (body) => body.text(),
    ...userOptions
  } = opts;

  let options = {
    ...defaultOptions,
    ...userOptions,
    headers: {
      ...tracingHeaders,
      ...userOptions.headers
    }
  };
  if (options.body && typeof options.body === 'object') {
    options = {
      body: JSON.stringify(options.body),
      headers: {
        ...options.headers,
        'Content-Type': 'application/json'
      }
    };
  }

  let error;
  let data;
  let statusCode;
  const responseHeader = {};
  try {
    const response = await fetch(url, options);
    statusCode = response.status;
    if (isImage) {
      return { statusCode };
    }
    if ((response.headers || {}).forEach) {
      (response.headers || []).forEach((name, value) => {
        responseHeader[value] = name;
      });
    } else {
      // For IE -> response.headers is object, not Map
      Object.keys(response.headers || []).forEach((name, value) => {
        responseHeader[value] = name;
      });
    }
    if (options.method === 'DELETE' && response.ok) {
      data = {};
      error = {};
    } else if (options.method === 'DELETE' && !response.ok) {
      data = {};
      error = {};
    } else if (!response.ok) {
      data =
        errorFormat === 'json'
          ? await readBody(response)
          : await readBodyAsText(response);
      error = response.statusText || data;
    } else if (opts.asText) {
      data = await readBodyAsText(response);
    } else {
      data = await readBody(response);
    }
  } catch (e) {
    error = e;
    statusCode = e.status;
  }
  if (error) {
    if (fallback && fallback.data) {
      return {
        error: undefined,
        data: fallback.data,
        statusCode: fallback.statusCode || 200,
        headers: fallback.headers || {}
      };
    }
    try {
      let errorMessage = error;
      // Network Failure errors are object
      if (typeof error === 'object') {
        errorMessage = JSON.stringify(errorMessage);
      }
      throw new Error(`Error Fetching GET ${url} => ${errorMessage}`);
    } catch (e) {
      Logger.error(e);
    }
  }
  return { error, data, statusCode, headers: responseHeader };
};

const makePostCall = async (
  url,
  reqBody,
  options,
  appCtx,
  additionalHeaders,
  errorFormat,
  fetchMethod
) => {
  let tracingHeaders = {};
  if (options.includeTracing && !isWindow() && appCtx) {
    // eslint-disable-next-line prefer-destructuring
    tracingHeaders = appCtx.tracingHeaders;
  }

  const rawResponse = await fetch(url, {
    method: fetchMethod,
    headers: {
      'Content-Type': 'application/json',
      ...tracingHeaders,
      ...additionalHeaders
    },
    body: JSON.stringify(reqBody),
    ...options
  });

  let error;
  let data;
  let statusCode;
  try {
    statusCode = rawResponse.status;
    if (rawResponse.ok) {
      data = await rawResponse.json();
    } else {
      error = rawResponse.statusText;
      data =
        errorFormat === 'json'
          ? await rawResponse.json()
          : await rawResponse.text();
    }
  } catch (e) {
    error = e;
  }
  if (error) {
    try {
      let errorMessage = error;
      // Network Failure errors are object
      if (typeof error === 'object') {
        errorMessage = JSON.stringify(errorMessage);
      }
      throw new Error(
        `Error Fetching POST ${url} => ${errorMessage} Payload => ${JSON.stringify(
          reqBody
        )}`
      );
    } catch (e) {
      Logger.error(e);
    }
  }
  return { error, data, statusCode };
};

const httpService = (appCtx) => {
  return {
    get: (url, options = {}, errorFormat = 'text', fallback = {}) => {
      return makeCall(
        url,
        { includeTracing: true, ...options, method: 'GET' },
        appCtx,
        errorFormat,
        fallback
      );
    },
    getImage: (url, options = {}, errorFormat = 'text', fallback = {}) => {
      // for checking scene7 images status
      return makeCall(
        url,
        { includeTracing: true, ...options, method: 'GET' },
        appCtx,
        errorFormat,
        fallback,
        true
      );
    },
    delete: (url, options = {}, errorFormat = 'text') => {
      return makeCall(
        url,
        { includeTracing: true, ...options, method: 'DELETE' },
        appCtx,
        errorFormat
      );
    },
    post: (
      url,
      data,
      options = { includeTracing: true },
      additionalHeaders = {},
      errorFormat = 'text'
    ) => {
      return makePostCall(
        url,
        data,
        { includeTracing: true, ...options },
        appCtx,
        additionalHeaders,
        errorFormat,
        'POST'
      );
    },
    patch: (
      url,
      data,
      options = { includeTracing: true },
      additionalHeaders = {},
      errorFormat = 'text'
    ) => {
      return makePostCall(
        url,
        data,
        { includeTracing: true, ...options },
        appCtx,
        additionalHeaders,
        errorFormat,
        'PATCH'
      );
    }
  };
};

export default httpService;
