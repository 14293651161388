import css from 'styled-jsx/css';
import { svgToDataUri, svgReplaceColor } from '../../utils/styles/svgMixins';
import { icons, colors, typography } from '../../config/styles';

const VariantAvailabilityOptionsStyles = css`
  .availability-options {
    display: flex;
    font-size: ${typography.sm};
    color: ${colors.black.shade7};
    margin-top: 28px;
    justify-content: space-between;
    align-items: center;
      @mixin smallMobileOnly {
        margin-top: 15px;
      }
    &.baseline{
      align-items: baseline;
    }
    &.noDeliverybutAvailable {
      .deliveryNotAvailable {
        position: relative;
        bottom: -3px;
        margin-top: 1px;
      }
    }
    &-item {
      display: flex;
      align-items: center;
      width: 50%;
      @mixin tabletToDesktop {
        width: 150px;
      }
    }
    .disabled {
      color: ${colors.gray.shade2};
    }
    &.mkp {
      margin-top: 24px;
      padding-left: 2.5px;
      padding-right: 2.5px; 
    }
  }

  .icon {
    min-width: 34px;
    height:36px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: block;
    margin-right: 7px;
    margin-top: 3px;
    
    &.deliveryAvailable {
      min-width: 26px;
      height:16px;
      background-image: url("${svgToDataUri(
        svgReplaceColor(icons.deliveryDisabledIcon, colors.gray.shade19)
      )}");
    }

    &.pickupInStoreAvailable {
      height:19px;
      min-width: 22px;
      background-image: url("${svgToDataUri(
        svgReplaceColor(icons.storeWithdrawalDisabledIcon, colors.gray.shade19)
      )}");
    }


    &.deliveryNotAvailable {
      background-image: url("${svgToDataUri(icons.deliveryNotAvailableIcon)}");
    }


    &.pickupInStoreNotAvailable {
      background-image: url("${svgToDataUri(
        icons.storeWithdrawalNotAvailableIcon
      )}")
    }
  }
  .icon-mkp {
    min-width: 34px;
    height:36px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: flex;
    margin-right: 7px;
    justify-content: center;
    align-items: center;
    
    &.deliveryAvailable {
      min-width: 26px;
      height:16px;
      background-image: url("${svgToDataUri(icons.deliveryMKP)}");
      @mixin mobileToTablet {
        min-width: 31px;
        height: 18.37px;
      }
    }

    &.pickupInStoreAvailable {
      height:19px;
      min-width: 22px;
      background-image: url("${svgToDataUri(icons.pickUpInStoreMKP)}");
    }


    &.deliveryNotAvailable {
      background-image: url("${svgToDataUri(icons.deliveryDisabledMKP)}");
      @mixin mobileToTablet {
        min-width: 36px;
        height: 38.31px;
      }
    }


    &.pickupInStoreNotAvailable {
      background-image: url("${svgToDataUri(icons.pickUpInStoreDisabledMKP)}")
    }
  }
`;

export { VariantAvailabilityOptionsStyles };
