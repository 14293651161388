import css from 'styled-jsx/css';
import { colors, zIndex, typography } from '../../config/styles';

export const ShareButtonStyle = css`
  .shareContainer {
    position: relative;
    display: flex;
    height: 34px;

    .socialContainer {
      position: absolute;
      display: flex;
      &.bottom {
        top: 40px;
        left: 50%;
        width: 42px;
        margin-left: -20px;
        padding-top: 7px;
        padding-bottom: 8px;
        flex-direction: column;
      }
      &.left {
        top: 40px;
        right: -9px;
        height: 51px;
        padding: 6px;
        flex-direction: row;
      }

      z-index: ${zIndex.popover.content};
      background-color: ${colors.white.shade1};
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      align-items: center;
      justify-content: center;
      .caret {
        position: absolute;
        height: 10px;
        width: 20px;
        bottom: 100%;
        &.bottom {
          left: 50%;
        }
        &.left {
          right: 0;
        }

        transform: translateX(-50%);
        overflow: hidden;
        &::after {
          content: '';
          position: absolute;
          height: 10px;
          width: 10px;
          transform: translateX(50%) translateY(50%) rotate(45deg);
          background-color: ${colors.white.shade1};
          box-shadow: -1px 0 4px 0 rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
`;

export const SocialIconStyle = css`
  .socialIcon {
    margin: 4px;
    @mixin tabletToDesktop {
      margin: 5px;
    }

    height: 35px;
    width: 35px;
    @mixin tabletToDesktop {
      height: 24px;
      width: 24px;
    }
    &.mobileOnly {
      @mixin desktop {
        display: none;
      }
    }

    display: flex;
    text-align: center;
    .link {
      margin: auto;
      text-decoration: none;
      background-color: transparent;
      color: inherit;
      .icon {
        font-size: ${typography.xla};
        @mixin tabletToDesktop {
          font-size: ${typography.lg1};
        }

        &.csicon-facebook {
          color: ${colors.socialIcons.facebook};
        }
        &.csicon-twitter {
          color: ${colors.socialIcons.twitter};
        }
        &.csicon-pinterest {
          color: ${colors.socialIcons.pinterest};
        }
        &.csicon-email {
          color: ${colors.socialIcons.email};
        }
        &.csicon-whatsapp {
          color: ${colors.socialIcons.whatsapp};
        }
      }
    }
  }
`;
