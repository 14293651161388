import css from 'styled-jsx/css';

const GenericCalculatorContainerStyle = css`
  .generic-calculator-container {
    margin-bottom: 0;
    @media screen and (min-width: 1024px) {
      margin-bottom: 8px;
    }
    @mixin mobileToTablet {
      margin-bottom: 16px;
    }
  }
`;

export { GenericCalculatorContainerStyle };
