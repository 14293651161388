import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { LoginModal } from '@digital-retail/falabella-ui-cross-components';
import { Modal } from '../../ui/Modal/Modal';
import BodyCopy from '../../ui/BodyCopy/BodyCopy';
import { withUser } from '../../../utils/UserContext';
import { priceTagStyles, CmrLogoGreen, cmrFaco } from './AperturaButton.style';
import CmrForm from '../CmrForm/CmrForm';
import { withLabels } from '../../../utils/LabelsContext';
import noop from '../../../utils/noop';
import MatchMedia from '../../ui/MatchMedia/MatchMedia';
import _ from '../../../utils/LodashImports';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withProduct } from '../../../utils/ProductContext';
import { getProductCategory } from '../../../utils/product/productUtils';
import constants from '../../../config/constants';

const AperturaButton = ({
  isUserLoggedIn,
  refreshUserData,
  savings,
  labels,
  symbol,
  setAperturaStatus,
  appCtx,
  product
}) => {
  const [loginModalVisibile, setLoginModalVisible] = useState(false);
  const [width, setWidth] = useState(500);
  const [toggle, setToggle] = useState(false);
  const hasSavings = typeof savings === 'string' && savings !== '0';
  const sellerId = _.get(product, 'variants[0].offerings[0].sellerId', '');
  const isGlobalCategoryEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isGlobalCategoryEnabled',
    false
  );
  const CmrLogo = appCtx.regionCode === 'co' ? cmrFaco : CmrLogoGreen;
  const isSoCom = appCtx.store === constants.STORES.so_com;
  const validCmrUrl = isSoCom
    ? _.get(appCtx, 'siteConfig.endpoints.SO_COM_APERTURA_URL_VALID_CMR', '')
    : _.get(appCtx, 'siteConfig.endpoints.APERTURA_URL_VALID_CMR', '');
  const invalidCmrUrl = isSoCom
    ? _.get(appCtx, 'siteConfig.endpoints.SO_COM_APERTURA_URL_INVALID_CMR', '')
    : _.get(appCtx, 'siteConfig.endpoints.APERTURA_URL_INVALID_CMR', '');
  let cmrAperturaUrl = hasSavings ? validCmrUrl : invalidCmrUrl;

  if (cmrAperturaUrl) {
    const fromIndex = cmrAperturaUrl.indexOf('&from');
    // eslint-disable-next-line prefer-const
    let [firsPart, secondPart] =
      fromIndex !== -1
        ? [cmrAperturaUrl.slice(0, fromIndex), cmrAperturaUrl.slice(fromIndex)]
        : [cmrAperturaUrl, ''];
    firsPart += `&seller=${sellerId}`;
    if (isGlobalCategoryEnabled) {
      const gCategory = getProductCategory(product, 'G');
      firsPart += `&category=${gCategory}`;
    } else {
      const jCategory = getProductCategory(product);
      firsPart += `&category=${jCategory}`;
    }
    cmrAperturaUrl = firsPart + secondPart;
  }

  const toggleState = {
    visible: toggle,
    setVisible: (bool) => setToggle(bool)
  };
  const isCMRAperturaInNewPageEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isCMRAperturaInNewPageEnabled',
    false
  );
  const isCMRAperturaInTab = _.get(
    appCtx,
    'siteConfig.toggles.isCMRAperturaInTab',
    false
  );
  const displayPriceinCMRText =
    _.get(
      appCtx,
      'siteConfig.toggles.isCMRButtonWithPriceVisibleInPDP',
      false
    ) && hasSavings;

  const { deviceType } = appCtx;

  useEffect(() => {
    if (!toggle) {
      setWidth(500);
    }
  }, [toggle]);

  const toggleModal = () => toggleState.setVisible(true);

  const {
    CMRAPERTURATEXT_REQUEST,
    CMRAPERTURATEXT_BENEFITS,
    CMRAPERTURATEXT_SAVE,
    CMRAPERTURATEXT_USING_CMR,
    CMRAPERTURATEXT_DONT_HAVE_IT,
    CMRAPERTURATEXT_OPEN_IT,
    CMRAPERTURATEXT_OPEN_YOUR_CMR,
    CMRAPERTURATEXT_ALREADY_HAVE
  } = labels;

  const PriceTag = () => (
    <div className="priceTag-content">
      <BodyCopy size="copy5" isHighlighted centered>
        {(classes) => (
          <span className={`${classes} cmr-title`}>
            {CMRAPERTURATEXT_REQUEST.toUpperCase()}
          </span>
        )}
      </BodyCopy>
      <BodyCopy size="copy8" centered>
        {CMRAPERTURATEXT_BENEFITS}
      </BodyCopy>
      <style jsx>{priceTagStyles}</style>
    </div>
  );

  const PriceTagWithCMR = () => (
    <div className="priceTag-content">
      <BodyCopy size="copy5" isHighlighted centered>
        {(classes) => (
          <span className={`${classes} cmr-title`}>
            {CMRAPERTURATEXT_SAVE}
            <BodyCopy importance="high" size="copy5" isHighlighted>
              {` ${symbol} ${savings} `}
            </BodyCopy>
            {CMRAPERTURATEXT_USING_CMR}
          </span>
        )}
      </BodyCopy>
      <BodyCopy size="copy8">
        {CMRAPERTURATEXT_DONT_HAVE_IT}
        <BodyCopy size="copy8" isHighlighted centered>
          {CMRAPERTURATEXT_OPEN_IT}
        </BodyCopy>
      </BodyCopy>
      <style jsx>{priceTagStyles}</style>
    </div>
  );

  const CmrDesktop = () => (
    <div className="priceTag">
      <img className="cmr-logo-img" src={CmrLogo} alt="Cmr logo" />
      {displayPriceinCMRText ? <PriceTagWithCMR /> : <PriceTag />}
      <style jsx>{priceTagStyles}</style>
    </div>
  );

  const onCmrAccordionClickHandle = () => {
    if (isCMRAperturaInNewPageEnabled) {
      if (isCMRAperturaInTab) {
        window.open(cmrAperturaUrl, '_blank');
      } else {
        window.location.href = cmrAperturaUrl;
      }
    } else {
      toggleModal();
    }
  };

  const CmrMobile = () => (
    <button
      type="button"
      className="cmr-apertura-mobile"
      onClick={onCmrAccordionClickHandle}
    >
      <span className="icon-cmr-rebranded" />
      <span className="content">
        <b>{CMRAPERTURATEXT_OPEN_YOUR_CMR}</b> {CMRAPERTURATEXT_ALREADY_HAVE}
      </span>
      <span className="icon-arrow-right-rebranded" />
      <style jsx>{priceTagStyles}</style>
    </button>
  );

  const [redirectLoginForm, setRedirectLoginForm] = useState(false);
  const onCloseRedirectLogin = () => {
    setRedirectLoginForm(false);
  };

  const redirectToLogin = (value) => {
    setRedirectLoginForm(value);
  };

  const atgBaseUrl = _.get(
    appCtx,
    'siteConfig.endpoints.ATG_AUTH_BASEURL',
    'https://www.falabella.com'
  );
  const catalystBaseUrl = _.get(
    appCtx,
    'siteConfig.endpoints.CATALYST_AUTH_BASEURL',
    'https://www.falabella.com'
  );

  const onUserLogin = () => {
    refreshUserData();
  };

  const CMRModal = ({ children }) => {
    const { isRebrandingEnabled } = appCtx;
    return redirectLoginForm === false ? (
      <Modal
        content={(actions) => (
          <CmrForm
            actions={actions}
            setWidth={setWidth}
            setLoginModalVisible={setLoginModalVisible}
            setAperturaStatus={setAperturaStatus}
            redirectToLoginForm={redirectToLogin}
          />
        )}
        options={{
          disableOutsideClick: true,
          width
        }}
        withPadding={false}
        toggleState={toggleState}
        appendClickElement
        deviceType={deviceType}
        stopScroll
        isRebrandingEnabled={isRebrandingEnabled}
      >
        {children}
      </Modal>
    ) : (
      <Fragment>
        <LoginModal
          atgBaseUrl={atgBaseUrl}
          catalystBaseUrl={catalystBaseUrl}
          showLoginForm={redirectLoginForm}
          onClose={onCloseRedirectLogin}
          onUserLogin={onUserLogin}
          tenant={appCtx.regionCode}
        />
        {children}
      </Fragment>
    );
  };

  CMRModal.propTypes = {
    children: PropTypes.node.isRequired
  };

  const [showLoginForm, setShowLoginForm] = useState(false);
  const onClose = () => {
    setShowLoginForm(!showLoginForm);
  };

  const CustomLoginModal = ({ children }) => {
    return (
      <Fragment>
        <LoginModal
          atgBaseUrl={atgBaseUrl}
          catalystBaseUrl={catalystBaseUrl}
          showLoginForm={showLoginForm}
          onClose={onClose}
          onUserLogin={onUserLogin}
          tenant={appCtx.regionCode}
        />
        <div
          onClick={() => {
            setShowLoginForm(true);
          }}
          onKeyDown={noop}
          role="button"
          tabIndex={0}
        >
          {children}
        </div>
      </Fragment>
    );
  };

  CustomLoginModal.propTypes = {
    children: PropTypes.node.isRequired
  };

  const CRMModalContent = () => {
    return (
      <MatchMedia
        content={{
          desktop: () => CmrDesktop(),
          mobile: () => CmrMobile(),
          tablet: () => CmrMobile()
        }}
      />
    );
  };

  const CRMNewPageContentDesktop = () => {
    return (
      <a
        href={cmrAperturaUrl}
        target={isCMRAperturaInTab ? '_blank' : ''}
        className={savings ? 'priceTag with-cmr' : 'priceTag'}
      >
        <img className="cmr-logo-img here" src={CmrLogo} alt="Cmr logo" />
        {displayPriceinCMRText ? <PriceTagWithCMR /> : <PriceTag />}
        <style jsx>{priceTagStyles}</style>
      </a>
    );
  };

  const CRMNewPageContent = () => {
    return (
      <MatchMedia
        content={{
          desktop: () => CRMNewPageContentDesktop(),
          mobile: () => CmrMobile(),
          tablet: () => CmrMobile()
        }}
      />
    );
  };

  const LoginModalContent = () => {
    return (
      <MatchMedia
        content={{
          desktop: () => CmrDesktop(),
          mobile: () => CmrMobile(),
          tablet: () => CmrMobile()
        }}
      />
    );
  };

  return isCMRAperturaInNewPageEnabled ? (
    <MatchMedia
      content={{
        desktop: () => CRMNewPageContent(),
        mobile: () => CmrMobile(),
        tablet: () => CmrMobile()
      }}
    />
  ) : (
    <React.Fragment>
      {isUserLoggedIn && loginModalVisibile === false ? (
        <CMRModal>
          <CRMModalContent />
        </CMRModal>
      ) : (
        <CustomLoginModal>
          <LoginModalContent />
        </CustomLoginModal>
      )}
    </React.Fragment>
  );
};

AperturaButton.defaultProps = {
  symbol: '$',
  setAperturaStatus: noop
};

AperturaButton.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  refreshUserData: PropTypes.func.isRequired,
  savings: PropTypes.string.isRequired,
  symbol: PropTypes.string,
  labels: PropTypes.object.isRequired,
  setAperturaStatus: PropTypes.func,
  appCtx: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
};

export { AperturaButton };
export default withApplicationContext(
  withUser(withLabels(withProduct(AperturaButton)))
);
