import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Availability from '../../../components/Availability/Availability';
import NearbyStores from '../../../components/NearbyStores';
import { GetSharedDetailsStyles } from './index.style';
import Button from '../../../components/ui/Button/Button';
import ComboItemsMobile from '../../../components/ComboSection/ComboItemsMobile';
import AdditionalInfoDrawer from '../../../components/AdditionalInfoDrawer/AdditionalInfoDrawer';
import ReturnPolicy from '../../ReturnPolicy/ReturnPolicy';
import ProductServices from '../../../components/ProductServices/ProductServices';
import Warranty from '../../../components/Warranty/Warranty';
import CMRCalculatorMobile from '../../../components/CMRCalculator/Mobile';
import _ from '../../../utils/LodashImports';
import { useSelectedDeliveryOption } from '../../../utils/hooks/UseSelectedDeliveryOption';
import { getVariant } from '../../../utils/variant';
import { AvailabilityLocation } from '../../../components/Availability/AvailabilityLocation/AvailabilityLocation';

const GetSharedDetails = ({
  sizes,
  colorVariants,
  regionCode,
  store,
  is1pSeller,
  showStoreAvailability,
  showCollectionButtonVisible,
  handleAddToCart,
  comboData,
  product,
  labels,
  showCMRCalculator,
  setServiceOption,
  setWarrantyOption,
  isWarrantyTooltipEnabled,
  additionalWarrantyLabel,
  layout,
  isSoCom = false,
  appCtx
}) => {
  const [comunaName, setComunaName] = useState('');
  const extendedWarrantyLabel = isSoCom
    ? _.get(labels, 'SO_COM_EXTENDED_WARRANTY_LABEL', 'Extiende tu garantía')
    : labels.EXTENDED_WARRANTY_LABEL;
  const additionalServicesLabel = isSoCom
    ? _.get(
        labels,
        'SO_COM_ADDITIONAL_SERVICES_LABEL',
        'Añade servicios adicionales'
      )
    : labels.ADDITIONAL_SERVICES_LABEL;

  const { variants, currentVariant: currentVariantId } = product;
  const variant = getVariant(variants, currentVariantId);

  const { availability } = variant;
  const { zoneConfig } = useSelectedDeliveryOption(
    availability?.shippingOptionType,
    appCtx,
    currentVariantId
  );
  useEffect(() => {
    if (zoneConfig) {
      setComunaName(zoneConfig.name);
    }
  }, [zoneConfig]);

  return (
    <Fragment>
      {showCollectionButtonVisible && (
        <div
          className={`addToCart-container ${
            layout === 'hardline' ? 'collection' : ''
          } fa--add-cart__mobile`}
        >
          <Button type="outline" onClick={handleAddToCart} size="xtra-large">
            <span>
              {labels.SEE_COLLECTION_BUTTON_LABEL}
              <i className="csicon-arrow_down_small" />
            </span>
          </Button>
        </div>
      )}
      {!!product.showShippingModal && (
        <div
          className={`delivery-options-wrapper ${
            isSoCom ? 'so-border' : 'mkp-border'
          }`}
        >
          {comunaName && (
            <div className="avl-content">
              <AvailabilityLocation comunaName={comunaName} />
            </div>
          )}
          <div className="dlv-opt-wrapper">
            <Availability
              sizes={sizes}
              colorVariants={colorVariants}
              regionCode={regionCode}
            />
            {(!!store || is1pSeller) && showStoreAvailability && (
              <NearbyStores sizes={sizes} colorVariants={colorVariants} />
            )}
          </div>
        </div>
      )}
      <div className="marginWrapper">
        {comboData && <ComboItemsMobile comboData={comboData} />}
        <AdditionalInfoDrawer
          description={product.longDescription}
          labels={labels}
          isSoCom={isSoCom}
        />
        {showCMRCalculator && (
          <div
            className={`cmrCalculator-container  ${
              layout === 'hardline'
                ? 'fa--cmr-calculator-hardline__mobile'
                : 'fa--cmr-calculator-softline__mobile'
            }`}
          >
            <CMRCalculatorMobile
              errorMessage={labels.CMR_INSTALLMENT_ERROR_MESSAGE}
            />
          </div>
        )}
        {layout === 'hardline' && (
          <Fragment>
            <ReturnPolicy returnPolicy={product.returnPolicy} />

            <ProductServices
              serviceOptions={product.serviceOptions}
              setServiceOption={setServiceOption}
              label={additionalServicesLabel}
            />
            <Warranty
              warrantyOptions={product.warrantyOptions}
              setWarrantyOption={setWarrantyOption}
              label={extendedWarrantyLabel}
              startsFromLabel={labels.PDP_STARTS_FROM}
              tooltipToggle={isWarrantyTooltipEnabled}
              additionalWarrantyLabel={additionalWarrantyLabel}
            />
          </Fragment>
        )}
      </div>
      <style jsx>{GetSharedDetailsStyles}</style>
    </Fragment>
  );
};

GetSharedDetails.defaultProps = {
  setServiceOption: () => {},
  setWarrantyOption: () => {},
  isWarrantyTooltipEnabled: false,
  additionalWarrantyLabel: false,
  isSoCom: false
};

GetSharedDetails.propTypes = {
  sizes: PropTypes.array.isRequired,
  colorVariants: PropTypes.array.isRequired,
  regionCode: PropTypes.string.isRequired,
  store: PropTypes.string.isRequired,
  is1pSeller: PropTypes.bool.isRequired,
  showStoreAvailability: PropTypes.bool.isRequired,
  showCollectionButtonVisible: PropTypes.bool.isRequired,
  handleAddToCart: PropTypes.func.isRequired,
  comboData: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  showCMRCalculator: PropTypes.bool.isRequired,
  setServiceOption: PropTypes.func,
  setWarrantyOption: PropTypes.func,
  isWarrantyTooltipEnabled: PropTypes.bool,
  additionalWarrantyLabel: PropTypes.bool,
  layout: PropTypes.string.isRequired,
  isSoCom: PropTypes.bool,
  appCtx: PropTypes.object.isRequired
};

export { GetSharedDetails };
