import { isSizesValid } from './getUniqueSizes';
import { findIndex } from '../jsUtils';

const getUniqueColorVariants = (variants = [], currentVariant) => {
  const mapper = {};
  const colorVariants = variants
    .map((variant) => {
      const { colorName, colorCode } = variant.attributes;
      if (!colorCode || !colorName) return undefined;

      if (!variant.isPurchaseable) {
        return undefined;
      }

      if (mapper[colorName]) return undefined;

      const isSizeValid = isSizesValid(variants, colorName);

      if (!isSizeValid) {
        return undefined;
      }

      mapper[colorName] = colorName;
      return {
        extraInfo: variant.id,
        label: colorName,
        mediaId: variant.id,
        selected: variant.id === currentVariant.id,
        value: colorCode,
        imageUrl: variant.medias[0]?.url || ''
      };
    })
    .filter(Boolean);
  return colorVariants;
};

const getUniqueColorVariantsSLP = (variants = [], currentVariant) => {
  const mapper = {};
  return variants
    .map((variant) => {
      const { label, value, ...rest } = variant;
      if (!value || !label) return undefined;
      if (!variant.isPurchaseable) {
        return undefined;
      }
      if (mapper[label]) return undefined;
      mapper[label] = label;
      return {
        extraInfo: variant.id,
        label,
        mediaId: variant.id,
        selected: variant.id === currentVariant.id,
        value,
        ...rest
      };
    })
    .filter(Boolean);
};

const getSelectedColorSwatchIndex = (variants, currentVariant) => {
  const currentVariantColor =
    currentVariant &&
    currentVariant.attributes &&
    currentVariant.attributes.colorName;

  const currentColorSwatchIndex = findIndex(
    variants,
    (variant) => variant.label === currentVariantColor
  );

  return currentColorSwatchIndex !== -1 ? currentColorSwatchIndex : 0;
};

export {
  getUniqueColorVariants,
  getSelectedColorSwatchIndex,
  getUniqueColorVariantsSLP
};
