/* eslint-disable no-useless-escape */
const ProductInfoStyle = `
.fb-product-information__reviews-tab:after {
  content: "";
  clear: both;
  display: table;
}

.fb-product-information__specification {
  background: #fff;
}
.fb-product-information__specification__table {
  background: #fff;
  width: 100%;
}
.fb-product-information__specification__table__row-options {
  background: #fff;
}
.fb-product-information__specification__table__row-label {
  background: #f8f8f8;
}
.fb-product-information__specification__table__row-data {
  background: #fff;
}
.fb-product-information__specification__table__row-data
  .fb-product-information__specification__table__data-label {
  display: none;
}
.fb-product-information__specification__table__data-options {
  padding: 15px 10px;
}
.fb-product-information__specification__table__data-label {
  font-family: Lato, sans-serif;
  font-weight: 700;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 18px;
  line-height: 1.8rem;
  margin-right: 20px;
  padding: 20px 10px;
  text-align: center;
  width: 300px;
}
.fb-product-information__specification__table__data-label--has-tooltip {
  cursor: pointer;
}
.fb-product-information__specification__table__data {
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 21px;
  line-height: 2.1rem;
  padding: 20px 10px;
  text-align: center;
}
.fb-product-information__product-information-tab {
  background: #fff;
  padding: 0 20px 0;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy {
  padding-top: 0px;
  max-width: -moz-available;
  max-width: -webkit-fill-available;
  max-width: fill-available;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  h3 {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 24px;
  line-height: 2.4rem;
  margin-bottom: 0.8em;
  margin-top: 1em;
  font-weight: 700;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  h2 {
  margin-bottom: 0.8em;
  margin-top: 1em;
  font-weight: 400;
  font-family: Lato, sans-serif;
  font-weight: 300;
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 28px;
  line-height: 2.8rem;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  p {
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 21px;
  line-height: 2.1rem;
  text-align: left;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  ul {
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 21px;
  line-height: 2.1rem;
  margin: 0;
  padding: 0;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  ul
  li {
  margin-bottom: 0.25em;
  display: block;
  list-style: none;
  margin-top: 0;
  padding-left: 20px;
  position: relative;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  ul
  li:before {
  background: currentColor;
  border-radius: 100%;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  top: 0.7em;
  width: 2px;
}
.fb-product-information-tab__logo {
  display: block;
  margin-bottom: 30px;
}
.fb-product-information-tab__logo img {
  display: block;
}
.fb-product-information-tab__copy .fb-list li {
  margin-bottom: 0.25em;
  margin-top: 0;
}
.fb-product-information-tab__media-gallery {
  background: #333;
  margin: 30px -20px 23px;
  position: relative;
  width: calc(100% + 40px);
}
.fb-product-information-tab__media-gallery .fb-hero-media__bar {
  width: 100%;
}
.fb-product-information-tab__media-gallery .fb-inspector-gallery__item--more {
  background-color: rgba(0, 0, 0, 0.3);
  border-color: hsla(0, 0%, 100%, 0.3);
}
.fb-product-information-tab__extra .fb-product-information-tab__copy {
  margin-top: 40px;
}
.fb-product-information-tab__toggle {
  font-family: Lato, sans-serif;
  font-weight: 700;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 18px;
  line-height: 1.8rem;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  background: #fff;
  border-top: 1px solid #f0f0f0;
  color: #888;
  margin-top: 33px;
  text-align: center;
}
.fb-product-information-tab__toggle a {
  color: currentColor;
  display: block;
  padding: 20px 0;
  text-decoration: none;
  transition: color 0.3s ease;
}
.fb-product-information-tab__toggle a:active,
.fb-product-information-tab__toggle a:hover {
  color: rgba(51, 51, 51, 0.8);
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  h3 {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 24px;
  line-height: 2.4rem;
  margin-bottom: 0.8em;
  margin-top: 1em;
  font-weight: 700;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  img {
  max-width: 100%;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  p:not(:last-child) {
  margin-bottom: 1.5em;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .separator {
  padding: 2.5em 0;
  border-bottom: 1px solid #f0f0f0;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .separator:last-child {
  border-bottom: none;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-list
  li {
  margin-bottom: 0.2em;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__banner,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__picture-text,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__pictures,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__text,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__video {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__banner-first,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__banner-second,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__picture-text-first,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__picture-text-second,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__pictures-first,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__pictures-second,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__text-first,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__text-second,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__video-first,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__video-second {
  width: 100%;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__banner-first
  h3,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__banner-first
  p,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__banner-second
  h3,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__banner-second
  p,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__picture-text-first
  h3,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__picture-text-first
  p,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__picture-text-second
  h3,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__picture-text-second
  p,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__pictures-first
  h3,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__pictures-first
  p,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__pictures-second
  h3,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__pictures-second
  p,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__text-first
  h3,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__text-first
  p,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__text-second
  h3,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__text-second
  p,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__video-first
  h3,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__video-first
  p,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__video-second
  h3,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__video-second
  p {
  text-align: left;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__picture-text
  h3 {
  margin-top: 1em;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__picture-text
  .orderFix {
  order: 2;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__picture-text
  .orderFix:last-child {
  order: 1;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__banner {
  flex-direction: column;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__banner
  p {
  width: 100%;
  text-align: left;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__banner
  h3 {
  margin-top: 1.5em;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__pictures {
  align-items: flex-start;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__pictures
  h3 {
  margin-top: 1.5em;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__pictures-first,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__pictures-second {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__pictures-first
  p,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__pictures-second
  p {
  text-align: left;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__pictures-first
  p:first-of-type,
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__pictures-second
  p:first-of-type {
  margin-bottom: 1.5em;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__video {
  flex-direction: column;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__video
  h3 {
  margin-top: 1rem;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__video-iframe {
  width: 100%;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__video-iframe
  iframe {
  width: 100%;
  height: 50vw;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__video-text {
  width: 100%;
  text-align: left;
}

@media screen and (min-width: 640px) {
  .fb-product-information__specification {
    padding: 60px;
  }
  .fb-product-information__specification__table-wrapper {
    background: #fff;
    padding: 0 12px 40px;
  }
  .fb-product-information__specification__table {
    border-bottom: 4px solid #e0e0e0;
    margin-bottom: 20px;
  }
  .fb-product-information__specification__table__row-label
    .fb-product-information__specification__table__data-label {
    display: none;
  }
  .fb-product-information__specification__table__row-data:nth-of-type(4n + 4) {
    background: #fff;
  }
  .fb-product-information__specification__table__row-data:nth-of-type(4n + 2) {
    background: #f8f8f8;
  }
  .fb-product-information__specification__table__row-data
    .fb-product-information__specification__table__data-label {
    display: table-cell;
  }
  .fb-product-information__specification__table__data-options {
    padding: 20px 0;
  }
  .fb-product-information__specification__table__data-label {
    padding: 0 30px 0 40px;
  }
  .fb-product-information__specification__table__data,
  .fb-product-information__specification__table__data-label {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 21px;
    line-height: 2.1rem;
    text-align: left;
  }
  .fb-product-information .fb-accordion-tabs__header {
    background-color: #f0f0f0;
    border-left: 1px solid #e0e0e0;
    border-top: 3px solid transparent;
    border-bottom: 0;
    color: #333;
  }
  .fb-product-information .fb-accordion-tabs__header.is-active {
    background-color: #fff;
    border-top: 3px solid #8fca00;
    color: #333;
  }
  div.fb-product-information__product-information-tab {
    display: block;
    position: relative;
    padding: 0 15px 30px;
  }
  .fb-product-information-tab__logo {
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 40px;
    padding-bottom: 44px;
    padding-right: 60px;
    padding-top: 36px;
    text-align: left;
  }
  .fb-product-information-tab__logo img {
    display: inline-block;
  }
  .fb-product-information-tab__primary {
    order: 1;
    padding-right: 0;
    width: 50%;
  }
  .fb-product-information-tab__primary .fb-product-information-tab__copy {
    padding-right: 60px;
  }
  .fb-product-information-tab__secondary {
    order: 2;
    padding-left: 60px;
    width: 50%;
  }
  .fb-product-information-tab__media-gallery {
    margin-bottom: 43px;
    margin-left: -60px;
    margin-right: -60px;
    width: calc(100% + 120px);
  }
  .fb-product-information-tab__extra {
    order: 3;
    width: 50%;
  }
  .fb-product-information-tab__extra .fb-product-information-tab__copy {
    margin-top: 20px;
    padding-right: 60px;
  }
  .fb-product-information-tab__toggle {
    color: #333;
    margin-left: -60px;
    margin-right: -60px;
    order: 4;
    width: calc(100% + 120px);
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__banner,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__picture-text,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__pictures,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__text,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__video {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__banner-first,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__banner-second,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__picture-text-first,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__picture-text-second,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__pictures-first,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__pictures-second,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__text-first,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__text-second,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__video-first,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__video-second {
    width: 48%;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__banner-first
    h3,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__banner-first
    p,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__banner-second
    h3,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__banner-second
    p,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__picture-text-first
    h3,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__picture-text-first
    p,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__picture-text-second
    h3,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__picture-text-second
    p,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__pictures-first
    h3,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__pictures-first
    p,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__pictures-second
    h3,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__pictures-second
    p,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__text-first
    h3,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__text-first
    p,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__text-second
    h3,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__text-second
    p,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__video-first
    h3,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__video-first
    p,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__video-second
    h3,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__video-second
    p {
    text-align: left;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__banner {
    flex-direction: column;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__banner
    p {
    width: 80%;
    text-align: center;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__banner
    h3 {
    margin-top: 1.5em;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__pictures {
    align-items: flex-start;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__pictures
    h3 {
    margin-top: 1.5em;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__pictures-first,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__pictures-second {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__pictures-first
    p,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__pictures-second
    p {
    text-align: center;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__picture-text
    h3 {
    margin-top: 0;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__picture-text
    .orderFix {
    order: 1;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__picture-text
    .orderFix:last-child {
    order: 2;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__video {
    flex-direction: column;
  }
  .fb-warranty-tab {
    margin-bottom: 70px;
  }
  .fb-warranty-tab__sections {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }
  .fb-warranty-tab__section {
    width: 50%;
  }
  .fb-warranty-tab__section p + p {
    margin-top: 27px;
  }
  .fb-warranty-tab__section + .fb-warranty-tab__section {
    margin-top: 0;
    width: 40%;
  }
}
@media screen and (min-width: 1024px) {
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__banner
    p {
    width: 55%;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__video {
    flex-direction: row;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__video-iframe {
    width: 68%;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__video-iframe
    iframe {
    width: 100%;
    height: 445px;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__video-text {
    width: 28%;
    text-align: left;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__video-text
    p {
    text-align: left;
  }
}

@media print, screen and (min-width: 740px) {
  .fb-product-cta__controls--instock,
  .fb-product-cta__prices {
    width: 100%;
  }
}
@media print, screen and (min-width: 1280px) {
  .fb-product-cta__prices {
    width: 50%;
    padding-right: 40px;
  }
}
.product-bundle__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 150px;
  text-align: center;
  width: 180px;
}
.product-bundle__head__checkbox {
  -ms-flex-item-align: center;
  align-self: center;
  height: 20px;
  margin-right: 15px;
  width: 20px;
}
.product-bundle__head__image {
  border: 1px solid #f0f0f0;
  display: block;
  margin: auto;
  max-height: 90px;
  max-width: 90px;
  padding: 0.2em;
}
@media screen and (min-width: 768px) {
  .product-bundle__head {
    border-bottom: 1px solid #e0e0e0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    overflow: hidden;
    position: relative;
  }
  .product-bundle__head__checkbox {
    left: 0;
    margin: 0.5em;
    position: absolute;
    top: 0;
  }
  .product-bundle__head__image {
    border: 0;
    height: 100%;
    max-height: 180px;
    max-width: 180px;
    width: 100%;
  }
}
.product-bundle__body {
  display: inline-block;
  font-size: 13px;
  margin-top: 1.2em;
  max-width: 180px;
  padding: 0 1em 1em;
  position: relative;
  text-decoration: none;
  vertical-align: top;
}
.product-bundle__body__brand {
  color: #888;
  line-height: 1em;
  margin: 1em 0 0.3em;
  text-transform: uppercase;
}
.product-bundle__body__name {
  color: initial;
  line-height: 1em;
  margin-bottom: 0.7em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media screen and (min-width: 568px) {
  .product-bundle__body {
    max-width: 390px;
  }
}
@media screen and (min-width: 768px) {
  .product-bundle__body {
    margin-top: 0;
    max-width: 180px;
  }
}
.product-bundle {
  background: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  left: -0.3em;
  margin: 0.5em 0.1em;
  position: relative;
}
.product-bundle:before {
  color: #d7d7d7;
  content: "+";
  display: block;
  font-size: 2em;
  left: calc(50% - 0.5em);
  padding: 0.3em 0;
  position: absolute;
  text-align: center;
  top: -0.4em;
}
.product-bundle__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .product-bundle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
  }
  .product-bundle:before {
    -ms-flex-item-align: center;
    align-self: center;
    left: -0.3em;
    padding: 0;
    top: calc(180px - 2em);
    -webkit-transform: translateX(-1.2em);
    transform: translateX(-1.2em);
  }
  .product-bundle__wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.fb-bundles__total__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1em 0;
}
.fb-bundles__total__title {
  font-size: 1.1em;
  font-weight: 700;
  margin-bottom: 0.4em;
}
.fb-bundles__total .fb-btn-primary {
  margin: auto;
}
@media screen and (min-width: 768px) {
  .fb-bundles__total {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.main-product {
  border: 1px solid #8fca00;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (min-width: 768px) {
  .main-product {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0.5em;
  }
}
.bundle-added {
  margin: auto;
  text-align: center;
}
.added-products-title {
  font-weight: 700;
}
.icon-circle-check {
  width: 30px;
  height: 30px;
}
.product-bundle-app {
  background: #fff;
  padding: 40px 20px;
}
.product-bundle-app .fb-bundles__total {
  font-weight: 400;
  padding: 0;
}
.product-bundle-app .fb-bundles__total.set-left-margin {
  margin-left: 40px;
}
.product-bundle-app__suggestion {
  font-family: Lato, sans-serif;
  font-size: 2.4rem;
  font-weight: 300;
  line-height: 2.4rem;
  padding: 0 0 30px;
}
.product-bundle-app .fb-price-list {
  padding-top: 0.5em;
}
.product-bundle-app .fb-price {
  font-size: 1em;
  font-weight: 400;
}
.product-bundle-app .fb-price:first-child {
  font-size: 1.1em;
  line-height: 1em;
  margin: 0;
}
.product-bundle-app .fb-price__cmr {
  height: 11px;
  width: 35px;
}
.product-bundle-app .product-bundle__product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  position: relative;
}
.product-bundle-app .product-bundle__product:not(:first-child) {
  margin-top: 1em;
}
@media screen and (min-width: 768px) {
  .product-bundle-app {
    padding: 40px 60px;
  }
  .product-bundle-app__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .product-bundle-app .fb-bundles__total {
    border: 0;
    margin: auto;
  }
  .product-bundle-app .fb-price-list {
    padding: 0;
  }
  .product-bundle-app .product-bundle__product {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .product-bundle-app .product-bundle__product:not(:first-child) {
    margin-left: 5em;
    margin-top: 0;
  }
  .product-bundle-app .product-bundle__product:not(.main-product) {
    border: 1px solid #f0f0f0;
  }
}
@media screen and (min-width: 1024px) {
  .fb-bundles__total {
    border: 0;
    padding: 0 1em 0 5em;
  }
}
.fb-price__reward-points {
  color: #888;
  font-size: 12px;
  margin-top: 5px;
}
.fb-price__cmr {
  height: 23px;
  margin-left: 8px;
  width: 70px;
}
.fb-price__pum-value {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 18px;
  line-height: 1.8rem;
  color: #bbb;
}
.fb-pum__price-default {
  color: #333;
}
.fb-price__bottom-spacing {
  margin: 0 0 15px;
}
.fb-pum-tooltip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.fb-tooltip__text.pum-popup {
  width: calc(35% - 30px);
  -webkit-transform: translate(-44%, calc(50% - 30px));
  transform: translate(-44%, calc(50% - 30px));
  font-size: 1.2rem;
  line-height: 1.8rem;
}
.fb-product-cta .fb-price__reward-points {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
  width: 50%;
}
.icon-ico-filter,
.icon-icons-services-payment {
  background-repeat: no-repeat;
  display: inline-block;
  background-size: cover;
}
@media screen and (max-width: 739px) {
  .fb-tooltip.fb-tooltip--is-active.fb-tooltip--mobile-color-default {
    color: #333;
  }
}
@media print, screen and (min-width: 1280px) {
  .fb-product-cta .fb-price__reward-points {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
    width: 50%;
  }
}
.fb-product-cta__prices > .fb-price:nth-child(3),
.fb-product-cta__prices > div:nth-child(3) .fb-price {
  color: #888;
  font-size: 14px;
  line-height: 16px;
}
.modal-active,
.modal-active body {
  overflow: hidden;
  min-height: none;
}
.modal-active .fb-masthead,
.modal-active body .fb-masthead {
  z-index: 101;
}
.modal-active
  .fb-product-sets__subtotal-bar--is-fixed.fb-product-sets__subtotal-bar--is-active,
.modal-active
  body
  .fb-product-sets__subtotal-bar--is-fixed.fb-product-sets__subtotal-bar--is-active {
  z-index: 99;
}
.icon-close:before {
  content: "\EA0A";
}
.fixed-gallery {
  position: fixed;
  width: 100%;
}
.shadow-inactive,
.shadow-inactive body {
  overflow: hidden;
  position: fixed;
  min-height: none;
}
.shadow-inactive .fb-filters .fb-filters-sort,
.shadow-inactive .fb-filters .fb-form,
.shadow-inactive .fb-selectedfilters {
  z-index: -1;
}
@-webkit-keyframes fadeInModal {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeInModal {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fb-modal {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 18px;
  line-height: 1.8rem;
  background: #000;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: none;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 3000;
}
.fb-modal.active--no-transition {
  display: block;
}
.fb-modal.active {
  -webkit-animation: fadeInModal ease-in 0.2s 1 forwards;
  animation: fadeInModal ease-in 0.2s 1 forwards;
  display: block;
}
.fb-modal__container {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 21px;
  line-height: 2.1rem;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  opacity: 0;
  overflow: auto;
  position: relative;
  width: 100%;
}
.active--no-transition .fb-modal__container {
  opacity: 1;
}
.fb-modal.active .fb-modal__container {
  -webkit-animation: fadeInModal ease-in 0.4s 1 forwards;
  animation: fadeInModal ease-in 0.4s 1 forwards;
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}
.fb-modal__header {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 20px 30px 30px;
}
.fb-modal__title {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 36px;
  line-height: 3.6rem;
  background-size: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-family: Lato, sans-serif;
  font-weight: 300;
  margin-bottom: 0;
  padding-right: 20px;
}
.fb-modal__main {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 30px 20px;
}
.fb-btn.fb-btn-default {
  color: #fff;
  background: #333;
  letter-spacing: 0.075em;
  text-transform: uppercase;
}
.fb-modal__content---narrow {
  max-width: 480px;
}
.fb-modal__close {
  font-size: 30px;
  font-size: 3rem;
  line-height: 30px;
  line-height: 3rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #888;
  padding: 22px 10px 0 0;
  position: absolute;
  right: 0;
  top: 0;
}
.fb-modal__close:active,
.fb-modal__close:hover {
  color: hsla(0, 0%, 53%, 0.8);
  outline: none;
}
.fb-modal__close:focus {
  outline: none;
}
.fb-modal__loading {
  font-family: Lato, sans-serif;
  font-weight: 700;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 18px;
  line-height: 1.8rem;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  margin: 50px auto;
  min-height: 50vh;
  padding: 40px;
  text-align: center;
}
.fb-modal__go {
  height: 32px;
  margin: 0 0 0 35%;
  width: 31%;
}
.fb-modal__footer {
  background: #fff;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-top: auto;
  padding-top: 60px;
  width: 100%;
}
@media screen and (min-width: 480px) {
  .fb-modal__footer {
    padding-top: 30px;
  }
}
@media screen and (min-width: 640px) {
  .fb-content--titles {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .fb-content--titles span {
    width: 50%;
    font-family: Lato, sans-serif;
  }
}
@media screen and (min-width: 740px) {
  .fb-modal__container {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 21px;
    line-height: 2.1rem;
    height: auto;
    left: 50%;
    margin: 0 auto;
    max-height: calc(100vh - 60px);
    max-width: 640px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
  .fb-modal__close,
  .fb-modal__header {
    padding-top: 10px;
  }
  .fb-modal__header {
    padding: 30px 60px;
  }
  .fb-modal__close {
    padding-right: 25px;
    padding-top: 30px;
  }
  .fb-modal__main {
    padding: 0 60px 30px;
  }
  .fb-modal__container--change-location {
    max-width: 440px;
  }
}
@media screen and (min-width: 1024px) {
  .mainImage-modal_container {
    background-color: #fff;
    max-width: none;
    width: 70%;
    overflow-y: hidden;
  }
}
.fb-modal-finance-calculator .fb-modal-finance-calculator__term {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 20px;
  line-height: 2rem;
  margin-bottom: 25px;
}
.fb-inline-dropdown {
  display: block;
  cursor: pointer;
}
.fb-product-cta .fb-inline-dropdown {
  width: 100%;
}
.fb-modal-finance-calculator
  .fb-modal-finance-calculator__term
  .fb-inline-dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.fb-inline-dropdown__link {
  display: none;
  text-decoration: none;
}
.fb-inline-dropdown__link__icon {
  color: #8fca00;
  font-size: 28px;
  left: -7px;
  position: relative;
  pointer-events: none;
  top: 5px;
  -ms-flex-order: 2;
  -webkit-box-ordinal-group: 3;
  order: 2;
}
.fb-modal-finance-calculator
  .fb-modal-finance-calculator__term
  .fb-inline-dropdown__link__icon {
  top: -2px;
}
.icon-bottom:before {
  content: "\EA10";
}
.fb-inline-dropdown__native-dropdown-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.fb-inline-dropdown__native-dropdown-sub-wrap {
  margin-left: 4px;
  max-width: 100%;
  padding-bottom: 3px;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
.fb-inline-dropdown__native-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  color: #8fca00;
  font-weight: 400;
  max-width: 100%;
  outline: 0;
}
.fb-modal-finance-calculator
  .fb-modal-finance-calculator__term
  .fb-inline-dropdown__list__container {
  left: auto;
  right: -80px;
  width: auto;
}
.fb-inline-dropdown__list,
.hidden {
  display: none;
}
.fb-modal-finance-calculator
  .fb-modal-finance-calculator__term
  .fb-inline-dropdown__list {
  width: auto;
}
a {
  background-color: transparent;
}
.fb-modal-finance-calculator .fb-modal-finance-calculator__total {
  clear: both;
  margin-bottom: 25px;
  text-align: center;
}
.fb-modal-finance-calculator
  .fb-modal-finance-calculator__total
  .fb-modal-finance-calculator__total__main-value {
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 36px;
  line-height: 3.6rem;
  margin-bottom: 10px;
}
.fb-modal-finance-calculator
  .fb-modal-finance-calculator__total
  .fb-modal-finance-calculator__total__sub-text {
  color: #bbb;
}
.fb-modal-finance-calculator
  .fb-modal-finance-calculator__total
  .fb-modal-finance-calculator__total__sub-sub-value {
  font-family: Lato, sans-serif;
  font-weight: 300;
  font-size: 20px;
  font-size: 2rem;
  line-height: 20px;
  line-height: 2rem;
}
.fb-modal-finance-calculator
  .fb-modal-finance-calculator__total
  .fb-modal-finance-calculator__total__sub-sub-value
  span {
  font-family: Lato, sans-serif;
  font-weight: 700;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 12px;
  line-height: 1.2rem;
  color: #bbb;
}
.fb-modal-finance-calculator .fb-modal-finance-calculator__done {
  margin-bottom: 10px;
}
.fb-modal-finance-calculator .fb-modal-finance-calculator__done .fb-btn {
  width: 100%;
}
.fb-btn.fb-btn-default {
  color: #fff;
  background: #333;
  letter-spacing: 0.075em;
  text-transform: uppercase;
}
.fb-modal-finance-calculator .fb-modal-finance-calculator__disclaimer {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 20px;
  line-height: 2rem;
  color: #bbb;
  text-align: center;
}
@media screen and (min-width: 740px) {
  .fb-modal-finance-calculator .fb-modal__container {
    max-width: 480px;
  }
  .fb-modal-finance-calculator .fb-modal-finance-calculator__term {
    font-size: 20px;
    font-size: 2rem;
    line-height: 20px;
    line-height: 2rem;
  }
  .fb-inline-dropdown {
    position: relative;
    display: inline-block;
  }
  .fb-modal-finance-calculator
    .fb-modal-finance-calculator__term
    .fb-inline-dropdown {
    display: inline;
  }
  .fb-inline-dropdown__link {
    display: inline;
    color: #8fca00;
  }
  .fb-inline-dropdown__link__title {
    padding-bottom: 3px;
  }
  .fb-inline-dropdown .fb-inline-dropdown__link__title {
    background: transparent
      url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAACCAYAAAB7Xa1eAAAAFklEQVR4AWPoP8VwGIQZ0ABM4gi6OAAXHghyOgg+GgAAAABJRU5ErkJggg==)
      0 100% repeat-x;
  }
  .fb-inline-dropdown__link__icon {
    left: 0;
  }
  .fb-modal-finance-calculator
    .fb-modal-finance-calculator__term
    .fb-inline-dropdown__link__icon {
    display: inline;
    top: 5px;
  }
  .fb-product-cta .fb-inline-dropdown .fb-inline-dropdown__link__icon {
    display: inline-block;
    top: 7px;
  }
  .fb-inline-dropdown__list__container {
    position: absolute;
    left: -50px;
    margin: 0;
    top: 45px;
    width: 360px;
    z-index: 50;
  }
  .fb-inline-dropdown__list {
    background: #fff;
    -webkit-box-shadow: 0 5px 75px rgba(0, 0, 0, 0.09);
    box-shadow: 0 5px 75px rgba(0, 0, 0, 0.09);
    display: block;
    margin: 0;
    padding: 40px 50px 20px;
  }
  .fb-modal-finance-calculator
    .fb-modal-finance-calculator__term
    .fb-inline-dropdown__list {
    padding: 20px 60px;
    min-width: 250px;
    max-height: 150px;
    overflow-y: scroll;
  }
  .fb-inline-dropdown__list-item {
    font-family: Lato, sans-serif;
    font-weight: 700;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 18px;
    line-height: 1.8rem;
    letter-spacing: 0.075em;
    text-transform: uppercase;
    color: #bbb;
    list-style: none;
    margin: 0 0 20px;
    padding: 0;
    position: relative;
  }
  .fb-modal-finance-calculator
    .fb-modal-finance-calculator__term
    .fb-inline-dropdown__list
    .fb-inline-dropdown__list-item__link {
    text-align: center;
  }
  .fb-inline-dropdown__list-item a {
    color: #333;
    display: block;
    text-decoration: none;
  }
  .fb-product-cta a {
    color: #333;
  }
}
.icon-close:before {
  content: "\EA0A";
}

.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  img {
  max-width: 100%;
  padding-bottom: 1.5em;
}
.fb-product-information-tab__copy
  .fb-product-information-tab__copy__new
  .fb-product-information-tab__text-first {
  width: 100%;
}
.fb-product-information-tab__copy ul h3,
.fb-product-information-tab__copy__new ul h3 {
  text-align: left;
}

/* COMPLEMENTARY INFO IN ADITIONAL INFORMATION */
h2.fb-cpy-light {
  font-family: Lato, sans-serif;
  width: 100%;
  color: #333333;
  font-weight: 300;
  font-size: 1.8rem;
  padding-top: 30px;
  padding-bottom: 10px;
  border-bottom: 2px solid #f0f0f0;
}
.fb-product-information__product-information-tab
  .fb-product-information-tab__copy
  .fb-product-information-tab__complementary-info
  h2.fb-cpy-light {
  margin-bottom: 0;
}
.fb-cpy-complementary-row {
  width: 100%;
  display: inline-block;
  padding-top: 18px;
}
.fb-cpy-complementary-row:last-child {
  padding-bottom: 30px;
}
.fb-cpy-complementary-row a {
  text-decoration: none;
}
.fb-cpy-complementary-row i.icon-download-doc {
  display: inline-block;
  /* background-image: url("data:image/svg+xml,%3Csvg width='19' height='16' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M10.106 7.178l1.896-1.908a.689.689 0 0 1 .979 0 .7.7 0 0 1 0 .986L9.904 9.354a.689.689 0 0 1-.98 0L5.848 6.256a.7.7 0 0 1 0-.986.689.689 0 0 1 .98 0l1.895 1.908V.543c0-.3.31-.543.692-.543.383 0 .692.243.692.543v6.635zM0 6.754c0-.385.31-.697.692-.697.383 0 .693.312.693.697v6.197c0 .47.379.852.846.852h13.96a.85.85 0 0 0 .845-.852V6.754c0-.385.31-.697.693-.697.382 0 .692.312.692.697v6.197c0 1.24-.999 2.246-2.23 2.246H2.23A2.239 2.239 0 0 1 0 12.951V6.754z' id='a'/%3E%3C/defs%3E%3Cg transform='translate(.29 .132)' fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cuse fill='%23333' fill-rule='nonzero' xlink:href='%23a'/%3E%3Cg mask='url(%23b)' fill='%239B9B9B'%3E%3Cpath d='M-4.145-2.763h25.568v19.279H-4.145z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");*/
  width: 19px;
  height: 16px;
  background-repeat: no-repeat;
  vertical-align: middle;
}
.fb-cpy-complementary-cta {
  font-weight: bold;
  color: #4a4a4a;
  display: inline-block;
  padding: 0 7px;
  vertical-align: middle;
  font-size: 1.4rem;
  letter-spacing: 0.15px;
}
.fb-cpy-complementary-row i.arrow-right {
  display: inline-block;
  /* background-image: url("data:image/svg+xml,%3Csvg width='9' height='6' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M.6 2.429L6.476 2.4 5.05.994s-.418-.394 0-.81c.419-.414.796 0 .796 0l2.387 2.41S8.4 2.747 8.4 3s-.166.406-.166.406l-2.386 2.41s-.378.415-.797 0c-.418-.417 0-.81 0-.81L6.476 3.6H.6S0 3.612 0 3s.6-.571.6-.571z' id='a'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cg mask='url(%23b)' fill='%23343433'%3E%3Cpath d='M0 0h8.4v6H0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");*/
  width: 9px;
  height: 6px;
  background-repeat: no-repeat;
  vertical-align: middle;
}

@media print, screen and (min-width: 740px) {
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__banner
    p {
    width: 100%;
    text-align: center;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__picture-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__picture-text
    .orderFix {
    -ms-flex-order: 2;
    order: 2;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__picture-text
    .orderFix:last-child {
    -ms-flex-order: 1;
    order: 1;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__text-second,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__text-first,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__picture-text-second,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__picture-text-first {
    width: 100%;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    img {
    padding-bottom: 1.5em;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__pictures-first
    p:first-of-type,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__pictures
    h3 {
    text-align: center;
    width: 100%;
  }
}
@media print, screen and (min-width: 1024px) {
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__banner
    p {
    width: 80%;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__video {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__video-iframe {
    width: 100%;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__video-text {
    width: 80%;
    text-align: center;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__video-text
    p {
    text-align: center;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__picture-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__picture-text
    .orderFix {
    -ms-flex-order: 1;
    order: 1;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__picture-text-second,
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__picture-text-first {
    width: 48%;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__picture-text
    .orderFix:last-child {
    -ms-flex-order: 2;
    order: 2;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    img {
    padding-bottom: 0;
  }
  .fb-product-information__product-information-tab
    .fb-product-information-tab__copy
    .fb-product-information-tab__copy__new
    .fb-product-information-tab__text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  b, strong{
    font-weight:bold;
  }
  i, em {
    font-style: italic;
  }
  ol {
    list-style: initial;
  }
}
`;

export { ProductInfoStyle };
