import React from 'react';
import _ from 'lodash';
import { PropTypes } from 'prop-types';
import { GenericCalculator } from '@digital-retail/journey-cross-components';
import { GenericCalculatorContainerStyle } from './GenericCalculatorContainer.style';
import { withProduct } from '../../utils/ProductContext';
import { useGenericCalculator } from './useGenericCalculator';
import { withApplicationContext } from '../../utils/ApplicationContext';

const GenericCalculatorContainer = ({ handleInputChange, product, appCtx }) => {
  const {
    setQuantity,
    calculatorConfig,
    productLevelAttributes,
    variantLevelAttributes,
    reload
  } = useGenericCalculator({ handleInputChange, product });

  const isGenericCalculatorEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isGenericCalculatorEnabled',
    true
  );

  if (_.isEmpty(calculatorConfig) || !isGenericCalculatorEnabled) return null;

  return (
    <>
      <div className="generic-calculator-container" key={reload}>
        <GenericCalculator
          calculatorConfig={calculatorConfig}
          theme="falabella"
          handleChangeProductCounter={setQuantity}
          productLevelAttributes={productLevelAttributes}
          variantLevelAttributes={variantLevelAttributes}
        />
        <style jsx>{GenericCalculatorContainerStyle}</style>
      </div>
    </>
  );
};

GenericCalculatorContainer.defaultProps = {};

GenericCalculatorContainer.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired
};

export default withProduct(withApplicationContext(GenericCalculatorContainer));
export { GenericCalculatorContainer };
