import React from 'react';
import PropTypes from 'prop-types';
import MatchMedia from '../../../components/ui/MatchMedia/MatchMedia';
import ProductSpecificationsDesktop from './Desktop/ProductSpecificationsDesktop';
import ProductSpecificationsMobile from './Mobile/ProductSpecificationsMobile';
import noop from '../../../utils/noop';

const HardlineProductSpecifications = ({
  isPumAvailable,
  pumQuantity,
  primeShipping,
  colorVariants,
  selectedColorSwatchIndex,
  colorSwatchHandler,
  sizes,
  selectedSize,
  sizeChangeHandler,
  handleAddToCart,
  isAddToCartInProgress,
  operatorChangeHandler,
  operators,
  addToCartButtonVisible,
  showCollectionButtonVisible,
  setWarrantyOption,
  setServiceOption,
  isVendorLogin,
  storeSkuData,
  currentTab,
  selectedOperatorId,
  errorAddingToCart,
  requestProductFromStore,
  handleM2QuantityChange,
  m2Quantity,
  m2ResultValue,
  handleM2ResultChange,
  m2Value,
  handleM2ValueChange,
  isExtraM2Checked,
  handleExtraM2Change,
  m2AttributeValue,
  setShowLoginForm,
  showLoginForm,
  setErrorAddingToCart
}) => {
  const specificationProps = {
    isPumAvailable,
    pumQuantity,
    primeShipping,
    colorVariants,
    selectedColorSwatchIndex,
    colorSwatchHandler,
    selectedSize,
    sizes,
    sizeChangeHandler,
    handleAddToCart,
    isAddToCartInProgress,
    operatorChangeHandler,
    operators,
    addToCartButtonVisible,
    showCollectionButtonVisible,
    setWarrantyOption,
    setServiceOption,
    isVendorLogin,
    storeSkuData,
    currentTab,
    selectedOperatorId,
    errorAddingToCart,
    requestProductFromStore,
    handleM2QuantityChange,
    m2Quantity,
    m2ResultValue,
    handleM2ResultChange,
    m2Value,
    handleM2ValueChange,
    isExtraM2Checked,
    handleExtraM2Change,
    m2AttributeValue,
    setShowLoginForm,
    showLoginForm,
    setErrorAddingToCart
  };

  return (
    <MatchMedia
      content={{
        desktop: () => <ProductSpecificationsDesktop {...specificationProps} />,
        mobile: () => <ProductSpecificationsMobile {...specificationProps} />,
        tablet: () => <ProductSpecificationsMobile {...specificationProps} />
      }}
    />
  );
};

HardlineProductSpecifications.defaultProps = {
  isPumAvailable: false,
  pumQuantity: 0,
  primeShipping: false,
  colorVariants: [],
  selectedColorSwatchIndex: 0,
  colorSwatchHandler: noop,
  sizes: [],
  selectedSize: '',
  sizeChangeHandler: noop,
  handleAddToCart: noop,
  isAddToCartInProgress: false,
  operatorChangeHandler: noop,
  requestProductFromStore: noop,
  operators: [],
  addToCartButtonVisible: true,
  showCollectionButtonVisible: false,
  setWarrantyOption: noop,
  setServiceOption: noop,
  currentTab: '',
  storeSkuData: {},
  selectedOperatorId: null,
  errorAddingToCart: false,
  handleM2QuantityChange: noop,
  m2Quantity: 1,
  m2ResultValue: 0,
  handleM2ResultChange: noop,
  m2Value: '',
  handleM2ValueChange: noop,
  isExtraM2Checked: true,
  handleExtraM2Change: noop,
  setErrorAddingToCart: noop,
  m2AttributeValue: -1,
  showLoginForm: false
};

HardlineProductSpecifications.propTypes = {
  isPumAvailable: PropTypes.bool,
  pumQuantity: PropTypes.number,
  primeShipping: PropTypes.bool,
  colorVariants: PropTypes.array,
  selectedColorSwatchIndex: PropTypes.number,
  colorSwatchHandler: PropTypes.func,
  sizes: PropTypes.arrayOf(PropTypes.object),
  selectedSize: PropTypes.string,
  sizeChangeHandler: PropTypes.func,
  handleAddToCart: PropTypes.func,
  isAddToCartInProgress: PropTypes.bool,
  operatorChangeHandler: PropTypes.func,
  requestProductFromStore: PropTypes.func,
  operators: PropTypes.array,
  addToCartButtonVisible: PropTypes.bool,
  showCollectionButtonVisible: PropTypes.bool,
  setWarrantyOption: PropTypes.func,
  setServiceOption: PropTypes.func,
  isVendorLogin: PropTypes.bool.isRequired,
  storeSkuData: PropTypes.object,
  currentTab: PropTypes.string,
  selectedOperatorId: PropTypes.string,
  errorAddingToCart: PropTypes.bool,
  handleM2QuantityChange: PropTypes.func,
  m2Quantity: PropTypes.number,
  m2ResultValue: PropTypes.number,
  handleM2ResultChange: PropTypes.func,
  m2Value: PropTypes.string,
  handleM2ValueChange: PropTypes.func,
  isExtraM2Checked: PropTypes.bool,
  handleExtraM2Change: PropTypes.func,
  m2AttributeValue: PropTypes.number,
  setShowLoginForm: PropTypes.func.isRequired,
  setErrorAddingToCart: PropTypes.func,
  showLoginForm: PropTypes.bool
};

export default HardlineProductSpecifications;
