import constants from '../config/constants';
import CookieStorage from './CookieStorage';
import { getStoreValue } from './tenantHelper';

const addQueryParameter = (uri, key, value) => {
  const separator = uri.indexOf('?') !== -1 ? '&' : '?';
  return `${uri + separator + key}=${value}`;
};

const getLocation = (uri) => {
  const regex = new RegExp(
    [
      '^(https?:)//', // protocol
      '(([^:/?#]*)(?::([0-9]+))?)', // host (hostname and port)
      '(/{0,1}[^?#]*)', // pathname
      '(\\?[^#]*|)', // search
      '(#.*|)$' // hash
    ].join('')
  );
  const match = uri.match(regex);
  return (
    match && {
      href: uri,
      protocol: match[1].replace(':', ''),
      host: match[2],
      hostname: match[3],
      port: match[4] || '80',
      pathname: match[5] || '/',
      search: match[6],
      hash: match[7]
    }
  );
};

const getPage = (uri) => {
  const errorPage = document.getElementsByClassName('error-page');
  if (errorPage.length > 0) {
    return 'error';
  }
  const path = getLocation(uri);
  const paths = path.pathname.split('/');
  return paths.length > 2 ? paths[2] : '';
};

const parseQueryString = (url) => {
  const params = {};
  if (url.indexOf('?') === -1) {
    return params;
  }
  url
    .substring(url.indexOf('?') + 1)
    .split('&')
    .filter((param) => param)
    .filter((p) => !!p)
    .forEach((param) => {
      const [name = '', value = ''] = param.split('=');
      if (name) {
        params[decodeURIComponent(name)] =
          decodeURIComponent(value.replace(/\+/g, '%20')) || '';
      }
    });

  return params;
};

const serializeQuery = (params = {}, ignoreQuery = []) => {
  return Object.keys(params)
    .filter((name) => ignoreQuery.indexOf(name) === -1)
    .map((name) => {
      if (params[name]) {
        const value = encodeURIComponent(params[name]);
        return `${encodeURIComponent(name)}=${value.replace(/(%20)+/g, '+')}`;
      }
      return encodeURIComponent(name);
    })
    .join('&');
};

const getPaginatedUrl = (currentUrl, pageIndex, store = null) => {
  let baseURL = currentUrl.split('?')[0];
  const params = parseQueryString(currentUrl);
  params.page = pageIndex;
  if (store) {
    params.store = store;
    const sellerTenant = getStoreValue(store);
    baseURL =
      store !== 'tienda' ? baseURL.replace('falabella', sellerTenant) : baseURL;
  }
  const qString = serializeQuery(params);
  return qString ? `${baseURL}?${serializeQuery(params)}` : `${baseURL}`;
};

const isMarketPlace = () => {
  const experience = CookieStorage.getCookie('seller_experience');
  return experience === 'MARKETPLACE';
};

const getSidCookie = () => {
  return CookieStorage.getCookie('sid');
};

const isSodimacSite = () =>
  /sodimac\.(cl|com\.pe)/.test(window.location.origin);

const getOGImageURL = (regionCode, isRebrandingEnabled) => {
  if (isRebrandingEnabled) {
    return constants.META.OG[regionCode].IMAGE_MKP;
  }
  return constants.META.OG[regionCode].IMAGE;
};

const getMarketplaceHost = (regionCode, env = 'production') => {
  const envMapping = {
    development: 'dev',
    staging: 'staging',
    production: 'www',
    uat: 'beta'
  };
  const region = regionCode === 'cl' || !regionCode ? '' : `.${regionCode}`;
  const environment = envMapping[env] || 'www';
  const host = `https://${environment}.falabella.com${region}`;
  return host;
};

const buildHrefURL = (pathname, appCtx) => {
  const { store, regionCode } = appCtx;
  let BU = 'falabella';
  if (store) {
    if (store === 'so_com') {
      BU = 'sodimac';
    } else {
      BU = store === 'tienda' ? 'falabella' : store;
    }
  }
  const URL = `/${BU}-${regionCode}/${pathname}`;
  return URL;
};

const getRequestUrl = (reqUrl, tenant) => {
  const { site = tenant, store, ...rest } = parseQueryString(reqUrl);
  const baseURL = `/${site}${reqUrl.split('?')[0]}`;
  const qString = serializeQuery(rest);
  return qString ? `${baseURL}?${qString}` : `${baseURL}`;
};

const getProductUrlParams = ({ params, appCtx, storeQuery }) => {
  return {
    ...params,
    pgid: appCtx.priceGroupId,
    pid: appCtx.politicalId,
    zones: appCtx.zones,
    channel: 'web',
    isUserSelectedZone: appCtx.isUserSelectedZone,
    ...(storeQuery && { exp: storeQuery })
  };
};

const isTiendaLinioPDP = (store, url) => {
  if (!store || typeof store !== 'string') {
    return false;
  }
  const newStore = store.toLowerCase();
  if ((newStore === 'tienda' || newStore === 'linio') && url === '/product') {
    return true;
  }
  return false;
};

const is3pSeller = (sellerId) => {
  return !constants.FIRST_PARTY_SELLER_REGEX.test(sellerId.toLowerCase());
};

const getPreviousUrl = () => {
  return window.previousUrl || document.referrer;
};

export {
  addQueryParameter,
  getLocation,
  getPage,
  getPaginatedUrl,
  isMarketPlace,
  getOGImageURL,
  getRequestUrl,
  getProductUrlParams,
  getMarketplaceHost,
  buildHrefURL,
  isTiendaLinioPDP,
  getSidCookie,
  is3pSeller,
  getPreviousUrl,
  isSodimacSite
};
