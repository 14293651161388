import React, { Fragment } from 'react';
import MatchMedia from '../../ui/MatchMedia/MatchMedia';
import ImageGalleryCarouselDesktop from './ImageGalleryCarouselDesktop/ImageGalleryCarouselDesktop';
import ImageGalleryCarouselMobile from './ImageGalleryCarouselMobile/ImageGalleryCarouselMobile';

const ImageGalleryCarousel = (props) => {
  return (
    <Fragment>
      <MatchMedia
        content={{
          mobile: () => <ImageGalleryCarouselMobile {...props} />,
          tablet: () => <ImageGalleryCarouselMobile {...props} />,
          desktop: () => <ImageGalleryCarouselDesktop {...props} />
        }}
      />
    </Fragment>
  );
};

export default ImageGalleryCarousel;
