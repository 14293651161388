/* stylelint-disable no-descending-specificity */
import css from 'styled-jsx/css';
import { colors } from '../../../config/styles';

const InternationalBuyModalStyles = css`
  .modal-header {
    padding: 29px 16px;
    @mixin tabletToDesktop {
      padding: 27px 32px 19px;
    }
    .modal-title {
      font-size: 22px;
      line-height: 24px;
      color: ${colors.gray.shade15};
      @mixin tabletToDesktop {
        font-size: 20px;
      }
    }
  }
  .modal-container {
    padding: 0 16px;
    color: ${colors.gray.shade40};
    overflow-y: auto;
    max-height: 75vh;
    @mixin tabletToDesktop {
      padding: 0 32px;
      max-height: 400px;
    }
  }
  .hide-footer {
    max-height: 100vh;
    @mixin tabletToDesktop {
      max-height: 455px;
    }
  }

  .paragraph {
    margin-top: 16px;
    font-size: 16px;
    line-height: 22px;
    .bold {
      font-weight: 700;
      color: ${colors.black.shade10};
    }
    @mixin tabletToDesktop {
      margin-top: 11px;
    }
  }

  .second-paragraph {
    margin-top: 16px;
  }

  .notifications-section {
    margin-top: 24px;
    @mixin tabletToDesktop {
      margin-top: 32px;
    }
    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: ${colors.black.shade10};
    }
    .list {
      margin-top: 16px;
      font-size: 16px;
      line-height: 20px;
      ul {
        list-style: disc;
        list-style-position: outside;
        margin-left: 16px;
      }
      li {
        margin: 8px 0;
      }
    }
  }
  .no-margin {
    margin: 0;
  }
  .rp-modal-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 16px;
    .divider-line {
      border-top: 1px solid #f0f0f0;
    }
    @mixin mobileToTablet {
      position: fixed;
      bottom: 0;
      left: 0;
      background: #fff;
      padding: 0 16px;
      height: 66px;
    }

    .footer-wrapper {
      display: flex;
      padding: 16px 20px 16px 16px;
      align-items: center;
      @mixin mobileToTablet {
        align-items: flex-start;
      }
      a {
        text-decoration: none;
        cursor: pointer;
        p {
          color: #4a4a4a;
          text-decoration: none;
          line-height: 19px;

          b {
            position: relative;
            font-weight: bold;
            line-height: 19.2px;
            color: #495867;
            border-bottom: 1px solid #495867;
            text-decoration: none;

            @mixin mobileToTablet {
              white-space: pre;
            }
          }
        }
      }
    }

    .alertIcon {
      cursor: pointer;
      display: inline-block;
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
      color: #495867;
      font-size: 1.5rem;
      margin-right: 8px;
      @mixin mobileToTablet {
        margin-top: 4px;
      }
    }
    p {
      font-size: 16px;
      line-height: 19.2px;
      letter-spacing: 0.2px;
      color: #4a4a4a;
      b {
        font-weight: 700;
        color: #4a4a4a;
        text-decoration: underline;
      }
    }
  }
`;

export default InternationalBuyModalStyles;
