import React from 'react';
import PropTypes from 'prop-types';
import { SingleChipletSelectionOptionStyles } from './SingleChipletSelectCheckbox.style';
import noop from '../../../../utils/noop';
import Checkbox from '../../Checkbox/Checkbox';

const SingleChipletSelectCheckbox = ({
  label,
  selected,
  onChange,
  itemId,
  type,
  variant,
  clickableChiplet,
  isCart,
  isMobile,
  disabled,
  isRebrandingEnabled,
  serviceType,
  isAdditionalService
}) => {
  const handleChange = () => {
    onChange(itemId);
  };

  const getIdForWarranty = () => {
    if (!label) {
      return '';
    }
    const isNoThanksLabel = /gracias/.test(label);
    const [firstMatch] = label.match(/^[^$]*/); // i.e: matches everything before a "$"
    if (!firstMatch || isNoThanksLabel) {
      return `testId-${serviceType}-btn-nothanks`;
    }
    const parsedFirstMatch = firstMatch
      .toLowerCase()
      .trim()
      .replace(',', '')
      .replace(' ', '-');
    return `testId-${serviceType}-btn-${parsedFirstMatch}`;
  };

  return clickableChiplet ? (
    <button
      className={`option pointer ${
        isRebrandingEnabled ? 'mkp' : ''
      } ${type} ${variant} ${
        selected ? 'option-selected' : 'option-not-selected'
      } ${isCart ? 'background' : ''}
       ${isMobile ? 'mobile' : ''} ${
        isAdditionalService ? 'additional-option' : ''
      }`}
      onClick={handleChange}
      type="button"
      onKeyDown={noop}
      disabled={disabled}
      id={isCart ? `testId-${serviceType}-btn-${itemId}` : getIdForWarranty()}
    >
      {isAdditionalService && (
        <span
          role="button"
          onClick={(e) => {
            if (disabled) return null;
            handleChange();
            e.preventDefault();
            e.stopPropagation();
            return null;
          }}
          onKeyDown={noop}
          tabIndex={0}
          className="checkbox-wrapper"
          id="testId-checkbox-additional-service"
        >
          <Checkbox
            isChecked={selected}
            disableClick
            selectedBorderClass=" mkp-border"
            type="mkp-succes"
            variant="services"
            id={`warranty-checkbox-${itemId}`}
            disabled={disabled}
          />
        </span>
      )}
      <span
        className={`label  ${isMobile ? 'mobile' : ''} ${
          isRebrandingEnabled ? 'mkp' : ''
        }`}
      >
        {label}
      </span>
      <style jsx>{SingleChipletSelectionOptionStyles}</style>
    </button>
  ) : (
    <div
      className={`option ${type} ${variant} ${
        selected ? 'option-selected' : ''
      }`}
    >
      <span className="checkbox-wrapper">
        <input
          type="checkbox"
          name="group"
          checked={selected}
          onChange={handleChange}
        />
      </span>
      {selected && <i className="csicon-succes selected" />}
      <span className="label">{label}</span>
      <style jsx>{SingleChipletSelectionOptionStyles}</style>
    </div>
  );
};

SingleChipletSelectCheckbox.defaultProps = {
  label: '',
  selected: false,
  onChange: noop,
  itemId: -1,
  type: 'small',
  variant: '',
  clickableChiplet: false,
  isCart: false,
  isMobile: false,
  disabled: false,
  isRebrandingEnabled: false,
  serviceType: '',
  isAdditionalService: false
};

SingleChipletSelectCheckbox.propTypes = {
  label: PropTypes.string,
  selected: PropTypes.bool,
  onChange: PropTypes.func,
  itemId: PropTypes.number,
  type: PropTypes.oneOf(['small', 'medium']),
  variant: PropTypes.oneOf(['', 'cart-mobile']),
  clickableChiplet: PropTypes.bool,
  isCart: PropTypes.bool,
  isMobile: PropTypes.bool,
  disabled: PropTypes.bool,
  isRebrandingEnabled: PropTypes.bool,
  serviceType: PropTypes.string,
  isAdditionalService: PropTypes.bool
};

export default SingleChipletSelectCheckbox;
