import createContainer from 'constate';
import WithRouter from '../../utils/WithRouter';
import constants from '../../config/constants';

const LanguageContext = createContainer(
  () => {
    const language = WithRouter(constants.lang) || 'es';
    // eslint-disable-next-line
    const translations = require(`../../config/translations/labels-${language}`);
    return translations;
  },
  () => []
);

const RouterContext = createContainer(
  (router) => router,
  (props) => [props.router.query, props.router.deviceType]
);

export { LanguageContext, RouterContext };
