import React, { useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ImageGalleryCarouselStyles } from './ImageGalleryCarouselMobile.style';
import noop from '../../../../utils/noop';
import { isVideo, getMediaData } from '../../ImageGalleryHelper';
import Image from '../../../ui/Image/Image';
import SliderDots from '../../SliderDots/SliderDots';
import DemoUpButtonContainer from '../../DemoUpButton/DemoUpButtonContainer';

const ImageGalleryCarouselMobile = ({
  carouselImageHeight,
  carouselImageWidth,
  activeImageIndex,
  setActiveImageIndex,
  images,
  productName,
  brandName,
  isRebrandingEnabled,
  isDottedImageCarouselEnabled
}) => {
  const carouselRef = useRef();

  const [xOffset, setXOffset] = useState(0);
  const [startX, setStartX] = useState();

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (!carouselRef.current) return;
    setAnimate(true);

    const {
      offsetLeft,
      clientWidth: activeImageWidth
    } = carouselRef.current.children[activeImageIndex];
    const { clientWidth } = carouselRef.current;
    const LastIndex = images.length - 2;

    const imageWidth =
      activeImageIndex === LastIndex
        ? activeImageWidth
        : activeImageWidth + activeImageWidth / 2;

    const isLeftThumbnailClipped = offsetLeft < Math.abs(xOffset);

    const isRightThumbnailClipped =
      offsetLeft + imageWidth > Math.abs(xOffset) + clientWidth;

    if (isLeftThumbnailClipped) {
      setXOffset(-offsetLeft);
      return;
    }

    if (isRightThumbnailClipped) {
      setXOffset(clientWidth - imageWidth - offsetLeft);
    }
  }, [activeImageIndex]);

  const handleTouchMove = (e) => {
    const { clientX } = e.targetTouches[0];
    const { clientWidth, scrollWidth } = carouselRef.current;

    const offset = clientX - startX;
    if (offset <= 0 && offset >= clientWidth - scrollWidth) {
      setXOffset(offset);
    }
  };

  const handleTouchStart = (e) => {
    const { clientX } = e.targetTouches[0];
    setStartX(clientX - xOffset);
  };

  const handleTouchEnd = () => {
    setStartX(xOffset);
  };

  const onTransitionEnd = () => {
    setAnimate(false);
  };

  const renderImage = (image) => {
    const { mediaId, mediaUrl } = getMediaData(image);
    return (
      <Fragment>
        {isVideo(image) ? (
          <div className="image-carousel fa--image-gallery-carousel__mobile">
            {' '}
          </div>
        ) : (
          <Image
            id={mediaId}
            imageUrl={mediaUrl}
            width={carouselImageWidth}
            height={carouselImageHeight}
            alt={`${brandName} - ${productName}`}
            useSrcSet
            lazyLoad
          />
        )}
        <style jsx>{ImageGalleryCarouselStyles}</style>
      </Fragment>
    );
  };
  if (isDottedImageCarouselEnabled && images.length - 2 > 0) {
    return (
      <>
        <SliderDots
          allDots={[...Array(images.length - 2).keys()]}
          maxDots={5}
          selectedIndex={activeImageIndex - 1}
          onDotClick={(newIndex) => setActiveImageIndex(newIndex + 1)}
        />
        <DemoUpButtonContainer />
      </>
    );
  }

  return (
    <div
      className={`carousel 
      fa--image-gallery-carousal__mobile
      ${isRebrandingEnabled && 'rebranded'}`}
    >
      <div
        className="carousel-wrapper"
        onTouchMoveCapture={handleTouchMove}
        role="button"
        tabIndex="0"
        ref={(r) => {
          carouselRef.current = r;
        }}
        onTouchMove={handleTouchMove}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onKeyDown={noop}
        onTransitionEnd={onTransitionEnd}
        style={{
          transform: `translateX(${xOffset}px)`,
          transition: animate ? 'all 0.2s ease-in-out' : 'none'
        }}
      >
        {images.map((image, index) => {
          const { mediaId } = getMediaData(image);
          return index === 0 || index === images.length - 1 ? (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${mediaId}-hidden-${index}`} />
          ) : (
            <div
              role="button"
              tabIndex="0"
              key={mediaId}
              className={`image-wrapper ${
                activeImageIndex === index ? 'active' : ''
              } ${isVideo(image) ? 'video' : ''}`}
              onClick={() => {
                setActiveImageIndex(index);
              }}
              onKeyDown={noop}
            >
              {renderImage(image)}
            </div>
          );
        })}
      </div>

      <style jsx>{ImageGalleryCarouselStyles}</style>
    </div>
  );
};
ImageGalleryCarouselMobile.defaultProps = {
  images: [],
  isRebrandingEnabled: false,
  isDottedImageCarouselEnabled: false
};
ImageGalleryCarouselMobile.propTypes = {
  carouselImageHeight: PropTypes.number.isRequired,
  carouselImageWidth: PropTypes.number.isRequired,
  activeImageIndex: PropTypes.number.isRequired,
  setActiveImageIndex: PropTypes.func.isRequired,
  images: PropTypes.array,
  productName: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  isRebrandingEnabled: PropTypes.bool,
  isDottedImageCarouselEnabled: PropTypes.bool
};

export default ImageGalleryCarouselMobile;
