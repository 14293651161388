import { isWindow } from './deviceChecker';
import { logger } from '../lib/logger';

const logError = (e, tracingHeaders, tenant) => {
  if (!e) return;

  if (typeof e === 'object') {
    e.msg = e.stack || e.reason;
  }

  logger.error({
    error: e,
    tenant,
    tracingHeaders,
    timestamp: new Date().toISOString()
  });
};

const logInfo = (msg, tracingHeaders, tenant) => {
  if (!msg) return;

  logger.info({
    info: msg,
    tenant,
    tracingHeaders,
    timestamp: new Date().toISOString()
  });
};

const attachErrorLogger = () => {
  if (!isWindow()) {
    return false;
  }

  window.addEventListener('error', (e) => {
    logError(e.error);
  });

  window.addEventListener('unhandledrejection', (e) => {
    logError(e);
  });

  return true;
};

class Logger {
  static instance;

  static error(e) {
    return Logger.instance && Logger.instance.error(e);
  }

  static info(msg) {
    return Logger.instance && Logger.instance.info(msg);
  }

  static init(appCtx) {
    const { tracingHeaders, regionCode } = appCtx || {};
    const traceOnlyId = {
      'x-request-id': tracingHeaders['x-request-id'],
      'x-b3-traceid': tracingHeaders['x-b3-traceid'],
      'x-b3-spanid': tracingHeaders['x-b3-spanid'],
      'x-b3-parentspanid': tracingHeaders['x-b3-parentspanid']
    };
    Logger.instance = {
      error: (e) => logError(e, traceOnlyId, regionCode),
      info: (msg) => logInfo(msg, traceOnlyId, regionCode)
    };
  }
}

export default Logger;
export { attachErrorLogger };
