export const FETCH_INSTALLMENT = 'FETCH_INSTALLMENT';
export const FETCH_INSTALLMENT_SUCCESS = 'FETCH_INSTALLMENT_SUCCESS';
export const FETCH_INSTALLMENT_ERROR = 'FETCH_INSTALLMENT_ERROR';
export const INSTALLMENT_NUM_CHANGE = 'INSTALLMENT_NUM_CHANGE';
export const UPDATE_INSTALLMENT_DETAILS = 'UPDATE_INSTALLMENT_DETAILS';
export const SAVE_CARD_INFO_PROFILE = 'SAVE_CARD_INFO_PROFILE';
export const SAVE_CARD_INFO_PROFILE_SUCCESS = 'SAVE_CARD_INFO_PROFILE_SUCCESS';
export const SAVE_CARD_INFO_PROFILE_ERROR = 'SAVE_CARD_INFO_PROFILE_ERROR';
export const SAVE_PROMOTION_INFO = 'SAVE_PROMOTION_INFO';
export const SAVE_PROMOTION_INFO_SUCCESS = 'SAVE_PROMOTION_INFO_SUCCESS';
export const SAVE_PROMOTION_INFO_ERROR = 'SAVE_PROMOTION_INFO_ERROR';
export const UPDATE_BANK_OR_CARD_NAME = 'UPDATE_BANK_OR_CARD_NAME';
export const SAVE_INSTALLMENT_INFO = 'SAVE_INSTALLMENT_INFO';
export const SAVE_INSTALLMENT_INFO_SUCCESS = 'SAVE_INSTALLMENT_INFO_SUCCESS';
export const SAVE_INSTALLMENT_INFO_ERROR = 'SAVE_INSTALLMENT_INFO_ERROR';
