import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TypeAVariantDesktop from './Desktop/TypeAVariantDesktop';
import MatchMedia from '../ui/MatchMedia/MatchMedia';
import TypeAVariantMobile from './Mobile/TypeAVariantMobile';
import noop from '../../utils/noop';
import constants from '../../config/constants';

const TypeAVariant = ({
  brandName,
  variantName,
  variantId,
  prices,
  addToCart,
  isHomeDeliveryAvailable,
  isPickFromStoreAvailable,
  offeringId,
  maxOnCartAmount,
  stockUnits,
  isRebrandingEnabled
}) => {
  const [productCount, setProductCount] = useState(1);

  const incrementCount = () => setProductCount(productCount + 1);
  const decrementCount = () => setProductCount(productCount - 1);

  const maxOnLimitCart = (networkStock, limit) => {
    if (typeof networkStock !== 'number') {
      return limit;
    }
    return networkStock > limit ? limit : networkStock;
  };

  const handleAddToCart = () => {
    addToCart({ skuId: variantId, quantity: productCount, offeringId });
  };

  const networkStock = stockUnits[variantId];
  const limit = maxOnCartAmount || constants.MAX_CART_PRODUCT;

  const maxForStepper = maxOnLimitCart(networkStock, limit) + 1;

  const showAlert = !!(productCount > maxOnLimitCart(networkStock, limit));

  const typeAVariantProps = {
    variantName,
    brandName,
    prices,
    isHomeDeliveryAvailable,
    isPickFromStoreAvailable,
    addToCart: handleAddToCart,
    variantId,
    count: productCount,
    incrementCount,
    decrementCount,
    maxForStepper,
    showAlert,
    isRebrandingEnabled
  };

  return (
    <MatchMedia
      content={{
        desktop: () => <TypeAVariantDesktop {...typeAVariantProps} />,
        mobile: () => <TypeAVariantMobile {...typeAVariantProps} />,
        tablet: () => <TypeAVariantDesktop {...typeAVariantProps} />
      }}
    />
  );
};

TypeAVariant.defaultProps = {
  brandName: '',
  prices: [],
  addToCart: noop,
  isHomeDeliveryAvailable: false,
  isPickFromStoreAvailable: false,
  offeringId: '',
  maxOnCartAmount: 0,
  stockUnits: [],
  isRebrandingEnabled: false
};

TypeAVariant.propTypes = {
  brandName: PropTypes.string,
  variantName: PropTypes.string.isRequired,
  variantId: PropTypes.string.isRequired,
  prices: PropTypes.arrayOf(PropTypes.object),
  addToCart: PropTypes.func,
  isHomeDeliveryAvailable: PropTypes.bool,
  isPickFromStoreAvailable: PropTypes.bool,
  offeringId: PropTypes.string,
  maxOnCartAmount: PropTypes.number,
  stockUnits: PropTypes.arrayOf(PropTypes.object),
  isRebrandingEnabled: PropTypes.bool
};

export default TypeAVariant;
