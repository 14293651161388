const pino = require('pino');

const traceHeaders = [
  'x-request-id',
  'x-b3-traceid',
  'x-b3-spanid',
  'x-b3-parentspanid',
  'x-b3-sampled',
  'x-b3-flags',
  'x-ot-span-context',
  'referer'
];

const getHeaders = (headers) => {
  const result = {};
  if (!headers) {
    return result;
  }
  traceHeaders.forEach((header) => {
    if (headers[header]) {
      result[header] = headers[header];
    }
  });
  return result;
};

const logger = pino({
  messageKey: 'message',
  changeLevelName: 'severity',
  useLevelLabels: true,
  base: {
    serviceContext: {
      service: 'web'
    }
  },
  level: process.env.LOG_LEVEL || 'error',
  serializers: {
    res(res) {
      return {
        statusCode: res.statusCode,
        headers: getHeaders(res.headers)
      };
    },
    req(req) {
      return {
        method: req.method,
        url: req.url,
        path: req.path,
        parameters: req.parameters,
        headers: getHeaders(req.headers)
      };
    }
  }
});
module.exports = {
  logger
};
