import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { AvailabilityMobileStyles } from './AvailabilityMobile.style';
import { getMetaKey, getSelectionText } from '../common';
import noop from '../../../utils/noop';
import AvailabilityModal from '../AvailabilityModal';
import { Modal } from '../../ui/Modal/Modal';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import constants from '../../../config/constants';
import { withProduct } from '../../../utils/ProductContext';
import { useRegionComuna } from '../../../utils/hooks';
import WebStorage from '../../../utils/WebStorage';
import CookieStorage from '../../../utils/CookieStorage';
import DeliveryOptionsModal from '../DeliveryOptions/DeliveryOptionsModal';
import DeliveryOptionsSelectedText from '../DeliveryOptions/DeliveryOptionsSelectedText';
import { useSelectedDeliveryOption } from '../../../utils/hooks/UseSelectedDeliveryOption';
import { getVariant } from '../../../utils/variant';
import { capitalizeString } from '../../../utils/jsUtils';

const AvailabilityMobileItem = ({
  appCtx,
  product,
  availability,
  labels,
  isLast,
  hasModal,
  sizes,
  colorVariants
}) => {
  const {
    currentVariant,
    showShippingModal,
    shippingEstimatedPrice,
    variants
  } = product;
  const variant =
    variants && variants.length ? getVariant(variants, currentVariant) : {};
  const { deviceType, isRebrandingEnabled, store } = appCtx;
  const isSodimacStandAlone = store === constants.STORES.so_com;
  const {
    labelsKey,
    iconsClass: { outOfStock, inStock }
  } = getMetaKey(availability.shippingOptionType, isSodimacStandAlone);

  const isOutOfStock =
    availability.stateOfStock === constants.AVAILABILITY_OUTOFSTOCK;

  const [modalVisible, setModalVisible] = useState(false);
  const isNewDeliveryOptionsEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isNewDeliveryOptionsEnabled',
    false
  );

  const {
    selectedDeliveryOption,
    setSelectedDeliveryOption,
    zoneConfig,
    setZoneConfig
  } = useSelectedDeliveryOption(
    availability.shippingOptionType,
    appCtx,
    currentVariant
  );
  const comunaName = _.get(zoneConfig, 'name', '');

  const { displayText } = useRegionComuna({
    modalVisible,
    lsComunaKey: labelsKey.localStorageComunaKey,
    lsRegionKey: labelsKey.localStorageRegionKey,
    lsCiudadKey: labelsKey.localStorageCiudadKey,
    displayKey: labelsKey.localAvailableTextDisplayKey
  });

  const [selectedCommuna, setSelectedCommuna] = useState();

  // Get selected comuna
  const getSelectedCommuna = () => {
    const isHD = availability.shippingOptionType === 'HomeDelivery';

    // Get availabilityCookieValue Cookie
    const availabilityCookieValue = CookieStorage.getCookie(
      isHD
        ? constants.COOKIE_HOME_DELIVERY_KEY
        : constants.COOKIE_STORE_PICKUP_KEY
    );
    // Decode cookie value
    const availabilityCookieDecoded = CookieStorage.parseCookieValue(
      isHD
        ? constants.COOKIE_HOME_DELIVERY_KEY
        : constants.COOKIE_STORE_PICKUP_KEY,
      availabilityCookieValue
    );
    // Store comuna data for HD or CC in the LS
    if (availabilityCookieDecoded) {
      // Save HD or CC information to local storage
      WebStorage.setItem({
        name: isHD
          ? constants.LS_HOME_DELIVERY_KEY
          : constants.LS_STORE_PICKUP_KEY,
        value: JSON.stringify(availabilityCookieDecoded),
        shouldPersist: true
      });
      return availabilityCookieDecoded.displayName;
    }
    // Get HD or CC from LS
    const selection = WebStorage.getItem({
      name: isHD
        ? constants.LS_HOME_DELIVERY_KEY
        : constants.LS_STORE_PICKUP_KEY,
      fromPersistence: true
    });

    if (selection) {
      return JSON.parse(selection).displayName;
    }

    return '';
  };

  useEffect(() => {
    setSelectedCommuna(getSelectedCommuna());
  }, []);

  const renderOOSView = () => (
    <div className="content-main outofstock">
      {isNewDeliveryOptionsEnabled && (
        <React.Fragment>
          <div className="content-main">
            <span className="no-delivery underline">
              {labels[`${labelsKey.outOfStock}`]}
              {comunaName ? ` en ${capitalizeString(comunaName)}` : ''}
            </span>
          </div>
        </React.Fragment>
      )}
      {!isNewDeliveryOptionsEnabled && (
        <span className="bold">{labels[`${labelsKey.outOfStock}`]}</span>
      )}
      <style jsx>{AvailabilityMobileStyles}</style>
    </div>
  );

  const handleAvailabilityUpdate = () => {
    setSelectedCommuna(getSelectedCommuna());
  };

  const getContent = (actions) => (
    <AvailabilityModal
      actions={actions}
      type={availability.shippingOptionType}
      variant={currentVariant}
      onUpdate={handleAvailabilityUpdate}
    />
  );

  const getDeliveryModal = () => {
    return isNewDeliveryOptionsEnabled ? (
      <DeliveryOptionsModal
        type={availability.shippingOptionType}
        variant={currentVariant}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        zoneConfig={zoneConfig}
        setZoneConfig={setZoneConfig}
        selectedDeliveryOption={selectedDeliveryOption}
        setSelectedDeliveryOption={setSelectedDeliveryOption}
        sizes={sizes}
        colorVariants={colorVariants}
      />
    ) : (
      <Modal
        content={getContent}
        isRebrandingEnabled={isRebrandingEnabled}
        withPadding={false}
        toggleState={{
          visible: modalVisible,
          setVisible: setModalVisible
        }}
        deviceType={deviceType}
      />
    );
  };

  return (
    <Fragment>
      {hasModal && modalVisible && getDeliveryModal()}
      <div
        className={`availability-item  ${isLast &&
          'lastItem'} ${isRebrandingEnabled && 'rebranded'} ${
          isOutOfStock ? 'out-of-stock' : ''
        }`}
        onKeyDown={noop}
        role="button"
        tabIndex={0}
        onClick={() => setModalVisible(true)}
      >
        <div
          className={`icon ${
            isOutOfStock || selectedDeliveryOption.isError === true
              ? outOfStock
              : inStock
          }`}
        >
          <i />
        </div>
        <div className="content-container">
          <div className="content">
            {!isOutOfStock ? (
              <Fragment>
                {hasModal && (
                  <div
                    className="content-main"
                    onKeyDown={noop}
                    role="button"
                    tabIndex={0}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {showShippingModal ? (
                      <div
                        id={`testId-
                      Availability-Mobile-ModalOpen-${
                        availability.shippingOptionType
                      }`}
                        className="content-sub"
                      >
                        <span>
                          {displayText && labels[`${labelsKey.subPrefix}`]}{' '}
                        </span>
                        <React.Fragment>
                          {isNewDeliveryOptionsEnabled &&
                            selectedDeliveryOption &&
                            !_.isEmpty(selectedDeliveryOption) && (
                              <DeliveryOptionsSelectedText
                                selectedDeliveryOption={selectedDeliveryOption}
                                availability={availability}
                              />
                            )}
                        </React.Fragment>
                        <span
                          className={`underline ${isRebrandingEnabled &&
                            'mkp-subtitle'}`}
                        >
                          {getSelectionText({
                            isNewDeliveryOptionsEnabled,
                            selectedDeliveryOption,
                            comunaName,
                            labels,
                            labelsKey,
                            selectedCommuna,
                            displayText,
                            isSodimacStandAlone,
                            shippingEstimatedPrice,
                            variant,
                            shippingOptionType: availability.shippingOptionType
                          })}
                        </span>
                      </div>
                    ) : (
                      <Fragment />
                    )}
                  </div>
                )}
                {!hasModal && (
                  <Fragment>
                    <div className="content-main">
                      <span className="bold">
                        {labels[`${labelsKey.title}`]}
                      </span>
                    </div>
                    {showShippingModal ? (
                      <div
                        id={`testId-
                      Availability-Mobile-ModalOpen-${
                        availability.shippingOptionType
                      }`}
                        className="content-sub"
                        onKeyDown={noop}
                        role="button"
                        tabIndex={0}
                        onClick={() => setModalVisible(true)}
                      >
                        <span>
                          {displayText && labels[`${labelsKey.subPrefix}`]}{' '}
                        </span>
                        <span className="underline">
                          {displayText || labels[`${labelsKey.pleaseSelect}`]}
                        </span>
                      </div>
                    ) : (
                      <Fragment />
                    )}
                  </Fragment>
                )}
              </Fragment>
            ) : (
              renderOOSView()
            )}
          </div>
        </div>
        <style jsx>{AvailabilityMobileStyles}</style>
      </div>
    </Fragment>
  );
};

AvailabilityMobileItem.propTypes = {
  availability: PropTypes.object.isRequired,
  isLast: PropTypes.object,
  labels: PropTypes.object.isRequired,
  hasModal: PropTypes.bool,
  appCtx: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  sizes: PropTypes.array,
  colorVariants: PropTypes.array
};

AvailabilityMobileItem.defaultProps = {
  isLast: false,
  hasModal: true,
  sizes: [],
  colorVariants: []
};

export { AvailabilityMobileItem };
export default withProduct(withApplicationContext(AvailabilityMobileItem));
