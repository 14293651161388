import React from 'react';
import PropTypes from 'prop-types';

import { withRatings } from '../../../../../utils/RatingsContext';
import { withApplicationContext } from '../../../../../utils/ApplicationContext';

import { PDPBasicInfoShared } from '../../../../common/PDPBasicInfoShared';
import PDPBasicInfoMobileStyleHardline from './PDPBasicInfoMobileHardline.style';

const PDPBasicInfoMobileHardline = ({ product, ratings, labels, appCtx }) => {
  return (
    <PDPBasicInfoShared
      appCtx={appCtx}
      product={product}
      ratings={ratings}
      labels={labels}
      template="hardline"
      customStyle={PDPBasicInfoMobileStyleHardline}
    />
  );
};

PDPBasicInfoMobileHardline.propTypes = {
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  ratings: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired
};

export default withRatings(withApplicationContext(PDPBasicInfoMobileHardline));
