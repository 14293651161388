import { useState, useEffect } from 'react';
import WebStorage from '../WebStorage';

export const setToWebStorage = (key, regionObj) => {
  WebStorage.setItem({
    name: key,
    value: JSON.stringify(regionObj),
    shouldPersist: true
  });
};

export const getFromWebStorage = (key) => {
  try {
    return JSON.parse(
      WebStorage.getItem({
        name: key,
        fromPersistence: true
      })
    );
  } catch (err) {
    return null;
  }
};

export const updateCRFromLocalStorage = ({
  setComuna,
  setRegion,
  setCiudad,
  setDisplayText,
  lsComunaKey,
  lsCiudadKey,
  lsRegionKey,
  displayKey
}) => {
  const comunaStorage = getFromWebStorage(lsComunaKey) || {};
  const regionStorage = getFromWebStorage(lsRegionKey) || {};
  const ciudadStorage = getFromWebStorage(lsCiudadKey) || {};
  const displayStorage = getFromWebStorage(displayKey) || {};
  if (
    Object.prototype.hasOwnProperty.call(comunaStorage, 'id') &&
    Object.prototype.hasOwnProperty.call(regionStorage, 'id')
  ) {
    setComuna(comunaStorage);
    setRegion(regionStorage);
  }

  if (Object.prototype.hasOwnProperty.call(ciudadStorage, 'id')) {
    setCiudad(ciudadStorage);
  }

  if (Object.prototype.hasOwnProperty.call(displayStorage, 'name')) {
    setDisplayText(displayStorage.name);
  }
};

export const useRegionComuna = ({
  modalVisible,
  lsComunaKey,
  lsRegionKey,
  lsCiudadKey,
  displayKey
}) => {
  const [region, setRegion] = useState({});
  const [comuna, setComuna] = useState({});
  const [ciudad, setCiudad] = useState({});
  const [displayText, setDisplayText] = useState('');
  useEffect(() => {
    updateCRFromLocalStorage({
      setComuna,
      setRegion,
      setCiudad,
      setDisplayText,
      lsComunaKey,
      lsRegionKey,
      lsCiudadKey,
      displayKey
    });
  }, [modalVisible]);

  const handleUpdateRegion = (newRegion) => setRegion(newRegion);
  const handleUpdateComuna = (newComuna) => setComuna(newComuna);
  const handleUpdateCiudad = (newCiudad) => setCiudad(newCiudad);

  return {
    region,
    comuna,
    ciudad,
    displayText,
    handleUpdateComuna,
    handleUpdateRegion,
    handleUpdateCiudad
  };
};
