import { useState, useEffect } from 'react';
import _ from 'lodash';
import { getVariant } from '../../utils/variant';

const useGenericCalculator = ({ handleInputChange, product }) => {
  const [reload, setReload] = useState(false);
  const calculatorConfig = _.get(product, 'calculatorConfig', {});
  const productLevelAttributes = _.get(
    product,
    'attributes.specifications',
    []
  );
  const variants = _.get(product, 'variants', []);
  const currentVariantId = _.get(product, 'currentVariant', '');
  const currentVariant = getVariant(variants, currentVariantId);
  const variantLevelAttributes = _.get(
    currentVariant,
    'attributes.specifications',
    []
  );

  useEffect(() => {
    // reload the component if there is any change in variant
    setReload(!reload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVariantId]);

  const setQuantity = (value) => {
    if (!value) return;
    const event = {
      target: {
        value: value.toString()
      }
    };
    handleInputChange(event);
  };

  return {
    setQuantity,
    calculatorConfig,
    productLevelAttributes,
    variantLevelAttributes,
    reload
  };
};

export { useGenericCalculator };
