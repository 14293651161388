import _ from '../../utils/LodashImports';

export const getScreenConfig = ({ labels }) => {
  return {
    screen1: {
      showCloseButton: false,
      withPadding: false,
      title: _.get(labels, 'SEE_STORES_SELECT_VARIANT', '¿Cuál buscas?'),
      mobileTitle: _.get(labels, 'STOCK_EN_TIENDA_TITLE', 'Stock en tienda'),
      icon: 'floorCalcWarningIcon',
      mobileIcon: 'stockEnTienda',
      footer: _.get(
        labels,
        'STORE_AVAILABILITY_MODAL_FOOTER',
        'El stock en tienda es una referencia y puede variar durante el día.'
      )
    },
    screen2: {
      showCloseButton: false,
      withPadding: false,
      icon: 'stockEnTienda',
      title: _.get(labels, 'STOCK_EN_TIENDA_TITLE', 'Stock en tienda')
    },
    screen3: {
      showCloseButton: true,
      withPadding: false,
      icon: 'stockEnTienda',
      title: _.get(labels, 'STOCK_EN_TIENDA_TITLE', 'Stock en tienda'),
      footer: _.get(
        labels,
        'STORE_AVAILABILITY_MODAL_FOOTER',
        'El stock en tienda es una referencia y puede variar durante el día.'
      )
    },
    screen4: {
      showCloseButton: true,
      withPadding: false,
      icon: 'stockEnTienda',
      title: _.get(labels, 'STOCK_EN_TIENDA_TITLE', 'Stock en tienda')
    }
  };
};
