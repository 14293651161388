import React from 'react';
import PropTypes from 'prop-types';
import { withLabels } from '../../../utils/LabelsContext';
import { ScrollToStyles } from './ScrollTo.style';
import _ from '../../../utils/LodashImports';

const ScrollTo = ({
  labels,
  scrollToElementId,
  isRebrandingEnabled,
  type,
  isSoCom = false
}) => {
  const handleClick = (e) => {
    const viewMoreEl = document.getElementById(scrollToElementId);
    if (!viewMoreEl) return;
    viewMoreEl.scrollIntoView({ behavior: 'smooth' });
    e.preventDefault();
  };
  const viewMoreSpecsText = isSoCom
    ? _.get(labels, 'SO_COM_VIEW_MORE_SPECIFICATONS', 'Ver más')
    : labels.VIEW_MORE_SPECIFICATONS;

  return (
    <button
      className={`specifications-action ${isRebrandingEnabled &&
        'rebranded'} ${type}`}
      id="testId-topSpecifications-action-btn"
      onClick={handleClick}
      type="button"
    >
      {viewMoreSpecsText}
      <style jsx>{ScrollToStyles}</style>
    </button>
  );
};

ScrollTo.defaultProps = {
  labels: {},
  scrollToElementId: '',
  isRebrandingEnabled: false,
  type: '',
  isSoCom: false
};

ScrollTo.propTypes = {
  labels: PropTypes.object,
  scrollToElementId: PropTypes.string,
  isRebrandingEnabled: PropTypes.bool,
  type: PropTypes.string,
  isSoCom: PropTypes.bool
};

export { ScrollTo };
export default withLabels(ScrollTo);
