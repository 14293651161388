import css from 'styled-jsx/css';
import colors from '../../config/styles/colors';
import { svgToDataUri } from '../../utils/styles/svgMixins';

const calculatorIconSvg = `<svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.68746 22.3709V20.8261H8.26391V19.5578H6.68746V18.0129H5.37836V19.5578H3.80191V20.8261H5.37836V22.3709H6.68746Z" fill="#495867"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.2147 13.769H16.2V12.5007H12.2147V13.769Z" fill="#495867"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.2147 21.7303H16.2V20.462H12.2147V21.7303Z" fill="#495867"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.2147 19.456H16.2V18.1864H12.2147V19.456Z" fill="#495867"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.06808 12.2763L7.14749 11.3741L6.03331 12.4659L4.91781 11.3741L3.99722 12.2763L5.11141 13.3681L3.99722 14.4586L4.91781 15.362L6.03331 14.2702L7.14749 15.362L8.06808 14.4586L6.95258 13.3681L8.06808 12.2763Z" fill="#495867"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 25.6815H20V0H0V25.6815ZM1.31014 7.32379H18.6906V1.26953H1.31014V7.32379ZM1.31014 24.412H18.6906V8.59349H1.31014V24.412Z" fill="#495867"/>
</svg>`;

const FloorCalculatorStyles = css`
    .floor-calc-container {
        display: flex;
        width: 100%;
        height: auto;
        background-color: ${colors.gray.shade12};
        border: 1.1px solid ${colors.gray.shade36};
        box-sizing: border-box;
        border-radius: 3px;
        flex-direction: column;
        padding: 9px;
    }
    
    .calculator {
        display: flex;
    }

    .calculator-icon-container {
        width: 20px;
        height: 26px;
    }
    .calculator-icon {
        width: 20px;
        height: 26px;
        background-image: url('${svgToDataUri(calculatorIconSvg)}');
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
    }

    .calculator-info {
        padding: 0 8px;
        width: fit-content;
        flex: 1;
    }

    .calculator-input {
        display: flex;
        width: 64px;
        height: 32px;
        background-color: ${colors.white.shade1};
        align-items: center;
        padding: 0 5px;
        font-size: 13px;
        border: 1.1px solid ${colors.gray.shade36};
        box-sizing: border-box;
        border-radius: 3px;
        @mixin mobileToTablet {
            width: 67px;
            height: 40px;
        }
    }
    .calculator-input-wrapper {
        flex: 1;
    }
    .input-box {
        outline: none;
        width: 100%;
        padding: 0 3px;
        color: ${colors.gray.shade40};
        text-align: left;
    }
    .line {
        height: 16px;
        border: 0.5px solid ${colors.gray.shade40};
        margin-right: 5px;
        margin-left: 2px;
    }
    .unit {
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        text-align: right;
        letter-spacing: 0.14px;
        text-transform: lowercase;
        color: ${colors.gray.shade40};
    }

    .formulae {
        margin-top: 4px;
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0.14px;
        color: ${colors.black.shade10};
        text-align: left;
        margin-left: 28px;
        @mixin mobileToTablet {
            margin-top: 0;
        }
    }
    .m2-formula {
        padding-right: 5px;
    }
    .m2-calculations-guide {
        padding-left: 5px;
    }
    .m2-link {
        color: ${colors.black.shade10};
        text-decoration: none;
        border-bottom: 1px solid ${colors.black.shade10};
    }

    .calculationResult {
        margin-top: 10px;
        border-top: 1px solid ${colors.gray.shade36};
    }
    .calculationResult>p {
        margin-top: 10px;
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.14px;
        color: ${colors.black.shade10};
    }
    .calculationResult p span{
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.14px;
        color: ${colors.black.shade10};
    }
    .wastage-label {
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;  
        color: ${colors.black.shade10};
        align-self: flex-end;
    }

`;

export default FloorCalculatorStyles;
