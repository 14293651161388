import constants from '../../config/constants';

const availabilityOptions = ['SiteToStore', 'HomeDelivery', 'PickupInStore'];

const isAvailable = (availability) => {
  return (
    availability.find(
      (a) =>
        availabilityOptions.includes(a.shippingOptionType) &&
        a.stateOfStock === constants.AVAILABILITY_INSTOCK
    ) !== undefined
  );
};

const isNetworkInStock = (availability) => {
  const networkStock = availability.find(
    (a) => a.stockGeoTypeKey === constants.AVAILABILITY_NETWORKSTOCK
  );
  return networkStock && networkStock.quantity > 0;
};

export { isAvailable, isNetworkInStock };
