import React from 'react';
import PropTypes from 'prop-types';
import VariantAvailabilityOptions from '../../VariantAvailabilityOptions/VariantAvailabilityOptions';
import Button from '../../ui/Button/Button';
import VariantHeader from '../../VariantHeader/VariantHeader';
import { TypeCProductDesktopStyles } from './TypeCProductDesktop.style';
import constants from '../../../config/constants';
import { withLabels } from '../../../utils/LabelsContext';
import ProductColorSwatches from '../../ProductColorSwatches/ProductColorSwatches';
import Size from '../../Size/Size';
import noop from '../../../utils/noop';
import Prices from '../../Prices/Prices';
import Warning from '../../Warning/Warning';

const TypeCProductDesktop = ({
  brandName,
  variantName,
  prices,
  variantId,
  isHomeDeliveryAvailable,
  isPickFromStoreAvailable,
  productId,
  labels,
  colorVariants,
  selectedColorSwatchIndex,
  colorSwatchHandler,
  sizes,
  selectedSize,
  sizeChangeHandler,
  addToCartLabel,
  isAddToCartDisabled,
  variant,
  handleAddToCart,
  errorAddingToCart,
  currentVariant,
  variantUrl,
  isRebrandingEnabled,
  appCtx,
  colorMetrics
}) => {
  const isForCart = variant === 'cart';

  return (
    <div
      className={`typeCProduct ${variant} ${isRebrandingEnabled ? 'mkp' : ''}`}
    >
      <VariantHeader
        brandName={brandName}
        variantName={variantName}
        prices={prices}
        variantId={variantId}
        variant={variant}
        selectedColorSwatchIndex={selectedColorSwatchIndex}
        colorVariants={colorVariants}
        colorSwatchHandler={colorSwatchHandler}
        layout="typeC"
        variantUrl={variantUrl}
        medias={currentVariant.medias}
      />
      <div className="typeCProduct-variants fa--color-swatches-type-c__desktop">
        {isForCart && prices && prices.length > 0 && (
          <Prices
            priceList={prices}
            deviceType="desktop"
            page="CART"
            priceCount={prices.length}
            variant="CART"
          />
        )}
        {!isForCart && colorVariants[selectedColorSwatchIndex] && (
          <div className="color-swatch-container">
            <div className="colorName">
              <span className="colorLabel">{`${labels.COLOR}: `}</span>
              {colorVariants[selectedColorSwatchIndex].label}
            </div>
            <ProductColorSwatches
              colorVariants={colorVariants}
              selectedColorSwatchIndex={selectedColorSwatchIndex}
              colorSwatchHandler={colorSwatchHandler}
              type={constants.VIEW_TEMPLATE.TYPE_C}
              size="medium"
              productId={productId}
              appCtx={appCtx}
              colorMetrics={colorMetrics}
            />
          </div>
        )}
        {sizes.length > 0 && (
          <Size
            variant={variant}
            title={labels.SELECTION_SIZE}
            sizes={sizes}
            selectedSize={selectedSize}
            onSizeChange={sizeChangeHandler}
            type="dropdown"
          />
        )}
      </div>
      <div
        className={`addToCart-button-wrapper ${
          isRebrandingEnabled ? 'mkp' : ''
        }`}
      >
        <Button
          size="small"
          type={isRebrandingEnabled ? 'mkp-secondary' : 'secondary'}
          onClick={handleAddToCart}
          disabled={isAddToCartDisabled}
          strech={
            isRebrandingEnabled && !isAddToCartDisabled && isForCart
              ? `auto`
              : ''
          }
        >
          <span className="addToCart-button-text">{addToCartLabel}</span>
        </Button>
        {errorAddingToCart && (
          <Warning
            label={errorAddingToCart}
            iconSize="copy10"
            labelSize="copy3"
            forPDP
          />
        )}
        {!isForCart && (
          <VariantAvailabilityOptions
            deviceType="desktop"
            isHomeDeliveryAvailable={isHomeDeliveryAvailable}
            isPickFromStoreAvailable={isPickFromStoreAvailable}
            isRebrandingEnabled={isRebrandingEnabled}
          />
        )}
      </div>
      <style jsx>{TypeCProductDesktopStyles}</style>
    </div>
  );
};

TypeCProductDesktop.defaultProps = {
  brandName: '',
  variantName: '',
  prices: [],
  variantId: '',
  isHomeDeliveryAvailable: false,
  isPickFromStoreAvailable: false,
  productId: '',
  colorVariants: [],
  selectedColorSwatchIndex: 0,
  colorSwatchHandler: noop,
  sizes: [],
  selectedSize: '',
  sizeChangeHandler: noop,
  addToCartLabel: '',
  isAddToCartDisabled: false,
  variant: '',
  currentVariant: {},
  handleAddToCart: noop,
  variantUrl: ''
};

TypeCProductDesktop.propTypes = {
  labels: PropTypes.object.isRequired,
  brandName: PropTypes.string,
  variantName: PropTypes.string,
  prices: PropTypes.arrayOf(PropTypes.object),
  variantId: PropTypes.string,
  isHomeDeliveryAvailable: PropTypes.bool,
  isPickFromStoreAvailable: PropTypes.bool,
  productId: PropTypes.string,
  colorVariants: PropTypes.arrayOf(PropTypes.object),
  selectedColorSwatchIndex: PropTypes.number,
  colorSwatchHandler: PropTypes.func,
  sizes: PropTypes.arrayOf(PropTypes.object),
  selectedSize: PropTypes.string,
  sizeChangeHandler: PropTypes.func,
  addToCartLabel: PropTypes.string,
  isAddToCartDisabled: PropTypes.bool,
  variant: PropTypes.string,
  currentVariant: PropTypes.object,
  handleAddToCart: PropTypes.func,
  errorAddingToCart: PropTypes.bool.isRequired,
  variantUrl: PropTypes.string,
  isRebrandingEnabled: PropTypes.bool.isRequired,
  appCtx: PropTypes.object.isRequired,
  colorMetrics: PropTypes.object.isRequired
};

export default withLabels(TypeCProductDesktop);
