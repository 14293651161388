import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import { ThumbnailImageStyles } from './ThumbnailImage.style';
import { getBackgroundCSS } from '../../../utils/variant/getBackgroundCSS';

const ThumbnailColor = ({
  options,
  index,
  selectedImageIndex,
  handleColorSwatch,
  theme,
  colorMetrics,
  isCFMediasEnabled,
  sellerName,
  regionCode
}) => {
  const {
    extraInfo,
    mediaId,
    url,
    selected,
    label,
    mediaUrls,
    value
  } = options;
  const themedStyles = ThumbnailImageStyles(theme);

  const handleThumbnailClick = () => {
    colorMetrics.setColorAtIndex(index, label);
    handleColorSwatch(
      {
        extraInfo,
        mediaId,
        colorName: value,
        url,
        selected,
        label,
        mediaUrls: mediaUrls || []
      },
      index
    );
  };

  return (
    <li key={`thumbnail-${extraInfo}`}>
      <button
        type="button"
        id={`testId-pod-colorSwatch-${extraInfo}`}
        className={` ${
          index === selectedImageIndex
            ? 'thumbnail-border-selected'
            : 'thumbnail-border'
        }`}
        onClick={() => handleThumbnailClick()}
      >
        <div
          className="thumbnail-color"
          style={getBackgroundCSS({
            value: options.value,
            sellerName,
            regionCode,
            isCfMediasEnabled: isCFMediasEnabled
          })}
        />
      </button>

      <style jsx>{themedStyles}</style>
    </li>
  );
};

ThumbnailColor.defaultProps = {
  theme: {},
  colorMetrics: { setColorAtIndex: () => {} }
};

ThumbnailColor.propTypes = {
  options: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  selectedImageIndex: PropTypes.number.isRequired,
  handleColorSwatch: PropTypes.func.isRequired,
  theme: PropTypes.object,
  colorMetrics: PropTypes.object,
  sellerName: PropTypes.string.isRequired,
  regionCode: PropTypes.string.isRequired,
  isCFMediasEnabled: PropTypes.bool.isRequired
};

export { ThumbnailColor };
export default withTheme(ThumbnailColor);
