import { useState } from 'react';
import { allowOnlyNumbers } from '../../utils/jsUtils';

const useInput = (maxCartQuantity) => {
  const [showQuantityError, setQuantityError] = useState(false);
  const [inputVal, setInputVal] = useState(1);
  const handleInputChange = (event) => {
    const { target } = event;
    const { value } = target;
    if (Number.isNaN(parseInt(value, 10))) {
      if (!inputVal || value.trim() === '') {
        setInputVal(undefined);
        target.value = '';
      } else {
        target.value = value.substring(0, value.length - 1);
      }
      return;
    }
    const val = parseInt(allowOnlyNumbers(value), 10);

    if (val < maxCartQuantity && val > 0) {
      setInputVal(val);
      setQuantityError(false);
    } else if (val === maxCartQuantity) {
      setInputVal(val);
      setQuantityError(true);
    } else if (val > maxCartQuantity) {
      setQuantityError(true);
    } else if (val === 0) {
      setInputVal('1');
    }
  };

  return {
    showQuantityError,
    inputVal,
    handleInputChange,
    setQuantityError,
    setInputVal
  };
};

export default useInput;
