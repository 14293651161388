import React from 'react';
import PropTypes from 'prop-types';

export const LocationIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 10.8334V10.8334C8.61917 10.8334 7.5 9.71421 7.5 8.33337V8.33337C7.5 6.95254 8.61917 5.83337 10 5.83337V5.83337C11.3808 5.83337 12.5 6.95254 12.5 8.33337V8.33337C12.5 9.71421 11.3808 10.8334 10 10.8334Z"
        stroke="#323232"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99996 17.5C9.99996 17.5 4.16663 12.7083 4.16663 8.33333C4.16663 5.11167 6.77829 2.5 9.99996 2.5C13.2216 2.5 15.8333 5.11167 15.8333 8.33333C15.8333 12.7083 9.99996 17.5 9.99996 17.5Z"
        stroke="#323232"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

LocationIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

LocationIcon.defaultProps = {
  width: 20,
  height: 20
};
