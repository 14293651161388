import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BundlePodStyles from './BundlePod.style';
import BodyCopy from '../../ui/BodyCopy/BodyCopy';
import Prices from '../../Prices/Prices';
import Checkbox from '../../ui/Checkbox/Checkbox';
import Image from '../../ui/Image/Image';
import noop from '../../../utils/noop';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { getTenant } from '../../../utils/tenantHelper';

const SIZES = {
  default: {
    desktop: {
      brandName: 'title15'
    },
    mobile: {
      brandName: 'title15'
    }
  },
  recommendation: {
    desktop: {
      brandName: 'title15'
    },
    mobile: {
      brandName: 'title15'
    }
  },
  cart: {
    desktop: {
      brandName: 'title16',
      description: 'copy5'
    },
    mobile: {
      brandName: 'copy4',
      description: 'copy1'
    }
  }
};

const BundlePod = ({
  product,
  onBundleChange,
  disableClick,
  variant,
  appCtx,
  isCurrentProduct,
  isSingleProduct,
  heading,
  totalProductInReco,
  currentProductID,
  position,
  productPosition
}) => {
  const { deviceType, isRebrandingEnabled, regionCode } = appCtx;
  const handleChange = (bundleProduct) => (checkboxValue) => {
    onBundleChange({ ...bundleProduct, isSelected: checkboxValue.isSelected });
  };
  const isForRecos = variant === 'recommendation';

  const [productSelected, setProductSelected] = useState(product.isSelected);

  useEffect(() => {
    setProductSelected(product.isSelected);
  }, [product]);

  const isForCart = variant === 'cart';

  const size = SIZES[variant][deviceType] || SIZES[variant].mobile;

  const getPriceVariant = () => {
    if (isForCart) {
      return 'CART';
    }

    return deviceType === 'desktop' ? 'BUNDLES' : 'MOBILE_BUNDLES';
  };
  const redirectToProduct = () => {
    if (isForRecos) {
      const eventData = {
        carouselName: heading,
        currentProductID,
        targetProductID: product.offeringId,
        numberProducts: totalProductInReco,
        productPosition,
        carouselPosition: position,
        country: getTenant(regionCode),
        type: 'bundles',
        store: appCtx.store
      };
      const eventToDigitalData = new CustomEvent('DDPDPCarouselProductClick', {
        bubbles: true,
        detail: eventData
      });
      window.dispatchEvent(eventToDigitalData);
    }
    const href = product.url ? product.url : '#';
    window.location.href = href;
  };
  return (
    <div
      className={`bundle-pod ${
        isSingleProduct ? 'bundle-pod-single' : ''
      } ${variant}`}
    >
      <div className={`bundle ${isCurrentProduct ? 'selected-product' : ''}`}>
        <div className="bundle-pod-head">
          <div className="image-wrapper">
            <Image
              id={product.id}
              width={100}
              height={100}
              alt={product.displayName}
              useSrcSet={false}
              inlineDimensions={false}
              lazyLoad
            />
          </div>
          {(!isForRecos || ['mobile', 'tablet'].includes(deviceType)) &&
            !isSingleProduct && (
              <div
                className={`selection-box ${isCurrentProduct ? 'fade' : ''}`}
              >
                <Checkbox
                  onChange={handleChange(product)}
                  variant="tertiary"
                  isChecked={productSelected}
                  disableClick={disableClick}
                  manageState={false}
                  type={isRebrandingEnabled ? 'mkp-bundle-succes' : 'succes'}
                  normalBorderClass={
                    isRebrandingEnabled ? ' mkp-normal-border' : ''
                  }
                  selectedBorderClass={isRebrandingEnabled ? ' mkp-border' : ''}
                />
              </div>
            )}
        </div>
        <div className="bundle-pod-body">
          <div
            onKeyDown={noop}
            role="button"
            tabIndex={0}
            className="anchor-div"
            onClick={redirectToProduct}
          >
            {isForRecos ? (
              <p className="brand-name">
                {product.brand ? `${product.brand}`.toUpperCase() : ''}
              </p>
            ) : (
              <BodyCopy isHighlighted size={size.brandName} type="secondary">
                {`${product.brand}`.toUpperCase()}
              </BodyCopy>
            )}
            {isForCart ? (
              <BodyCopy size={size.description}>
                <div className="description">{product.displayName}</div>
              </BodyCopy>
            ) : (
              <span className="product-name">{product.displayName}</span>
            )}
            <div className={`price ${isForCart ? 'cart' : ''}`}>
              <Prices
                priceList={product.prices}
                deviceType="desktop"
                page="PDP"
                variant={getPriceVariant()}
              />
            </div>
            {!product.url && !isForRecos && (
              <p className="current-viewing">(Estás viendo este producto)</p>
            )}
          </div>
          {isForRecos && (
            <div className="reco-checkbox">
              {deviceType === 'desktop' && (
                <p className="rebranding-current">
                  {isCurrentProduct
                    ? 'Producto principal'
                    : 'Producto adicional'}
                </p>
              )}
              {deviceType === 'desktop' &&
                isForRecos &&
                !isCurrentProduct &&
                !isSingleProduct && (
                  <div className="checkbox">
                    <Checkbox
                      onChange={handleChange(product)}
                      variant="tertiary"
                      isChecked={productSelected}
                      disableClick={disableClick}
                      manageState={false}
                      type="mkp-bundle-succes"
                      normalBorderClass=" mkp-bundle-border"
                      selectedBorderClass=" mkp-border"
                    />
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
      <style jsx>{BundlePodStyles}</style>
    </div>
  );
};

BundlePod.defaultProps = {
  onBundleChange: noop,
  disableClick: false,
  variant: 'default',
  isCurrentProduct: false,
  isSingleProduct: false,
  heading: '',
  currentProductID: '',
  totalProductInReco: 0,
  position: 0,
  productPosition: 0
};

BundlePod.propTypes = {
  product: PropTypes.object.isRequired,
  onBundleChange: PropTypes.func,
  disableClick: PropTypes.bool,
  variant: PropTypes.string,
  appCtx: PropTypes.object.isRequired,
  isCurrentProduct: PropTypes.bool,
  isSingleProduct: PropTypes.bool,
  heading: PropTypes.string,
  currentProductID: PropTypes.string,
  totalProductInReco: PropTypes.number,
  position: PropTypes.number,
  productPosition: PropTypes.number
};

export { BundlePod };
export default withApplicationContext(BundlePod);
