const createScriptTag = ({
  src,
  async = false,
  defer = false,
  onLoad,
  integrity,
  crossOrigin,
  id
}) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = src;
  script.defer = defer;
  script.async = async;
  script.onload = onLoad;
  script.id = id;
  if (integrity) {
    script.integrity = integrity;
  }
  if (crossOrigin) {
    script.crossOrigin = crossOrigin;
  }
  return script;
};

const createNoScriptIframeTag = ({
  src,
  height = 0,
  width = 0,
  style = 'display:none;visibility:hidden'
}) => {
  const noScript = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.src = src;
  iframe.height = height;
  iframe.width = width;
  iframe.style = style;
  noScript.appendChild(iframe);
  return noScript;
};

const appendScriptsToHead = (script) => {
  const { head } = document;
  const documentFragment = document.createDocumentFragment();
  documentFragment.appendChild(script);
  head.appendChild(documentFragment);
};

const appendToBodyTop = (script) => {
  const { body } = document;
  body.insertAdjacentHTML('afterbegin', script.outerHTML);
};

const appendBeforeBodyEnd = (script) => {
  const { body } = document;
  body.insertAdjacentHTML('beforeend', script.outerHTML);
};

const appendScriptToTop = (script) => {
  const first = document.getElementsByTagName('script')[0];
  first.parentNode.insertBefore(script, first);
};

export {
  createScriptTag,
  appendScriptsToHead,
  appendScriptToTop,
  createNoScriptIframeTag,
  appendToBodyTop,
  appendBeforeBodyEnd
};
