import React, { useState, Fragment, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { ImageGalleryMobileVideoStyles } from './ImageGalleryVideo.style';
import noop from '../../../../utils/noop';
import { withLabels } from '../../../../utils/LabelsContext';
import constants from '../../../../config/constants';

const ImageGalleryMobileVideo = ({ url, labels, setVideoPlaying }) => {
  const getThumbnailUrl = () => {
    const matches = url.match(/.*\/embed\/(.*)/i) || [];
    return constants.YOUTUBE_THUMBNAIL_URL.replace(`{id}`, matches[1]);
  };

  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    setVideoPlaying(false);
  }, []);

  return (
    <div
      role="button"
      className="video-headline"
      onClick={() => {
        setShowVideo(true);
        setVideoPlaying(true);
      }}
      allow="autoplay"
      onKeyDown={noop}
      tabIndex="0"
    >
      <Fragment>
        <iframe
          width="100vw"
          className="video-frame"
          title="Video"
          src={url}
          frameBorder="0"
          allowFullScreen
        />
        <div className={classnames('video-preview', { showVideo })}>
          <span className="video-icon">
            <span className="sr-only">{labels.A11Y_VIDEO_LABEL}</span>
          </span>

          <img
            className="video-thumbnail"
            src={getThumbnailUrl()}
            alt="video"
          />
        </div>
      </Fragment>
      <style jsx>{ImageGalleryMobileVideoStyles}</style>
    </div>
  );
};

ImageGalleryMobileVideo.defaultProps = {
  url: '',
  setVideoPlaying: noop
};

ImageGalleryMobileVideo.propTypes = {
  url: PropTypes.string,
  labels: PropTypes.object.isRequired,
  setVideoPlaying: PropTypes.func
};

export default withLabels(ImageGalleryMobileVideo);
export { ImageGalleryMobileVideo };
