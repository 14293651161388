import css from 'styled-jsx/css';
import { colors } from '../../../config/styles';

const DeliveryOptionsSelectedTextStyle = css`
  .delivery-option-selection-container {
    width: 100%;
    .delivery-option-selection {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: ${colors.secondary_color};
      text-decoration: underline;
      text-underline-offset: 2px;
      text-decoration-color: ${colors.secondary_color};
      span {
        &.green {
          color: ${colors.green.shade25};
          text-decoration: underline;
          text-decoration-color: ${colors.green.shade25};
        }
        &.bold {
          font-weight: 700;
        }
        &.capitalize {
          text-transform: capitalize;
        }
      }
      &.not-found {
        color: ${colors.gray.shade52};
      }
    }
  }
`;

export { DeliveryOptionsSelectedTextStyle };
