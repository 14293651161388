import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ProductTopSpecificationsStyle } from './ProductTopSpecifications.style';
import { withLabels } from '../../utils/LabelsContext';
import _ from '../../utils/LodashImports';
import { getVariant } from '../../utils/variant';
import { withProduct } from '../../utils/ProductContext';
import { withDigitalData } from '../../utils/DigitalDataContext';
import ScrollTo from '../ui/ScrollTo/ScrollTo';
import Drawer from '../ui/Drawer/Drawer';
import SpecificationTable from '../SpecificationTable/SpecificationTable';
import ComboSpecifications from '../ComboSection/ComboSpecifications';
import { withApplicationContext } from '../../utils/ApplicationContext';

const ProductTopSpecifications = ({
  product,
  labels,
  scollToElementId,
  isRebrandingEnabled,
  digitalData,
  appCtx
}) => {
  const [isDrawerActive, activateDrawer] = React.useState(false);
  const topSpecifications = _.get(product, 'attributes.topSpecifications', []);
  const specifications = _.get(product, 'attributes.specifications', []);
  const { deviceType, store } = appCtx;
  const topSpecificationsTitle =
    store === 'so_com'
      ? _.get(
          labels,
          'SO_COM_TOP_SPECIFICATIONS',
          'Características principales'
        )
      : labels.TOP_SPECIFICATIONS;
  const viewMoreText =
    store === 'so_com'
      ? _.get(labels, 'SO_COM_VIEW_MORE_SPECIFICATONS', 'Ver más')
      : labels.VIEW_MORE_SPECIFICATONS;
  const specificationsTitle =
    store === 'so_com'
      ? _.get(labels, 'SO_COMSPECIFICATIONS_LABEL', 'Ver más')
      : labels.SPECIFICATIONS_LABEL;
  const { variants, currentVariant } = product;
  const comboData = _.get(
    getVariant(variants, currentVariant),
    'comboData',
    null
  );
  digitalData.pdp.setProductFeatures(topSpecifications.length > 0 ? '2' : '');
  if (topSpecifications.length <= 0) {
    return <Fragment />;
  }
  return (
    <Fragment>
      <div className="specifications-container fa--product-characteristic-features">
        <div className="specifications-title">{topSpecificationsTitle}</div>
        <div className="specifications-list">
          <ul>
            {topSpecifications.map((spec) => (
              <li key={spec.name}>
                <strong>{spec.name}</strong>: {spec.value}
              </li>
            ))}
          </ul>
        </div>
        {deviceType === 'desktop' ? (
          <ScrollTo
            scrollToElementId={scollToElementId}
            isRebrandingEnabled={isRebrandingEnabled}
            isSoCom={store === 'so_com'}
          />
        ) : (
          <React.Fragment>
            <button
              type="button"
              onClick={() => activateDrawer(true)}
              className="view-more"
            >
              {viewMoreText}
            </button>
            <Drawer
              toggleState={[isDrawerActive, activateDrawer]}
              heading={specificationsTitle}
              isLoading={false}
              type="secondary"
            >
              {comboData?.hasSpecifications ? (
                <ComboSpecifications
                  comboItems={comboData.comboItems}
                  isDrawer
                  labels={labels}
                />
              ) : (
                <SpecificationTable
                  specifications={specifications}
                  type="secondary"
                />
              )}
            </Drawer>
          </React.Fragment>
        )}
      </div>
      {deviceType === 'desktop' && <div className="specifications-divider" />}
      <style jsx>{ProductTopSpecificationsStyle}</style>
    </Fragment>
  );
};

ProductTopSpecifications.defaultProps = {
  scollToElementId: null,
  isRebrandingEnabled: false
};
ProductTopSpecifications.propTypes = {
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  scollToElementId: PropTypes.string,
  isRebrandingEnabled: PropTypes.bool,
  digitalData: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired
};

export { ProductTopSpecifications };
export default withLabels(
  withProduct(withDigitalData(withApplicationContext(ProductTopSpecifications)))
);
