import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { CartItemStyles } from './CartItem.style';
import SingleChipletSelect from '../../ui/SingleChipletSelect/SingleChipletSelect';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import noop from '../../../utils/noop';
import { withLabels } from '../../../utils/LabelsContext';
import constants from '../../../config/constants';
import { getDefaultSelectedIndex } from '../Cart.Utils';
import { withProduct } from '../../../utils/ProductContext';
import { getErrorMessage } from '../../../utils/errorHandler';
import _ from '../../../utils/LodashImports';
import WarrantySelect from '../../ui/WarrantySelect/WarrantySelect';
import { ProductCounter } from '../../ui/ProductCounter/ProductCounter';
import Stepper from '../../ui/Stepper/Stepper';
import { allowOnlyNumbers } from '../../../utils/jsUtils';
import { withCart } from '../../../utils/CartContext';
import { RouterContext } from '../../../containers/Layout/LayoutContext';
import { getVariant } from '../../../utils/variant';
import Image from '../../ui/Image/Image';
import {
  createOption,
  getLink,
  getSecondaryLabel,
  getViewConfig,
  handleErrors,
  handleEvents,
  inPDP
} from './CartItem.util';
import CartItemActionLabel from './CartItemComponents/CartItemActionLabel';
import CartItemAdditionalInformation from './CartItemComponents/CartItemAdditionalInformation';

const CartItem = ({
  item,
  textDictionary,
  onAddToCart,
  onRemoveFromCart,
  setWarrantyOption,
  setAssemblyOption,
  showWarrantyOption,
  setBusy,
  labels,
  showButton,
  appCtx,
  isAtgPrice,
  type,
  endPoints,
  showAssemblyOption,
  product,
  setCrossSelling,
  variantDetails,
  disableQuantitySelection,
  setWarrantyOptionCurrent,
  limitAlerts,
  cart
}) => {
  const {
    brandName,
    name,
    skuId,
    image,
    productId,
    offeringId,
    price,
    totalQuantity,
    commerceItemId,
    warrantyOptions,
    assemblyOptions,
    addedQuantity,
    cartLineId = '',
    childProducts = []
  } = item;
  const [showMaxQuantityAlert, setShowMaxQuantityAlert] = useState(false);
  const [errorAddingToCart, setErrorAddingToCart] = useState('');
  const [errorWarranty, setErrorWarranty] = useState(false);
  const [errorServices, setErrorServices] = useState(false);
  const [limitFromAPI, setLimitFromAPI] = useState(false);
  const [inputVal, setInputVal] = useState(totalQuantity);
  const routerContext = useContext(RouterContext.Context);
  const { deviceType, regionCode, isRebrandingEnabled } = appCtx;
  const isQuantityEditableEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isQuantityEditableEnabled',
    false
  );

  const isNewWarrantyUIEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isNewWarrantyUIEnabled',
    false
  );

  const isAddtionalInfoForSodimacEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isAddtionalInfoForSodimacEnabledInUI',
    false
  );
  const isSodimacProduct = constants.SODIMAC_REGEX.test(
    _.get(product, 'variants[0].offerings[0].sellerName', '').toLowerCase()
  );
  const timeputForLoginModalOn401 = _.get(
    appCtx,
    'siteConfig.textDictionary.TIMEOUT_FOR_LOGIN_MODAL',
    1000
  );

  const inPdp = inPDP(routerContext, appCtx);
  const inPageText = inPdp ? 'inPDP' : 'inXLP';
  const currentVariant = getVariant(product.variants, product.currentVariant);
  const { maxOnCartAmount } = currentVariant;
  const isDesktop = deviceType === 'desktop';

  const shouldRenderAdditionalInfo = () => {
    if (isSodimacProduct) {
      if (isAddtionalInfoForSodimacEnabled) {
        return true;
      }
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (limitAlerts && (limitAlerts[cartLineId] || limitAlerts.basket)) {
      setLimitFromAPI(true);
      if (limitAlerts[cartLineId]) {
        setErrorAddingToCart(limitAlerts[cartLineId].message);
      } else {
        setErrorAddingToCart(limitAlerts.basket.message);
      }
    } else {
      setLimitFromAPI(false);
    }
  }, [limitAlerts]);
  useEffect(() => {
    setInputVal(totalQuantity);
    const eventToDigitalData = new CustomEvent(
      'DDPDPUpdateSelectedProductCount',
      {
        bubbles: true,
        detail: totalQuantity
      }
    );
    window.dispatchEvent(eventToDigitalData);
  }, [totalQuantity]);

  const warrantyTooltip =
    product.warrantyOptions && product.warrantyOptions.warrantyTooltip
      ? product.warrantyOptions.warrantyTooltip
      : undefined;
  const isWarrantyTooltipEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isWarrantyTooltipEnabled',
    false
  );
  const cheapestWarranty =
    product.warrantyOptions &&
    product.warrantyOptions.fieldOptions &&
    Array.isArray(product.warrantyOptions.fieldOptions) &&
    product.warrantyOptions.fieldOptions.length &&
    product.warrantyOptions.fieldOptions[0]
      ? product.warrantyOptions.fieldOptions[0].price
      : '';

  const handleWarrantyOptionsSelected = (option) => {
    const value = option ? option.variantId || option.value : '';
    const warrantyOfferingId = _.get(option, 'offeringId', '');
    setBusy(true);
    setErrorWarranty(false);
    setWarrantyOptionCurrent({ ...option, selected: true });
    setWarrantyOption({
      appCtx,
      item: { commerceItemId, skuId: value },
      productContext: product,
      variantId: skuId,
      setWarrantyOptionCurrent,
      cartLineId: warrantyOptions.cartLineId || '',
      totalQuantity,
      offeringId: warrantyOfferingId
    }).then((res) => {
      setBusy(false);
      if (res) {
        setErrorWarranty(true);
      }
    });
  };

  const handleAssemblyOptionSelected = (options, index) => {
    const option = _.get(assemblyOptions, `fieldOptions[${index}]`, {});
    const value = option ? option.variantId || option.value : '';
    const assemblyOfferingId = _.get(option, 'offeringId', '');
    setBusy(true);
    setErrorServices(false);
    setAssemblyOption({
      appCtx,
      item: { commerceItemId, skuId: value, type: 2 },
      productContext: product,
      variantId: skuId,
      cartLineId: option.cartLineId || '',
      totalQuantity,
      offeringId: assemblyOfferingId
    }).then((res) => {
      setBusy(false);
      if (res) {
        setErrorServices(true);
      }
    });
  };

  const handleAddItem = (currQuantity = 1, isClickedByUser = false) => {
    const maximumLimit = maxOnCartAmount || constants.MAX_CART_PRODUCT;
    if (totalQuantity === maximumLimit) {
      setShowMaxQuantityAlert(true);
      return;
    }
    setBusy(true);

    const selectedWarranty =
      warrantyOptions && warrantyOptions.cartLineId
        ? childProducts.find(
            (cp) => cp.cartLineId === warrantyOptions.cartLineId
          )
        : {};

    const selectedService =
      assemblyOptions && assemblyOptions.cartLineId && totalQuantity > 0
        ? childProducts.find(
            (cp) => cp.cartLineId === assemblyOptions.cartLineId
          )
        : {};

    const warrantySkuId = _.get(selectedWarranty, 'variantId', '');
    const warrantyOfferingId = _.get(selectedWarranty, 'offeringId', '');
    const serviceSkuId = _.get(selectedService, 'variantId', '');
    const serviceOfferingId = _.get(selectedService, 'offeringId', '');
    const isMaxLimitExceeded = inputVal > maximumLimit;

    const option = createOption({
      actionType: 'add',
      type,
      currQuantity,
      maximumLimit,
      warrantySkuId,
      warrantyOfferingId,
      serviceSkuId,
      serviceOfferingId,
      offeringId,
      skuId,
      productId,
      appCtx,
      inputVal,
      totalQuantity,
      disableServicesAndWarranties: false
    });

    setErrorAddingToCart('');
    onAddToCart(option).then((res) => {
      handleErrors(
        res,
        getErrorMessage,
        cart,
        timeputForLoginModalOn401,
        setErrorAddingToCart,
        appCtx
      );
      handleEvents({
        actionType: 'add',
        isClickedByUser,
        res,
        inputVal,
        maxOnCartAmount
      });
      if (isMaxLimitExceeded) {
        setShowMaxQuantityAlert(true);
        setInputVal(maximumLimit);
      }
      setBusy(false);
    });
  };

  const handleRemoveItem = (
    currQuantity = 1,
    disableServicesAndWarranties = false,
    cb,
    isClickedByUser = false
  ) => {
    setShowMaxQuantityAlert(false);
    setBusy(true);

    const option = createOption({
      actionType: 'remove',
      type,
      currQuantity,
      appCtx,
      disableServicesAndWarranties,
      cartLineId,
      commerceItemId,
      warrantyOptions,
      assemblyOptions,
      totalQuantity
    });

    setErrorAddingToCart('');
    onRemoveFromCart(option).then((res) => {
      setBusy(false);
      handleEvents({
        actionType: 'remove',
        isClickedByUser,
        res,
        inputVal,
        maxOnCartAmount
      });
      handleErrors(
        res,
        getErrorMessage,
        cart,
        timeputForLoginModalOn401,
        setErrorAddingToCart,
        appCtx
      );
      if (!res && cb) {
        cb();
      }
    });
  };

  const viewConfig = getViewConfig(deviceType, isRebrandingEnabled);

  const showCartServicesSection = () => {
    return (
      (showWarrantyOption && warrantyOptions) ||
      (showAssemblyOption && assemblyOptions)
    );
  };

  if (showCartServicesSection()) {
    setCrossSelling(true);
  }

  const getMaxForStepper = () => {
    if (limitFromAPI) {
      return totalQuantity;
    }
    const maximumLimit = maxOnCartAmount || constants.MAX_CART_PRODUCT;
    return showMaxQuantityAlert ? maximumLimit : maximumLimit + 1;
  };

  const getMaxForProductCounter = () => {
    if (limitFromAPI) {
      return totalQuantity;
    }
    const maximumLimit = maxOnCartAmount || constants.MAX_CART_PRODUCT;

    return maximumLimit;
  };

  const isMobile = !isDesktop;
  const mobileStyle = isDesktop ? { width: '100%' } : {};
  const mobileClass = isDesktop ? '' : 'mobile';
  const supportedKeyValues = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'Backspace',
    'Delete'
  ];

  const handleInputChange = (event, keyPressed) => {
    const { target } = event;
    let { value } = target;

    if (Number.isNaN(parseInt(value, 10))) {
      if (!inputVal || value.trim() === '') {
        setInputVal(undefined);
        target.value = '';
      } else {
        target.value = value.substring(0, value.length - 1);
      }
      return;
    }

    value = allowOnlyNumbers(value);
    if (value.length >= 4) {
      if (value.startsWith('0')) {
        value = value.slice(1, 4);
      } else {
        value = value.slice(0, 3);
      }
    }

    if (
      (keyPressed && !supportedKeyValues.includes(keyPressed)) ||
      value > getMaxForProductCounter()
    ) {
      return;
    }

    value = parseInt(value, 10);

    if (value < 1000) {
      setInputVal(value);
      target.value = value;
    }

    if (value > 1000) {
      setInputVal(totalQuantity);
      target.value = totalQuantity;
    }
    if (Number.isNaN(value)) {
      setInputVal(undefined);
    }
  };
  const handleQuantityInput = (keyPressed) => {
    if (
      keyPressed === 'ArrowLeft' ||
      keyPressed === 'ArrowRight' ||
      keyPressed === 'Backspace'
    ) {
      return;
    }

    setShowMaxQuantityAlert(false);

    let value = parseInt(inputVal, 10);

    if (value.length >= 4) {
      value = value.slice(0, 3);
    }
    if (value >= getMaxForProductCounter()) {
      setShowMaxQuantityAlert(true);
      value = getMaxForProductCounter();
    }
    if (Number.isNaN(value)) {
      setInputVal(totalQuantity);
      value = totalQuantity;
    }

    if (value < totalQuantity) {
      handleRemoveItem(totalQuantity - value, value === 0, () => {
        if (value === 0) {
          if (warrantyOptions.cartLineId) {
            handleWarrantyOptionsSelected();
          }
        }
      });
    } else if (value > totalQuantity) {
      const currValue = value - totalQuantity;
      handleAddItem(currValue);
    } else if (value === totalQuantity) {
      setInputVal(totalQuantity);
    }
  };

  const stepperStyle =
    (price && price.length) === 3 ? { marginTop: '15px' } : {};

  useEffect(() => {
    if (
      isQuantityEditableEnabled &&
      totalQuantity > getMaxForProductCounter()
    ) {
      handleRemoveItem(totalQuantity - getMaxForProductCounter());
      setShowMaxQuantityAlert(true);
    }
  }, []);

  return (
    <div
      className={`cart-item-wrapper ${mobileClass} ${
        isRebrandingEnabled ? 'mkp' : ''
      }`}
      style={mobileStyle}
    >
      <div className="cart-item">
        <div
          className={`cart-item-image ${
            isDesktop ? 'cart-image-desktop' : 'cart-image-mobile'
          }`}
        >
          <Image
            id={currentVariant.id}
            imageUrl={image}
            width={isDesktop ? 52 : 120}
            height={isDesktop ? 52 : 120}
            alt={name}
            useSrcSet={false}
            inlineDimensions
            type="thumbnail"
            lazyLoad
          />
        </div>
        <CartItemAdditionalInformation
          viewConfig={viewConfig}
          brandName={brandName}
          name={name}
          variantDetails={variantDetails}
          isMobile={isMobile}
          productId={productId}
          currentVariant={currentVariant}
          isAtgPrice={isAtgPrice}
          price={price}
          disableQuantitySelection={disableQuantitySelection}
          stepperStyle={stepperStyle}
          totalQuantity={totalQuantity}
          handleRemoveItem={handleRemoveItem}
          handleAddItem={handleAddItem}
          getMaxForStepper={getMaxForStepper}
          isQuantityEditableEnabled={isQuantityEditableEnabled}
          inputVal={inputVal}
          handleQuantityInput={handleQuantityInput}
          handleInputChange={handleInputChange}
          getMaxForProductCounter={getMaxForProductCounter}
          showButton={showButton}
          labels={labels}
          showMaxQuantityAlert={showMaxQuantityAlert}
          maxOnCartAmount={maxOnCartAmount}
          errorAddingToCart={errorAddingToCart}
          addedQuantity={addedQuantity}
          appCtx={appCtx}
        />
      </div>
      {isMobile && (
        <div>
          <div className="cart-item cart-item-mobile">
            <div className="additional-information">
              {!disableQuantitySelection && (
                <div
                  className={isMobile ? 'stepper-mobile' : 'stepper'}
                  style={stepperStyle}
                >
                  {totalQuantity >= 0 &&
                    (!isRebrandingEnabled ? (
                      <Stepper
                        count={totalQuantity}
                        onDecrement={handleRemoveItem}
                        onIncrement={handleAddItem}
                        minimum={0}
                        maximum={getMaxForStepper()}
                      />
                    ) : (
                      <ProductCounter
                        count={
                          isQuantityEditableEnabled ? inputVal : totalQuantity
                        }
                        className={
                          isMobile
                            ? 'product-count-mobile'
                            : 'product-count-desktop'
                        }
                      >
                        <ProductCounter.Decrement
                          className={
                            isMobile ? 'decrement-mobile' : 'decrement-desktop'
                          }
                          minimum={0}
                          onClick={handleRemoveItem}
                          type="primary"
                        />
                        {isQuantityEditableEnabled ? (
                          <ProductCounter.CountInput
                            className={
                              isMobile
                                ? 'product-count-value-mobile'
                                : 'product-count-value-desktop'
                            }
                            handleInputBlurKey={handleQuantityInput}
                            handleOnChange={handleInputChange}
                            maximum={getMaxForProductCounter()}
                            minimum={0}
                          />
                        ) : (
                          <ProductCounter.CountText type="primary" />
                        )}
                        <ProductCounter.Increment
                          className={
                            isMobile ? 'increment-mobile' : 'increment-desktop'
                          }
                          maximum={getMaxForStepper()}
                          onClick={handleAddItem}
                          type="primary"
                        />
                      </ProductCounter>
                    ))}
                </div>
              )}
              {
                <CartItemActionLabel
                  showButton={showButton}
                  totalQuantity={totalQuantity}
                  handleAddItem={handleAddItem}
                  labels={labels}
                  showMaxQuantityAlert={showMaxQuantityAlert}
                  maxOnCartAmount={maxOnCartAmount}
                  errorAddingToCart={errorAddingToCart}
                  addedQuantity={addedQuantity}
                  isRebrandingEnabled={isRebrandingEnabled}
                  appCtx={appCtx}
                />
              }
            </div>
          </div>
        </div>
      )}
      {showCartServicesSection() && (
        <React.Fragment>
          <div className="services-divider" />
          <div
            className={`cart-services ${
              isNewWarrantyUIEnabled ? 'new-options' : ''
            }`}
          >
            {!isNewWarrantyUIEnabled && showWarrantyOption && warrantyOptions && (
              <div className="list-wrapper warranty-chiplet-select">
                <SingleChipletSelect
                  key={warrantyOptions.label}
                  icon="csicon-shield"
                  options={warrantyOptions.fieldOptions}
                  tooltipContent={warrantyTooltip}
                  cheapestOption={cheapestWarranty}
                  tooltipToggle={isWarrantyTooltipEnabled}
                  label={labels.EXTENDED_WARRANTY_LABEL}
                  startsFromLabel={labels.PDP_STARTS_FROM}
                  secondaryLabel={getSecondaryLabel(
                    warrantyOptions.fieldName,
                    textDictionary
                  )}
                  secondaryLink={getLink(
                    warrantyOptions.fieldName,
                    endPoints,
                    regionCode
                  )}
                  shouldHaveBackground={false}
                  defaultSelectedIndex={
                    totalQuantity > 0
                      ? getDefaultSelectedIndex(warrantyOptions.fieldOptions)
                      : -1
                  }
                  onOptionSelected={handleWarrantyOptionsSelected}
                  secondaryLabelIcon="csicon-arrow"
                  variant="CART"
                  showAsDropdown={
                    warrantyOptions.fieldOptions.length >=
                    constants.WARRANTY_OPTIONS_MIN_ITEM_COUNT_FOR_DROPDOWN
                  }
                  errorMessage={
                    errorWarranty ? labels.FAILED_ADDING_TO_CART_WARRANTY : null
                  }
                  clickableChiplet
                  disableAll={totalQuantity === 0}
                  isRebrandingEnabled={isRebrandingEnabled}
                  serviceType={`InCart-${inPageText}-WarrantyOption`}
                />
              </div>
            )}

            {isNewWarrantyUIEnabled && showWarrantyOption && warrantyOptions && (
              <div className="cart-warranties">
                <WarrantySelect
                  defaultSelectedIndex={
                    totalQuantity > 0
                      ? getDefaultSelectedIndex(warrantyOptions.fieldOptions)
                      : -1
                  }
                  deviceType={deviceType}
                  errorMessage={
                    errorWarranty ? labels.FAILED_ADDING_TO_CART_WARRANTY : null
                  }
                  handleWarrantyOptionsSelected={handleWarrantyOptionsSelected}
                  isRebrandingEnabled={isRebrandingEnabled}
                  options={warrantyOptions.fieldOptions}
                  inPdp={inPdp}
                />
              </div>
            )}
            {showAssemblyOption &&
              assemblyOptions &&
              shouldRenderAdditionalInfo() && (
                <div className="list-wrapper">
                  <SingleChipletSelect
                    key={assemblyOptions.label}
                    icon="csicon-icon-services"
                    options={assemblyOptions.fieldOptions}
                    label={assemblyOptions.label}
                    secondaryLabel={getSecondaryLabel(
                      assemblyOptions.fieldName,
                      labels
                    )}
                    secondaryLink={getLink(
                      assemblyOptions.fieldName,
                      endPoints,
                      regionCode
                    )}
                    defaultSelectedIndex={
                      totalQuantity > 0
                        ? getDefaultSelectedIndex(assemblyOptions.fieldOptions)
                        : -1
                    }
                    onOptionSelected={handleAssemblyOptionSelected}
                    secondaryLabelIcon="csicon-arrow"
                    variant="CART"
                    showAsDropdown={
                      assemblyOptions.fieldOptions.length >=
                      constants.WARRANTY_OPTIONS_MIN_ITEM_COUNT_FOR_DROPDOWN
                    }
                    errorMessage={
                      errorServices
                        ? labels.FAILED_ADDING_TO_CART_SERVICE
                        : null
                    }
                    clickableChiplet
                    isRebrandingEnabled={isRebrandingEnabled}
                    isModal
                    disableAll={totalQuantity === 0}
                    isNewWarrantyUIEnabled={isNewWarrantyUIEnabled}
                    serviceType={`InCart-${inPageText}-ServiceOption`}
                  />
                </div>
              )}
          </div>
        </React.Fragment>
      )}

      <style jsx>{CartItemStyles}</style>
    </div>
  );
};

CartItem.defaultProps = {
  onAddToCart: noop,
  onRemoveFromCart: noop,
  setBusy: noop,
  setWarrantyOption: noop,
  setAssemblyOption: noop,
  showWarrantyOption: true,
  showButton: true,
  type: '',
  isAtgPrice: true,
  endPoints: {},
  showAssemblyOption: true,
  setCrossSelling: noop,
  variantDetails: '',
  disableQuantitySelection: false,
  setWarrantyOptionCurrent: noop,
  limitAlerts: undefined,
  cart: {}
};

CartItem.propTypes = {
  item: PropTypes.object.isRequired,
  textDictionary: PropTypes.object.isRequired,
  onAddToCart: PropTypes.func,
  onRemoveFromCart: PropTypes.func,
  setBusy: PropTypes.func,
  appCtx: PropTypes.object.isRequired,
  setWarrantyOption: PropTypes.func,
  setAssemblyOption: PropTypes.func,
  showWarrantyOption: PropTypes.bool,
  showAssemblyOption: PropTypes.bool,
  showButton: PropTypes.bool,
  labels: PropTypes.object.isRequired,
  type: PropTypes.string,
  isAtgPrice: PropTypes.bool,
  endPoints: PropTypes.object,
  product: PropTypes.object.isRequired,
  setCrossSelling: PropTypes.func,
  variantDetails: PropTypes.string,
  disableQuantitySelection: PropTypes.bool,
  setWarrantyOptionCurrent: PropTypes.func,
  limitAlerts: PropTypes.object,
  cart: PropTypes.object
};

export default withProduct(
  withLabels(withApplicationContext(withCart(CartItem)))
);
export { CartItem };
