/* eslint-disable no-param-reassign */
import React from 'react';
import MatchMedia from '../../components/ui/MatchMedia/MatchMedia';
import PDPDesktop from './Desktop/SoftLineDesktop';
import PDPMobile from './Mobile/SoftLineMobile';
import { ProductLandingContainerStyles } from './SoftLineProductContainer.style';

const SoftLineProductContainer = () => (
  <div className="pdp-softline">
    <MatchMedia
      content={{
        desktop: () => <PDPDesktop />,
        mobile: () => <PDPMobile />,
        tablet: () => <PDPMobile />
      }}
    />
    <style jsx>{ProductLandingContainerStyles}</style>
  </div>
);

export default SoftLineProductContainer;
