import css from 'styled-jsx/css';
import { colors } from '../../../config/styles';

const SliderDotsStyles = css`
  .dot-container {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    .dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      transition: all 0.2s ease-in-out;
      display: inline-block;
      background-color: ${colors.gray.shade33};
      animation: 0.2s ease-in 0s 1 slideInFromLeft;
      @keyframes slideInFromLeft {
        0% {
          transform: translateX(-100%);
        }
        100% {
          transform: translateX(0);
        }
      }
      &.active {
        background-color: ${colors.gray.shade62};
      }
      &.full:last-child {
        width: 6px;
        height: 6px;
      }
      &.full:first-child {
        width: 6px;
        height: 6px;
      }
    }
  }
`;

export { SliderDotsStyles };
