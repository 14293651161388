import apiConfig from '../../config/api/apiConfig';
import httpService from '../httpService';
import _ from '../LodashImports';
import Logger from '../Logger';
import CookieStorage from '../CookieStorage';
import { mapProduct, segregateCollection } from './helpers';
import { getCommerceHeaders } from '../tenantHelper';

const removeCollection = ({ cart, setCart }) => async ({
  appCtx,
  products,
  isTypeC
}) => {
  const { regionCode, store } = appCtx;
  const isSoCom = store === 'so_com';
  const cartId = CookieStorage.getCookie('cartId');
  const { OFFER_PRICE_LABEL, NORMAL_PRICE_LABEL } = _.get(
    appCtx,
    'siteConfig.textDictionary',
    {}
  );
  const toggles = _.get(appCtx, 'siteConfig.toggles', {});

  const patchCart = products.map(({ cartLineId, totalQuantity }) => ({
    op: 'replace',
    path: `${cartLineId}/quantityNumber`,
    value: totalQuantity - 1
  }));
  const reqBody = {
    data: {
      cart: {
        patchCart
      }
    },
    metadata: {
      priceGroup: 'default'
    }
  };

  reqBody.metadata.politicalAreaId = appCtx.politicalId;
  reqBody.metadata.priceGroup = appCtx.priceGroupId;

  const additionalHeaders = getCommerceHeaders(regionCode, true);

  const patchCartLineUrl = apiConfig.patchCartlineCommerceUrl(
    cartId,
    regionCode
  );

  const { data: response, statusCode } = await httpService().patch(
    patchCartLineUrl,
    reqBody,
    {
      credentials: 'include'
    },
    additionalHeaders,
    'json'
  );

  const items = _.get(response, 'data.cart.cartLines', []);
  const totalProductsQuantityCount = _.get(
    response,
    'data.cart.totalProductsQuantityCount',
    0
  );
  const totalProductsCount = totalProductsQuantityCount;

  if (statusCode === 200 && items) {
    const mappedProducts = items.map((item) =>
      mapProduct(
        item,
        regionCode,
        { OFFER_PRICE_LABEL, NORMAL_PRICE_LABEL },
        toggles,
        isSoCom
      )
    );
    const mappedItems = segregateCollection({
      mappedProducts,
      collection: isTypeC ? cart.typeCCollections : cart.typeACollections,
      action: 'REMOVE',
      isTypeC,
      products: [],
      cartItems: cart.cartItems
    });

    setCart({
      ...cart,
      ...mappedItems,
      totalProductsCount,
      isAtgPrice: false
    });
  } else {
    const { errors } = response;
    Logger.error(`Failed to Remove Product ${JSON.stringify(products)}`);
    return errors || [];
  }
  return null;
};

export default removeCollection;
