import { useState, useEffect } from 'react';
import _ from 'lodash';
import WebStorage from '../WebStorage';
import CookieStorage from '../CookieStorage';

const SELECTED_HD = 'SELECTED_HD';
const SELECTED_CC = 'SELECTED_CC';
export const setToWebStorage = (key, regionObj) => {
  WebStorage.setItem({
    name: key,
    value: JSON.stringify(regionObj),
    shouldPersist: true
  });
};

export const getFromWebStorage = (key) => {
  try {
    return JSON.parse(
      WebStorage.getItem({
        name: key,
        fromPersistence: true
      })
    );
  } catch (err) {
    return null;
  }
};

export const getFromCookie = (key) => {
  const cookieVal = CookieStorage.getCookie(key);
  const parsedCookieVal = CookieStorage.parseCookieValue(key, cookieVal);
  return parsedCookieVal;
};

const getSelectedHDFromStorage = () => {
  const selectedHomeDeliveryOption =
    getFromCookie(SELECTED_HD) || getFromWebStorage(SELECTED_HD) || {};
  if (selectedHomeDeliveryOption && !_.isEmpty(selectedHomeDeliveryOption)) {
    return selectedHomeDeliveryOption;
  }
  return {};
};

const getSelectedCCFromStorage = async () => {
  const selectedPickupInStoreOption =
    getFromCookie(SELECTED_CC) || getFromWebStorage(SELECTED_CC) || {};
  if (selectedPickupInStoreOption && !_.isEmpty(selectedPickupInStoreOption)) {
    return selectedPickupInStoreOption;
  }
  return {};
};

const useSelectedDeliveryOption = (type, appCtx, currentVariant) => {
  const { regionCode } = appCtx;
  const selectedZone = _.get(appCtx, 'comuna.data', undefined);
  const isDefaultComuna = _.get(appCtx, 'isDefaultComuna', false);
  const defaultZoneConfig =
    (type === 'HomeDelivery' || type === 'PickupInStore') &&
    selectedZone &&
    !_.isEmpty(selectedZone) &&
    !isDefaultComuna
      ? selectedZone
      : {};
  const [zoneConfig, setZoneConfig] = useState(defaultZoneConfig);
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState({});
  const setSelectedOption = (option) => {
    if (type === 'HomeDelivery') {
      if (option && !_.isEmpty(option)) {
        setSelectedDeliveryOption({ ...option });
        CookieStorage.setCookie({
          name: SELECTED_HD,
          value: { ...option, currentVariant },
          regionCode
        });
        setToWebStorage(SELECTED_HD, { ...option, currentVariant });
      }
    } else if (type === 'PickupInStore') {
      if (option && !_.isEmpty(option)) {
        setSelectedDeliveryOption({ ...option });
        CookieStorage.setCookie({
          name: SELECTED_CC,
          value: { ...option, currentVariant },
          regionCode
        });
        setToWebStorage(SELECTED_CC, { ...option, currentVariant });
      }
    }
  };

  useEffect(() => {
    const isCC = type === 'PickupInStore';
    const isHD = type === 'HomeDelivery';
    if (isCC && zoneConfig && !_.isEmpty(zoneConfig)) {
      CookieStorage.setCookie({
        name: 'SELECTED_PID_CC',
        value: zoneConfig.politicalId,
        regionCode
      });
      setToWebStorage('SELECTED_ZONE_CC', zoneConfig);
    }
    let storageData = {};
    if (isHD) {
      storageData = getSelectedHDFromStorage();
    }
    if (isCC) {
      storageData = getSelectedCCFromStorage();
    }
    if (
      _.get(storageData, 'politicalId', undefined) !==
        _.get(zoneConfig, 'politicalId', undefined) ||
      _.get(storageData, 'currentVariant', undefined) !== currentVariant
    ) {
      setSelectedDeliveryOption({});
    }
  }, [zoneConfig]);

  useEffect(() => {
    if (selectedZone && !isDefaultComuna) {
      setZoneConfig({ ...selectedZone });
    }
  }, [selectedZone]);

  useEffect(() => {
    setSelectedDeliveryOption({});
  }, [currentVariant]);

  return {
    selectedDeliveryOption,
    setSelectedDeliveryOption: setSelectedOption,
    zoneConfig,
    setZoneConfig
  };
};

export { useSelectedDeliveryOption };
