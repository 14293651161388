/* stylelint-disable length-zero-no-unit */
import css from 'styled-jsx/css';
import { colors, zIndex } from '../../../../config/styles';

const CarouselControlButtonsStyles = css`
  .headline-control {
    position: absolute;
    top: 50%;
    height: 64px;
    width: 34px;
    background-color: rgba(255, 255, 255, 0.4);
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: ${zIndex.imageGallery.button};
    outline: none;
    &.left {
      left: 0;
      box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.2);
    }

    &.right {
      right: 0;
      box-shadow: -2px 0 5px -2px rgba(0, 0, 0, 0.2);
    }

    &.opaque {
      background-color: ${colors.black.shade6};
    }
  }

  .hide {
    display: none;
  }

  .icon {
    font-size: 15px;
    color: ${colors.black.shade1};
  }

  .products-carousel-headline-control {
    position: absolute;
    top: 50%;
    height: 4.086875rem;
    width: 2.17125rem;
    background-color: rgba(255, 255, 255, 0.4);
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: ${zIndex.imageGallery.button};
    outline: none;
    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    &.opaque {
      background-color: ${colors.black.shade6};
    }
  }

  .products-carousel-icon {
    color: ${colors.gray.shade18};
    font-size: 14px;
    margin: 0;
  }
  .secondary-buttons {
    &-left {
      height: 64px;
      width: 34px;
      box-shadow: 2px 0px 5px -2px rgb(0 0 0 / 20%);
    }
    &-right {
      height: 64px;
      width: 34px;
      box-shadow: -2px 0px 5px -2px rgb(0 0 0 / 20%);
      right: 0;
    }
  }
`;

export { CarouselControlButtonsStyles };
