const getProductFromMappedProduct = (product, mappedProducts) => {
  for (let i = 0; i < mappedProducts.length; i += 1) {
    if (product.skuId && product.skuId === mappedProducts[i].skuId) {
      return mappedProducts[i];
    }
    if (
      product.commerceItemId &&
      product.commerceItemId === mappedProducts[i].commerceItemId
    ) {
      return mappedProducts[i];
    }
  }
  return undefined;
};

const addProductToBundle = (product = {}, bundleItems, action) => {
  let isNewItem = true;

  const { skuId } = product;

  const newBundleItems = bundleItems.map((item) => {
    const hasSameSkuID = skuId && item.skuId === skuId;
    if (hasSameSkuID) {
      isNewItem = false;
      return {
        ...product,
        addedQuantity: action === 'ADD' ? (item.addedQuantity || 0) + 1 : 0
      };
    }
    return item;
  });

  const newProduct = {
    ...product,
    addedQuantity: action === 'ADD' ? (product.addedQuantity || 0) + 1 : 0
  };

  if (isNewItem) {
    return [...newBundleItems, newProduct];
  }
  return newBundleItems;
};

const updateProductsAfterDelete = (items = [], mappedProducts = []) => {
  const newItems = [];
  items.forEach((ci) => {
    const tempMappedProduct = mappedProducts.find(
      (mp) => mp.skuId === ci.skuId
    );
    if (tempMappedProduct) {
      newItems.push({
        ...tempMappedProduct,
        addedQuantity: ci.addedQuantity || 0
      });
    } else {
      newItems.push({
        ...ci,
        addedQuantity: 0,
        quantity: 0,
        totalQuantity: 0
      });
    }
  });
  return newItems;
};

const segregateProduct = ({
  mappedProducts,
  products,
  action,
  bundledProducts,
  bundleItems = [],
  cartItems = []
}) => {
  let newCartItems = [];
  let newBundleItems = [];
  const mappedItem = {};

  if (action === 'ADD') {
    newCartItems = cartItems;
    if (products.length !== 0) {
      products.forEach((product) => {
        const mappedProduct = getProductFromMappedProduct(
          product,
          mappedProducts
        );
        newCartItems = addProductToBundle(mappedProduct, newCartItems);
        // newCartItems.push(mappedProduct);
      });
      mappedItem.cartItems = newCartItems;
    }

    if (bundledProducts.length !== 0) {
      newBundleItems = bundleItems;

      bundledProducts.forEach((bundledProduct) => {
        const mappedBundleProduct = getProductFromMappedProduct(
          bundledProduct,
          mappedProducts
        );

        newBundleItems = addProductToBundle(
          mappedBundleProduct,
          newBundleItems
        );
      });

      mappedItem.bundleItems = newBundleItems;
    }
  }

  if (action === 'REMOVE') {
    if (products.length !== 0) {
      newCartItems = updateProductsAfterDelete(cartItems, mappedProducts);
      mappedItem.cartItems =
        !newCartItems.length && cartItems[0].totalQuantity === 1
          ? [{ ...cartItems[0], totalQuantity: 0 }]
          : newCartItems;
    }

    if (bundledProducts.length !== 0) {
      newBundleItems = updateProductsAfterDelete(bundleItems, mappedProducts);
      mappedItem.bundleItems = newBundleItems;
    }
  }

  return mappedItem;
};

const updateCollectionsAfterDelete = (items = [], mappedProducts = []) => {
  const newItems = [];
  items.forEach((ci) => {
    const tempMappedProduct = mappedProducts.find(
      (mp) => mp.skuId === ci.skuId
    );
    if (tempMappedProduct) {
      newItems.push({
        ...tempMappedProduct,
        addedQuantity: ci.addedQuantity || 0
      });
    }
  });
  return newItems;
};

const segregateCollection = ({
  mappedProducts,
  collection = [],
  action,
  isTypeC,
  products,
  cartItems = []
}) => {
  const mappedItems = {};

  if (action === 'ADD') {
    let newCartItems = cartItems;
    let newCollection = collection;
    mappedProducts.forEach((p) => {
      const productInMainSection = getProductFromMappedProduct(p, products);
      if (productInMainSection) {
        newCartItems = addProductToBundle(p, cartItems);
      } else {
        newCollection = addProductToBundle(p, collection, action);
      }
    });

    mappedItems.cartItems = newCartItems || [];
    mappedItems[isTypeC ? 'typeCCollections' : 'typeACollections'] =
      [
        ...newCartItems.map((item) => ({
          ...item,
          addedQuantity: item.addedQuantity + 1
        })),
        ...newCollection
      ].filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.skuId === item.skuId)
      ) || [];
  }

  if (action === 'REMOVE') {
    let newCartItems = [];
    let newCollection = [];

    if (cartItems.length) {
      newCartItems = updateCollectionsAfterDelete(cartItems, mappedProducts);
      newCartItems = !newCartItems.length ? cartItems : newCartItems;
    }

    if (collection.length) {
      newCollection = updateCollectionsAfterDelete(collection, mappedProducts);
    }

    mappedItems.cartItems = newCartItems || [];
    mappedItems[isTypeC ? 'typeCCollections' : 'typeACollections'] =
      [
        ...newCartItems.map((item) => ({
          ...item,
          addedQuantity: item.addedQuantity + 1
        })),
        ...newCollection
      ].filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.skuId === item.skuId)
      ) || [];
  }
  return mappedItems;
};

const queryMaker = (cartId, isBuyNowApplicable) => {
  const queryArray = [
    {
      key: 'cartId',
      isValid: !!cartId && !isBuyNowApplicable,
      value: cartId
    },
    {
      key: 'flowType',
      isValid: !!isBuyNowApplicable,
      value: 'BUY-NOW'
    }
  ].filter((e) => e.isValid);
  const query = queryArray.map((q) => {
    return `${q.key}=${q.value}`;
  });
  if (query.length) {
    return `?${query.join('&')}`;
  }
  return '';
};

export { segregateProduct, segregateCollection, queryMaker };
