import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { VariantAvailabilityOptionsStyles } from './VariantAvailabilityOptions.style';
import { withLabels } from '../../utils/LabelsContext';
import constants from '../../config/constants';

const VariantAvailabilityOptions = ({
  deviceType,
  labels,
  isHomeDeliveryAvailable,
  isPickFromStoreAvailable,
  isRebrandingEnabled
}) => {
  const deliveryAvailability = isHomeDeliveryAvailable
    ? {
        availability: 'deliveryAvailable',
        label: isRebrandingEnabled
          ? labels.TYPE_C_HOME_DELI_TITLE_MKP
          : labels.TYPE_C_HOME_DELI_TITLE
      }
    : {
        availability: 'deliveryNotAvailable',
        label: isRebrandingEnabled
          ? labels.TYPE_C_HOME_DELI_OUTOFSTOCK_MKP
          : labels.TYPE_C_HOME_DELI_OUTOFSTOCK
      };

  const pickupInStoreAvailability = isPickFromStoreAvailable
    ? {
        availability: 'pickupInStoreAvailable',
        label: isRebrandingEnabled
          ? labels.TYPE_C_PICKUP_INSTORE_TITLE_MKP
          : labels.TYPE_C_PICKUP_INSTORE_TITLE
      }
    : {
        availability: 'pickupInStoreNotAvailable',
        label: isRebrandingEnabled
          ? labels.TYPE_C_PICKUP_INSTORE_OUTOFSTOCK_MKP
          : labels.TYPE_C_PICKUP_INSTORE_OUTOFSTOCK
      };

  const alignItem =
    isHomeDeliveryAvailable && isPickFromStoreAvailable ? 'baseline' : '';
  const noDeliverybutAvailable =
    !isHomeDeliveryAvailable && isPickFromStoreAvailable
      ? 'noDeliverybutAvailable'
      : '';
  const iconClass = isRebrandingEnabled ? 'icon-mkp' : 'icon';
  return (
    <ul
      className={`availability-options ${
        isRebrandingEnabled ? ' mkp' : ''
      } ${alignItem} ${noDeliverybutAvailable}`}
    >
      <li
        className={classNames('availability-options-item', {
          disabled: !isHomeDeliveryAvailable
        })}
      >
        <span className={`${iconClass} ${deliveryAvailability.availability}`} />
        {deviceType === 'desktop' && deliveryAvailability.label}
      </li>
      <li
        className={classNames('availability-options-item', {
          disabled: !isPickFromStoreAvailable
        })}
      >
        <span
          className={`${iconClass} ${pickupInStoreAvailability.availability}`}
        />
        {deviceType === 'desktop' && pickupInStoreAvailability.label}
      </li>
      <style jsx>{VariantAvailabilityOptionsStyles}</style>
    </ul>
  );
};

VariantAvailabilityOptions.defaultProps = {
  deviceType: constants.DEVICE_DESKTOP,
  isRebrandingEnabled: false
};

VariantAvailabilityOptions.propTypes = {
  labels: PropTypes.object.isRequired,
  deviceType: PropTypes.string,
  isHomeDeliveryAvailable: PropTypes.bool.isRequired,
  isPickFromStoreAvailable: PropTypes.bool.isRequired,
  isRebrandingEnabled: PropTypes.bool
};

export { VariantAvailabilityOptions };

export default withLabels(VariantAvailabilityOptions);
