import React from 'react';
import PropTypes from 'prop-types';
import { arrowStyles } from './SingleChipletSelect.style';
import noop from '../../../utils/noop';

export const ExpansionIcon = ({
  setcollapseState,
  isRebrandingEnabled,
  collapseState,
  collapseClass,
  collapseIds
}) => {
  return (
    <div
      className={`toggle-icon${isRebrandingEnabled ? ' mkp' : ''}`}
      onClick={() => setcollapseState(!collapseState)}
      role="button"
      tabIndex={0}
      onKeyDown={noop}
    >
      <i
        className={collapseState ? collapseClass.down : collapseClass.up}
        id={collapseState ? collapseIds.open : collapseIds.close}
      />

      <style jsx>{arrowStyles}</style>
    </div>
  );
};

ExpansionIcon.propTypes = {
  setcollapseState: PropTypes.func.isRequired,
  isRebrandingEnabled: PropTypes.bool.isRequired,
  collapseState: PropTypes.bool.isRequired,
  collapseClass: PropTypes.object.isRequired,
  collapseIds: PropTypes.object.isRequired
};
