import React, { useState, useContext } from 'react';
import createContainer from 'constate';
import { nameHOC } from './hocutils';

function useRatingsContext({ data: initialRatingsData }) {
  const [ratings, setRatings] = useState(initialRatingsData);

  return {
    ratings,
    setRatings
  };
}

const RatingsContext = createContainer(useRatingsContext);

const withRatings = (WrappedComponent) => {
  const { getInitialProps } = WrappedComponent;
  const ComponentWithRatingsData = (props) => {
    const ratingDataWithState = useContext(RatingsContext.Context);
    return <WrappedComponent {...props} ratings={ratingDataWithState} />;
  };

  if (typeof getInitialProps === 'function') {
    ComponentWithRatingsData.getInitialProps = getInitialProps;
  }

  ComponentWithRatingsData.originalName = nameHOC(WrappedComponent);
  ComponentWithRatingsData.displayName = nameHOC(
    WrappedComponent,
    'WithRatings'
  );
  return ComponentWithRatingsData;
};

export default RatingsContext;
export { withRatings };
