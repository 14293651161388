import sizesOrder from './constants';

const convertSize = (size) => {
  // return false if size is not number
  if (+size) {
    return Number(size);
  }
  if (size.includes('MESES')) {
    return Number(size.trim().replace(/MESES/i, ''));
  }
  return sizesOrder[size.toUpperCase()];
};

const sortSizes = (sizes) => {
  return sizes.sort((size1, size2) => {
    const s1 = size1.sizeOrder;
    const s2 = size2.sizeOrder;
    return s1 > s2 ? 1 : -1;
  });
};

const getUniqueSizes = (variants = [], selectedColor) => {
  const mapper = {};
  let isAnyVariantAvailable = false;
  const sizes = variants
    .map((variant) => {
      const { size, colorName, sizeOrder } = variant.attributes;
      const variantSize = size && size.toUpperCase();
      if (!size || mapper[variantSize]) {
        return undefined;
      }
      if (typeof colorName === 'undefined' || colorName === selectedColor) {
        const available = variant.isPurchaseable;
        if (available) {
          mapper[variantSize] = true;
          isAnyVariantAvailable = true;
        }
        return {
          value: variantSize,
          available,
          sizeOrder
        };
      }
      return undefined;
    })
    .filter(Boolean);

  if (!isAnyVariantAvailable) {
    return [];
  }

  return sortSizes(sizes);
};

const getUniqueSizesForSLP = (variants = []) => {
  const mapper = {};
  let isAnyVariantAvailable = false;
  const sizes = variants
    .map((variant) => {
      const { value } = variant;
      const variantSize = value && value.toUpperCase();
      if (!value || mapper[variantSize]) {
        return undefined;
      }
      mapper[variantSize] = true;
      isAnyVariantAvailable = true;
      return {
        value: variantSize,
        ...variant
      };
    })
    .filter(Boolean);

  if (!isAnyVariantAvailable) {
    return [];
  }

  return sortSizes(sizes);
};

const getUniqueAvailableSizes = (variants = [], selectedColor) => {
  const mapper = {};
  const sizes = variants
    .map((variant) => {
      const { size, colorName, sizeOrder } = variant.attributes;
      const variantSize = size && size.toUpperCase();
      if (!size || mapper[variantSize]) {
        return undefined;
      }
      if (typeof colorName === 'undefined' || colorName === selectedColor) {
        if (variant.isPurchaseable) {
          mapper[variantSize] = true;
          return {
            value: variantSize,
            available: true,
            sizeOrder
          };
        }
      }
      return undefined;
    })
    .filter(Boolean);

  return sortSizes(sizes);
};

const isSizesValid = (variants, colorName) => {
  if (variants.find((v) => !v.attributes.size)) {
    return true;
  }
  const sizes = getUniqueSizes(variants, colorName);
  return sizes.length !== 0;
};

export {
  getUniqueSizes,
  isSizesValid,
  getUniqueAvailableSizes,
  convertSize,
  getUniqueSizesForSLP
};
