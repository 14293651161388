import _ from 'lodash';

const getHref = (product, details, isSponsored, config, appCtx) => {
  const { store, regionCode, deviceType } = appCtx;
  const { MABAYA_EVENTS_ENABLED } = config;
  const areSponsoredClickEventsEnabledWithQueryParams = _.get(
    appCtx,
    'siteConfig.toggles.areSponsoredClickEventsEnabledWithQueryParams',
    false
  );

  if (isSponsored && areSponsoredClickEventsEnabledWithQueryParams) {
    const sponsoredClickData = {
      adInfo: product.adInfo,
      mPos: details.productPosition,
      enabled: MABAYA_EVENTS_ENABLED,
      sku: product.id,
      platform: deviceType,
      store,
      regionCode
    };

    const JsonSponsoredClickData = JSON.stringify(sponsoredClickData);
    const encodedJsonSponsoredClickData = encodeURIComponent(
      JsonSponsoredClickData
    );

    const url = new URL(product.url, window.location.origin);
    url.searchParams.set('sponsoredClickData', encodedJsonSponsoredClickData);

    return url.toString();
  }

  const href = product.url ? product.url : '#';

  return href;
};

export { getHref };
