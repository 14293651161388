import React, { useRef, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import noop from '../../../utils/noop';
import { CarouselStyles } from './Carousel.style';
import CarouselControlButtons from './CarouselControlButtons/CarouselControlButtons';
import { withApplicationContext } from '../../../utils/ApplicationContext';

// function useStateRef(initialValue) {
//   const [value, setValue] = useState(initialValue);
//   const ref = useRef(value);
//   useEffect(() => {
//     ref.current = value;
//   }, [value]);
//   return [value, setValue, ref];
// }

const Carousel = ({
  appCtx,
  items,
  itemsPerSlide,
  minimumItemWidth,
  navButtonsVisible,
  isForProductsCarousel,
  heading,
  carouselPosition,
  showSlideButton,
  showIndictors,
  extraClasses
}) => {
  const { deviceType } = appCtx;
  const containerRef = useRef();
  const slideCount = itemsPerSlide && Math.ceil(items.length / itemsPerSlide);

  const [slideIndex, setScrollIndex] = useState(0);
  const [itemWidth, setItemWidth] = useState(0);
  // const [dotsLeftScroll, setDotsLeftScroll, ref] = useStateRef(40);
  // const prevRef = useRef(0);
  useEffect(() => {
    if (itemsPerSlide) {
      const { clientWidth } = containerRef.current;
      const calculatedItemWidth = Math.ceil(clientWidth / itemsPerSlide);
      setItemWidth(calculatedItemWidth);
    }

    const updatedScrollPoints = [];
    for (let i = 0; i < slideCount; i += 1) {
      const scrollPoint = 100 * i;
      updatedScrollPoints.push(-scrollPoint);
    }
  }, [items]);
  const itemsPerSlideValue = isForProductsCarousel ? 6 : itemsPerSlide;
  const indicatorsArrayMap = {
    desktop:
      itemsPerSlideValue && Array(Math.ceil(items.length / itemsPerSlideValue)),
    mobile:
      itemsPerSlideValue &&
      Array(Math.floor(items.length / itemsPerSlideValue)),
    tablet:
      itemsPerSlideValue && Array(Math.ceil(items.length / itemsPerSlideValue))
  };
  const handleNextSlideClick = () => {
    const currentScrollLeft = Math.ceil(
      Number(containerRef.current.scrollLeft)
    );
    const currentClientWidth = Math.ceil(
      Number(containerRef.current.clientWidth)
    );
    const currentIndex = Math.ceil(currentScrollLeft / currentClientWidth);
    if (
      itemsPerSlideValue &&
      indicatorsArrayMap[deviceType].length - 1 === currentIndex
    ) {
      return;
    }
    const currentPosition =
      Math.ceil(currentScrollLeft / currentClientWidth) * currentClientWidth;
    containerRef.current.scrollTo({
      left: currentPosition + currentClientWidth,
      behavior: 'smooth'
    });
    setScrollIndex(currentIndex + 1);
  };

  const handlePreviousSlideClick = () => {
    const currentScrollLeft = Math.ceil(
      Number(containerRef.current.scrollLeft)
    );
    const currentClientWidth = Math.ceil(
      Number(containerRef.current.clientWidth)
    );
    const currentIndex = Math.ceil(currentScrollLeft / currentClientWidth);
    if (currentIndex === 0) {
      return;
    }
    const currentPosition =
      Math.ceil(currentScrollLeft / currentClientWidth) * currentClientWidth;
    containerRef.current.scrollTo({
      left: currentPosition - currentClientWidth,
      behavior: 'smooth'
    });
    setScrollIndex(currentIndex - 1);
  };

  const handleIndicatorClick = (index) => {
    let multiplicant = 1;
    if (isForProductsCarousel) {
      if (deviceType === 'mobile') {
        multiplicant = 3;
      } else if (deviceType === 'tablet') {
        multiplicant = 1.5;
      }
    }
    containerRef.current.scrollTo({
      left: index * multiplicant * containerRef.current.clientWidth,
      behavior: 'smooth'
    });
    setScrollIndex(index);
  };
  useEffect(() => {
    const carouselElement = document.getElementById(
      `product-carousel-scrollable-content-${heading}`
    );
    // const currScroll = 0;
    // const prevScroll = 0;
    let multiplicant;
    if (isForProductsCarousel) {
      if (deviceType === 'mobile') {
        multiplicant = 3;
      } else if (deviceType === 'tablet') {
        multiplicant = 1.5;
      } else if (deviceType === 'desktop') {
        multiplicant = 1;
      }
    }
    const scrollEvent = () => {
      const currentIndex = Math.floor(
        containerRef.current.scrollLeft /
          (multiplicant * containerRef.current.clientWidth)
      );
      setScrollIndex(currentIndex);
      // currScroll = containerRef.current.scrollLeft;

      // if (containerRef.current.scrollLeft > prevScroll) {
      //   if (
      //     currentIndex > 1 &&
      //     currentIndex % 4 === 0 &&
      //     currentIndex > prevRef.current
      //   ) {
      //     setDotsLeftScroll(ref.current - 8);
      //     prevRef.current = currentIndex;
      //   }
      // } else if (
      //   (currentIndex + 1) % 4 === 0 &&
      //   currentIndex < prevRef.current
      // ) {
      //   setDotsLeftScroll(ref.current + 8);
      //   prevRef.current = currentIndex;
      // }
      // prevScroll = currScroll;
    };
    if (isForProductsCarousel && carouselElement && deviceType !== 'desktop') {
      carouselElement.addEventListener('scroll', scrollEvent);
    }
    return () => {
      if (
        isForProductsCarousel &&
        carouselElement &&
        deviceType !== 'desktop'
      ) {
        carouselElement.removeEventListener('scroll', scrollEvent);
      }
    };
  }, []);

  return (
    <Fragment>
      <div
        className={`carousel-container ${
          isForProductsCarousel && deviceType !== 'desktop'
            ? 'carousel-container-mobile'
            : ''
        }${extraClasses.length ? extraClasses.join(' ') : ''} ${deviceType}`}
      >
        {navButtonsVisible && showSlideButton && (
          <CarouselControlButtons
            onNextSlide={handleNextSlideClick}
            onPreviousSlide={handlePreviousSlideClick}
            navButtonsVisible={navButtonsVisible}
            isForProductsCarousel={isForProductsCarousel}
            carouselPosition={carouselPosition}
          />
        )}
        <div
          id={`product-carousel-scrollable-content-${heading}`}
          style={
            items.length < 6 && items.length > 1 && deviceType === 'desktop'
              ? { justifyContent: 'space-evenly' }
              : null
          }
          className={
            isForProductsCarousel
              ? 'products-carousel-items-container'
              : 'carousel-items-container'
          }
          ref={(r) => {
            containerRef.current = r;
          }}
        >
          {items.length < 6 && deviceType === 'desktop'
            ? items.map((item, index) => {
                return (
                  <>
                    <div
                      key={item.key}
                      className={`${'fewer-products-carousel-item-wrapper'}`}
                    >
                      <div className="carousel-item">{item}</div>
                    </div>
                    {index < items.length - 1 && <div className="divider" />}
                  </>
                );
              })
            : items.map((item) => {
                return (
                  <div
                    key={item.key}
                    style={
                      itemsPerSlide
                        ? {
                            minWidth: `${minimumItemWidth || itemWidth}px`
                          }
                        : undefined
                    }
                    className={`${
                      isForProductsCarousel
                        ? 'products-carousel-item-wrapper'
                        : 'item-wrapper'
                    }`}
                  >
                    <div className="carousel-item">{item}</div>
                  </div>
                );
              })}
        </div>
      </div>
      {showIndictors &&
      itemsPerSlideValue &&
      Math.ceil(items.length / itemsPerSlide) > 1 ? (
        <div
          className={`${
            isForProductsCarousel && deviceType !== 'desktop'
              ? 'indicator-wrapper'
              : ''
          }`}
        >
          <div
            id={
              isForProductsCarousel
                ? `pdp-carousel-bullets-${carouselPosition}`
                : ''
            }
            className={`${
              isForProductsCarousel
                ? 'products-carousel-indicator'
                : 'indicator'
            }`}
          >
            {[...indicatorsArrayMap[deviceType]].map((_, index) => (
              <div
                onClick={() => {
                  handleIndicatorClick(index);
                }}
                role="button"
                onKeyDown={noop}
                tabIndex="0"
                // eslint-disable-next-line react/no-array-index-key
                key={`${heading}-${index}`}
                className={`dot ${
                  isForProductsCarousel ? 'products-carousel-dot' : ''
                } ${index === slideIndex ? 'dot-active' : ''} `}
              />
            ))}
          </div>
          {/* {isForProductsCarousel && deviceType !== 'desktop' && (
            <div className="indicator-overlay-left" />
          )}
          {isForProductsCarousel && deviceType !== 'desktop' && (
            <div className="indicator-overlay-right" />
          )} */}
        </div>
      ) : (
        ''
      )}
      <style jsx>{CarouselStyles}</style>
    </Fragment>
  );
};

Carousel.defaultProps = {
  itemsPerSlide: 6,
  items: [],
  minimumItemWidth: 0,
  navButtonsVisible: true,
  isForProductsCarousel: false,
  heading: '',
  carouselPosition: 0,
  showIndictors: true,
  showSlideButton: false,
  extraClasses: []
};

Carousel.propTypes = {
  items: PropTypes.array,
  itemsPerSlide: PropTypes.number,
  minimumItemWidth: PropTypes.number,
  navButtonsVisible: PropTypes.bool,
  isForProductsCarousel: PropTypes.bool,
  appCtx: PropTypes.object.isRequired,
  heading: PropTypes.string,
  carouselPosition: PropTypes.number,
  showIndictors: PropTypes.bool,
  showSlideButton: PropTypes.bool,
  extraClasses: PropTypes.array
};

export default withApplicationContext(Carousel);
