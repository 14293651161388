import css from 'styled-jsx/css';
import { svgToDataUri } from '../../../../utils/styles/svgMixins';
import { icons } from '../../../../config/styles';

const DeliveryOptionsContentStyles = css`
    .container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        width: 100%;
        height: 71px;
        border-bottom: 1px solid #F0F0F0;
        display: flex;
        align-items: center;
        padding: 27px 0 19px 37px;

        @mixin mobileToTablet {
            height: 83px;    
            padding: 36px 0 24px 16px;   
        }

        .icon-container {
                
            .icon {
                width: 40px;
                height: 24px;
                background-image: url('${svgToDataUri(
                  icons.homeDeliveryIcon
                )}');
                background-size: contain;
                background-repeat: no-repeat;
                display: block;

                &.homeDelivery {
                    width: 40px;
                    height: 24px;
                    background-image: url('${svgToDataUri(
                      icons.homeDeliveryIcon
                    )}');
                }

                &.pickupInStore {
                    width: 35px;
                    height: 29px;
                    background-image: url('${svgToDataUri(
                      icons.pickupInStoreIcon
                    )}');
                    margin-bottom: 5px;
                }
            }
        }

        .heading {
            font-family: Lato, sans-serif;
            margin-left: 18px;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: #333;

            @mixin mobileToTablet {
                font-size: 22px;
                line-height: 26px;
            }
        }
    }

    .body {
        padding: 0 32px;
        width: 100%;
        flex: 1;

        @mixin mobileToTablet {
            padding: 0 17px;       
        }

        .location-selection {
            width: 100%;
            height: 51px;
            background: #F2F2F2;
            border-radius: 4.5px;
            margin-top: 16px;
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;

            .location-icon {
                margin: 6px 0 5px 8px;
                width: 40px;
                height: 40px;
                background-image: url('${svgToDataUri(icons.locationIcon)}');
                background-size: contain;
                background-repeat: no-repeat;
                display: block;

                &.icon-lb {
                    margin-top: -5px;
                }
            }

            .location-name-prefix {
                font-family: 'Lato', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;        
                color: #333;
                text-transform: capitalize;
                @mixin mobileToTablet {
                    font-size: 14px;
                    line-height: 17px;
                }
                &.line-break-hd {
                    position: absolute;
                    top: 8.5px;
                    left: 48px;
                }
                &.line-break-cc {
                    position: absolute;
                    left: 49px;
                    top: 8.5px;
                }
            }

            .location-name {
                font-family: 'Lato', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;  
                margin-left: 4px;      
                color: #333;
                flex: 1;
                text-transform: capitalize;

                @mixin mobileToTablet {
                    font-size: 14px;
                    line-height: 17px;
                    margin-left: 5px;
                }
                &.line-break-hd {
                    text-indent: 15px;
                    margin: 0 10px 0 0;
                }
                &.line-break-cc {
                    text-indent: 20.5px;
                    margin: 0 10px 0 0;
                }
            }

            .change-location-container {
                display: flex;
                margin-right: 24px;

                &.location-lb {
                    align-self: start;
                    margin-top: 8px;
                }
                .change-location-icon {
                    width: 12px;
                    height: 13px;
                    background-image: url('${svgToDataUri(
                      icons.changeLocationIcon
                    )}');
                    background-size: contain;
                    background-repeat: no-repeat;
                    display: block;
                    margin-right: 6px;
                    margin-top: 3px;
                }

                .change-location-button {
                    font-family: 'Lato', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    text-align: right;
                    text-decoration-line: underline;
                    color: #333;

                    @mixin mobileToTablet {
                        font-size: 14px;
                        line-height: 17px;
                    }
                }
            }
        }
    }

    .data-section {
        width: 100%;
        margin-top: 24px;

        @mixin mobileToTablet {
            margin-top: 32px;       
        }

        h1 {
            font-family: 'Lato', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: #333;
        }

        .data-body {
            width: 100%;
            display: flex;
            flex-direction: column;
           
            .data-list {
                display: flex;
                width: 100%;
                margin-top: 24px;
                flex-direction: column;

                @mixin mobileToTablet {
                    margin-top: 32px;       
                }
                
                .data-entity {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    .check-icon {
                        width: 10px;
                        height: 9px;
                        background-image: url('${svgToDataUri(
                          icons.darkCheckIcon
                        )}');
                        background-size: contain;
                        background-repeat: no-repeat;
                        display: block;
                    }
                    .data-title {
                        font-family: 'Lato', sans-serif;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 20px;
                        color: #333;
                        margin-left: 12px;
                        
                        .data-span {
                            font-size: 14px;
                            &.green {
                                color: #30871f;
                            }
                            &.bold {
                                font-weight: 700;
                            }
                            @mixin mobileToTablet {
                                float: right;
                                line-height: 17px;
                            }
                        }
                        @mixin mobileToTablet {
                            width: 100%;
                        }
                    }
                }

                .data-subtitle {
                    font-family: 'Lato', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: 0.2px;
                    margin-left: 22px;
                    color: #4A4A4A;
                    margin-top: 2px;
                    span {
                        color: #30871F;
                        font-weight: 700;
                    }
                    @mixin mobileToTablet {
                        margin-top: 8px;
                    }
               } 
            }
        }
    }

    .footer {
        padding: 0 32px;
        width: 100%;
        height: 52px;

        @mixin mobileToTablet {
            padding: 0 16px;    
            height: 49px;   
        }

        .footer-body {
            width: 100%;
            height: 100%;
            border-top: 1px solid #F0F0F0;
            display: flex;
            align-items: center;

            .alert-icon {
                width: 15px;
                height: 15px;
                background-image: url('${svgToDataUri(icons.alertIcon)}');
                background-size: contain;
                background-repeat: no-repeat;
                display: block;

                @media screen and (max-width: 360px) {
                    align-self: self-start;
                    margin: 10px 0;
                }
            }

            .alert-message {
                font-family: Lato, sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                letter-spacing: 0.2px;
                color: #4A4A4A;
                margin-left: 8px;

                @mixin mobileToTablet {
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
    }
`;

export { DeliveryOptionsContentStyles };
