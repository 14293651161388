import React, { useState, useContext } from 'react';
import createContainer from 'constate';
import { nameHOC } from './hocutils';
import httpService from './httpService';
import apiConfig from '../config/api/apiConfig';

const fetchSizeInfo = (setSizeInfo) => async ({
  isNewSizeGroupService,
  regionCode,
  productId,
  sizes,
  backendCategoryId,
  webCategoryId,
  brandId
}) => {
  if (sizes.length === 0) {
    return;
  }

  const sizeInfoUrl = apiConfig.getSizeInfoURL(
    isNewSizeGroupService,
    regionCode,
    productId,
    sizes,
    backendCategoryId,
    webCategoryId,
    brandId
  );

  const { data, error } = await httpService().get(sizeInfoUrl);

  if (error || !Array.isArray(data.sizeGuide)) {
    return;
  }

  setSizeInfo(
    data.sizeGuide.reduce((acc, { title, sizeMap }) => {
      acc[title] = sizeMap;
      return acc;
    }, {})
  );
};

function useSizeInfoContext() {
  const [sizeInfo, setSizeInfo] = useState();

  return {
    sizeInfo,
    fetchSizeInfo: fetchSizeInfo(setSizeInfo)
  };
}

const SizeInfoContext = createContainer(useSizeInfoContext);

const withSizeInfo = (WrappedComponent) => {
  const { getInitialProps } = WrappedComponent;
  const ComponentWithSizeInfoData = (props) => {
    const sizeInfoWithState = useContext(SizeInfoContext.Context);
    return <WrappedComponent {...props} {...sizeInfoWithState} />;
  };

  if (typeof getInitialProps === 'function') {
    ComponentWithSizeInfoData.getInitialProps = getInitialProps;
  }

  ComponentWithSizeInfoData.originalName = nameHOC(WrappedComponent);
  ComponentWithSizeInfoData.displayName = nameHOC(
    WrappedComponent,
    'WithSizeInfo'
  );
  return ComponentWithSizeInfoData;
};

export default SizeInfoContext;
export { withSizeInfo };
