import apiConfig from '../../../config/api/apiConfig';
import constants from '../../../config/constants';
import _ from '../../../utils/LodashImports';

export const inPDP = (context, appCtx) => {
  const productRegex = /product/i;
  if (!context || !context.router) {
    const path = appCtx.url;
    return productRegex.test(path);
  }
  const { pathname } = context.router;
  return productRegex.test(pathname);
};

export const getViewConfig = (deviceType, isRebrandingEnabled) => {
  const dt = !deviceType || deviceType === 'tablet' ? 'mobile' : deviceType;
  const config = {
    desktop: {
      brandName: {
        size: 'title17',
        type: isRebrandingEnabled ? 'secondary' : 'primary'
      },
      description: {
        size: 'title19',
        type: 'primary'
      },
      price: 'CART'
    },
    mobile: {
      brandName: {
        size: 'title18',
        type: isRebrandingEnabled ? 'secondary' : 'primary'
      },
      description: {
        size: 'title20',
        type: 'primary'
      },
      price: 'CART'
    }
  };
  return config[dt];
};

export const getSecondaryLabel = (fieldName, textDictionary) => {
  switch (fieldName) {
    case 'Warranty': {
      return textDictionary.extendedWarrantyLightBoxLinkText;
    }
    case '2': {
      return textDictionary.CART_ASSEMBLY_OPTION_SEC_LABEL;
    }
    default: {
      return '';
    }
  }
};

export const getLink = (fieldName, endPoints, regionCode) => {
  switch (fieldName) {
    case 'Warranty': {
      return apiConfig.formatRelativeUrl(
        endPoints.extendedWarrantyLightBoxLink.path,
        regionCode
      );
    }
    default: {
      return '';
    }
  }
};

export const createOption = ({
  actionType, // 'add' or 'remove'
  type,
  currQuantity,
  maximumLimit,
  warrantySkuId,
  warrantyOfferingId,
  serviceSkuId,
  serviceOfferingId,
  offeringId,
  skuId,
  productId,
  appCtx,
  inputVal,
  totalQuantity,
  disableServicesAndWarranties,
  cartLineId,
  commerceItemId,
  warrantyOptions,
  assemblyOptions
}) => {
  const option = {
    appCtx,
    offeringId
  };

  const warrantycartLineId = disableServicesAndWarranties
    ? ''
    : _.get(warrantyOptions, 'cartLineId', '');

  const servicecartLineId = disableServicesAndWarranties
    ? ''
    : _.get(assemblyOptions, 'cartLineId', '');

  if (actionType === 'remove') {
    if (type === 'bundle') {
      option.bundledProducts = [
        {
          cartLineId,
          totalQuantity: Number(totalQuantity),
          commerceItemId,
          quantity: currQuantity
        }
      ];
    } else {
      option.products = [
        {
          cartLineId,
          totalQuantity: Number(totalQuantity),
          commerceItemId,
          quantity: currQuantity,
          warrantycartLineId,
          servicecartLineId
        }
      ];
    }
  } else if (actionType === 'add') {
    if (type === 'bundle') {
      option.bundledProducts = [{ skuId, productId, quantity: 1, offeringId }];
    } else {
      option.products = [
        {
          skuId,
          productId,
          quantity:
            inputVal < maximumLimit
              ? currQuantity
              : maximumLimit - totalQuantity,
          warrantySkuId,
          warrantyOfferingId,
          serviceSkuId,
          serviceOfferingId,
          offeringId
        }
      ];
    }
  }

  return option;
};

export const handleErrors = (
  res,
  getErrorMessage,
  cart,
  timeputForLoginModalOn401,
  setErrorAddingToCart,
  appCtx
) => {
  if (res) {
    const errorMessage = getErrorMessage(res, appCtx.siteConfig);
    const errorCode = _.get(res, '[0].code', '');
    if (errorCode === constants.ATC_SESSION_EXPIRED_CODE) {
      setTimeout(() => {
        cart.setShowCart(false);
        cart.setShowLoginForm(true);
      }, timeputForLoginModalOn401);
    }
    setErrorAddingToCart(errorMessage);
  }
};

export const handleEvents = (
  actionType, // 'add' or 'remove'
  isClickedByUser,
  res,
  inputVal,
  maxOnCartAmount
) => {
  let detail;
  if (isClickedByUser && !res) {
    const eventName =
      actionType === 'add'
        ? 'DDPDPAddQuantitySelector'
        : 'DDPDPRemoveQuantitySelector';

    if (actionType === 'add') {
      detail = {
        bubbles: true,
        detail: {
          maxCartQuantity: maxOnCartAmount,
          value: inputVal,
          cart: true
        }
      };
    } else {
      detail = {
        bubbles: true,
        detail: {
          value: inputVal - 1,
          cart: true
        }
      };
    }
    const eventToDigitalData = new CustomEvent(eventName, detail);
    window.dispatchEvent(eventToDigitalData);
  }
};
