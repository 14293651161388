/* eslint-disable consistent-return */
import apiConfig from '../config/api/apiConfig';
import httpService from './httpService';
import _ from './LodashImports';
import WebStorage from './WebStorage';
import constants from '../config/constants';

const ORIGIN_TYPE = {
  MAIN_CART: 'MAIN_CART',
  MAIN_CART_ITEMS: 'MAIN_CART_ITEMS',
  BUNDLE: 'BUNDLE',
  CART_BUNDLE: 'CART_BUNDLE'
};

const formatWarrantyOptions = (warrantyOptions) => {
  if (
    warrantyOptions &&
    warrantyOptions.fieldOptions &&
    Array.isArray(warrantyOptions.fieldOptions) &&
    warrantyOptions.fieldOptions.length &&
    warrantyOptions.fieldOptions[0].label.match(/No, gracias/)
  ) {
    const [firstElem, ...rest] = warrantyOptions.fieldOptions;
    return {
      ...warrantyOptions,
      fieldOptions: [...rest, firstElem]
    };
  }
  return warrantyOptions;
};

const getSessionConfirmationNumber = async (regionCode) => {
  const sessionCnfNumber = WebStorage.getItemWithTTL({
    name: constants.SESSION_CNF_KEY_NAME
  });
  if (sessionCnfNumber) {
    return sessionCnfNumber;
  }

  const { data } = await httpService().get(
    apiConfig.getFetchSessionInfoUrl(regionCode),
    {
      credentials: 'include'
    }
  );

  const sessionConfirmationNumber = _.get(
    data,
    'state.sessionConfirmationNumber'
  );

  WebStorage.setItemWithTTL({
    name: constants.SESSION_CNF_KEY_NAME,
    value: sessionConfirmationNumber,
    ttl: constants.SESSION_CNF_TTL
  });

  return sessionConfirmationNumber;
};

const setPromotionalProductToAdd = ({ cart, setCart }) => ({
  promotionalProductsToAdd
}) => {
  setCart({
    ...cart,
    promotionalProductsToAdd
  });
};

export {
  ORIGIN_TYPE,
  setPromotionalProductToAdd,
  getSessionConfirmationNumber,
  formatWarrantyOptions
};
