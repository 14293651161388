const findIndex = (list, callback) => {
  if (!list) {
    return -1;
  }

  if (Array.prototype.findIndex) {
    return list.findIndex(callback);
  }

  let index = 0;
  list.some((element) => {
    if (callback(element)) {
      return true;
    }
    index += 1;
    return false;
  });
  return index === list.length ? -1 : index;
};

const isEmpty = (obj) => {
  if (!obj || Object.keys(obj).length === 0) {
    return true;
  }
  return false;
};

const getClassNames = (className, flag) => {
  return flag ? `${className} ${className}--active` : className;
};

const changeTextCase = (
  label,
  isRebrandingEnabled = false,
  m2AttributeValue = -1
) => {
  let newLabel = label;
  if (m2AttributeValue > 0) {
    return 'Agregar';
  }
  if (isRebrandingEnabled) {
    newLabel = newLabel.toLowerCase();
    return newLabel[0].toUpperCase() + newLabel.slice(1);
  }
  return label;
};

const toggleBodyClass = (condition, name) => {
  /* eslint-disable no-unused-expressions */
  condition
    ? document.body.classList.add(`${name}`)
    : document.body.classList.remove(`${name}`);
  /* eslint-enable no-unused-expressions */
};

const allowOnlyNumbers = (value) => {
  let onlyDigitVal = '';
  for (let i = 0; i < value.length; ) {
    if (value.charCodeAt(i) >= 48 && value.charCodeAt(i) <= 57) {
      onlyDigitVal = `${onlyDigitVal}${value.charAt(i)}`;
    }
    i += 1;
  }
  return onlyDigitVal;
};

const formatPoints = (points) => {
  let pointsStr = points.toString();
  if (pointsStr.length < 5) {
    for (let i = 0; i < 5 - pointsStr.length; ) {
      /* eslint-disable-line no-unused-vars */
      pointsStr = `0${pointsStr}`;
      i += 1;
    }
  }
  const firstPart = pointsStr.substring(0, pointsStr.length - 3);
  const secondPart = pointsStr.substring(
    pointsStr.length - 3,
    pointsStr.length
  );
  return `${firstPart}.${secondPart}`;
};

function capitalizeString(str) {
  // Split the string into an array of words
  const words = str.toLowerCase().split(' ');

  // Capitalize the first letter of each word
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  // Join the words back into a string
  return words.join(' ');
}

export {
  findIndex,
  isEmpty,
  getClassNames,
  toggleBodyClass,
  changeTextCase,
  allowOnlyNumbers,
  formatPoints,
  capitalizeString
};
