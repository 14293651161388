import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import headingStyles from './Heading.style';

const Heading = ({
  children,
  level,
  variation,
  isHighlighted,
  inline,
  light,
  type,
  alignment
}) => {
  const CustomTag = `h${level}`;
  const inlineClass = inline ? 'inline' : '';
  const lightClass = light ? 'light' : '';
  const highlightedClass = isHighlighted ? 'highlighted' : '';

  return (
    <Fragment>
      <CustomTag
        className={`${variation} ${type} ${highlightedClass} ${lightClass} ${inlineClass} ${alignment}`}
      >
        {children}
      </CustomTag>
      <style jsx>{headingStyles}</style>
    </Fragment>
  );
};

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  level: PropTypes.oneOf(['1', '2', '3', '4', '5', '6']),
  variation: PropTypes.oneOf([
    'title1',
    'title2',
    'title3',
    'title4',
    'title5',
    'title6',
    'title7',
    'title8',
    'title9',
    'title10',
    'title11',
    'title12',
    'title13'
  ]),
  alignment: PropTypes.oneOf(['left', 'right', 'center', 'justify', '']),
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    '',
    'mkp-primary',
    'mkp-secondary'
  ]),
  isHighlighted: PropTypes.bool,
  inline: PropTypes.bool,
  light: PropTypes.bool
};

Heading.defaultProps = {
  level: '1',
  type: '',
  variation: 'title1',
  isHighlighted: false,
  inline: false,
  light: false,
  alignment: ''
};

export default Heading;
