import React from 'react';
import PropTypes from 'prop-types';

const Slots = ({ children, position }) => (
  <div id={`slots-position-${position}`}>{children}</div>
);

Slots.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.string.isRequired
};

export default Slots;
