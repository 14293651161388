import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { SizeEquilancyStyles } from './EquilancyTable.style';
import { withLabels } from '../../../../../utils/LabelsContext';

const CountrySize = ({ sizelable, size }) => {
  return size ? (
    <Fragment>
      <span className="country-size">
        <b>{sizelable}:</b> {`${size}`}
      </span>
      <style jsx>{SizeEquilancyStyles}</style>
    </Fragment>
  ) : (
    ''
  );
};
const EquilancyTable = ({
  isMobile,
  sizeMap,
  tenantValue,
  labels,
  tenantSize,
  isRebrandingEnabled
}) => {
  const { CMSIZE, EURSIZE, USSIZE, UKSIZE } = labels;

  return (
    <div
      className={`size-equilancy-wrapper ${isRebrandingEnabled && 'rebranded'}`}
    >
      <div
        className={`${
          isMobile
            ? 'size-equilancy-table-mobile'
            : 'size-equilancy-table-desktop'
        }`}
      >
        <p className="align-center z-top">
          <span className="tenant-size">{`${tenantSize} ${tenantValue} =`}</span>
          <CountrySize sizelable={CMSIZE} size={sizeMap.CM} />
          <CountrySize sizelable={USSIZE} size={sizeMap.US} />
          <CountrySize sizelable={EURSIZE} size={sizeMap.EUR} />
          <CountrySize sizelable={UKSIZE} size={sizeMap.UK} />
        </p>
      </div>
      <style jsx>{SizeEquilancyStyles}</style>
    </div>
  );
};

EquilancyTable.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  sizeMap: PropTypes.object.isRequired,
  tenantValue: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  tenantSize: PropTypes.string.isRequired,
  isRebrandingEnabled: PropTypes.bool.isRequired
};

CountrySize.propTypes = {
  size: PropTypes.string.isRequired,
  sizelable: PropTypes.string.isRequired
};

export default withLabels(EquilancyTable);
export { EquilancyTable };
