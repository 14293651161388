import React, { useContext, useEffect, useState } from 'react';
import createContainer from 'constate';
import { nameHOC } from './hocutils';
import {
  addToList as addToListCommerce,
  deleteFromList as deleteFromListCommerce,
  getAllLists as getAllListsCommerce
} from './cart/myLists';
import _ from './LodashImports';
import UserContext from './UserContext';
import constants from '../config/constants';
import useMyListsMetrics from '../components/MyLists/useMyListsMetrics';

const { WITH_ML } = constants.ML_METRICS.METRICS_DATA;
const { ATC_SESSION_EXPIRED_CODE } = constants;

function useMyListsContext({ data: initialData, appCtx }) {
  const isMyListsEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isPDPMyListsEnabled',
    false
  );
  const userContext = useContext(UserContext.Context);
  const [myListData, setMyListData] = useState(initialData || []);
  const [error, setError] = useState('');
  const [addedLists, setAddedLists] = useState([]);
  const [undo, setUndo] = useState();
  const [alertType, setAlertType] = useState('success');

  const myListMetrics = useMyListsMetrics();

  const getAllLists = async ({ listType }) => {
    const response = await getAllListsCommerce({
      appCtx,
      listType
    });
    if (response.ok) {
      setMyListData(response.data.lists || []);
      myListMetrics.setIsWithMyLists(WITH_ML);
      myListMetrics.setIsEvaluated(true);
    } else {
      setError(response.error_code || 'error');
    }
    return response;
  };

  useEffect(() => {
    if (isMyListsEnabled && userContext.isUserLoggedIn) getAllLists({});
  }, [userContext.isUserLoggedIn]);

  const addTolList = async ({
    listType,
    tagName,
    product,
    setShowLoginForm
  }) => {
    const response = await addToListCommerce({
      tagName,
      listType,
      product,
      appCtx
    });
    if (
      response.error_code &&
      response.error_code === ATC_SESSION_EXPIRED_CODE
    ) {
      setShowLoginForm(true);
    }
    return response;
  };

  const deleteFromList = async (
    listId,
    listLineItemId,
    tagName,
    setShowLoginForm
  ) => {
    const response = await deleteFromListCommerce({
      appCtx,
      listId,
      listLineItemId,
      tagName
    });
    if (
      response.error_code &&
      response.error_code === ATC_SESSION_EXPIRED_CODE
    ) {
      setShowLoginForm(true);
    }
    return response;
  };

  return {
    myLists: {
      myListData,
      addTolList,
      setMyListData,
      getAllLists,
      deleteFromList,
      error,
      setAddedLists,
      addedLists,
      setUndo,
      undo,
      alertType,
      setAlertType,
      myListMetrics
    }
  };
}

const MyListContext = createContainer(useMyListsContext);

const withMyLists = (WrappedComponent) => {
  const { getInitialProps } = WrappedComponent;
  const ComponentWithMyLists = (props) => {
    const { myLists } = useContext(MyListContext.Context);
    return <WrappedComponent {...props} myLists={myLists} />;
  };
  if (typeof getInitialProps === 'function') {
    ComponentWithMyLists.getInitialProps = getInitialProps;
  }
  ComponentWithMyLists.originalName = nameHOC(WrappedComponent);
  ComponentWithMyLists.displayName = nameHOC(
    WrappedComponent,
    'WithMyListsContext'
  );

  return ComponentWithMyLists;
};
export default MyListContext;
export { withMyLists };
