import css from 'styled-jsx/css';
import { colors } from '../../config/styles';

const ProductContainerStyles = css`
  .pdp-body {
    background-color: ${colors.gray.shade6};
    padding-bottom: 20px;
    .container-my-lists-alert {
      padding-top: 10px;
      background: #fff;
    }
  }

  .productContainer {
    background-color: ${colors.white.shade1};
  }

  .additionInformation-container {
    margin-top: 30px;
    background-color: transparent;
  }

  .typeA-variants {
    margin-top: 20px;
    background-color: ${colors.white.shade1};
    padding: 0 23px 0 22px;

    @mixin desktop {
      padding: 0 40px 0 60px;
    }

    &-item {
      padding: 30px 0;
      border-bottom: 1px solid ${colors.gray.shade3};
    }
  }

  .typeC-products-container {
    margin-top: 24px;
    background-color: ${colors.white.shade1};
    padding: 32px 23px 30px 22px;

    @mixin phabletToDesktop {
      margin-top: 20px;
      padding: 0 40px 12px 60px;
    }

    @mixin desktop {
      margin-top: 30px;
    }

    .typeC-heading {
      padding: 0;
      font-family: inherit;
      margin-bottom: 40px;
      @mixin phabletToDesktop {
        padding-top: 26px;
        margin-bottom: 0;
      }
    }
    @mixin phabletToDesktop {
      .typeC-products {
        padding-top: 30px;
      }
    }
  }

  .promotionalProduct-container {
    display: flex;
    justify-content: center;
  }
  .surveybar-wrapper {
    flex-direction: row;
  }
  .fa--bazaarvoice-questions {
    background-color: ${colors.white.shade1};
  }
`;

export { ProductContainerStyles };
