import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';

const CardStatusStyles = css`
  .cmrButton {
    display: flex;
    align-items: center;
    padding: 8px 8px 8px 10px;
    color: ${colors.black.shade1};
    font-weight: 400;
    border: 0;
    background-color: ${colors.white.shade2};
    border-radius: 7px;
    font-size: ${typography.sm};
    max-width: 273px;
    margin: 29px 0 33px;
    @mixin tabletToDesktop {
      width: 250px;
    }
  }
  .cmrButton.border-success {
    border: 1px solid ${colors.green.shade2};
    .apertura-icon-success {
      color: ${colors.green.shade17};
    }
  }
  .cmrButton.border-warning {
    border: 1px solid ${colors.yellow.shade2};
    .apertura-icon-warning {
      color: ${colors.yellow.shade3};
    }
  }
  .cmrButtonAlertIcon {
    font-size: ${typography.lg};
    margin-right: 5px;
  }
  .apertura-content {
    line-height: 14px;
  }
`;

export default CardStatusStyles;
