import css from 'styled-jsx/css';
import { colors } from '../../config/styles';

const PDPBasicInfoSharedStyle = css`
  .vertical-separator {
    width: 0;
    height: 18px;
    border-left: 1px solid ${colors.gray.shade14};
    margin: 0 4px;
  }
  .basic-details-Mobile {
    display: flex;
    flex-direction: column;
    margin-bottom: 13px;
    &.out-of-stock {
      margin-bottom: 4px;
    }
    .corner-details {
      display: flex;
      justify-content: space-between;
      margin: 12px 0;
    }
  }
  .reviews {
    display: flex;
    margin-top: 9px;
    &-column {
      position: relative;
      width: auto;
      display: flex;
      &-hidden {
        display: none;
      }
    }
  }
  .badges {
    position: relative;
    height: 5px;
  }
  .brand-and-rating {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export default PDPBasicInfoSharedStyle;
