/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { SellerInfoDesktopStyle } from './SellerInfoDesktop.style';
import { withLabels } from '../../../utils/LabelsContext';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import Tooltip, { TooltipText } from '../../ui/Tooltip/Tooltip';
import constants from '../../../config/constants';
import { withConfig } from '../../../utils/ConfigurationContext';
import {
  getSellerClass,
  getSellerLink,
  getTooltipText,
  validateSellerName
} from '../helpers';
import { withDigitalData } from '../../../utils/DigitalDataContext';
import defaultConfig from '../../../config/url/default.config';

const SellerInfoDesktop = ({
  product,
  labels,
  appCtx,
  config,
  digitalData
}) => {
  const { regionCode, isRebrandingEnabled, store } = appCtx;
  const isSellerPopupEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isSellerPopupEnabled',
    false
  );
  const { ENVIRONMENT } = config;
  const { currentVariant, variants, isOutOfStock } = product;
  const variant = variants.find((item) => item.id === currentVariant);

  const sellerId = _.get(variant, 'offerings[0].sellerId', '');
  const sellerName = _.get(variant, 'offerings[0].sellerName', '');
  const sellerNameLower = sellerName && sellerName.toLowerCase();

  useEffect(() => {
    digitalData.pdp.setSoldBy(sellerNameLower);
  }, [sellerNameLower]);

  const shouldShowSellerInfo = _.get(
    appCtx,
    'siteConfig.toggles.isSellerInfoVisibleInPDP',
    false
  );
  const isSISExperienceOn = _.get(
    appCtx,
    'siteConfig.toggles.isSISExperienceOn',
    false
  );

  if (!sellerId || !sellerName || !shouldShowSellerInfo) return <div />;

  const sellerClass = getSellerClass({ constants, sellerNameLower });
  const sellerRedirectionMap = _.get(
    appCtx,
    'siteConfig.configurations.sellerRedirectionMap',
    defaultConfig.SELLER_REDIRECTION_MAP[ENVIRONMENT][regionCode]
  );
  const validatedSellerName = validateSellerName(sellerNameLower, regionCode);
  return (
    <React.Fragment>
      <div
        className={`sellerText ${isRebrandingEnabled &&
          'rebranded'} ${isOutOfStock && 'out-of-stock'}`}
      >
        <div className="sellerInfoContainer fa--seller-info__desktop">
          <span>{labels.SELLER_SOLD_BY}&nbsp;</span>
          {sellerClass ? <span className={`${sellerClass}Icon`} /> : null}
          <a
            id="testId-SellerInfo-sellerName"
            href={getSellerLink({
              sellerClass,
              regionCode,
              sellerName:
                sellerClass === 'homecenter'
                  ? validatedSellerName.toUpperCase()
                  : sellerName,
              env: ENVIRONMENT,
              isSISExperienceOn,
              store,
              sellerRedirectionMap
            })}
            className="link"
          >
            <span className="underline">{validatedSellerName}</span>
          </a>
          {isSellerPopupEnabled && (
            <Tooltip
              position="bottom-middle"
              hasCaret
              tooltipContent={
                <TooltipText
                  text={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getTooltipText({
                          sellerName: sellerClass || sellerNameLower,
                          labels,
                          storePlaceholderText: constants.STORE_PLACEHOLDER,
                          sellerData: product.sellerInfo,
                          isNational: !_.get(
                            product,
                            'internationalShipping.applicable',
                            false
                          )
                        })
                      }}
                      id="seller-tooltip-text"
                    />
                  }
                  viewType="medium"
                />
              }
            >
              <i className="csicon-alert alertIcon">
                <span className="sr-only">Seller Info</span>
              </i>
            </Tooltip>
          )}
        </div>
        <style jsx>{SellerInfoDesktopStyle}</style>
      </div>
    </React.Fragment>
  );
};

SellerInfoDesktop.propTypes = {
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  digitalData: PropTypes.object.isRequired
};

export default withApplicationContext(
  withLabels(withConfig(withDigitalData(SellerInfoDesktop)))
);
