import React from 'react';
import PropTypes from 'prop-types';
import SingleChipletSelect from '../ui/SingleChipletSelect/SingleChipletSelect';
import { isEmpty } from '../../utils/customMethods';
import { withDigitalData } from '../../utils/DigitalDataContext';

const Warranty = ({
  warrantyOptions,
  setWarrantyOption,
  label,
  startsFromLabel,
  tooltipToggle,
  additionalWarrantyLabel,
  digitalData
}) => {
  const handleWarrantyChange = (option) => {
    setWarrantyOption(option);
  };
  const tooltipContent = !isEmpty(warrantyOptions.warrantyTooltip)
    ? warrantyOptions.warrantyTooltip
    : undefined;
  if (
    warrantyOptions &&
    warrantyOptions.fieldOptions &&
    warrantyOptions.fieldOptions.length
  ) {
    digitalData.pdp.setExtendedWarrantyOptsQty(
      warrantyOptions.fieldOptions.length
    );
    return (
      <div className="fa--warranty">
        <SingleChipletSelect
          options={warrantyOptions.fieldOptions}
          cheapestOption={warrantyOptions.fieldOptions[0].price}
          tooltipContent={tooltipContent}
          tooltipToggle={tooltipToggle}
          startsFromLabel={startsFromLabel}
          label={label}
          onOptionSelected={handleWarrantyChange}
          secondaryLabelIcon="csicon-alert"
          icon="csicon-shield"
          shouldHaveBackground={false}
          type="medium"
          withPadding={false}
          highLightHeading
          clickableChiplet
          additionalWarrantyLabel={additionalWarrantyLabel}
          serviceType="warranty-option"
          hideSubtitle
        />
      </div>
    );
  }
  return null;
};

Warranty.defaultProps = {
  setWarrantyOption: () => {},
  warrantyOptions: {
    label: '',
    fieldOptions: []
  },
  label: 'Garantía Extendida',
  startsFromLabel: 'A partir de: ',
  tooltipToggle: false,
  additionalWarrantyLabel: {}
};

Warranty.propTypes = {
  warrantyOptions: PropTypes.object,
  setWarrantyOption: PropTypes.func,
  label: PropTypes.string,
  startsFromLabel: PropTypes.string,
  tooltipToggle: PropTypes.bool,
  additionalWarrantyLabel: PropTypes.object,
  digitalData: PropTypes.object.isRequired
};

export default withDigitalData(Warranty);
