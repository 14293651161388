import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import decodeHtml from '../../../utils/decodeHtml';
import { ProductInfoStyle } from './ProductInfo.style';

const resolveScopedStyles = (scope) => {
  return {
    className: scope.props.className,
    styles: () => scope.props.children,
    wrapClassNames: (...classNames) =>
      [scope.props.className, ...classNames].filter(Boolean).join(' ')
  };
};

const ProductInfo = ({ description }) => {
  if (!description || typeof description !== 'string') return null;
  const style = resolveScopedStyles(
    <Fragment>
      <style>{`
        ${ProductInfoStyle}
      `}</style>
    </Fragment>
  );

  return (
    <Fragment>
      {/* eslint-disable react/no-danger */}
      <div
        className={style.wrapClassNames(
          'fb-product-information__product-information-tab'
        )}
      >
        <div
          className={style.wrapClassNames('fb-product-information-tab__copy')}
          dangerouslySetInnerHTML={{
            __html: decodeHtml(decodeHtml(description))
          }}
        />
      </div>
      <style.styles />
    </Fragment>
  );
};

ProductInfo.propTypes = {
  description: PropTypes.string.isRequired
};

export default ProductInfo;
