import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ImageGalleryStyles,
  ImageGalleryGlobalStyles
} from './ImageGallery.style';
import { withProduct } from '../../utils/ProductContext';
import ImageGalleryCarousel from './ImageGalleryCarousel/ImageGalleryCarousel';
import ImageGalleryHeadLine from './ImageGalleryHeadLine/ImageGalleryHeadLine';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { getVariant } from '../../utils/variant';
import _ from '../../utils/LodashImports';
import { fetchImages, guessFirstImage, isVideo } from './ImageGalleryHelper';
import { withDigitalData } from '../../utils/DigitalDataContext';

const ImageGallery = ({
  product,
  carouselImageHeight,
  carouselImageWidth,
  desktopImagesPerSlide,
  zoomSize,
  imageQuality,
  headlineImageHeight,
  headlineImageWidth,
  imagesPerSlide,
  appCtx,
  crop,
  isConnect,
  digitalData
}) => {
  const {
    isOutOfStock,
    variants,
    attributes,
    plusSizes = [],
    brandName,
    name
  } = product;
  const { sameImage } = attributes;

  const isDottedImageCarouselEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isDottedImageCarouselEnabled',
    true
  );

  const variantId =
    !sameImage && product.currentVariant ? product.currentVariant : product.id;

  const [selectedColor, setSelectedColor] = useState();
  const [selectedVariant, setSelectedVariant] = useState(variantId);

  const { regionCode } = appCtx;
  const isCFMediasEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isCFMediasEnabled',
    false
  );
  const sellerName = _.get(
    product,
    'variants[0].offerings[0].sellerName',
    ''
  ).toLowerCase();

  const firstImageGuess = guessFirstImage(product, variantId, sameImage);

  const [images, setImages] = useState(firstImageGuess);
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const [activeIndicatorIndex, setActiveIndicatorIndex] = useState(0);

  const [carouselImages, setCarouselImages] = useState([]);
  const getVariantDetails = () => {
    const currentVariantData = getVariant(variants, variantId);
    const size = _.get(currentVariantData, 'attributes.size', '').toUpperCase();
    const color = _.get(currentVariantData, 'attributes.colorName');
    return { size, color, id: currentVariantData.id };
  };

  const [isPrevPlusSize, setIsPrevPlusSize] = useState(
    plusSizes.includes(getVariantDetails().size)
  );

  const shouldChangeImage = (size) => {
    const isCurrentPlusSize = plusSizes.includes(size);
    return isPrevPlusSize !== isCurrentPlusSize;
  };

  useEffect(() => {
    if (!sameImage) {
      const { color, id, size } = getVariantDetails();
      if (selectedColor !== color || shouldChangeImage(size)) {
        setIsPrevPlusSize(plusSizes.includes(size));
        setSelectedColor(color);
        setSelectedVariant(id);
      }
    }
  }, [variantId]);

  useEffect(() => {
    const variantObj = product.variants.find(
      (variant) => variant.id === product.currentVariant
    );
    if (!sameImage && _.get(variantObj, 'medias', []).length > 0) {
      const variantVideosUrls = _.get(variantObj, 'medias', [])
        .filter(isVideo)
        .map((m) => m.url);
      const productVideos = _.get(product, 'medias', []).filter(
        (m) => isVideo(m) && !variantVideosUrls.includes(m.url)
      );
      setImages([...variantObj.medias, ...productVideos]);
      return;
    }
    if (sameImage && _.get(product, 'medias', []).length > 0) {
      setImages(product.medias);
      return;
    }
    const media = _.get(variantObj, 'medias') || _.get(product, 'medias');
    fetchImages({
      variantId: selectedVariant,
      regionCode,
      sellerName,
      isCFMediasEnabled,
      media
    })
      .then((img) => {
        if (img) {
          setImages([...product.mediaList, ...img]);
        } else {
          setImages(firstImageGuess);
        }
      })
      .catch(() => {
        setImages(firstImageGuess);
      });
  }, [product.currentVariant]);

  useEffect(() => {
    setCarouselImages(
      images.slice(
        activeIndicatorIndex * desktopImagesPerSlide,
        (1 + activeIndicatorIndex) * desktopImagesPerSlide
      )
    );
  }, [activeIndicatorIndex, images]);

  useEffect(() => {
    const index = appCtx.deviceType === 'desktop' || isOutOfStock ? 0 : 1;
    setActiveImageIndex(index);
  }, [images]);

  const getImages = () => {
    if (isOutOfStock) {
      return [images[0]];
    }
    return appCtx.deviceType === 'desktop'
      ? images
      : [images[images.length - 1], ...images, images[0]];
  };

  digitalData.pdp.setProductBigImagesQty(images.length > 0 ? 1 : 0);
  digitalData.pdp.setProductSmallImagesQty(images.length);

  return (
    <div className="imageGallery fa--image-gallery">
      {images.length > 0 && (
        <ImageGalleryHeadLine
          imageQuality={imageQuality}
          zoomSize={!isOutOfStock ? zoomSize : { x: 0, y: 0 }}
          activeImageIndex={activeImageIndex}
          images={getImages()}
          imagesPerSlide={desktopImagesPerSlide}
          activeIndicatorIndex={activeIndicatorIndex}
          setActiveIndicatorIndex={setActiveIndicatorIndex}
          setActiveImageIndex={setActiveImageIndex}
          headlineImageHeight={headlineImageHeight}
          headlineImageWidth={headlineImageWidth}
          tenant={regionCode}
          showControlButtons={!isOutOfStock}
          crop={crop}
          isConnect={isConnect}
          productName={name}
          brandName={brandName}
        />
      )}

      {!isOutOfStock && (
        <ImageGalleryCarousel
          imageQuality={imageQuality}
          carouselImageHeight={carouselImageHeight}
          carouselImageWidth={carouselImageWidth}
          activeImageIndex={activeImageIndex}
          activeIndicatorIndex={activeIndicatorIndex}
          setActiveImageIndex={setActiveImageIndex}
          setActiveIndicatorIndex={setActiveIndicatorIndex}
          images={getImages()}
          imagesPerSlide={imagesPerSlide}
          carouselImages={carouselImages}
          headlineImageHeight={headlineImageHeight}
          headlineImageWidth={headlineImageWidth}
          tenant={regionCode}
          productName={name}
          brandName={brandName}
          isRebrandingEnabled={appCtx.isRebrandingEnabled}
          isConnect={isConnect}
          isDottedImageCarouselEnabled={isDottedImageCarouselEnabled}
        />
      )}
      <style jsx>{ImageGalleryGlobalStyles}</style>
      <style jsx>{ImageGalleryStyles}</style>
    </div>
  );
};

ImageGallery.defaultProps = {
  carouselImageHeight: 100,
  carouselImageWidth: 100,
  headlineImageHeight: 800,
  headlineImageWidth: 800,
  desktopImagesPerSlide: 5,
  zoomSize: { x: 1500, y: 1500 },
  imageQuality: 70,
  imagesPerSlide: 5,
  crop: undefined,
  isConnect: false
};

ImageGallery.propTypes = {
  product: PropTypes.object.isRequired,
  carouselImageHeight: PropTypes.number,
  carouselImageWidth: PropTypes.number,
  desktopImagesPerSlide: PropTypes.number,
  zoomSize: PropTypes.object,
  imageQuality: PropTypes.number,
  headlineImageWidth: PropTypes.number,
  headlineImageHeight: PropTypes.number,
  imagesPerSlide: PropTypes.number,
  appCtx: PropTypes.object.isRequired,
  crop: PropTypes.object,
  isConnect: PropTypes.bool,
  digitalData: PropTypes.object.isRequired
};

export default withProduct(
  withApplicationContext(withDigitalData(ImageGallery))
);
export { ImageGallery };
