import React from 'react';
import PropTypes from 'prop-types';
import CardStatusStyles from './CardStatus.style';

const CardStatus = ({ status, message }) => (
  <div className={`cmrButton border-${status}`}>
    <i className={`csicon-alert cmrButtonAlertIcon apertura-icon-${status}`} />
    <div className="apertura-content">{message}</div>
    <style jsx>{CardStatusStyles}</style>
  </div>
);

CardStatus.propTypes = {
  status: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

export default CardStatus;
