import css from 'styled-jsx/css';

const DemoUpButtonContainerStyles = css`
  .demo-up-wrapper {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
    @mixin mobileToTablet {
      padding-top: 12px;
    }
  }
`;

export default DemoUpButtonContainerStyles;
