import React, { useState } from 'react';
import PropTypes from 'prop-types';
import WithTogggle from '../../../../utils/WithToggle';
import { SnackbarWithTimeout } from '../../../ui/Snackbar/Snackbar';

const MobileAlert = ({ content, toggleState, type, page }) => {
  const { setVisible } = toggleState;
  useState(() => {
    setVisible(true);
  }, []);

  return (
    <SnackbarWithTimeout
      dismissOnOutsideClick
      toggleState={toggleState}
      content={content}
      type={type}
      page={page}
    />
  );
};

MobileAlert.propTypes = {
  toggleState: PropTypes.object,
  content: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['success', 'warning']),
  page: PropTypes.string
};
MobileAlert.defaultProps = {
  toggleState: null,
  type: 'success',
  page: ''
};

export default WithTogggle(MobileAlert);
