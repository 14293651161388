import css from 'styled-jsx/css';
import { colors } from '../../../config/styles';
import { svgToDataUri, svgReplaceColor } from '../../../utils/styles/svgMixins';
import icons from '../../../config/styles/icons';

const SoftLineDesktopStyles = css`
  .pdp-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    padding-bottom: 20px;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .icon {
    min-width: 34px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: block;
    &.internet-icon-disabled {
      background-image: url("${svgToDataUri(
        svgReplaceColor(icons.onlyInternet, colors.gray.shade40)
      )}");
      height: 30px;
      }
    &.internet-icon-active {
      background-image: url("${svgToDataUri(
        svgReplaceColor(icons.onlyInternet, colors.green.shade1)
      )}");
      height: 30px;
      }
    &.tienda-active {
      background-image: url("${svgToDataUri(
        svgReplaceColor(icons.tiendaIcon, colors.green.shade1)
      )}");
      height: 22px;
    }
    &.tienda-disabled {
      background-image: url("${svgToDataUri(
        svgReplaceColor(icons.tiendaIcon, colors.gray.shade40)
      )}");
      height: 22px;
    }
  }
  .pdp-body {
    &-container {
      background-color: white;
    }
  }

  .pdp-image-section {
    width: 61%;
    padding: 15px 40px;
  }

  .pdp-details-section {
    display: flex;
    width: 39%;
    flex-direction: column;
    padding-right: 40px;
    border-left: 1px solid ${colors.gray.shade3};

    &-header {
      border-bottom: 1px solid ${colors.gray.shade3};
      padding: 0 0 13px 50px;
    }

    &-body {
      padding-left: 50px;
    }
  }

  .basic-details-Mobile {
    display: flex;
    flex-direction: column;
    .reviews {
      display: flex;
      margin-top: 9px;
      &-column {
        position: relative;
        width: auto;
        display: flex;
      }
    }
    > div {
      margin-top: 15px;
      border: 1px solid red;
    }
    .corner-details {
      display: flex;
      justify-content: space-between;
    }
  }
  hr.divider-line {
    box-sizing: border-box;
    height: 1px;
    width: 100%;
    border: 1px solid ${colors.gray.shade3};
    transform: scaleY(-1);
  }
  .pdp-container.out-of-stock {
    .pdp-image-section {
      width: auto;
      padding: 12px 44px 22px 37px; 
    }
    .pdp-details-section {
      width: auto;
      &-header {
        border-bottom: none;
      }
    }
  }
`;

export default SoftLineDesktopStyles;
