import css from 'styled-jsx/css';

const RatingAndReviewsStyles = css`
  .rr-wrapper {
    display: flex;
    align-items: center;
    .rr-rating-stars {
      cursor: pointer;
    }
    .rr-label-to-comment {
      margin-left: 7px;
    }
  }
`;

export default RatingAndReviewsStyles;
