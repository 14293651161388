import { useContext } from 'react';
import { RouterContext } from '../containers/Layout/LayoutContext';

const WithRouter = (queryParam) => {
  const context = useContext(RouterContext.Context);
  if (!context || !context.router) {
    return '';
  }
  const { query } = context.router;
  return query[queryParam] || '';
};

export default WithRouter;
