import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MobileAlert from './Mobile/MobileAlert';
import { withMyLists } from '../../../utils/MyListsContext';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import DesktopAlert from './Desktop/DesktopAlert';
import style from './MyListAlert.style';
import { withLabels } from '../../../utils/LabelsContext';
import { buildHrefURL } from '../../../utils/urlHelper';

const MyListsAlert = ({ deviceType, myLists, labels, page, appCtx }) => {
  const { addedLists = [], undo, alertType } = myLists || {};
  const urlMyLists = buildHrefURL('myaccount/myLists', appCtx);
  const l = {
    savedChanges: labels.MY_LISTS_SAVED_CHANGES || 'Se guardaron los cambios.',
    undo: labels.MY_LISTS_UNDO || 'Deshacer',
    checkList: labels.MY_LISTS_CHECK_LISTS || 'Revisar listas',
    hasBeenAdded: labels.MY_LISTS_ADDED_LIST_MESSAGE || 'Se agregó a:'
  };
  const Content = () => {
    return (
      <div>
        {alertType === 'warning' ? (
          <div className="my-lists-alert warning">
            {l.savedChanges}{' '}
            {undo && (
              <button type="button" className="link" onClick={undo}>
                {l.undo}
              </button>
            )}
          </div>
        ) : (
          <div className="my-lists-alert">
            {l.hasBeenAdded}{' '}
            {addedLists.map((e, i, a) => (
              <b key={e}>
                {e}
                {i !== a.length - 1 && ', '}
              </b>
            ))}
            .{' '}
            <a className="link" href={urlMyLists}>
              {l.checkList}
            </a>
          </div>
        )}
        <style jsx>{style}</style>
      </div>
    );
  };
  return (
    addedLists.length > 0 && (
      <Fragment>
        {deviceType !== 'desktop' && (
          <MobileAlert content={Content} type={alertType} page={page} />
        )}
        {deviceType === 'desktop' && (
          <DesktopAlert content={Content} type={alertType} />
        )}
      </Fragment>
    )
  );
};

MyListsAlert.propTypes = {
  myLists: PropTypes.object.isRequired,
  deviceType: PropTypes.string.isRequired,
  page: PropTypes.string,
  appCtx: PropTypes.object.isRequired
};
MyListsAlert.defaultProps = {
  page: ''
};
export { MyListsAlert };
export default withLabels(withMyLists(withApplicationContext(MyListsAlert)));
