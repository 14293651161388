import React from 'react';
import PropTypes from 'prop-types';
import badgesStyles from './Badges.style';
import stickersStyles from './Stickers.style';
import BodyCopy from '../ui/BodyCopy/BodyCopy';

const Badges = ({
  badgeItems,
  deviceType,
  layout,
  isSticker,
  promotionBadge
}) => {
  const getBadgeSize = () => {
    if (deviceType === 'mobile') {
      return layout.indexOf('PDP') !== -1 ? 'copy4' : 'copy7';
    }
    return 'copy8';
  };
  return (
    <div
      className={`pod-badges pod-badges-${layout}${
        isSticker ? ' sticker-group' : ''
      }`}
    >
      {badgeItems &&
        badgeItems.map((badge) => (
          <BodyCopy size={getBadgeSize()} key={badge.label} isHighlighted>
            {(classes) => (
              <span
                id={`testId-Pod-badges-${badge.label}`}
                className={`${classes} pod-badges-item-${layout} pod-badges-item`}
                style={{
                  backgroundColor: `${badge.styles.backgroundColor}`,
                  color: `${badge.styles.textColor}`
                }}
              >
                {badge.label}
              </span>
            )}
          </BodyCopy>
        ))}
      {promotionBadge && (
        <BodyCopy
          size={getBadgeSize()}
          key={promotionBadge.displayName}
          isHighlighted
        >
          {(classes) => (
            <span
              className={`${classes} pod-badges-item-${layout} pod-badges-item`}
              style={{
                backgroundColor: `${promotionBadge.bgColor}`,
                color: `${promotionBadge.textColor}`
              }}
            >
              {promotionBadge.displayName}
            </span>
          )}
        </BodyCopy>
      )}
      <style jsx>{badgesStyles}</style>
      <style jsx>{stickersStyles}</style>
    </div>
  );
};

Badges.propTypes = {
  badgeItems: PropTypes.array,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
  layout: PropTypes.string,
  isSticker: PropTypes.bool,
  promotionBadge: PropTypes.object
};

Badges.defaultProps = {
  badgeItems: [],
  layout: '',
  isSticker: false,
  promotionBadge: undefined
};

export default Badges;
