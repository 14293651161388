/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import CartItem from './CartItem/CartItem';
import BodyCopy from '../ui/BodyCopy/BodyCopy';
import { getMatchingVariants } from '../../utils/variant';
import TypeCProduct from '../TypeCProduct/TypeCProduct';
import _ from '../../utils/LodashImports';
import getImages from '../../utils/GetImages';

const mapItem = (item, regionCode, isCFMediasEnabled) => {
  const {
    brand,
    displayName,
    id,
    prices,
    mediaUrls,
    sellerName
  } = item.product;

  const imageUrl = getImages({
    variantId: id,
    url: _.get(mediaUrls, '[0]', ''),
    sellerName,
    regionCode,
    isCFMediasEnabled
  });

  return {
    brandName: brand,
    name: displayName,
    skuId: id,
    image: imageUrl,
    productId: '',
    price: prices,
    totalQuantity: 0
  };
};

const mapBundledItem = ({
  bundleItems,
  setBusy,
  textDictionary,
  removeProduct,
  setWarrantyOption,
  addProduct,
  limitAlerts,
  isCFMediasEnabled,
  regionCode
}) => (item) => {
  let isItemInCart = false;
  let bundleItem;

  bundleItems.forEach((bundle) => {
    const itemId = _.get(item, 'product.id', _.get(item, 'id', ''));
    if (!isItemInCart && bundle.skuId === itemId) {
      isItemInCart = true;
      bundleItem = bundle;
    }
  });

  return (
    <CartItem
      key={item.skuId || item.id}
      setBusy={setBusy}
      item={
        isItemInCart ? bundleItem : mapItem(item, regionCode, isCFMediasEnabled)
      }
      textDictionary={textDictionary}
      onRemoveFromCart={removeProduct}
      showWarrantyOption={false}
      showAssemblyOption={false}
      setWarrantyOption={setWarrantyOption}
      onAddToCart={addProduct}
      type="bundle"
      isAtgPrice={!isItemInCart}
      limitAlerts={limitAlerts}
    />
  );
};

const renderAddedBundle = ({
  labels,
  CartAddedBundleStyles,
  renderDescription,
  renderBrandName,
  bundleItems,
  isRebrandingEnabled,
  isCFMediasEnabled,
  regionCode
}) => {
  return (
    <Fragment>
      <div className="added-bundles">
        <div className={`header${isRebrandingEnabled ? ' mkp' : ''}`}>
          <i className="csicon-icon-success-circle">
            <span className="sr-only">{labels.ITEM_ADDED_A11Y_LABEL}</span>
          </i>
          <span className="label">
            {bundleItems.length} {labels.ITEM_ADDED_IN_CART}
          </span>
        </div>

        <div className="bundle-items">
          {bundleItems.map(
            ({ brandName, name, skuId, sellerName, mediaUrls }) => {
              const imageUrl = getImages({
                variantId: skuId,
                url: _.get(mediaUrls, '[0]', ''),
                isCFMediasEnabled,
                regionCode,
                sellerName
              });
              return (
                <div className="bundle-item">
                  <img src={imageUrl} width="50" height="50" alt={name} />
                  <div className="item-info">
                    {renderBrandName(brandName)}
                    {renderDescription(name)}
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
      <style jsx>{CartAddedBundleStyles}</style>
    </Fragment>
  );
};

const renderBundleItem = ({
  bundledProductOrigin,
  bundles,
  labels,
  CartAddedBundleStyles,
  bundleItems,
  setBusy,
  textDictionary,
  removeProduct,
  setWarrantyOption,
  addProduct,
  renderDescription,
  renderBrandName,
  isTypeA,
  limitAlerts,
  isRebrandingEnabled,
  isCFMediasEnabled,
  regionCode
}) => {
  if (isTypeA || !bundledProductOrigin) {
    return <Fragment />;
  }

  if (bundledProductOrigin === 'CART') {
    return renderAddedBundle({
      labels,
      bundles,
      CartAddedBundleStyles,
      renderDescription,
      renderBrandName,
      bundleItems,
      isRebrandingEnabled,
      isCFMediasEnabled,
      regionCode
    });
  }

  const renderBundledItem = mapBundledItem({
    bundleItems,
    setBusy,
    textDictionary,
    removeProduct,
    setWarrantyOption,
    addProduct,
    limitAlerts,
    isCFMediasEnabled,
    regionCode
  });
  return bundles.map(renderBundledItem);
};

const getTypeCollectionFromCart = (collection, variantId) => {
  for (let i = 0; i < collection.length; i += 1) {
    if (
      collection[i].skuId === variantId ||
      collection[i].productId === variantId
    ) {
      return collection[i];
    }
  }
  return null;
};

const isCollectionInMainSection = (cartItems, variantId) => {
  for (let i = 0; i < cartItems.length; i += 1) {
    if (cartItems[i].skuId === variantId) {
      return true;
    }
  }
  return false;
};

const isAvailableInCart = (variants, collections) => {
  const cartCollection = variants.filter(({ id: variantId }) => {
    return collections.find(
      (collection) =>
        collection.skuId === variantId || collection.productId === variantId
    );
  });
  return cartCollection.length > 0;
};

const isAddedAsMainProduct = (variantID, cartItems) => {
  return cartItems.find((item) => item.productId === variantID) && true;
};

const renderTypeCCollection = ({
  cartItems,
  collection,
  variants,
  setBusy,
  textDictionary,
  removeProduct,
  addProduct,
  isBundlesAvailable,
  isAtgPrice,
  limitAlerts,
  isRebrandingEnabled
}) => {
  return variants.map(({ id: pId, variants: innerVariants }) => {
    const idsArr = innerVariants.map((v) => v.id);

    const items = collection
      .filter((col) => {
        if (!col) return false;
        let isItemInCart = false;
        cartItems.forEach((item) => {
          if (col.skuId === item.skuId) {
            isItemInCart = true;
          }
        });
        return !isItemInCart;
      })
      .map((col) => {
        if (idsArr.includes(col.skuId)) {
          return (
            <CartItem
              key={col.skuId}
              setBusy={setBusy}
              item={col}
              textDictionary={textDictionary}
              onRemoveFromCart={removeProduct}
              showWarrantyOption={false}
              showAssemblyOption={false}
              onAddToCart={addProduct}
              isAtgPrice={isAtgPrice}
              limitAlerts={limitAlerts}
              isRebrandingEnabled={isRebrandingEnabled}
            />
          );
        }
        return null;
      })
      .filter(Boolean);

    let isvariantInMainProduct = false;

    cartItems.forEach((item) => {
      if (idsArr.includes(item.skuId)) {
        isvariantInMainProduct = true;
      }
    });

    if (items.length) {
      return <Fragment>{items}</Fragment>;
    }

    if (isvariantInMainProduct) {
      return null;
    }

    const item = getTypeCollectionFromCart(collection, pId);

    const handleTypeCProduct = () =>
      isBundlesAvailable ? (
        <Fragment />
      ) : (
        <TypeCProduct
          productId={pId}
          key={pId}
          isSoftline={false}
          variant="cart"
          setBusy={setBusy}
          isRebrandingEnabled={isRebrandingEnabled}
        />
      );

    return item ? (
      <CartItem
        key={pId}
        setBusy={setBusy}
        item={item}
        textDictionary={textDictionary}
        onRemoveFromCart={removeProduct}
        showWarrantyOption={false}
        showAssemblyOption={false}
        onAddToCart={addProduct}
        isAtgPrice={isAtgPrice}
        limitAlerts={limitAlerts}
        isRebrandingEnabled={isRebrandingEnabled}
      />
    ) : (
      handleTypeCProduct()
    );
  });
};

const handletypeCTitle = (
  variants,
  cartItems,
  collection,
  isBundlesAvailable
) => {
  if (variants.length === 1) {
    return !isAddedAsMainProduct(variants[0].id, cartItems);
  }

  if (variants.length > 1 && isAvailableInCart(variants, collection)) {
    return true;
  }

  if (variants.length > 1 && isBundlesAvailable) {
    return false;
  }
  return true;
};

const renderCollections = ({
  labels,
  variants,
  colorCode,
  brandName,
  imageBaseURL,
  productId,
  setBusy,
  textDictionary,
  removeProduct,
  addProduct,
  cartItems,
  collection,
  isTypeC,
  isBundlesAvailable,
  isAtgPrice,
  limitAlerts,
  isRebrandingEnabled
}) => {
  if (isTypeC) {
    const shoudShowTitle = handletypeCTitle(
      variants,
      cartItems,
      collection,
      isBundlesAvailable
    );

    return (
      <Fragment>
        {shoudShowTitle && (
          <BodyCopy size="title14">
            {labels.MORE_PRODUCTS_FROM_COLLECTION}
          </BodyCopy>
        )}
        {renderTypeCCollection({
          cartItems,
          collection,
          variants,
          setBusy,
          textDictionary,
          removeProduct,
          addProduct,
          isBundlesAvailable,
          isAtgPrice,
          limitAlerts,
          isRebrandingEnabled
        })}
      </Fragment>
    );
  }

  const variantsToRender = getMatchingVariants(variants, {
    colorCode
  }).filter((variant) => {
    const { id: skuId } = variant;

    return !isCollectionInMainSection(cartItems, skuId);
  });

  return (
    variantsToRender &&
    variantsToRender.length !== 0 && (
      <Fragment>
        <BodyCopy size="title14">
          {labels.MORE_PRODUCTS_FROM_COLLECTION}
        </BodyCopy>
        {variantsToRender.map((variant) => {
          const { name, id: skuId } = variant;

          const collectionItem = getTypeCollectionFromCart(collection, skuId);

          const item = {
            brandName,
            name,
            skuId,
            image: `${imageBaseURL}/${skuId}`,
            productId,
            price: variant.prices,
            totalQuantity: _.get(collectionItem, 'totalQuantity', -1),
            commerceItemId: _.get(collectionItem, 'commerceItemId', 0),
            addedQuantity: _.get(collectionItem, 'addedQuantity', 0),
            cartLineId: _.get(collectionItem, 'cartLineId', ''),
            offeringId: _.get(variant, 'offerings[0].offeringId', '')
          };

          return (
            <CartItem
              key={skuId}
              setBusy={setBusy}
              item={item}
              textDictionary={textDictionary}
              onRemoveFromCart={removeProduct}
              showWarrantyOption={false}
              showAssemblyOption={false}
              onAddToCart={addProduct}
              isAtgPrice={false}
              limitAlerts={limitAlerts}
            />
          );
        })}
      </Fragment>
    )
  );
};

const getDefaultSelectedIndex = (options = []) => {
  for (let i = 0; i < options.length; i += 1) {
    if (options[i].selected) {
      return i;
    }
  }
  return -1;
};

const showWarningMessage = (stockUnits, cart) => {
  const {
    cartItems = [],
    bundleItems = [],
    typeCCollections = [],
    typeACollections = []
  } = cart;

  const products = [
    ...cartItems,
    ...bundleItems,
    ...typeACollections,
    ...typeCCollections
  ];

  return !!products
    .filter((p) => p)
    .find((p) => {
      if (
        p.skuId &&
        typeof stockUnits[p.skuId] !== 'undefined' &&
        stockUnits[p.skuId] <= 3
      ) {
        return true;
      }
      return false;
    });
};

export {
  renderCollections,
  renderBundleItem,
  getDefaultSelectedIndex,
  showWarningMessage
};
