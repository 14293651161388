import css from 'styled-jsx/css';
import { typography, colors } from '../../config/styles';
import _ from '../../utils/LodashImports';

const OperatorStyles = (theme) => {
  const secondaryColor =
    _.get(theme, 'secondary_color', null) || colors.secondary_color;
  return css`
    .container {
      display: flex;
      flex-direction: column;

      @mixin mobileToTablet {
        border-top: 1px solid #f8f8f8;
        padding-top: 10px;

        &.mkp {
          border-top: none;
        }
      }
    }

    .operator-container {
      margin-bottom: 18px;
    }

    .selection-message {
      font-size: ${typography.lg1};
      font-weight: 600;
      margin: 2px 0 10px 0;

      &.with-margin {
        margin: 10px 0 10px 0;
      }

      @mixin tabletToDesktop {
        font-size: ${typography.base};
      }
    }
    i.rebrand-alert {
      color: ${colors.gray.shade45};

      @mixin mobileToTablet {
        transform: rotate(180deg);
        padding-right: 0;
        padding-left: 12px;
      }
    }
    .csicon-alert {
      color: ${colors.green.shade1};
      padding-right: 12px;
      font-size: ${typography.xl};

      @mixin tabletToDesktop {
        padding-right: 8px;
        font-size: ${typography.lg1};
      }
    }

    .price-alert {
      height: 55px;
      display: flex;
      align-items: center;
      background: ${colors.gray.shade41};
      font-size: ${typography.base};
      padding: 15px;

      @mixin tabletToDesktop {
        height: 38px;
        padding: 8px;
        font-size: ${typography.xs2};
      }
    }

    .operators {
      display: flex;
      flex-wrap: wrap;

      &.with-margin {
        margin-top: 20px;
      }
    }

    .operator {
      height: 41px;
      width: 154px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 20px 15px 0;
      font-size: ${typography.base};
      font-family: inherit;
      border-radius: 3px;
      color: ${colors.black.shade7};
      background: ${colors.gray.shade12};
      border: 1px solid ${colors.gray.shade36};
      font-weight: 700;

      @mixin tabletToDesktop {
        height: 35px;
        width: 122px;
        margin: 0 12px 10px 0;
      }
      @mixin smallMobileOnly {
        height: 40px;
        width: 142px;
      }

      &-unavailable {
        position: relative;
        color: ${colors.gray.shade33};
        border: 1px solid ${colors.gray.shade36};
        background-color: inherit;
        background: linear-gradient(
          to bottom right,
          #fff calc(50% - 1px),
          ${colors.gray.shade36},
          #fff calc(50% + 1px)
        );
      }
    }

    .selected-operator {
      background: ${colors.green.shade16};
      color: ${colors.green.shade10};
      border: 1px solid ${colors.green.shade12};
      &.mkp {
        background: ${colors.white.shade1};
        color: ${colors.black.shade1};
        border: 2px solid ${secondaryColor};
      }
    }
    .rebranding-connect {
      font-size: ${typography.sm1};
      text-transform: uppercase;
      @mixin mobileToTablet {
        font-size: ${typography.base};
      }
    }
  `;
};

export { OperatorStyles };
