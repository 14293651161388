const getResult = (m2Value, m2AttributeValue, isExtraM2Checked) => {
  const parsedM2Value = parseFloat(
    Number(m2Value.toString().replace(',', '.'))
  );
  const validatedM2Value = Number(parsedM2Value);
  if (!Number.isNaN(validatedM2Value)) {
    const result = parseFloat(validatedM2Value) / m2AttributeValue;
    const finalResult = isExtraM2Checked
      ? Math.ceil(result + 0.1 * result)
      : Math.ceil(result);
    return finalResult;
  }
  return 0;
};

const formatM2Value = (m2Value) => {
  const re1 = /^\d+,$/;
  const re2 = /^,(\d)+$/;
  if (re1.test(m2Value)) {
    return m2Value.toString().replace(',', '');
  }
  if (re2.test(m2Value)) {
    return `0${m2Value}`;
  }
  return m2Value;
};

export { getResult, formatM2Value };
