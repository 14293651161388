import React from 'react';
import PropTypes from 'prop-types';
import { TableStyles } from './Table.style';

const Table = ({ mainInfoRow, attrRowsData, deviceType }) => {
  const { attributes } = attrRowsData;

  if (deviceType === 'mobile') {
    return (
      <table className="mobile-table">
        <tbody>
          <tr>
            {mainInfoRow.map((product) => (
              <td>{product}</td>
            ))}
          </tr>
          {attributes.map((attr) => (
            <>
              <tr className="attr-title-row">
                <p className="attr-title">{attr.name}</p>
              </tr>
              <tr className="attributes-row">
                {attr.data.map((text) => (
                  <td className="attr-value">{text || '-'}</td>
                ))}
              </tr>
            </>
          ))}
        </tbody>
        <style jsx>{TableStyles}</style>
      </table>
    );
  }

  return (
    <table className="desktop-table">
      <tbody>
        <tr>
          <td className="first-col" />
          {mainInfoRow.map((product) => (
            <td>{product}</td>
          ))}
        </tr>
        {attributes.map((attr) => (
          <tr className="attributes-row">
            <td className="first-col attr-title">{attr.name}</td>
            {attr.data.map((text) => (
              <td className="attr-value">{text || '-'}</td>
            ))}
          </tr>
        ))}
      </tbody>
      <style jsx>{TableStyles}</style>
    </table>
  );
};

Table.defaultProps = {
  mainInfoRow: [],
  deviceType: 'desktop',
  attrRowsData: {}
};

Table.propTypes = {
  mainInfoRow: PropTypes.array,
  deviceType: PropTypes.string,
  attrRowsData: PropTypes.object
};

export default Table;
