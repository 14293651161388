import React from 'react';
import PropTypes from 'prop-types';
import AddtionalInformationCard from '../../AdditionalInformationCard/AdditionaInformationCard';
import SpecificationTable from '../../SpecificationTable/SpecificationTable';
import ComboSpecifications from '../../ComboSection/ComboSpecifications';
import ExpandableSwatch from '../../ExpandableSwatch/ExpandableSwatch';
import { withLabels } from '../../../utils/LabelsContext';
import { withProduct } from '../../../utils/ProductContext';
import { AdditionalInformationDesktopCss } from './AdditionalInformationDesktop.styles';
import ProductInfo from '../ProductInfo/ProductInfo';
import { getVariant } from '../../../utils/variant';
import _ from '../../../utils/LodashImports';

const AdditionalInformationDesktop = ({
  specifications,
  description,
  labels,
  isRebrandingEnabled,
  product,
  appCtx
}) => {
  const headerType = isRebrandingEnabled
    ? 'mkp-headerContainer'
    : 'headerContainer';
  const swatchType = isRebrandingEnabled ? 'mkp-swatchButton' : 'swatchButton';
  const { currentVariant, variants } = product;

  const comboData = _.get(
    getVariant(variants, currentVariant),
    'comboData',
    null
  );
  const isSoCom = appCtx.store === 'so_com';
  const specificationsTitle = isSoCom
    ? _.get(labels, 'SO_COM_SPECIFICATIONS_LABEL', 'Ficha técnica')
    : labels.SPECIFICATIONS_LABEL;
  const productInformationLabel = isSoCom
    ? _.get(labels, 'SO_COM_PRODUCT_INFORMATION_LABEL', 'Descripción')
    : labels.PRODUCT_INFORMATION_LABEL;

  const showSpecifications =
    comboData?.hasSpecifications || specifications.length > 0;

  return (
    <ExpandableSwatch height={700} buttonType={swatchType}>
      <div className="productInfoContainer" id="productInfoContainer">
        {showSpecifications && (
          <div
            className={`specification ${
              description ? 'specification-2-column' : 'specification-1-column'
            }`}
          >
            <AddtionalInformationCard
              headerText={specificationsTitle}
              headerType={headerType}
            >
              {comboData?.hasSpecifications ? (
                <ComboSpecifications comboItems={comboData.comboItems} />
              ) : (
                <SpecificationTable specifications={specifications} />
              )}
            </AddtionalInformationCard>
          </div>
        )}
        {description && (
          <div
            className={`description ${
              showSpecifications
                ? 'description-2-column'
                : 'description-1-column'
            }`}
          >
            <AddtionalInformationCard
              headerText={productInformationLabel}
              headerType={headerType}
            >
              <ProductInfo description={description} />
            </AddtionalInformationCard>
          </div>
        )}
        <style jsx>{AdditionalInformationDesktopCss}</style>
      </div>
    </ExpandableSwatch>
  );
};

AdditionalInformationDesktop.defaultProps = {
  description: undefined,
  isRebrandingEnabled: false
};

AdditionalInformationDesktop.propTypes = {
  specifications: PropTypes.array.isRequired,
  description: PropTypes.string,
  labels: PropTypes.object.isRequired,
  isRebrandingEnabled: PropTypes.bool,
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired
};

export { AdditionalInformationDesktop };

export default withProduct(withLabels(AdditionalInformationDesktop));
