import getNextConfig from 'next/config';
import _ from './LodashImports';

const nextConfig = getNextConfig();
const { ENVIRONMENT } = (nextConfig && nextConfig.publicRuntimeConfig) || {
  ENVIRONMENT: 'development'
};

const loadConfig = (name) => {
  try {
    // eslint-disable-next-line
    return require(`../config/url/${name}.config`);
  } catch (e) {
    return {};
  }
};

const getConfig = () => {
  const envConfig = loadConfig(ENVIRONMENT);
  const defaultConfig = loadConfig('default');
  return _.merge(defaultConfig, envConfig);
};

const getCurrentValue = (values, name) => {
  if (!values || typeof values === 'string') {
    return values;
  }
  return values[name.toUpperCase()] || '';
};

const getQueryParams = (params) => {
  if (typeof params === 'object') {
    return Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
  }
  return '';
};

const getCustomParams = (value, params) => {
  let newValue = value;
  if (typeof params === 'object') {
    Object.keys(params).forEach((key) => {
      newValue = newValue.replace(`{${key}}`, params[key]);
    });
  }
  return newValue;
};

const replaceVariables = (value, region, config, options = {}, store) => {
  const regionComplete = store ? `${region}_${store}` : region;
  const host = getCurrentValue(config.HOST, region);
  const envHost = getCurrentValue(config.ENV_HOST, region);
  const fullHost = getCurrentValue(config.FULL_HOST, regionComplete);
  return getCustomParams(value, options.custom)
    .replace('{host}', host)
    .replace('{env_host}', envHost)
    .replace('{full_host}', fullHost)
    .replace('{query}', getQueryParams(options.query))
    .replace('{region}', region);
};

const getValue = (name, region = 'cl', options, store) => {
  const config = getConfig();
  const values = config[name];
  if (values) {
    const currentValue = getCurrentValue(values, region);
    return replaceVariables(currentValue, region, config, options, store);
  }
  return '';
};

const WithUrl = (name, param2 = 'cl', param3, store) => {
  if (typeof param2 === 'object') {
    return getValue(name, 'cl', param2);
  }
  return getValue(name, param2, param3, store);
};

export default WithUrl;
