/* stylelint-disable no-descending-specificity */
import css from 'styled-jsx/css';
import { svgToDataUri } from '../../../utils/styles/svgMixins';
import { icons, colors } from '../../../config/styles';
import _ from '../../../utils/LodashImports';

export const StoresListStyles = (theme) => {
  const secondaryColor =
    _.get(theme, 'secondary_color', null) || colors.secondary_color;

  return css`
    .red {
      color: ${colors.red.shade4};
    }
    .bold {
      font-weight: bold;
    }
    .container {
      display: flex;
      justify-content: space-between;
    }
    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .change-location {
      cursor: pointer;
      line-height: 19.2px;
      @mixin mobileToTablet {
        line-height: 16.8px;
      }
    }
    .list-section {
      width: 49.5%;
      &.max-width {
        width: 100%;
        .geofinder-container {
          margin-right: 0;
        }
      }
      @mixin mobileToTablet {
        width: 100%;
        height: 100%;
      }
    }
    .map-section {
      height: 419px;
      width: 371px;
      margin-left: auto;
      background-color: #F2F2F2;
    }

    .geofinder-container {
      display: flex;
      background: #F2F2F2;
      padding: 16px;
      border-radius: 4.5px;
      justify-content: space-between;
      margin-bottom: 16px;
      @mixin tabletToDesktop {
        margin-right: 24px;
      }
      &.out-of-stock {
        background: #F3D8DA;
      }
    }
    .list-header {
      p {
          line-height: 2.4rem;
          color: ${colors.black.shade1};
          font-size: 1.6rem;
          margin-bottom: 12px;
          font-weight: 700;
          @mixin mobileToTablet {
            line-height: 19.2px;
          }

          &.out-of-stock {
            font-weight: normal;
          }
      }
    }
      
    .list-container {
        overflow-y: auto;
        padding-right: 43px;
        @mixin tabletToDesktop {
          max-height: 338px;
        }
        @mixin mobileToTablet {
          padding-right: 0;
          height: calc(100vh - 264px);
          padding-bottom: 16px;
        }
      }
    .store-icon {
        width: 30px;
        height: 48px;
        margin-right: 16px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        &.falabella {
          background-image: url('/a/fa/product/static/styles/svg/falabellaStoreIcon.svg');
        }
        &.tottus {
          background-image: url('/a/fa/product/static/styles/svg/tottus.svg');
        }
        &.sodimac {
          background-image: url('/a/fa/product/static/styles/svg/sodimac.svg');
        }
        &.linio {
          background-image: url('/a/fa/product/static/styles/svg/linio.svg');
        }
    }
    .store-list-item-wrapper {
      display: flex;
        padding: 12px 0;
        border-right: 6px solid #E6E6E6;
        border-bottom: 1px solid #E6E6E6;
        cursor: pointer;
        @mixin mobileToTablet {
          border-right: none;
          padding: 16px 0;
        }
        &:last-child {
          border-bottom: none;
        }
        &.selected {
          border-right: 6px solid #495867;
        }
        &:first-child {
          border-top: 1px solid #E6E6E6;
        }
    }
    .capitalize {
      text-transform: capitalize;
    }
    .ellipsis {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .region-section {
      font-size: 16px;
      line-height: 19.2px;
      @mixin mobileToTablet {
        font-size: 14px;
        line-height: 16.8px;
      }
    }
    .store-name {
      color: #4A4A4A;
        font-size: 1.6rem;
        line-height: 1.8rem;
        font-weight: 700;
        margin-bottom: 3px;
        @mixin mobileToTablet {
          font-size: 1.5rem;
        }
    }
    .store-item-details {
      max-width: calc(100% - 80px);
      @mixin mobileToTablet {
        max-width: calc(100% - 45px);
      }
    }
    .floating-details {
      position: relative;
      width: 50.5%;
    }
    .store-location {
      display: flex;
      p {
        font-size: 1.3rem; 
        line-height: 2rem;
        color: #4A4A4A;
        white-space: nowrap;
        text-overflow: ellipsis;
        align-items: center;
        margin-left: 6px;
        @mixin mobileToTablet {
          font-size: 1.4rem;
        }
        span {
          border-bottom: 1px solid #4A4A4A;
        }
      }
      .csicon-right {
        font-size: 7px;
        height: fit-content;
        align-self: center;
        margin-left: 8.33px;
      }
    }
    i.location-icon {
      background-image: url("${svgToDataUri(icons.locationSmall)}");
      padding:8px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      @mixin mobileToTablet {
        width: 14.65px;
        height: 18.5px;
      }
      &.small {
        padding: 6px;
      }
    }
    i.edit-icon {
      background-image: url("${svgToDataUri(icons.editBrush)}");
      padding: 6px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 11.71px;
      height: 11.7px;
      align-self: center;
    }
    .change-region-button {
      margin-left: 6px;
      text-decoration: underline;
      @mixin tabletToDesktop {
        font-size: 16px;
      }
    }
    .selected-comuna {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      .region-name {
        font-weight: 700;
      }
      p {
        margin-left: 10.56px;
        max-width: 85%;
      }
    }
    .flex {
      display: flex;
    }
    .store-availability {
        font-size: 1.4rem;
        line-height: 1.68rem;
        margin-top: 5px;
        font-weight: 700;
        padding-left: 18px;
        position: relative;
        @mixin mobileToTablet {
          line-height: 18px;
          font-size: 1.5rem;
        }
        &.available {
          color: #30871F;
          &::before {
            background: #30871F;
          }
        }
        &.not-available {
          color: #CC4E00;
          &::before {
            background: #CC4E00;
          }
        }
        &::before {
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 50%;
          position: absolute;
          left: 3px;
          top: 5px;
        }
    }
    .footer-text {
      letter-spacing: 0.2px;
      @mixin tabletToDesktop {
        font-size: 13px;
        line-height: 15.6px;
      }
      @mixin mobileToTablet {
        line-height: 16.8px;
        letter-spacing: 0.2px;
      }
    }
    .alert-icon {
      background-image: url("${svgToDataUri(icons.alertIcon)}");
      padding:8px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 6px;
      @mixin tabletToDesktop {
        width: 16px;
        height: 15px;
      }
      @mixin mobileToTablet {
        margin-top: 2px;
        margin-right: 8px;
        align-self: self-start;
      }
    }
    .footer-with-stock {
      display: flex;
    }
    .footer-out-of-stock {
      display: flex;
      justify-content: center;
      width: 100%;
      .cancel {
        font-weight: 400;
        cursor: pointer;
        font-family: Lato, sans-serif;
        border-radius: 0;
        text-transform: none;
        color: #333;
        font-size: 14px;
        line-height: 16.8px;
        -webkit-text-decoration: underline;
        text-decoration: underline;
        margin-right: 14.5px;
        display: inline-block;
        margin-top: 5px;
      }
      .check {
        -webkit-letter-spacing: 0.5px;
        -moz-letter-spacing: 0.5px;
        -ms-letter-spacing: 0.5px;
        letter-spacing: 0.5px;
        background: ${secondaryColor};
        font-weight: 400;
        border-radius: 20px;
        width: 220px;
        height: 40px;
        font-size: 19px;
        line-height: 23px;
        color: white;
        font-family: Lato, sans-serif;    
      }
    }
    `;
};
