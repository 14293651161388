import css from 'styled-jsx/css';
import { typography } from '../../../config/styles';

const TypeAVariantMobileStyles = css`
  .typeAVariant {
    display: flex;
    flex-direction: column;

    &-info {
      display: flex;

      .availability-options {
        width: 100px;
        margin-right: 32px;
        @mixin smallMobileOnly {
          margin-right: 48px;
        }
      }
      .addToCart {
        margin-top: 20px;
        width: 100%;
        @mixin smallMobileOnly {
          margin-top: 0;
        }
      }
    }

    .addToCart-button {
      margin-top: 25px;
      &.mkp {
        width: 199px;
        margin-right: 0;
        margin-left: auto;
      }
      &-text {
        font-size: ${typography.sm1};
        letter-spacing: 1.08px;
        line-height: 16px;
        text-transform: uppercase;
        font-weight: bold;
        padding: 0 12px;
        &.mkp {
          font-size: ${typography.lg1};
          font-weight: 400;
          letter-spacing: 0.5px;
          line-height: 19px;
          text-transform: inherit;
          padding: 0;
        }
        &.mkp::first-letter {
          text-transform: uppercase;
        }
      }
    }
    &.mkp {
      .typeAVariant-info {
        @mixin smallerMobile {
          margin-left: -23px;
        }
      }
      .addToCart {
        @mixin smallerMobile {
          margin-left: -23px;
        }
      }
    }
  }
`;

export { TypeAVariantMobileStyles };
