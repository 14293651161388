import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';

const StepperStyles = css`
  .product-count {
    display: flex;
    position: relative;

    &.primary {
      width: 88px;
      height: 30px;
    }

    &.secondary {
      width: 166px;
      height: 48px;
      border: 2px solid #f0f0f0;
      border-radius: 4px;
    }

    &.tertiary {
      width: 110.67px;
      height: 32px;
      border: 2px solid #f0f0f0;
      border-radius: 4px;
    }

    &-value {
      margin: auto;
      width: 100%;
      display: flex;
      justify-content: center;
      padding-bottom: 7px;

      &.value-primary {
        border-bottom: 1.3px solid ${colors.gray.shade5};
        color: ${colors.gray.shade5};
        font-size: ${typography.base};
        line-height: 17px;
      }
      &.value-secondary {
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: ${colors.black.shade10};
        padding-bottom: 0;
      }

      & > input {
        display: flex;
        width: calc(100% - 46px * 2);
        height: 100%;
        justify-content: center;
        align-items: center;
        font-size: ${typography.lg1};
        font-weight: 700;
        color: #333;
        line-height: 19px;
        text-align: center;

        &::-webkit-inner-spin-button,
        ::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &:focus {
          outline: none;
        }
      }
    }
    &.value-tertiary {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: ${colors.black.shade10};
      padding-bottom: 0;
      & > input {
        display: flex;
        width: calc(100% - 30.67px * 2);
        height: 100%;
        justify-content: center;
        align-items: center;
        font-size: ${typography.base};
        font-weight: 700;
        color: #333;
        line-height: 17px;
        text-align: center;

        &::-webkit-inner-spin-button,
        ::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &:focus {
          outline: none;
        }
      }
    }

    .increment,
    .decrement {
      position: absolute;

      &.btn-primary {
        font-size: ${typography.lg1};
        bottom: 10px;
        color: ${colors.black.shade1};
      }
      &.btn-secondary {
        width: 46px;
        height: 44px;
        background: #f0f0f0;
        border-radius: 2px 0 0 2px;
        color: #495867;
        text-align: center;
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: bold;
      }
      &.btn-tertiary {
        width: 30.67px;
        height: 29.33px;
        background: #f0f0f0;
        border-radius: 2px 0 0 2px;
        color: #495867;
        text-align: center;
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: bold;
      }
    }

    .increment {
      &.btn-primary {
        right: 4px;
      }
      &.btn-secondary {
        right: 0;
        font-size: 22px;
      }
      &.btn-tertiary {
        right: 0;
        font-size: 22px;
      }
    }
    .decrement {
      &.btn-primary {
        left: 4px;
      }
      &.btn-secondary {
        left: 0;
        font-size: 22px;
      }
      &.btn-tertiary {
        left: 0;
        font-size: 22px;
      }
    }

    .increment:disabled,
    .decrement:disabled {
      color: ${colors.gray.shade5};
    }
  }
`;

export { StepperStyles };
