import css from 'styled-jsx/css';

const StockQuantityStyles = css`
  .stock-quantity {
    margin: -8px 0;
    border-top: 1px solid #f8f8f8;
    padding: 7px 0 21px;

    @mixin phabletToDesktop {
      padding: 11px 0 39px;
      margin: -15px 0;
    }

    span {
      font-weight: 700;
    }
  }
`;

export { StockQuantityStyles };
