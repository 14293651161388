import css from 'styled-jsx/css';
import colors from '../../config/styles/colors';
import { typography } from '../../config/styles';

const SpecificationTableStyle = css`
  .specification-table {
    width: 100%;
    font-family: inherit;
    font-size: ${typography.base};
  }
  .specification-table.secondary {
    font-size: ${typography.lg};
  }
  .specification-table td {
    padding: 21px;
  }

  .property-name {
    width: 43%;
    font-weight: 600;
  }

  .property-value {
    width: 57%;
  }
  .specification-table tr:nth-child(2n + 1) {
    background-color: ${colors.gray.shade3};
  }
  .specification-table.secondary tr {
    background: none;
    border-bottom: 1px solid ${colors.gray.shade2};

    td:nth-child(odd) {
      padding: 20px 0 20px 16px;
    }
    td:nth-child(even) {
      padding: 20px 16px 20px 16px;
    }
  }
`;

export { SpecificationTableStyle };
