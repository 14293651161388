import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withProduct } from '../../utils/ProductContext';
import { withApplicationContext } from '../../utils/ApplicationContext';
import BodyCopy from '../ui/BodyCopy/BodyCopy';
import { withLabels } from '../../utils/LabelsContext';
import { StockQuantityStyles } from './StockQuantity.styles';
import _ from '../../utils/LodashImports';

const StockQuantity = ({ product, appCtx, labels }) => {
  const getStockUnit = () => {
    const qty = product.stockUnits[product.currentVariant];
    return qty > 10 ? '10+' : qty;
  };
  return (
    <Fragment>
      {_.get(appCtx, 'siteConfig.toggles.isStockQuantityVisibleInPDP') && (
        <BodyCopy size={appCtx.deviceType === 'desktop' ? 'copy5' : 'copy3'}>
          {(cls) => (
            <p className={`${cls} stock-quantity`}>
              {labels.UNITS_AVAILABLE_LABEL}: <span>{getStockUnit()}</span>
              <style jsx>{StockQuantityStyles}</style>
            </p>
          )}
        </BodyCopy>
      )}
    </Fragment>
  );
};

StockQuantity.propTypes = {
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired
};

export default withLabels(withApplicationContext(withProduct(StockQuantity)));
export { StockQuantity };
