/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './NutritionalDetails.style';
import _ from '../../utils/LodashImports';
import { svgUrl } from '../../utils/staticUrls';
import { withLabels } from '../../utils/LabelsContext';

const NutrinalDescription = ({
  description,
  ingredients,
  certificates,
  labels
}) => {
  const descriptionLabel = _.get(labels, 'DESCRIPTION', 'Descripción');
  const ingredientsLabel = _.get(labels, 'INGREDIENTS', 'Ingredientes');
  const certificatesLabel = _.get(labels, 'CERTIFICATES', 'Certificados');
  return (
    <React.Fragment>
      <div className="nutrition-description-container">
        <div className="title-wrapper">
          <span>{descriptionLabel}</span>
        </div>
        {!!description.length && (
          <p className="description">{description.join(', ')}</p>
        )}
        {!!ingredients.length && (
          <div className="ingredients">
            <span className="ingredients-title">{ingredientsLabel}</span>
            <p className="ingredients-value">{ingredients.join(', ')}</p>
          </div>
        )}
        {!!certificates.length && (
          <div className="certificates">
            <span>{certificatesLabel}</span>
            <div className="certificates-icon-section">
              {certificates.map((ctft = '') => {
                const [code, iconName] = ctft.split('|');
                return (
                  <div className="certificate-icon-wrapper">
                    <div
                      style={{
                        backgroundImage: `url('${svgUrl(code)}.svg')`
                      }}
                      className="certificate-icon"
                    />
                    <p>{iconName}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <style jsx>{styles}</style>
    </React.Fragment>
  );
};
NutrinalDescription.propTypes = {
  description: PropTypes.array,
  ingredients: PropTypes.array,
  certificates: PropTypes.array,
  labels: PropTypes.object.isRequired
};
NutrinalDescription.defaultProps = {
  description: [],
  certificates: [],
  ingredients: []
};
const NutritionalInformation = ({ warning, tableData, labels, portion }) => {
  const nutrionalInfoLabel = _.get(
    labels,
    'NUTRITIONAL_INFO',
    'Información nutricional'
  );
  const [
    portionText,
    portionValue,
    basic_unit,
    portion_unit,
    numberOfPortionsLabel,
    numberOfPortions,
    portionLabel
  ] = _.get(portion, '[0]', '').split('|');
  const onePortionLabel = _.get(labels, 'ONE_PORTION', '1 Portion');
  const importantLabel = _.get(labels, 'IMPORTANT_LABEL', 'Importante: ');
  const importantLabelText = _.get(
    labels,
    'IMPORTANT_LABEL_TEXT',
    `La información del empaque y/o contenido del producto final puede
  diferir respecto a lo que mostramos en nuestro sitio web, tanto a
  nivel de ingredientes, trazas, información nutricional, sellos, modo
  de uso y/o modo de conservación. Usted no debe basarse únicamente en
  la información que se muestra en nuestro sitio web y siempre debe leer
  las etiquetas, advertencias e instrucciones antes de usar o consumir
  un producto.`
  );
  const okToShopLabel = _.get(
    labels,
    'OK_TO_SHOP_LABEL',
    'Ficha actualizada el 26.10.2021 por'
  );
  return (
    <React.Fragment>
      <div className="nutrition-information-container">
        <div className="title-wrapper">
          <span>{nutrionalInfoLabel}</span>
        </div>
        <div className="warning">
          {!!warning.length &&
            warning.map((w) => (
              <div
                className="certificate-icon"
                style={{ backgroundImage: `url('${svgUrl(w)}.svg')` }}
              />
            ))}
        </div>
        <div className="nutrition-table">
          {!!portion.length && (
            <div className="portion-section">
              <div>
                <b>{portionLabel}: </b>
                <p>{` ${portionText} ${
                  portion_unit ? `(${portion_unit})` : ''
                }`}</p>
              </div>
              <div>
                <b>{numberOfPortionsLabel}: </b>
                <p>{numberOfPortions}</p>
              </div>
            </div>
          )}
          {!!tableData.length && (
            <table>
              <tr className="table-header">
                <th />
                <th>{`${basic_unit ? `100 ${basic_unit}` : ''}`}</th>
                <th>{onePortionLabel}</th>
              </tr>
              {tableData.map((rowValues) => {
                const [
                  rowName,
                  rowPortionValue,
                  perPortionValue,
                  rowUnit,
                  subGroupOf
                ] = rowValues.split('|');
                return (
                  <tr>
                    <td
                      className={`${
                        subGroupOf ? 'nutrition-sub-name' : 'nutrition-name'
                      }`}
                    >{`${rowName} ${rowUnit ? `(${rowUnit})` : ''}`}</td>
                    <td>{rowPortionValue}</td>
                    <td>{perPortionValue}</td>
                  </tr>
                );
              })}
            </table>
          )}
        </div>
        <div className="important-text">
          <b>{importantLabel}</b>
          {importantLabelText}
          <div className="updated-date">
            {okToShopLabel} <div className="ok-to-shop-icon" />
          </div>
        </div>
      </div>
      <style jsx>{styles}</style>
    </React.Fragment>
  );
};
NutritionalInformation.propTypes = {
  warning: PropTypes.array,
  tableData: PropTypes.array,
  portion: PropTypes.array,
  labels: PropTypes.object.isRequired
};
NutritionalInformation.defaultProps = {
  warning: [],
  portion: [],
  tableData: []
};
const NutritionalDetails = ({ nutritionalSpefications, labels }) => {
  return (
    <React.Fragment>
      <div id="testId-nutrition-container" className="nutrition-container">
        <NutrinalDescription
          description={_.get(nutritionalSpefications, 'description', [])}
          ingredients={_.get(nutritionalSpefications, 'ingredients', [])}
          certificates={_.get(nutritionalSpefications, 'certificates', [])}
          labels={labels}
        />
        <NutritionalInformation
          warning={_.get(nutritionalSpefications, 'warnings', [])}
          tableData={_.get(nutritionalSpefications, 'nutritionValues', [])}
          portion={_.get(nutritionalSpefications, 'portion', [])}
          labels={labels}
        />
      </div>
      <style jsx>{styles}</style>
    </React.Fragment>
  );
};

NutritionalDetails.propTypes = {
  nutritionalSpefications: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired
};

export { NutritionalDetails };
export default withLabels(NutritionalDetails);
