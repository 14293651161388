import css from 'styled-jsx/css';
import { colors } from '../../config/styles';

const ReviewSectionMobileStyles = css`
  .review-section-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding-bottom: 20px;
    background-color: ${colors.white.shade1};
  }
  .review-section-heading {
    color: ${colors.black.shade1};
    font-size: 1.6rem;
    font-weight: 700;
    padding: 16px 16px 12px 16px;
    border-bottom: 1px solid ${colors.gray.shade6};
  }
  .review-section-top-container {
    margin-top: 4px;
    margin-bottom: 16px;
  }
  .give-rating-container {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
  .top-photos-pdp {
    margin-bottom: 16px;
  }
`;

const ReviewSectionDesktopStyles = css`
  .review-section-container {
    position: relative;
    margin-top: 20px;
    max-width: 100%;
    background-color: ${colors.white.shade1};
    padding: 16px 50px 16px 50px;
  }
  .review-section-heading {
    color: ${colors.black.shade1};
    font-size: 1.8rem;
    font-weight: 400;
    padding-top: 16px;
    padding-bottom: 12px;
    border-bottom: 2px solid ${colors.gray.shade45};
    margin-bottom: 24px;
  }
  .give-rating-container {
    @mixin tabletOnly {
      display: flex;
      justify-content: center;
      margin: 16px 0;
    }
    @mixin desktop {
      cursor: pointer;
      position: absolute;
      top: 97px;
      right: 2.75%;
    }
  }
  .top-photos-pdp {
    margin: 16px 0 8px 0;
  }
`;

export { ReviewSectionMobileStyles, ReviewSectionDesktopStyles };
