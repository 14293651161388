import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../ui/Button/Button';
import noop from '../../../utils/noop';
import VariantHeader from '../../VariantHeader/VariantHeader';
import Stepper from '../../ui/Stepper/Stepper';
import VariantAvailabilityOptions from '../../VariantAvailabilityOptions/VariantAvailabilityOptions';
import { TypeAVariantDesktopStyles } from './TypeAVariantDesktop.style';
import { withLabels } from '../../../utils/LabelsContext';
import Warning from '../../Warning/Warning';

const TypeAVariantDesktop = ({
  brandName,
  variantName,
  prices,
  addToCart,
  variantId,
  labels,
  count,
  incrementCount,
  decrementCount,
  maxForStepper,
  showAlert,
  isHomeDeliveryAvailable,
  isPickFromStoreAvailable,
  isRebrandingEnabled
}) => {
  const buttonType = isRebrandingEnabled ? 'mkp-secondary' : 'secondary';
  return (
    <div className={`typeAVariant${isRebrandingEnabled ? ' mkp' : ''}`}>
      <VariantHeader
        brandName={brandName}
        variantName={variantName}
        prices={prices}
        variantId={variantId}
      />
      <div className="typeAVariant-count">
        <Stepper
          count={count}
          onIncrement={incrementCount}
          onDecrement={decrementCount}
          minimum={0}
          maximum={maxForStepper}
        />
      </div>
      <div className="addToCart-button-wrapper">
        {!showAlert ? (
          <React.Fragment>
            <Button
              size={isRebrandingEnabled ? 'medium-extend' : 'medium'}
              type={buttonType}
              onClick={addToCart}
            >
              <span
                className={`addToCart-button-text${
                  isRebrandingEnabled ? ' mkp' : ''
                }`}
              >
                {labels.ADD_TO_BAG}
              </span>
            </Button>
            <VariantAvailabilityOptions
              deviceType="desktop"
              isHomeDeliveryAvailable={isHomeDeliveryAvailable}
              isPickFromStoreAvailable={isPickFromStoreAvailable}
              isRebrandingEnabled={isRebrandingEnabled}
            />
          </React.Fragment>
        ) : (
          <Warning
            label={labels.MAXIMUM_QUANTITY_LIMIT_LABEL}
            iconSize="copy2"
            labelSize="copy1"
          />
        )}
      </div>
      <style jsx>{TypeAVariantDesktopStyles}</style>
    </div>
  );
};

TypeAVariantDesktop.defaultProps = {
  brandName: '',
  prices: [],
  addToCart: noop,
  isHomeDeliveryAvailable: false,
  isPickFromStoreAvailable: false,
  showAlert: false,
  isRebrandingEnabled: false
};

TypeAVariantDesktop.propTypes = {
  brandName: PropTypes.string,
  variantName: PropTypes.string.isRequired,
  variantId: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  incrementCount: PropTypes.func.isRequired,
  decrementCount: PropTypes.func.isRequired,
  prices: PropTypes.arrayOf(PropTypes.object),
  addToCart: PropTypes.func,
  maxForStepper: PropTypes.number.isRequired,
  showAlert: PropTypes.bool,
  isHomeDeliveryAvailable: PropTypes.bool,
  isPickFromStoreAvailable: PropTypes.bool,
  isRebrandingEnabled: PropTypes.bool
};

export default withLabels(TypeAVariantDesktop);
