import css from 'styled-jsx/css';
import { colors } from '../../../../../config/styles';

const PDPBasicInfoMobileStyle = css`
  .basic-details-Mobile {
    display: flex;
    flex-direction: column;
    .corner-details {
      display: flex;
      justify-content: space-between;
    }
    .reviews {
      display: flex;
      margin-top: 9px;
      &-column {
        position: relative;
        width: auto;
        display: flex;
        margin-top: 6.5px;
        &-hidden {
          display: none;
        }
      }
    }
    .variant-seller-ids {
      display: flex;
      align-items: center;
      margin-top: 15.5px;
    }
    .vertical-separator {
      width: 0;
      height: 18px;
      border-left: 1px solid ${colors.gray.shade14};
      margin: 0 4px;
    }
  }
`;

export default PDPBasicInfoMobileStyle;
