import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import { DropdownStyles } from './Dropdown.style';
import BodyCopy from '../BodyCopy/BodyCopy';
import WithTogggle from '../../../utils/WithToggle';
import MediaComponent from '../../Media/MediaComponent';
import WithOutsideClick from '../../../utils/WithOutsideClick';
import noop from '../../../utils/noop';

const Dropdown = ({
  label,
  options,
  toggleState,
  onOptionSelected,
  selectedOption,
  variant,
  showDesktopViewInMobile,
  isRebrandingEnabled,
  theme
}) => {
  const themedStyles = DropdownStyles(theme);
  const { visible, setVisible } = toggleState;
  const toggleView = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    setVisible(false);
  }, [options]);

  if (!selectedOption)
    // eslint-disable-next-line no-param-reassign
    selectedOption =
      options.filter((option) => option.selected).length > 0
        ? options.filter((option) => option.selected)[0]
        : options[0];

  const listItemActiveClass = isRebrandingEnabled
    ? 'dropdown-list-item-mkp-active'
    : 'dropdown-list-item-active';

  const renderDropdown = () => {
    return (
      <div className="dropdown-select">
        <button
          type="button"
          id="testId-Dropdown-desktop-button"
          data-order="catalyst-orderBy"
          onClick={toggleView}
        >
          <BodyCopy
            size={variant === 'CART' ? 'copy5' : 'copy3'}
            isHighlighted={variant !== 'CART'}
          >
            {selectedOption.label}
          </BodyCopy>
          <i
            className={`icon ${
              visible ? 'csicon-arrow_up' : 'csicon-arrow_down'
            }`}
          />
        </button>
        <style jsx>{themedStyles}</style>
      </div>
    );
  };

  return (
    <WithOutsideClick
      onOutsideClick={() => {
        setVisible(false);
      }}
    >
      <div className={`dropdown-wrapper ${variant}`}>
        <div className="dropdown-header">
          {showDesktopViewInMobile ? (
            renderDropdown()
          ) : (
            <Fragment>
              <MediaComponent from="mobile" to="phablet">
                <button
                  className="btn-with-icon"
                  id="testId-Dropdown-mobile-button"
                  data-order="catalyst-orderBy"
                  onClick={toggleView}
                  type="button"
                >
                  <i className="csicon-icon-order" />
                  <span>{label}</span>
                </button>
              </MediaComponent>
              <MediaComponent from="tablet" to="desktop">
                {label && (
                  <div>
                    <BodyCopy size="copy4" type="tertiary">
                      {label}:
                    </BodyCopy>
                  </div>
                )}
              </MediaComponent>
              <MediaComponent from="tablet" to="desktop">
                {renderDropdown()}
              </MediaComponent>
            </Fragment>
          )}
        </div>

        {visible && (
          <ul className="dropdown-list">
            {options.map((option, index) => (
              <li
                key={option.label}
                className={
                  option.label === selectedOption.label
                    ? listItemActiveClass
                    : 'dropdown-list-item'
                }
              >
                <button
                  id={`testId-Dropdown-${option.label}`}
                  type="button"
                  className="dropdown-link"
                  onClick={() => onOptionSelected(option, index)}
                >
                  {option.label}
                </button>
              </li>
            ))}
          </ul>
        )}

        <style jsx>{themedStyles}</style>
      </div>
    </WithOutsideClick>
  );
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
  toggleState: PropTypes.object,
  onOptionSelected: PropTypes.func,
  selectedOption: PropTypes.any,
  variant: PropTypes.string,
  showDesktopViewInMobile: PropTypes.bool,
  isRebrandingEnabled: PropTypes.bool,
  theme: PropTypes.object.isRequired
};

Dropdown.defaultProps = {
  toggleState: null,
  onOptionSelected: noop,
  selectedOption: null,
  variant: '',
  showDesktopViewInMobile: false,
  isRebrandingEnabled: false
};

export default withTheme(WithTogggle(Dropdown));
