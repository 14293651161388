import css from 'styled-jsx/css';
import { colors, typography, zIndex } from '../../../config/styles';

const DRAWER_WIDTH = 760;

const DrawerStyles = css`
  .drawer-container {
    width: 100%;
    position: fixed;
    background-color: ${colors.white.shade1};
    right: 0;
    top: 0;
    z-index: ${zIndex.drawer.container};
    height: 100%;
    transform: translateX(100%);
    transition: transform 0.2s ease;
    @mixin tabletToDesktop {
      transform: translateX(${DRAWER_WIDTH}px);
      width: ${DRAWER_WIDTH}px;
    }
  }

  .drawer-container.is-open {
    transform: translateX(0);
  }
  .drawer-container.secondary {
    transform: translateY(100%);
    border-radius: 15px;
    overflow-y: scroll;
  }
  .drawer-container.secondary.is-open {
    transform: translateY(80px);
  }
  .drawer-header {
    background-color: ${colors.black.shade1};
    font-size: ${typography.lg2};
    color: ${colors.white.shade1};
    padding: 27px 0;
    text-align: center;
    font-weight: bold;
    position: relative;
    display: block;
    height: 68px;
    width: 100%;
    font-family: Roboto, sans-serif;
    font-style: normal;
    letter-spacing: -0.03em;
    line-height: 20px;
    i {
      position: absolute;
      right: 25px;
      cursor: pointer;
    }
  }
  .drawer-header.secondary {
    background: none;
    color: ${colors.black.shade12};
    text-align: left;
    letter-spacing: 0;
    font-family: inherit;
    font-size: 18px;
    font-weight: normal;
    line-height: 24px;
    padding: 12px 20px 12px 20px;
    height: fit-content;
    position: sticky;
    top: 0;
    background-color: ${colors.white.shade1};
    border-bottom: 1px solid #e0e0e0;
    z-index: 1;

    i {
      position: absolute;
      right: 12px;
      top: 14px;
      cursor: pointer;
      font-size: 16px;
    }
    .drawer-line {
      width: 40px;
      height: 5px;
      border-radius: 5px;
      background: #a9b5c0;
      display: flex;
      align-self: center;
      margin: 0 auto 19px auto;
    }
    .sub-heading {
      padding-top: 12px;
      color: ${colors.gray.shade45};
      font-size: 1.8rem;
      font-weight: 300;
      line-height: 21.6px;
      letter-spacing: -0.3px;
    }
  }
  .drawer-layout {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    min-height: 100%;
    width: 100%;
  }

  .drawer-content {
    width: 100%;
  }
  .drawer-content.secondary {
    padding-bottom: 30%;
  }
  .drawer-container :global(iframe) {
    height: 100vh;
  }

  .drawer-container :global(.doc-click-overlay) {
    height: 100%;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    @mixin phabletToDesktop {
      position: inherit;
      align-items: inherit;
      justify-content: inherit;
    }
  }
`;

const DrawerGlobalStyles = css.global`
    body.drawer-active{
        overflow: hidden;
        width:100%;
    }

    body.drawer-active .background-overlay{
      opacity:1;
      background-color: rgba(0, 0, 0, 0.5);
    }

    body.drawer-active .loader{
        width: 100%;
        left:auto;
        @mixin tabletToDesktop {
           width:${DRAWER_WIDTH}px;
        }
    }

`;

export { DrawerStyles, DrawerGlobalStyles };
