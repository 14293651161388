import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withLabels } from '../../../utils/LabelsContext';
import { AvailabilityDesktopStyles } from './AvailabilityDesktop.style';
import { withApplicationContext } from '../../../utils/ApplicationContext';

const AvailabilityDesktopDownloadable = ({ labels, appCtx }) => {
  const { isRebrandingEnabled } = appCtx;
  return (
    <Fragment>
      <div
        className={`availability-item ${isRebrandingEnabled && 'rebranded'}`}
      >
        <div className="icon downloadAvailable" />
        <div className="content">
          <div className="content-sub">
            <div id="testId-Availability-Desktop-ModalOpen-downloadAvailable">
              <span className="underline">
                {labels.AVAILABILITY_IMMEDIATE_DELIVERY}{' '}
              </span>
            </div>
            <span className="bold">{`(${
              labels.AVAILABILITY_DOWNLOADABLE
            })`}</span>
          </div>
        </div>
      </div>
      <style jsx>{AvailabilityDesktopStyles}</style>
    </Fragment>
  );
};

AvailabilityDesktopDownloadable.propTypes = {
  labels: PropTypes.object,
  appCtx: PropTypes.object.isRequired
};

AvailabilityDesktopDownloadable.defaultProps = {
  labels: {}
};

export { AvailabilityDesktopDownloadable };
export default withLabels(
  withApplicationContext(AvailabilityDesktopDownloadable)
);
