import React from 'react';
import PropTypes from 'prop-types';
import RatingsVisual from '../RatingsVisual/RatingsVisual';
import ratingsNumericStyle from './RatingsNumeric.style';

const RatingsNumeric = ({ maximum, value }) => {
  return (
    <div className="ratingsNumeric fa--ratings">
      <RatingsVisual maximum={maximum} value={value} />
      <div className="count">
        <span className="actualRatings">{value}</span>
        <span className="maximumRatings">/ {maximum}</span>
      </div>
      <style jsx>{ratingsNumericStyle}</style>
    </div>
  );
};

RatingsNumeric.defaultProps = {
  maximum: 5,
  value: 0
};
RatingsNumeric.propTypes = {
  maximum: PropTypes.number,
  value: PropTypes.number
};

export default RatingsNumeric;
