import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import RatingsNumeric from '../ui/RatingsNumeric/RatingsNumeric';
import Reviews from '../ui/Reviews/Reviews';
import {
  ProductNameStyle,
  ShareIconStyle,
  ReviewAndRatingsStyle,
  BrandNameStyle,
  VariantIdStyle
} from './index.style';
import ShareButton from '../ShareButton/ShareButton';
import apiConfig from '../../config/api/apiConfig';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withProduct } from '../../utils/ProductContext';
import { withConfig } from '../../utils/ConfigurationContext';
import _ from '../../utils/LodashImports';

const ProductName = withProduct(({ productName, product }) => {
  const { isOutOfStock } = product;
  return (
    <h1
      data-name={productName}
      className={`product-name fa--product-name ${isOutOfStock &&
        'out-of-stock'}`}
    >
      {productName}
      <style jsx>{ProductNameStyle}</style>
    </h1>
  );
});

ProductName.propTypes = {
  productName: PropTypes.string.isRequired
};

const BrandName = withProduct(
  withApplicationContext(
    withConfig(({ brandName, appCtx, product, config = {} }) => {
      const { regionCode, store } = appCtx;
      const env = (config && config.ENVIRONMENT) || 'production';
      const { isOutOfStock } = product;

      return (
        <div
          data-brand={brandName}
          className={`product-brand fa--brand ${isOutOfStock &&
            'out-of-stock'}`}
        >
          <a
            id="pdp-product-brand-link"
            className="product-brand-link"
            href={apiConfig.getBrandURL({
              brandName: brandName.toUpperCase(),
              regionCode,
              env,
              store
            })}
          >
            {brandName}
          </a>
          <style jsx>{BrandNameStyle}</style>
        </div>
      );
    })
  )
);

BrandName.propTypes = {
  brandName: PropTypes.string.isRequired,
  appCtx: PropTypes.string.isRequired
};

const VariantId = ({ variantId, labels, isSoCom = false }) => {
  const productCodeText = isSoCom
    ? _.get(labels, 'SO_COM_PRODUCT_CODE', 'Cód. del producto')
    : labels.PRODUCT_CODE;
  return (
    <Fragment>
      <div data-id={variantId} className="variant-id fa--variant-id">
        {' '}
        <span>{`${productCodeText}: ${variantId}`}</span>
      </div>
      <style jsx>{VariantIdStyle}</style>
    </Fragment>
  );
};
const SellerSkuId = ({ sellerSkuId, labels }) => {
  const label = labels.SELLER_SKU_ID || 'Cód. tienda';
  return (
    <Fragment>
      <div data-id={sellerSkuId} className="variant-id fa--variant-id">
        {' '}
        <span>{`${label}: ${sellerSkuId}`}</span>
      </div>
      <style jsx>{VariantIdStyle}</style>
    </Fragment>
  );
};

VariantId.propTypes = {
  variantId: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  isSoCom: PropTypes.bool.isRequired
};

SellerSkuId.propTypes = {
  sellerSkuId: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired
};

const ReviewsAndRatings = ({ totalReviews, ratings, labels }) => {
  return (
    <Fragment>
      <RatingsNumeric maximum={5} value={ratings} />
      <div className="more-comments-scroll">
        <Reviews value={totalReviews} layout="PDP" />
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" className="pod-link">
          {labels.SEE_COMMENTS}
        </a>
      </div>
      <style jsx>{ReviewAndRatingsStyle}</style>
    </Fragment>
  );
};

ReviewsAndRatings.propTypes = {
  totalReviews: PropTypes.number.isRequired,
  ratings: PropTypes.number.isRequired,
  labels: PropTypes.object.isRequired
};

const ProductShareIcon = ({ isRebrandingEnabled }) => {
  return (
    <ShareButton>
      <div
        className={`product-share-icon ${isRebrandingEnabled && 'rebranded'}`}
      />
      <style jsx>{ShareIconStyle}</style>
    </ShareButton>
  );
};

ProductShareIcon.defaultProps = {
  isRebrandingEnabled: false
};

ProductShareIcon.propTypes = {
  isRebrandingEnabled: PropTypes.bool
};

export {
  ProductName,
  BrandName,
  VariantId,
  ReviewsAndRatings,
  ProductShareIcon,
  SellerSkuId
};
