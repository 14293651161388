import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withLabels } from '../../../utils/LabelsContext';
import Heading from '../../ui/Heading/Heading';
import { ModalContentCss } from '../ModalContent/ModalContent.style';
import { ModalHeaderStyle } from './AvailabilityModal.style';
import PickupInStoreModal from './PickupInStoreModal';
import HomeDeliveryModal from './HomeDeliveryModal';
import ShippingLocationSelection from './ShippingLocationSelection';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import noop from '../../../utils/noop';
import BodyCopy from '../../ui/BodyCopy/BodyCopy';
import { getVariant } from '../../../utils/variant';
import { withProduct } from '../../../utils/ProductContext';

const ModalHeader = ({ title, logo, isRebrandingEnabled }) => {
  return (
    <div className={`container ${isRebrandingEnabled && 'rebranded'}`}>
      <div className={`logo ${logo}`} />
      <Heading type="secondary" variation="title10">
        {title}
      </Heading>
      <style jsx>{ModalHeaderStyle}</style>
    </div>
  );
};

ModalHeader.defaultProps = {
  isRebrandingEnabled: false
};

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  isRebrandingEnabled: PropTypes.bool
};

const getConfig = (type, labels) => {
  if (type === 'HomeDelivery') {
    return {
      logo: 'deliveryLogo',
      title: labels.HOME_DELI_TITLE,
      startingText: labels.HOME_DELI_RESULT_WILL_COME_HERE
    };
  }

  return {
    logo: 'pickupInStoreLogo',
    title: labels.PICKUP_INSTORE_TITLE,
    startingText: labels.PICKUP_INSTORE_RESULT_WILL_COME_HERE
  };
};

const AvailabilityModal = ({
  type,
  actions,
  labels,
  appCtx,
  variant,
  onUpdate,
  product: { variants }
}) => {
  const [error, setError] = useState('');
  const config = getConfig(type, labels);
  const [selection, setSelection] = useState();
  const { offerings = [] } = getVariant(variants || [], variant);
  const offeringId =
    offerings.length > 0 && offerings[0].offeringId
      ? offerings[0].offeringId
      : variant;
  const { regionCode, isRebrandingEnabled } = appCtx;

  useEffect(() => {
    actions.setCustomHeader(
      <ModalHeader
        logo={config.logo}
        title={config.title}
        isRebrandingEnabled={isRebrandingEnabled}
      />
    );
  }, []);

  const handleChange = (userSelection) => {
    setSelection(userSelection);
  };

  const handleOnClose = () => {
    actions.close();
    onUpdate();
  };

  return (
    <div className="container">
      <ShippingLocationSelection
        onChange={handleChange}
        setLoading={actions.setBusy}
        type={type}
        setError={setError}
      />

      <div className="resultContainer">
        {selection && (
          <Fragment>
            {type === 'HomeDelivery' ? (
              <HomeDeliveryModal
                onClose={handleOnClose}
                regionCode={regionCode}
                selection={selection}
                setLoading={actions.setBusy}
                setError={setError}
                error={error}
                offeringId={offeringId}
              />
            ) : (
              <PickupInStoreModal
                onClose={handleOnClose}
                regionCode={regionCode}
                selection={selection}
                setLoading={actions.setBusy}
                setError={setError}
                offeringId={offeringId}
              />
            )}
          </Fragment>
        )}

        {error && (
          <div className="resultActive hasBottomOption">
            <div className="heading">
              <BodyCopy>{error}</BodyCopy>
            </div>
          </div>
        )}

        {!selection && (
          <div className="resultInActive">
            <div className={`logo ${config.logo}`} />
            <div>
              <h2>{config.startingText}</h2>
            </div>
          </div>
        )}
      </div>

      <style jsx>{ModalContentCss}</style>
    </div>
  );
};

AvailabilityModal.propTypes = {
  type: PropTypes.string.isRequired,
  actions: PropTypes.object,
  labels: PropTypes.object,
  appCtx: PropTypes.object,
  variant: PropTypes.string,
  onUpdate: PropTypes.func,
  product: PropTypes.object
};

AvailabilityModal.defaultProps = {
  actions: { setBusy: noop, setCustomHeader: noop, close: noop },
  labels: {},
  appCtx: {},
  variant: '',
  onUpdate: noop,
  product: {}
};

export { AvailabilityModal, ModalHeader };
export default withProduct(
  withApplicationContext(withLabels(AvailabilityModal))
);
