import css from 'styled-jsx/css';
import { colors } from '../../config/styles';
import _ from '../../utils/LodashImports';

const surveyBarStyles = (theme) => {
  const secondaryColor =
    _.get(theme, 'secondary_color', null) || colors.secondary_color;
  return css`
    .survey-wrapper {
      border-bottom: 2px solid ${secondaryColor};
      @mixin tabletToDesktop {
        display: flex;
        flex-direction: row;
      }
    }

    .with-border {
      border-top: 5px solid ${colors.gray.shade6};
      border-bottom: 5px solid ${colors.gray.shade6};
    }
  `;
};
export default surveyBarStyles;
