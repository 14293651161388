import css from 'styled-jsx/css';
import { colors } from '../../../../../config/styles';

const PDPBasicInfoMobileStyleHardline = css`
  .basic-details-Mobile {
    display: flex;
    flex-direction: column;
    margin-bottom: 13px;
    &.out-of-stock {
      margin-bottom: 4px;
    }
    .corner-details {
      display: flex;
      justify-content: space-between;
    }
    .variant-seller-ids {
      display: flex;
      align-items: center;
      margin-top: 15.5px;
    }
    .vertical-separator {
      width: 0;
      height: 18px;
      border-left: 1px solid ${colors.gray.shade14};
      margin: 0 4px;
    }
  }
  .reviews {
    display: flex;
    margin-top: 9px;
    &-column {
      position: relative;
      width: auto;
      display: flex;
      margin-top: 6.5px;
      &-hidden {
        display: none;
      }
    }
  }
  .badges {
    position: relative;
    height: 5px;
  }
`;

export default PDPBasicInfoMobileStyleHardline;
