import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import SurveyBox from './SurveyBox/SurveyBox';
import surveyBarStyles from './SurveyBar.style';
import BodyCopy from '../ui/BodyCopy/BodyCopy';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withLabels } from '../../utils/LabelsContext';
import _ from '../../utils/LodashImports';
import { isWindow } from '../../utils/deviceChecker';

const SurveyBar = ({ appCtx, labels, withBorder, theme }) => {
  const themedStyles = surveyBarStyles(theme);
  const [sdkAvailable, setSdkAvailability] = useState(false);
  const [clienteID, setClienteID] = useState('');

  const intervalRef = useRef();
  const digitalDataRef = useRef();

  const abortTheInterval = () => clearInterval(intervalRef.current);
  const abortDigitalDataInterval = () => clearInterval(digitalDataRef.current);

  const { deviceType } = appCtx;
  const { SURVEY_TEXT_HELP_US } = labels;

  const SurveyContent = () => (
    <BodyCopy size={deviceType === 'desktop' ? 'copy5' : 'copy3'} isHighlighted>
      {SURVEY_TEXT_HELP_US}
    </BodyCopy>
  );

  const abortAllTimers = () => {
    abortTheInterval();
    abortDigitalDataInterval();
  };

  const abortTimeIntervalSearch = () => {
    setTimeout(() => {
      abortAllTimers();
    }, 10000);
  };

  const openSurvey = () => window.KAMPYLE_ONSITE_SDK.showForm(2958);

  const checkSurveyScripts = () => {
    if (isWindow() && window.KAMPYLE_ONSITE_SDK) {
      setSdkAvailability(true);
      abortTheInterval();
    }
  };

  const getClientId = () => {
    const cid = _.get(window, 'digitalData.login.clienteID', undefined);
    if (cid) {
      setClienteID(cid);
      abortDigitalDataInterval();
    }
  };

  useEffect(() => {
    const checkForSurveyScripts = setInterval(checkSurveyScripts, 500);
    intervalRef.current = checkForSurveyScripts;
    const checkForClienteID = setInterval(getClientId, 500);
    digitalDataRef.current = checkForClienteID;
    abortTimeIntervalSearch();
    return () => {
      abortAllTimers();
    };
  }, []);

  return clienteID && sdkAvailable ? (
    <div
      className={`container survey-wrapper ${withBorder ? 'with-border' : ''}`}
    >
      <SurveyBox
        icon="csicon-ayudanos"
        content={SurveyContent}
        onClickHandler={openSurvey}
        type="button"
      />
      <style jsx>{themedStyles}</style>
    </div>
  ) : (
    ''
  );
};

SurveyBar.defaultProps = {
  withBorder: false,
  theme: {}
};

SurveyBar.propTypes = {
  appCtx: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  withBorder: PropTypes.bool,
  theme: PropTypes.object
};

export default withTheme(withApplicationContext(withLabels(SurveyBar)));
export { SurveyBar };
