import React from 'react';
import PropTypes from 'prop-types';
import AdditionalInformationDesktop from './Desktop/AdditionalInformationDesktop';
import AdditionalInformationMobile from './Mobile/AdditionalInformationMobile';
import MatchMedia from '../ui/MatchMedia/MatchMedia';
import { withLabels } from '../../utils/LabelsContext';

const AdditionalInformation = ({
  specifications,
  description,
  isRebrandingEnabled,
  topSpecifications,
  appCtx
}) => {
  return (
    <MatchMedia
      content={{
        desktop: () => (
          <AdditionalInformationDesktop
            specifications={specifications}
            description={description}
            isRebrandingEnabled={isRebrandingEnabled}
            appCtx={appCtx}
          />
        ),
        mobile: () => (
          <AdditionalInformationMobile
            specifications={specifications}
            description={description}
            isRebrandingEnabled={isRebrandingEnabled}
            topSpecifications={topSpecifications}
            isMobile
            appCtx={appCtx}
          />
        ),
        tablet: () => (
          <AdditionalInformationMobile
            specifications={specifications}
            description={description}
            isRebrandingEnabled={isRebrandingEnabled}
            topSpecifications={topSpecifications}
            isMobile
            appCtx={appCtx}
          />
        )
      }}
    />
  );
};

AdditionalInformation.defaultProps = {
  description: undefined,
  isRebrandingEnabled: false,
  topSpecifications: []
};

AdditionalInformation.propTypes = {
  specifications: PropTypes.array.isRequired,
  topSpecifications: PropTypes.array,
  description: PropTypes.string,
  isRebrandingEnabled: PropTypes.bool,
  appCtx: PropTypes.object.isRequired
};

export { AdditionalInformation };

export default withLabels(AdditionalInformation);
