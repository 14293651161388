import css from 'styled-jsx/css';

const ImageGalleryHeadlineDesktopStyles = css`
  .headline-wrapper {
    text-align: center;
    margin: 0 auto 12px auto;
    position: relative;
    overflow: hidden;
    min-height: 410px;
    max-height: 485px;
    max-width: 485px;

    .image-headline-wrapper-zoom {
      position: absolute;
      top: 0;
      left: 0;
    }

    .image-headline {
      &.zoom {
        position: absolute;
        top: 0;
        left: 0;
        max-width: none;
      }
    }
  }

  .headline-wrapper :global(img) {
    width: 100%;
    height: auto;
  }

  .headline-wrapper :global(.video-headline) {
    width: 100%;
    height: 485px;
  }
`;

const PreloadImageStyles = css`
  .hidden {
    display: none;
  }
`;

export { ImageGalleryHeadlineDesktopStyles, PreloadImageStyles };
