import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';

const cmrFormStyles = css`
  .cmrLogoContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid ${colors.gray.shade42};
    padding: 13px 60px;

    .logo-lg {
      font-size: ${typography.xxl};
    }
  }

  .cmrForm {
    display: flex;
    min-height: 563px;
  }
`;

export default cmrFormStyles;
