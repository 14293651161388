import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withLabels } from '../../../../utils/LabelsContext';
import noop from '../../../../utils/noop';
import BodyCopy from '../../../ui/BodyCopy/BodyCopy';
import PriceCalendar from '../PriceCalendar/PriceCalendar';
import { deliveryCalendarStyle } from './DeliveryCalendar.style';

const DeliveryCalendar = ({ labels, slots = [] }) => {
  const [pageIndex, setPageIndex] = useState(1);
  const numberOfPages = slots.length < 7 ? 1 : slots.length / 7;

  const loadPrevWeekData = () => {
    const nextIndex = pageIndex - 1 > 0 ? pageIndex - 1 : 1;
    setPageIndex(nextIndex);
  };

  const loadNextWeekData = () => {
    const nextIndex =
      pageIndex + 1 < numberOfPages ? pageIndex + 1 : numberOfPages;
    setPageIndex(nextIndex);
  };

  return (
    <div className="deliverySlot-container container">
      <section className="calendar-description">
        <BodyCopy isHighlighted>{labels.CHOOSE_OPTION_LABEL}</BodyCopy>
      </section>
      <section className="calendar-month">
        <span
          id="testId-DeliverySlotCalendar-back"
          role="button"
          className={`backward-icon ${
            pageIndex > 1 ? 'show-icon' : 'hide-icon'
          }`}
          onKeyDown={noop}
          tabIndex="0"
          onClick={loadPrevWeekData}
        >
          <i className="csicon-arrow_left">
            <span className="sr-only">Previous</span>
          </i>
        </span>
        <span className="calendar-month-name">
          <BodyCopy size="copy3" isHighlighted>
            {slots[0].slotDate.month} {slots[0].slotDate.year}
          </BodyCopy>
        </span>
        <span
          id="testId-DeliverySlotCalendar-forward"
          role="button"
          className={`forward-icon ${
            pageIndex < numberOfPages ? 'show-icon' : 'hide-icon'
          }`}
          onClick={loadNextWeekData}
          onKeyDown={noop}
          tabIndex="0"
        >
          <i className="csicon-arrow_right">
            <span className="sr-only">Next</span>
          </i>
        </span>
      </section>
      <PriceCalendar
        slots={slots.slice((pageIndex - 1) * 7, pageIndex * 7)}
        pageIndex={pageIndex}
      />
      <style jsx>{deliveryCalendarStyle}</style>
    </div>
  );
};

DeliveryCalendar.propTypes = {
  labels: PropTypes.object,
  slots: PropTypes.array
};

DeliveryCalendar.defaultProps = {
  labels: {},
  slots: []
};

export { DeliveryCalendar };
export default withLabels(DeliveryCalendar);
