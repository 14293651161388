import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import MatchMedia from '../ui/MatchMedia/MatchMedia';
import { ComparativeTableStyles } from './ComparativeTable.style';
import { mapATGPricesToCatalystPrices } from '../../utils/PriceHelpers';
import { withApplicationContext } from '../../utils/ApplicationContext';
import _ from '../../utils/LodashImports';
import noop from '../../utils/noop';
import ComparativeTableTab from './ComparativeTableTab/ComparativeTableTab';
import ComparativeTablePod from './ComparativeTablePod/ComparativeTablePod';
import { withProduct } from '../../utils/ProductContext';

const getPayloadTitle = (rawTabSelectedObject) => {
  return `${rawTabSelectedObject.index}|${rawTabSelectedObject.text
    .split(' ')
    .join('_')}`;
};

const ComparativeTable = ({
  products,
  appCtx,
  heading,
  carouselPosition,
  parentVariantID,
  handleViewedProducts,
  handleAddedToCartProducts,
  handleClickedProduct,
  addToCart,
  attributes,
  product
}) => {
  const similarUniqueViewedProducts = new Set();
  const { regionCode } = appCtx;
  const toggles = _.get(appCtx, 'siteConfig.toggles', {});
  const comparativeTableTitle = _.get(
    appCtx,
    'siteConfig.textDictionary.COMPARATIVE_TABLE_TITLE',
    'Encuentra la opción correcta'
  );
  const carouselRecoRef = React.useRef(null);
  const [headingRawTabSelected, setHeadingRawTabSelected] = useState({
    index: 0,
    text: 'Productos similares'
  });

  const mProducts = products.map((p) => ({
    ...p,
    prices: mapATGPricesToCatalystPrices(p.prices, regionCode, toggles)
  }));

  const aggregateViewedProducts = ({ sku, selectedTab }) => {
    if (selectedTab === 'similar') {
      similarUniqueViewedProducts.add(sku);
      const skus = Array.from(similarUniqueViewedProducts).join(',');
      handleViewedProducts(heading, skus);
    }
  };
  const podClicked = (id) => {
    handleClickedProduct(headingRawTabSelected.index, id);
  };
  const addedToCart = (id) => {
    handleAddedToCartProducts(headingRawTabSelected.index, id);
  };

  const showRecommendationsWrapper = mProducts.length > 0;

  const similarProducts = mProducts.map((item, index) => (
    <ComparativeTablePod
      key={item.productId}
      product={item}
      appCtx={appCtx}
      aggregateViewedProducts={aggregateViewedProducts}
      podClicked={podClicked}
      addedToCart={addedToCart}
      attributes={attributes}
      details={{
        numberProducts: mProducts.length,
        productPosition: index + 1,
        carouselPosition,
        carouselName: heading,
        parentVariantID,
        selectedTab: 'similar'
      }}
      addToCart={addToCart}
      totalProducts={mProducts.length}
      currentVariant={product.currentVariant}
      slot={carouselPosition}
    />
  ));

  // The rest of them will be the N attributes received from the API. ...
  // attributes rows....
  function createAttributeRowsData(fnAttributes, fnProducts) {
    const tableData = {
      attributes: []
    };

    fnAttributes.forEach((attr) => {
      const attrColumn = fnProducts.map((p) => {
        if (
          p.attributes &&
          Object.prototype.hasOwnProperty.call(p.attributes, attr)
        ) {
          return p.attributes[attr];
        }
        return '';
      });
      const attrObject = {};
      attrObject.name = attr;
      attrObject.data = attrColumn;
      tableData.attributes.push(attrObject);
    });

    return tableData;
  }

  const attrRowsData = createAttributeRowsData(attributes, products);

  return (
    showRecommendationsWrapper && (
      <div
        data-widget-name={heading}
        ref={carouselRecoRef}
        className="comparative-table-wrapper"
      >
        <div className="comparative-table-heading">{comparativeTableTitle}</div>
        <Fragment>
          <MatchMedia
            content={{
              mobile: () => (
                <ComparativeTableTab
                  deviceType="mobile"
                  setHeadingRawTabSelected={setHeadingRawTabSelected}
                  appCtx={appCtx}
                  mProducts={mProducts}
                  podClicked={podClicked}
                  addedToCart={addedToCart}
                  carouselPosition={carouselPosition}
                  heading={heading}
                  parentVariantID={parentVariantID}
                  addToCart={addToCart}
                  similarProducts={similarProducts}
                  sameSellerProducts={[]}
                  attrRowsData={attrRowsData}
                />
              ),
              tablet: () => (
                <ComparativeTableTab
                  deviceType="mobile"
                  setHeadingRawTabSelected={setHeadingRawTabSelected}
                  appCtx={appCtx}
                  mProducts={mProducts}
                  podClicked={podClicked}
                  addedToCart={addedToCart}
                  carouselPosition={carouselPosition}
                  heading={heading}
                  parentVariantID={parentVariantID}
                  addToCart={addToCart}
                  similarProducts={similarProducts}
                  sameSellerProducts={[]}
                  attrRowsData={attrRowsData}
                />
              ),
              desktop: () => (
                <ComparativeTableTab
                  deviceType="desktop"
                  setHeadingRawTabSelected={setHeadingRawTabSelected}
                  appCtx={appCtx}
                  mProducts={mProducts}
                  podClicked={podClicked}
                  addedToCart={addedToCart}
                  carouselPosition={carouselPosition}
                  heading={heading}
                  parentVariantID={parentVariantID}
                  addToCart={addToCart}
                  similarProducts={similarProducts}
                  sameSellerProducts={[]}
                  attrRowsData={attrRowsData}
                />
              )
            }}
          />
        </Fragment>
        <style jsx>{ComparativeTableStyles}</style>
      </div>
    )
  );
};
ComparativeTable.defaultProps = {
  handleViewedProducts: noop,
  handleAddedToCartProducts: noop,
  handleClickedProduct: noop,
  addToCart: noop,
  attributes: []
};

ComparativeTable.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  appCtx: PropTypes.object.isRequired,
  heading: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  carouselPosition: PropTypes.object.isRequired,
  parentVariantID: PropTypes.string.isRequired,
  handleViewedProducts: PropTypes.func,
  handleAddedToCartProducts: PropTypes.func,
  handleClickedProduct: PropTypes.func,
  addToCart: PropTypes.func,
  attributes: PropTypes.array
};

export default withApplicationContext(withProduct(ComparativeTable));
export { ComparativeTable, getPayloadTitle };
