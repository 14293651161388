import css from 'styled-jsx/css';
import { colors } from '../../config/styles';

const ProductLandingContainerStyles = css`
  .pdp-softline {
    background-color: ${colors.gray.shade6};
  }
`;

export { ProductLandingContainerStyles };
