import css from 'styled-jsx/css';

const CmrListItemStyle = css`
  .cmr-points-list-item {
    height: 58px;
    background: #fff;
    display: flex;
    align-items: center;
    padding-left: 32px;
    @mixin mobileToTablet {
      width: 100%;
      padding-left: 17px;
    }
    @mixin smallerMobile {
      padding-left: 9px;
    }
    &.bottom-line {
      border-bottom: 1px solid #f0f0f0;
    }
    .cmr-icon-container {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .accum-text {
      margin-top: 14px;
      margin-bottom: 15px;
      color: #333;
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      min-width: 250px;
      @mixin mobileToTablet {
        min-width: 178px;
      }
      @mixin smallerMobile {
        max-width: 139px;
        min-width: auto;
      }
      .cmr-text-title {
        font-size: 16px;
        line-height: 16.8px;
        @mixin mobileToTablet {
          font-size: 14px;
          line-height: 17px;
        }
      }

      .cmr-text-subtitle {
        font-size: 19.2px;
        line-height: 16.8px;
        font-weight: 700;
        @mixin mobileToTablet {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
    .cmr-points-group {
      display: flex;
      width: 102px;
      justify-content: space-between;
      @mixin mobileToTablet {
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
        margin-right: 16px;
      }
      @mixin smallerMobile {
        margin-right: 14px;
      }
    }
    .cmr-points-text {
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      @mixin smallerMobile {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
    .more-options {
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      text-align: right;
      text-decoration-line: underline;
      color: #333;
      margin-top: 14px;
      margin-bottom: 15px;
      margin-left: 58px;
    }
    .cmr-icon {
      margin: 8px;
    }
    .cmr-text-icon {
      margin-left: 8px;
    }
  }
`;

export { CmrListItemStyle };
