import css from 'styled-jsx/css';
import { colors } from '../../config/styles';

const CMRLoaderStyle = css`
  @keyframes animePalceHolder {
    0% {
      background-position: -150% 0;
    }
    100% {
      background-position: 160% 0;
    }
  }
  .animatedTableBackground {
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: animePalceHolder;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #fafafa 8%, #ddd 18%, #fafafa 33%);
    background-size: 1024px 104px;
  }

  .placeholder-hardline {
    margin-top: 16px;
  }

  .placeholder-small {
    width: 100%;
    height: 8px;
    margin-bottom: 8px;
  }
  .placeholder-large {
    width: 100%;
    height: 48px;
    background: ${colors.gray.shade17};
  }
`;

export default CMRLoaderStyle;
