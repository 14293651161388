import React from 'react';
import PropTypes from 'prop-types';
import { spinnerVisualStyle } from './Spinner.style';

const Spinner = ({ size, color }) => {
  return (
    <div className={`spinner ${size} ${color}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="spinner__svg"
        viewBox="-2.5 -2.5 49 49"
      >
        <circle
          className="spinner-ie"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          cx="22"
          cy="22"
          r="22"
        />
      </svg>
      <style jsx>{spinnerVisualStyle}</style>
    </div>
  );
};

Spinner.propTypes = {
  size: PropTypes.oneOf(['medium', 'small']),
  color: PropTypes.oneOf(['white', 'green'])
};

Spinner.defaultProps = {
  size: 'medium',
  color: 'green'
};

export default Spinner;
