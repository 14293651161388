import fetch from 'isomorphic-unfetch';
import apiConfig from '../config/api/apiConfig';
import WebStorage from './WebStorage';
import { getPreviousUrl } from './urlHelper';
import constants from '../config/constants';

export const getStore = (store = '') => {
  const { STORE_NAMES_FOR_VAS } = constants;
  return STORE_NAMES_FOR_VAS[`${store}`.toUpperCase()] || null;
};

export const makeRequest = async (url, data, platform, regionCode) => {
  const { DEVICE_DESKTOP, DEVICE_MOBILE, DEVICE_TABLET } = constants;
  const { WEB, MOBILE, TABLET } = constants.X_CHANNEL_IDS_HEADERS_VALUES;

  const platformTypes = {
    [DEVICE_DESKTOP]: WEB,
    [DEVICE_MOBILE]: MOBILE,
    [DEVICE_TABLET]: TABLET
  };

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-channel-id': platformTypes[platform],
      'x-tenant-id': regionCode
    },
    body: JSON.stringify(data)
  };

  await fetch(url, options);
};

export const getStoreHash = (fullToken, regionCode) => {
  let storeHash = '';
  const MABAYA_TOKEN = fullToken;
  if (MABAYA_TOKEN && typeof MABAYA_TOKEN === 'string') {
    try {
      // eslint-disable-next-line prefer-destructuring
      storeHash = `${MABAYA_TOKEN}`
        .split('||')
        .filter((e) => e.startsWith(regionCode))[0]
        .split('=')[1];
    } catch (e) {
      storeHash = '';
    }
  }
  return storeHash;
};

export const formatSearchTerm = (referrerUrl = '') => {
  let term = '';
  if (referrerUrl) {
    const ntt = referrerUrl.split('/search?Ntt=')[1];
    if (ntt && typeof ntt === 'string') {
      try {
        term = encodeURIComponent(
          ntt
            .split('&')[0]
            .toLowerCase()
            .replace(/\+/gi, ' ')
        );
      } catch (e) {
        term = '';
      }
    }
  }
  return term;
};

const sponsoredEvents = {
  visit: ({
    mabayaToken,
    referrerUrl = '',
    regionCode,
    sku,
    enabled,
    isPDP = false,
    categoryId
  }) => {
    if (`${enabled}` === 'false') {
      return;
    }
    const token = getStoreHash(mabayaToken, regionCode);
    if (!token) {
      return;
    }
    const isSLP = !!referrerUrl.split('Ntt=')[1];
    const referrerSignal = isSLP ? formatSearchTerm(referrerUrl) : categoryId;
    if (!isPDP && !referrerSignal) {
      return;
    }
    const cookie =
      (isPDP &&
        WebStorage.getItem({ name: 'mabayaSession', fromPersistence: true })) ||
      null;
    const previousUrl = getPreviousUrl();

    const visitUrl = apiConfig.mabayaVisitUrl({
      storeHash: token,
      sku,
      referrerSignal,
      cookie,
      previousUrl
    });

    fetch(visitUrl);
  },
  click: ({ mabayaToken, platform, regionCode, adInfo, mPos, enabled }) => {
    if (`${enabled}` === 'false') {
      return;
    }
    const token = getStoreHash(mabayaToken, regionCode);
    if (!token) {
      return;
    }
    const cookie =
      WebStorage.getItem({ name: 'mabayaSession', fromPersistence: true }) ||
      '';
    const clickUrl = apiConfig.mabayaClickUrl({
      storeHash: token,
      platform,
      adInfo,
      mPos,
      cookie
    });
    fetch(clickUrl);
  },
  impression: ({
    mabayaToken,
    platform,
    regionCode,
    adInfo,
    mPos,
    enabled
  }) => {
    if (`${enabled}` === 'false') {
      return;
    }
    const token = getStoreHash(mabayaToken, regionCode);
    if (!token) {
      return;
    }
    const impressionUrl = apiConfig.mabayaImpressionUrl({
      storeHash: token,
      platform,
      adInfo,
      mPos
    });
    fetch(impressionUrl);
  }
};

export default sponsoredEvents;
