import css from 'styled-jsx/css';
import { typography, colors } from '../../../config/styles';

const SizeDropdownStyles = css`
  .list {
    z-index: 1;
    box-shadow: 0 5px 75px rgba(0, 0, 0, 0.09);
    background: ${colors.white.shade1};
    position: absolute;
    left: 0;
    padding: 10px 0;
    top: 0;
    width: 100%;

    &-item {
      width: 100%;
      font-size: ${typography.sm1};
      line-height: 1.8rem;
      letter-spacing: 0.075em;
      padding: 10px;
      position: relative;
      text-align: left;

      &-selected {
        font-weight: 600;
        background-color: ${colors.gray.shade3};
        border-left: 3px solid ${colors.green.shade2};
        &.mkp {
          border-left: 3px solid ${colors.secondary_color};
        }
      }

      &:hover {
        background-color: ${colors.gray.shade1};
      }
    }
  }

  .size-container {
    min-width: 200px;

    .menu-button {
      color: ${colors.black.shade1};
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid ${colors.gray.shade5};
      padding: 10px 0;
      font-size: ${typography.lg1};
      font-family: inherit;
      align-items: baseline;
      @mixin tabletToDesktop {
        font-size: ${typography.base};
      }
    }

    .icon {
      font-size: 9px;
      color: ${colors.black.shade1};
    }

    &.medium {
      width: 125px;
      min-width: 125px;

      .list-item {
        padding: 10px;
        text-align: left;
        font-size: ${typography.sm};
        line-height: ${typography.base};
        font-family: inherit;
      }

      .menu-button {
        font-size: ${typography.sm};
        font-weight: 700;
      }

      .icon {
        font-size: 6px;
      }
    }
  }
`;

export { SizeDropdownStyles };
