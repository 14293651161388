const svgToDataUri = (svgStr) => {
  const encoded = encodeURIComponent(svgStr)
    .replace(/'/g, '%27')
    .replace(/"/g, '%22');

  const header = 'data:image/svg+xml;charset=utf8,';
  const dataUrl = header + encoded;

  return dataUrl;
};

const svgReplaceColor = (svgStr, color) => {
  return svgStr.replace(/#xxxxxx/g, color);
};

export { svgToDataUri, svgReplaceColor };
