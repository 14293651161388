import css from 'styled-jsx/css';
import colors from '../../../config/styles/colors';
import { svgToDataUri } from '../../../utils/styles/svgMixins';
import { icons, typography } from '../../../config/styles';

const AvailabilityMobileStyles = css`
  .availability {
    background-color: ${colors.white.shade1};
    flex-basis: 66.66%;
    &-container {
      display: flex;
      flex-direction: row;
      gap: 12px;
      height: auto;
      width: 100%;
      font-family: Lato, serif;
      .availability-item {
        color: ${colors.black.shade1};
        flex:1;
        &.title {
          span {
            margin-left: 6px;
            width: 100%;
            text-align: left;
            font-weight: bold;
            font-size: 16px;
            padding-top: 10px;
          }
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        margin: 0;
        box-sizing: border-box;
        .icon {
          width: 32px;
          height: 32px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          &.deliveryAvailable {
            min-width: 32px;
            width: 32px;
            height: 32px;
            background-image: url("${svgToDataUri(icons.deliveryIcon)}");
          }
          &.deliveryNotAvailable {
            width: 48px;
            height: 48px;
            flex-shrink: 0;
            background-image: url("${svgToDataUri(icons.noHomeDelivery)}")
          }
          &.siteToStoreAvailable {
            margin-top: 3px;
            height:21px;
            width:24px;
            background-image: url("${svgToDataUri(icons.buyInStoreIcon)}");
          }
          &.siteToStoreNotAvailable {
            background-image: url("${svgToDataUri(
              icons.buyInStoreNotAvaliableIcon
            )}")
          }
          &.pickupInStoreAvailable {
            width: 32px;
            height: 32px;
            min-width: 32px;
            background-image: url("${svgToDataUri(icons.storeWithdrawalIcon)}");
          }
          &.pickupInStoreNotAvailable {
            width: 48px;
            height: 48px;
            flex-shrink: 0;
            background-image: url("${svgToDataUri(icons.noPickupInStore)}")
          }
          &.downloadAvailable {
            height:20px;
            width:27px;
            background-image: url("${svgToDataUri(icons.downloadIcon)}");
            &.rebranded {
                background-image: url("${svgToDataUri(
                  icons.downloadIconRebranded
                )}");
                width: 48px;
                height: 48px;
                flex-shrink: 0;

            }
          }
          &.purchaseOnlineAvailable {
            height:18px;
            width:20px;
            background-image: url("${svgToDataUri(
              icons.purchaseOnlineAvailableIcon
            )}");
          }
          &.purchaseOnlineNotAvailable {
            height: 30px;
            background-image: url("${svgToDataUri(
              icons.purchaseOnlineNotAvailableIcon
            )}");
          }
           i {
            font-size: 18px;
            line-height: 1.5;
          }
        }
        &.rebranded {
          .icon {
            &.deliveryAvailable {
              background-image: url("${svgToDataUri(icons.homeDeliveryMint)}");
              height: 48px;
              width: 48px;
              flex-shrink: 0;
            }
            &.so-deliveryAvailable {
              background-image: url("${svgToDataUri(
                icons.so_com_homeDeliveryMint
              )}");
              height: 48px;
              width: 48px;
              flex-shrink: 0;
            }
            &.pickupInStoreAvailable {
              background-image: url("${svgToDataUri(icons.pickUpInStoreMint)}");
              height: 48px;
              width: 48px;
              flex-shrink: 0;
            }
            &.so-pickupInStoreAvailable {
              background-image: url("${svgToDataUri(
                icons.so_com_pickUpInStoreMint
              )}");
              height: 48px;
              width: 48px;
              flex-shrink: 0;
            }
            &.siteToStoreAvailable {
                background-image: url("${svgToDataUri(
                  icons.buyInStoreIconRebranded
                )}");
            }
            &.purchaseOnlineAvailable {
                background-image: url("${svgToDataUri(
                  icons.purchaseOnlineAvailableIconRebranded
                )}");
            }
          }
          &.out-of-stock {
            color: ${colors.gray.shade60};
          }
        }
        &.lastItem {
          margin-top: 10px;
          border-top: 1px solid ${colors.gray.shade3};
        }
        .content-container {
          justify-content: space-between;
          width: 100%;
          .content {
            height: auto;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content:center;
            font-family: Lato, serif;
            font-size: 14px;
            line-height: 18px;
            color: inherit;
            letter-spacing: 0.14px;
            &-main {
              outline: none;
              display: flex;
              flex-direction: column;
              >.bold {
                font-weight: bold;
              }
              >.mkp-title {
                font-size: ${typography.lg1};
                line-height: 19.2px;
              }
              &.outofstock {
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                .underline {
                  text-decoration: underline;
                  text-underline-offset: 2px;
                  text-decoration-color: ${colors.gray.shade52};
                }
                .no-delivery {
                  text-align: center;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 18px;
                  color: ${colors.gray.shade52};
                }
              }
            }
            &-sub {
              cursor: pointer;
              text-align: center;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
              color: ${colors.secondary_color};
              text-decoration: underline;
              text-underline-offset: 2px;
              text-decoration-color: ${colors.secondary_color};
            }
          }
          .placeholder-underline {
            color: ${colors.gray.shade60};
            font-size: 14px;
            letter-spacing: 0.14px;
            line-height: 17px;
            border-bottom: 1px solid ${colors.gray.shade60};
          }
  .placeholder-text {
    color: ${colors.gray.shade44};
  font-size: 13px;
  letter-spacing: 0;
  line-height: 21px;
    span {
      font-weight: bold;
    }
  }
          .iconCaret {
            outline:none;
            color:${colors.black.shade1};
            display: flex;
            align-items: center;
            i {
              width: 20px;
              height: 20px;
              background-image: url("${svgToDataUri(
                icons.chevronRightRebranded
              )}");
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              &::before {
                content: '';
              }
            }
          }
        }
      }
    }
  }
`;

export { AvailabilityMobileStyles };
