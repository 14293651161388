import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { svgToDataUri } from '../../utils/styles/svgMixins';
import { AdditionalMessageStyles } from './AdditionalPDPLabel.style';

const AdditionalPDPLabel = ({ label, type, productType, desktop }) => {
  const { title, description, moreInfoLink, moreInfoDisplayText, icon } = label;
  return (
    <Fragment>
      {(description || title || moreInfoLink) && (
        <div
          className={classnames('additional-label-in-pdp', {
            warranty: type === 'warranty',
            softline: productType === 'softline' && !desktop
          })}
        >
          <div>
            <span
              className="icon"
              style={{ backgroundImage: `url('${svgToDataUri(icon)}')` }}
            />
          </div>
          <div className="label-content">
            {title && <p className="label-header">{title}</p>}
            {description && (
              <p
                className="label-description"
                dangerouslySetInnerHTML={{
                  __html: description.replace(
                    /<strong/gi,
                    "<strong style='font-weight: bold'"
                  )
                }}
              />
            )}
            {moreInfoLink && (
              <div>
                <a
                  className="underline"
                  href={moreInfoLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {moreInfoDisplayText || 'More Information'}
                </a>
                <span className="caret">
                  <i className="csicon-right">
                    <span className="sr-only">
                      {moreInfoDisplayText || 'More Information'}
                    </span>
                  </i>
                </span>
              </div>
            )}
          </div>
          <style jsx>{AdditionalMessageStyles}</style>
        </div>
      )}
    </Fragment>
  );
};

AdditionalPDPLabel.propTypes = {
  label: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.string,
    description: PropTypes.string,
    moreInfoLink: PropTypes.string,
    moreInfoDisplayText: PropTypes.string
  }),
  type: PropTypes.string,
  productType: PropTypes.string,
  desktop: PropTypes.bool
};

AdditionalPDPLabel.defaultProps = {
  label: {},
  type: 'addToCart',
  productType: 'hardline',
  desktop: false
};

export { AdditionalPDPLabel };

export default AdditionalPDPLabel;
