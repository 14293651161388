import css from 'styled-jsx/css';
import { colors, icons, typography } from '../../config/styles';
import { svgToDataUri } from '../../utils/styles/svgMixins';

const helpLineStyle = css`
  .helpLine-container {
    background: ${colors.white.shade1};
    @mixin tabletToDesktop {
      margin-top: 40px;
    }
  }
  .helpline-options-container {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    padding: 1.2rem;
  }
  .helpLine-section {
    display: block;
    color: ${colors.black.shade1};
    background-color: ${colors.white.shade1};
    @mixin tabletToDesktop {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 14px 20px;
      border-top: 1px solid ${colors.gray.shade3};
      margin-right: 8px;
    }
  }
  .telephone-text {
    font-size: ${typography.lg1};
    @mixin tabletToDesktop {
      font-size: ${typography.base};
    }
  }
  .subtitle-text {
    font-size: 14px;
  }
  .telephone-text-highlight {
    font-weight: bold;
    display: block;
    margin-top: 5px;
  }
  .telephone-section-mobile {
    display: flex;
    flex-direction: column;
  }
  .telephone-section-desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .telephone-text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
    margin-left: 15px;
    font-size: ${typography.base};
  }
  .telephone-link-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    border-radius: 28px;
    padding: 9px 32px;
    font-weight: 700;
    color: ${colors.black.shade1};
    border: 1px solid ${colors.black.shade1};
    letter-spacing: 0.75px;
    text-decoration: none;
    text-transform: uppercase;
  }
  .telephone-link-number {
    margin-left: 5px;
    font-size: ${typography.sm};
    @mixin tabletToDesktop {
      font-size: ${typography.base};
      font-weight: bold;
    }
  }
  .csicon-helpline {
    font-size: ${typography.xl4};
    margin-right: 15px;
    @mixin tabletToDesktop {
      margin-right: 10px;
      font-size: ${typography.xl2};
    }
  }
  .rebranded {
    .telephone-text-container {
      @mixin mobileToTablet {
        margin-bottom: 16px;
      }
    }
    .telephone-link-button {
      height: 32px;
      border-radius: 28px;
      padding: 9px 32px;
      color: ${colors.gray.shade45};
      border: 1.5px solid ${colors.black.shade11};
      text-transform: none;
      .telephone-link-number,
      .telephone-link-text {
        font-weight: 700;
        line-height: 22px;
        color: ${colors.black.shade10};
        @mixin mobileToTablet {
          font-size: ${typography.lg1};
          color: ${colors.black.shade11};
        }
        @mixin smallMobileOnly {
          font-size: ${typography.lg1};
        }
      }
    }
    .csicon-helpline {
      width: 32px;
      height: 32px;
      background-image: url("${svgToDataUri(icons.helplineRebranded)}");
      background-size: 100%;
      background-position: center;
      &::before {
        content: '';
      }
    }
  }
  .helpline-button-mobile {
    color: ${colors.black.shade1};
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 100%;
    .icon-helpline-rebranded {
      display: block;
      width: 32px;
      height: 32px;
      background-image: url("${svgToDataUri(icons.helplineRebranded)}");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      &::before {
        content: '';
      }
    }
    .content {
      margin-left: 12px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      b {
        font-weight: 700;
        font-size: 16px;
      }
    }
    .icon-arrow-right-rebranded {
      flex: 0 0 20px;
      width: 20px;
      height: 20px;
      margin-left: auto;
      background-image: url("${svgToDataUri(icons.chevronRightRebranded)}");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      &::before {
        content: '';
      }
    }
  }  
`;

export { helpLineStyle };
