import { useState, useEffect, useReducer } from 'react';
import {
  installmentReducer,
  bankCardsReducers,
  installmentInfoReducer
} from '../reducers';
import {
  getCache,
  fetchCMRInstallments,
  saveCardInfotoProfile,
  savePromotions,
  saveInstallmentInfoToProfile,
  getInstallmentStatement
} from '../helpers';
import constants from '../../../config/constants';

const useInstallments = ({
  quantity,
  variantId,
  regionCode,
  setInitialCmrData,
  labels
}) => {
  const [state, dispatch] = useReducer(installmentReducer, {
    isLoading: false,
    error: null,
    installmentNum:
      labels.CMR_DEFAULT_INSTALLMENTS || constants.DEFAULT_CMR_INSTALLMENTS,
    data: {},
    bankName: constants.DEFAULT_CMR_BANK_NAME
  });

  const [installmentInfoSate, installmentInfoDispatch] = useReducer(
    installmentInfoReducer,
    {
      isLoading: false,
      error: null,
      data: {}
    }
  );

  const { data, installmentNum, bankName, error: installmentError } = state;
  const {
    isLoading: installmentInfoLoading,
    error: installmentInfoError
  } = installmentInfoSate;

  // TODO to listen to quantity change add quantity here
  const listeners = [installmentNum, variantId, bankName];
  const installmentInfoListener = [installmentNum];
  const cache = getCache(listeners);
  const isInitialLoad = Object.keys(data).length === 0;
  const defaults = getCache([
    labels.CMR_DEFAULT_INSTALLMENTS || constants.DEFAULT_CMR_INSTALLMENTS,
    variantId,
    constants.DEFAULT_CMR_BANK_NAME
  ]);

  const fetchCMRInstallmentsHandler = () =>
    fetchCMRInstallments(
      { installmentNum, variantId, bankName, quantity },
      regionCode,
      cache,
      dispatch,
      setInitialCmrData
    );

  useEffect(() => {
    if (typeof data[cache] === 'undefined') {
      fetchCMRInstallmentsHandler();
    }
  }, listeners);

  useEffect(() => {
    if (
      !isInitialLoad &&
      regionCode === 'ar' &&
      bankName !== constants.DEFAULT_CMR_BANK_NAME
    ) {
      saveInstallmentInfoToProfile(
        {
          selectedInstallment: installmentNum,
          skus: [
            {
              skuId: variantId,
              quantity: 1
            }
          ]
        },
        {
          installmentInfoDispatch
        },
        regionCode
      );
    }
  }, installmentInfoListener);

  const installmentStatement = getInstallmentStatement(data[cache], labels);
  return {
    state: {
      ...state,
      installmentErrors: [installmentInfoError, installmentError],
      installmentInfoLoading,
      installmentStatement
    },
    fetchCMRInstallmentsHandler,
    installmentDispatch: dispatch,
    cache,
    defaults
  };
};

const useBankAndCards = ({
  defaultBankName,
  defaultCardName,
  skuId,
  quantity,
  regionCode,
  cache,
  installmentDispatch,
  defaultPromotion
}) => {
  const [bankCardsState, bankCardsDispatch] = useReducer(bankCardsReducers, {
    isLoading: false,
    error: null,
    bankName: defaultBankName,
    cardName: defaultCardName,
    promotion: defaultPromotion
  });

  const {
    bankName,
    cardName,
    promotion,
    error: bankCardsError
  } = bankCardsState;
  const bankCardListeners = [bankName, cardName, skuId];

  const promotionListeners = [promotion];

  useEffect(() => {
    if (regionCode === 'ar') {
      saveCardInfotoProfile(
        {
          bankName,
          cardName,
          skus: [
            {
              skuId,
              quantity
            }
          ]
        },
        {
          bankCardsDispatch,
          installmentDispatch
        },
        regionCode,
        cache
      );
    }
  }, bankCardListeners);

  useEffect(() => {
    if (promotion !== '') {
      savePromotions(
        {
          selectedBankPromotionId: promotion,
          skus: [
            {
              skuId,
              quantity
            }
          ]
        },
        {
          installmentDispatch,
          bankCardsDispatch
        },
        regionCode,
        cache
      );
    }
  }, promotionListeners);

  return {
    bankName,
    cardName,
    bankCardsError,
    promotion,
    isBankCardLoading: bankCardsState.isLoading,
    bankCardsDispatch
  };
};

const useInitialCMRData = ({ regionCode }) => {
  const bank =
    regionCode === 'ar'
      ? constants.DEFAULT_CMR_BANK_NAME_AR
      : constants.DEFAULT_CMR_BANK_NAME;
  const [defaultBankName, setDefaultBankName] = useState(bank);
  const [defaultPromotion, setDefaultPromotion] = useState('');

  return {
    defaultBankName,
    defaultPromotion,
    setDefaultBankName,
    setDefaultPromotion
  };
};

export { useInstallments, useBankAndCards, useInitialCMRData };
