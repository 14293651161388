/* stylelint-disable no-descending-specificity */
import css from 'styled-jsx/css';
import { colors } from '../../config/styles';
import { svgUrl } from '../../utils/staticUrls';

const NutritionalInformationStyles = css`
  .nutrition-container {
    margin-top: 32px;
    width: 100%;
    display: flex;
    @mixin mobileToTablet {
      flex-direction: column;
    }
    .title-wrapper {
      border-bottom: 2px solid ${colors.secondary_color};
      width: 100%;
      padding-bottom: 8px;
      margin-bottom: 16px;

      span {
        font-size: 18px;
        line-height: 22px;
        color: ${colors.black.shade1};
        padding-top: 8px;
      }
    }
    .nutrition-description-container {
      background: ${colors.white.shade1};
      padding: 20px 4px 20px 60px;
      @mixin mobileToTablet {
        padding: 20px 32px;
      }
      @mixin tabletToDesktop {
        width: 38.77%;
      }
      .description,
      .ingredients-value {
        line-height: 24px;
        font-size: 14px;
        margin-bottom: 32px;
      }
      .ingredients,
      .certificates {
        margin-bottom: 32px;
        span {
          font-weight: 900;
          font-size: 16px;
          line-height: 32px;
          color: ${colors.black.shade1};
        }
      }
      .certificates {
        .certificates-icon-section {
          margin-top: 16px;
          display: flex;
          flex-wrap: wrap;

          .certificate-icon {
            background-repeat: no-repeat;
            height: 48px;
            width: 100%;
            background-position: center;
          }
          div {
            margin-right: 8px;
            width: 70px;
            @mixin tabletToDesktop {
              width: 86px;
            }
          }
          div.certificate-icon-wrapper {
            margin-bottom: 24px;
            &:nth-child(4n + 4) {
              margin-right: 0;
            }
            @mixin smallerMobile {
              &:nth-child(4n + 4) {
                margin-right: 8px;
              }
            }
          }
          p {
            font-size: 13px;
            line-height: 18px;
            margin-top: 6px;
            color: #4a4a4a;
            text-align: center;
            @mixin tabletToDesktop {
              font-size: 16px;
            }
          }
        }
      }
    }
    .nutrition-information-container {
      background: ${colors.white.shade1};
      padding: 20px 60px;
      @mixin mobileToTablet {
        padding: 20px 32px;
        margin-top: 36px;
      }
      @mixin tabletToDesktop {
        width: 61.23%;
      }
      .warning {
        display: flex;
        margin-bottom: 24px;
        @mixin mobileToTablet {
          justify-content: center;
        }
        div {
          background-repeat: no-repeat;
          background-position: center;
          height: 80px;
          width: 80px;
          margin-right: 4.5px;
        }
      }
    }
    .nutrition-table {
      @mixin tabletToDesktop {
        max-width: 616px;
      }
      .portion-section {
        border: 1px solid ${colors.black.shade1};
        border-bottom: 0;
        padding: 5px 0 10px 5px;
        div {
          color: ${colors.black.shade1};
          display: flex;
          font-size: 14px;
          line-height: 20px;
          b {
            font-weight: bold;
          }
          p {
            margin-left: 5px;
          }
        }
      }
      table {
        width: 100%;

        .table-header {
          border-bottom: 4px solid ${colors.black.shade3};
        }
        .nutrition-sub-name {
          text-align: left;
          padding-left: 10px;
        }
        .nutrition-name {
          text-align: left;
          font-weight: bold;
        }
      }
      table,
      th,
      td {
        border: 1px solid ${colors.black.shade3};
        text-align: right;
      }
      th,
      td {
        padding: 6px;
        line-height: 18px;
        color: ${colors.black.shade1};
        font-size: 14px;
      }
      th {
        font-weight: bold;
        min-width: 60px;
        vertical-align: bottom;
      }
    }
    .important-text {
      margin-top: 24px;
      line-height: 20px;
      color: ${colors.black.shade1};
      font-size: 14px;
      @mixin tabletToDesktop {
        max-width: 616px;
      }
      b {
        font-weight: bold;
      }
      .updated-date {
        margin-top: 26px;
        display: flex;
        justify-content: center;
        line-height: 18px;

        .ok-to-shop-icon {
          background-repeat: no-repeat;
          background-image: url(${svgUrl('ok_to_shop.svg')});
          height: 24px;
          width: 47px;
          background-position: center;
          margin-left: 7px;
        }
      }
    }
  }
`;

export default NutritionalInformationStyles;
