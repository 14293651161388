import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';

const ConnectTabStyles = css`
  .connect-headers {
    &-primary {
      width: 100%;
      height: 48px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 35px;
      margin-top: 8px;
      @mixin tabletToDesktop {
        height: 42px;
      }
      @mixin desktop {
        max-width: 648px;
      }
    }
    &-rebranding {
      width: 100%;
      height: 44px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 28px;
      margin-top: 8px;
      @mixin tabletToDesktop {
        height: 42px;
      }
      @mixin mobileToTablet {
        margin-bottom: 38px;
      }
      @mixin desktop {
        max-width: 648px;
      }
    }
    &-secondary {
      border-bottom: 3px solid #ededed;
      width: 100%;
      height: 43px;
      display: flex;
      margin-bottom: 35px;
      margin-top: 8px;
      @mixin tabletToDesktop {
        height: 42px;
      }
      @mixin desktop {
        max-width: 648px;
      }
    }
  }

  .header {
    &-primary {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid ${colors.green.shade1};
      background: ${colors.green.shade16};
      color: ${colors.green.shade10};
      font-size: ${typography.base};
      font-weight: 550;
      letter-spacing: 1px;
      font-family: inherit;
    }
    &-rebranding {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${colors.black.shade10};
      font-size: ${typography.base};
      font-weight: 550;
      letter-spacing: 1px;
      font-family: inherit;
      background: ${colors.gray.shade6};
      @mixin mobileToTablet {
        font-size: ${typography.lg1};
      }
    }
    &-secondary {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 42px;
      width: 100%;
      width: 121px;
      color: ${colors.green.shade10};
      font-size: ${typography.base};
      font-weight: 550;
      letter-spacing: 1px;
      font-family: inherit;
    }
  }

  .active-tab {
    &-primary {
      border-top: 1px solid ${colors.green.shade1};
      border-left: 1px solid ${colors.green.shade1};
      border-right: 1px solid ${colors.green.shade1};
      border-bottom: none;
      color: ${colors.gray.shade40};
      background: inherit;
    }
    &-rebranding {
      background: ${colors.white.shade1};
      position: relative;
      &::after {
        content: '';
        background: #495867;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        width: 100%;
        top: -2px;
      }
    }
    &-secondary {
      color: ${colors.gray.shade40};
      border-bottom: 3px solid ${colors.green.shade10};
    }
  }

  .hidden-tab {
    visibility: hidden;
    display: none;
  }

  .tabs-content {
    @mixin desktop {
      max-width: 648px;
    }
  }
`;

export { ConnectTabStyles };
