const fontUrl = (filename) => {
  return `/a/fa/product/static/fonts/${filename}`;
};

const svgUrl = (filename) => {
  return `/a/fa/product/static/styles/svg/${filename}`;
};

const imageUrl = (filename) => {
  return `/a/fa/product/static/images/${filename}`;
};

const operatorSvgUrl = (filename) => {
  return `/a/fa/product/static/styles/svg/ConnectFilterIcons/${filename}`;
};
export { fontUrl, svgUrl, imageUrl, operatorSvgUrl };
