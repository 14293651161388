import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import { ImageGalleryCarouselStyles } from './ImageGalleryCarouselDesktop.style';
import noop from '../../../../utils/noop';
import { isVideo, getMediaData } from '../../ImageGalleryHelper';
import Image from '../../../ui/Image/Image';
import DemoUpButtonContainer from '../../DemoUpButton/DemoUpButtonContainer';

const ImageGalleryCarouselDesktop = ({
  carouselImageHeight,
  carouselImageWidth,
  activeImageIndex,
  activeIndicatorIndex,
  imagesPerSlide,
  setActiveImageIndex,
  setActiveIndicatorIndex,
  carouselImages,
  images,
  productName,
  brandName,
  isRebrandingEnabled,
  isConnect,
  theme
}) => {
  const themedStyles = ImageGalleryCarouselStyles(theme);

  const getCarouselItem = (image, mediaUrl, mediaId, index) => {
    if (isVideo(image)) {
      return <div className="image-carousel video"> </div>;
    }
    return (
      <Image
        id={mediaId}
        imageUrl={mediaUrl}
        width={carouselImageWidth}
        height={carouselImageHeight}
        alt={`${brandName} - ${productName}`}
        lazyLoad
        inlineDimensions={false}
        carrouselImagePosition={index + 1}
      />
    );
  };

  return (
    <div
      className={`image-carousel desktop
      fa--image-gallery-carousel__desktop
      ${isRebrandingEnabled && 'rebranded'}`}
    >
      <div className="carousel">
        <DemoUpButtonContainer isConnect={isConnect} />
        {carouselImages.map((image, index) => {
          const { mediaId, mediaUrl } = getMediaData(image);
          return (
            <div
              className={`carousel-wrapper ${
                activeImageIndex % imagesPerSlide === index ? 'active' : ''
              } ${isVideo(image) ? 'video' : ''}`}
              onClick={() => {
                setActiveImageIndex(
                  activeIndicatorIndex * imagesPerSlide + index
                );
              }}
              role="button"
              // eslint-disable-next-line react/no-array-index-key
              key={`${mediaId}-${index}`}
              tabIndex="0"
              onKeyDown={noop}
            >
              {getCarouselItem(image, mediaUrl, mediaId, index)}
            </div>
          );
        })}
      </div>

      {Math.ceil(images.length / imagesPerSlide) > 1 && (
        <div className="indicator">
          {[...Array(Math.ceil(images.length / imagesPerSlide))].map(
            (val, index) => (
              <div
                onClick={() => {
                  setActiveIndicatorIndex(index);
                  setActiveImageIndex(index * imagesPerSlide);
                }}
                role="button"
                tabIndex="0"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onKeyDown={noop}
                className={`dot ${
                  index === activeIndicatorIndex ? 'dot-active' : ''
                } ${isRebrandingEnabled && 'rebranded'}`}
                id={`testId-ImageGalleryCarouselDesktop-dot-${index}`}
              />
            )
          )}
        </div>
      )}
      <style jsx>{themedStyles}</style>
    </div>
  );
};

ImageGalleryCarouselDesktop.defaultProps = {
  images: [],
  carouselImages: [],
  isRebrandingEnabled: false,
  isConnect: false,
  theme: null
};

ImageGalleryCarouselDesktop.propTypes = {
  carouselImageHeight: PropTypes.number.isRequired,
  carouselImageWidth: PropTypes.number.isRequired,
  carouselImages: PropTypes.array,
  activeImageIndex: PropTypes.number.isRequired,
  activeIndicatorIndex: PropTypes.number.isRequired,
  imagesPerSlide: PropTypes.number.isRequired,
  setActiveImageIndex: PropTypes.func.isRequired,
  setActiveIndicatorIndex: PropTypes.func.isRequired,
  images: PropTypes.array,
  productName: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  isRebrandingEnabled: PropTypes.bool,
  isConnect: PropTypes.bool,
  theme: PropTypes.object
};

export default withTheme(ImageGalleryCarouselDesktop);
