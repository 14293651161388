module.exports = {
  searchPlaceholder: '¿Qué buscas?',
  relatedCategories: 'CATEGORÍAS RELACIONADAS',
  relatedBrands: 'MARCAS RELACIONADAS',
  product: 'Product',
  CATEGORIES: 'Categorías',
  WELCOME_LABEL: 'Bienvenid@,',
  MENU: 'Menu',
  FILTERS_TITLE: 'Filtrar por:',
  ADD_TO_CART: 'VER PRODUCTO',
  SAVE: 'save',
  FILTERS_TITLE_MOBILE: 'Filtrar',
  FILTERS_AVAILABILITY_TITLE_MOBILE: 'Tipo de entrega',
  CLEAR: 'Cerrar',
  CLEAN: 'Limpiar',
  READY: 'Listo',
  HOME: 'Home',
  LOCATION_DIALOG_HEADER: 'Cambia tu región',
  LOCATION_DIALOG_BODY:
    'Selecciona la región donde quieres ver la disponibilidad de productos.',
  REGION: 'Región',
  SEARCH_RESULTS_FOR: 'Resultados de la búsqueda',
  OF: 'de',
  RESULTS: 'Resultados',
  NEXT: 'Siguiente',
  PREVIOUS: 'Anterior',
  LOGIN_LABEL: 'Inicia Sesión',
  LOGIN: 'Inicia sesión',
  LOGIN_HEADER_DESC: 'Compra más rápido y revisa los detalles de tus órdenes',
  MY_ACCOUNT_LABEL: 'Mi Cuenta',
  SUBSCRIPTION_EMPTY_ERROR: 'Por favor ingresa un mail',
  SUBSCRIPTION_INVALID_EMAIL_ERROR: 'Debes ingresar un mail valido',
  SUBSCRIPTION_ACCEPT_TERMS: 'Debes aceptar los terminos y condiciones',
  MY_ACCOUNT: 'Mis ordenes',
  MY_ORDERS: 'Mis órdenes',
  MY_ORDERS_DESC: 'Aquí podrás revisar el estado de tus órdenes.',
  TRACK_ORDER: 'Seguimiento de mi orden',
  BAG: 'Bolsa',
  HELP: 'ayuda',
  A11Y_LOCATION_LABEL: 'location icon',
  VIEW_PREPAID: 'Ver Prepago',
  VIEW_PLAN: 'Ver Plan',
  CHOOSE_VARIANTS: 'ELIGE TUS OPCIONES',
  NO_ACCOUNT_LABEL: '¿No tienes cuenta?',
  REGISTER_LABEL: 'Registrate',
  A11Y_USER_LABEL: 'user info',
  LOG_OUT_LABEL: 'Cerrar sesión',
  ORDER_BY: 'Ordenar',
  CLEAR_FILTERS: 'Quitar filtros',
  SELECTED_FILTERS: 'Filtro seleccionado',
  CHOOSE_LOCATION: 'Selecciona tu regió',
  POD_BOOM_SHIPPING_LABEL: 'Boom',
  VENDOR_LABEL: 'Vendedor',
  VENDOR_LOGOUT_LABEL: 'Cerrar sesión',
  VENDOR_NEW_CUSTOMER_SEARCH_LABEL: 'Buscar nuevo cliente',
  VENDOR_CLIENT_LABEL: 'Cliente',
  VENDOR_STORE_LABEL: 'Tienda',
  VENDOR_HOME_LABEL: 'VENDOR HOME',
  VENDOR_SCAN_LABEL: 'Scan',
  PAGE_TITLE: 'falabella.com - Mejor Compra Online',
  META: {
    DESCRIPTION:
      '&iexcl;Ya comenz&oacute;! Vive el CyberDay en Falabella.com. Encuentra grandes ofertas en tus productos favoritos. Televisores, Smartphones, Calzado y m&aacute;s.',
    OG: {
      COUNTRY: 'CL',
      SITENAME: 'Falabella'
    }
  },
  FAV:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAFcUExURUxpcbndJbbcHa3XAarWAKrVAP///6vWAKvWAKvWAP7+//z9+anVAM3naLPaEbXbGNrtjrDYBv7+/qzWAMfkVK7XAsHhQa3XALPaD7PaErzeMbveLdHoc8nlXL/gPNPqe7/gONrtj6/YBdrtkLrdKd3ul/v99NDob8bjUrndJL7fNrHZCPD308LhQ/f76+/2zt/voPz999jsifL42/j77dfrhe72y+Txru/30ebytP3++uHvo7LZDbTaE7DZCO72zbXbGsDgPfT538nkWtTqffj766/YBNvukuTxr+DvosbjUO31ysXjT/n777reK9Doct/vn7HZCb/gOdXrgK7XAcHhQtDocbjcIrbbGqzXAOXxr87navT54q3XAsvlYNbrg7bbG+Lwpuv0w/r88unzvrjcId7vnL3fNPr88//+//H417jdJPH31/X549LpduXxsMzmZenzvLLaD7zeMvA3VeYAAAAJdFJOUwD///////8DrQpuBb0AAAERSURBVDjLfdNVcwJBDAfwk38OAnfFSqHu7i60pe7u7u7y/Wd6PHRo4TZ52ZffbpJJVtPc8Pn5b4CQOfw+7TdyQKikWAI1leGKAgGY1W2GzWpA7Q1hsAC4PtpEEmjt7GiWXsDa6nKEBUCFgQ2WAM6tPZJA4j1YJIKvl9cLKAFgf1/fxKHqYvPM+bgLXDnO8ZY32NnXny8f3251PalMcfr5cG8DMVWRODh5SjJWFqrgDWg3eHTIPG+VkQKsW9vuXaNcuQ+L0SXKrJyqhvH0rA5WzwJ1fQMRFoA7yi6WAPqtRpKAOZwdpRfAWO9UCAKgye7BhFQDzYxMkwBoYig1x2pgttT2jEIA8VIj9u8L56cAe4Ef1o0vxI4rCr4AAABXelRYdFJhdyBwcm9maWxlIHR5cGUgaXB0YwAAeJzj8gwIcVYoKMpPy8xJ5VIAAyMLLmMLEyMTS5MUAxMgRIA0w2QDI7NUIMvY1MjEzMQcxAfLgEigSi4A6hcRdPJCNZUAAAAASUVORK5CYII=',
  VENDOR_INVALID_CODE_LABEL:
    'Lo sentimos, no hemos podido encontrar el producto escaneado.',
  VENDOR_BARCODE_CENTER_LABEL:
    'Centra tu código de barras y evita mover tu teléfono.',
  VENDOR_SCAN_INFO_LABEL: 'El escaneo es automático, evita brillos y reflejos.',
  VENDER_CANCEL_BUTTON_LABEL: 'CANCELAR',
  VENDOR_PRODUCT_AVAILABLE_LABEL: 'Producto disponible en tienda',
  VENDOR_PRICE_LABEL: 'Precio',
  VENDOR_STOCK_LABEL: 'Stock',
  VENDOR_SCAN_ANOTHER_PRODUCT_LABEL: 'ESCANEAR OTRO PRODUCTO',
  VENDOR_INFORMATION_NOT_AVAILABLE_LABEL: 'Informacion no disponible',
  VENDOR_LOADING_LABEL: 'Buscando..',
  LOGIN_LOGIN: 'Inicia sesión',
  LOGIN_EMPTY_EMAIL: 'Por favor ingresa tu e-mail.',
  LOGIN_INVALID_EMAIL: 'Formato de email inválido nombre@ejemplo.com',
  LOGIN_EMAIL_PLACEHOLDER: 'Ingresa tu e-mail',
  LOGIN_PASSWORD_PLACEHOLDER: 'Ingresa tu contraseña',
  LOGIN_FORGOT_PASSWORD: '¿Olvidaste tu contraseña?',
  LOGIN_LOG_IN: 'Iniciar sesión',
  LOGIN_OR: 'ó',
  LOGIN_FACEBOOK: 'Ingresa Con Facebook',
  LOGIN_NOT_REGISTERED: '¿No tienes cuenta?',
  LOGIN_SIGN_UP: 'Regístrate',
  LOGIN_INVALID_PASSWORD: 'Formato de contraseña inválido',
  RECOMMENDED_PRODUCT_LABEL: 'Clientes que vieron este producto tambieñ vieron',
  CMRAPERTURATEXT_REQUEST: 'Solicita ahora tu CMR y',
  CMRAPERTURATEXT_BENEFITS: '¡descubre un mundo de beneficios!'
};
