import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ComboItemsMobileStyles from './ComboItemsMobile.style';
import _ from '../../utils/LodashImports';
import { withApplicationContext } from '../../utils/ApplicationContext';
import Accordion from '../ui/Accordion/Accordion';
import Drawer from '../ui/Drawer/Drawer';
import Image from '../ui/Image/Image';
import SpecificationTable from '../SpecificationTable/SpecificationTable';
import { getComboItemPrice, dispatchDDComboEvent } from './helper';

const ComboItemSpecificationsDrawer = ({ product }) => {
  const [isDrawerActive, activateDrawer] = useState(false);
  const specifications = _.get(product, 'attributes.specifications', []);
  const displayName = _.get(product, 'displayName');

  return (
    <div className="item-specifications">
      {specifications.length > 0 ? (
        <>
          <button
            type="button"
            onClick={() => activateDrawer(true)}
            className="zoom-in-icon"
          />
          <Drawer
            toggleState={[isDrawerActive, activateDrawer]}
            heading="Especificaciones"
            subHeading={displayName}
            isLoading={false}
            type="secondary"
          >
            <SpecificationTable
              specifications={specifications}
              type="secondary"
            />
          </Drawer>
        </>
      ) : null}
      <style jsx>{ComboItemsMobileStyles}</style>
    </div>
  );
};

const ComboItemsMobile = ({ appCtx, comboData }) => {
  const { comboItems, totalPrice, showTotalPrice } = comboData;

  const { regionCode } = appCtx;
  const toggles = _.get(appCtx, 'siteConfig.toggles', {});

  const COMBO_SECTION_HEADING = _.get(
    appCtx,
    'siteConfig.textDictionary.COMBO_SECTION_HEADING_MOBILE',
    'Comprando por separado'
  );
  const COMBO_TOTAL_PRICE_TEXT = _.get(
    appCtx,
    'siteConfig.textDictionary.COMBO_TOTAL_PRICE_TEXT',
    'Total por separado'
  );
  useEffect(() => {
    dispatchDDComboEvent();
  }, []);

  const getFormattedPrice = (prices = []) => {
    const normalPrice = getComboItemPrice({ prices, regionCode, toggles });
    const symbol = _.get(normalPrice, '[0].symbol');
    const price = _.get(normalPrice, '[0].price[0]');
    return `${symbol}${price}`;
  };

  return (
    <div>
      <Accordion
        items={[
          {
            title: COMBO_SECTION_HEADING,
            logo: 'combos-heading-logo',
            content: () => {
              return (
                <div className="combos-container">
                  <section className="combo-items-wrapper">
                    {comboItems.map((item) => {
                      const { product } = item;
                      const {
                        mediaList,
                        brand,
                        url: itemUrl,
                        displayName,
                        prices
                      } = product;
                      const imageUrl = _.get(mediaList, '[0].url', null);
                      return (
                        <div className="combo-item" key={displayName}>
                          {itemUrl && (
                            <div className="photo">
                              <a href={itemUrl}>
                                {imageUrl && (
                                  <div className="image-wrapper">
                                    <Image
                                      imageUrl={imageUrl}
                                      width={60}
                                      height={60}
                                      useSrcSet={false}
                                      alt={displayName}
                                      lazyLoad
                                    />
                                  </div>
                                )}
                              </a>
                            </div>
                          )}
                          <div className="name-seller-wrapper">
                            <div className="seller">{brand}</div>
                            <a className="item-name" href={itemUrl}>
                              {displayName}
                            </a>
                          </div>
                          {showTotalPrice ? (
                            <div className="price-specs-wrapper">
                              <div className="item-price">
                                {getFormattedPrice(prices)}
                              </div>
                              <ComboItemSpecificationsDrawer
                                product={product}
                              />
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                      );
                    })}
                  </section>
                  {showTotalPrice && (
                    <section className="combo-total-price">
                      <div className="label">{COMBO_TOTAL_PRICE_TEXT}</div>
                      <div className="price-value-wrapper">
                        <div className="price">{totalPrice}</div>
                        <div className="green-check" />
                      </div>
                    </section>
                  )}
                </div>
              );
            },
            iconOptions: {
              up: 'csicon-arrow_up_small',
              down: 'csicon-arrow_down_small',
              styles: {
                fontSize: '1rem',
                paddingRight: '9px'
              }
            },
            state: true,
            border: true,
            withPadding: false,
            isRebrandingEnabled: true,
            highLightHeading: true
          }
        ]}
        state
        hasCustomVisualsForItem={false}
      />
      <style jsx>{ComboItemsMobileStyles}</style>
    </div>
  );
};

ComboItemSpecificationsDrawer.propTypes = {
  product: PropTypes.object.isRequired
};

ComboItemsMobile.propTypes = {
  appCtx: PropTypes.object.isRequired,
  comboData: PropTypes.object.isRequired
};

export default withApplicationContext(ComboItemsMobile);
