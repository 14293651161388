import css from 'styled-jsx/css';
import { colors } from '../../config/styles';

export const ComboSpecificationsStyles = css`
  .combo-specifications-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 24px;
    &.is-drawer {
      padding-top: 24px;
    }
    .combo-item-specifications {
      display: flex;
      padding: 12px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid ${colors.gray.shade65};
      @mixin mobileToTablet {
        margin: 0 16px;
      }
      .item-name {
        color: ${colors.gray.shade45};
        font-size: 18px;
        font-weight: 300;
        line-height: 21.6px;
        letter-spacing: -0.3px;
      }
    }
  }
`;
