import css from 'styled-jsx/css';
import { colors } from '../../../config/styles';

const spinnerVisualStyle = css`
  .spinner {
    animation: rotate 3s linear infinite forwards;
    display: inline-block;
    height: 100%;
    margin: auto;
    width: 100%;
    z-index: 1;
  }

  .spinner.medium {
    max-height: 50px;
  }

  .spinner.small {
    height: 18px;
    max-width: 18px;
  }

  .spinner .spinner__svg {
    display: block;
    height: 100%;
    width: 100%;
    max-height: 50px;
  }

  .spinner.white .spinner__svg .path_circle {
    stroke: ${colors.white.shade1};
  }

  .spinner .spinner__svg .spinner-ie {
    stroke-dasharray: 85;
    stroke-dashoffset: 138;
    animation: collapse 5s linear infinite forwards;
  }

  .spinner.green .spinner__svg .spinner-ie {
    stroke: ${colors.green.shade1};
  }
  .spinner.orange .spinner__svg .spinner-ie {
    stroke: ${colors.orange.shade3};
  }

  .spinner.white .spinner__svg .spinner-ie {
    stroke: ${colors.white.shade1};
  }

  @keyframes rotate {
    to {
      transform: rotate(1turn);
    }
  }

  @keyframes collapse {
    to {
      stroke-dashoffset: -138;
    }
  }
`;

export { spinnerVisualStyle };
