module.exports = {
  searchPlaceholder: 'What are you looking for?',
  relatedCategories: 'RELATED CATEGORIES',
  relatedBrands: 'RELATED BRANDS',
  product: 'Product',
  CATEGORIES: 'Categories',
  MENU: 'Menu',
  FILTERS_TITLE: 'Filtrar por:',
  ADD_TO_CART: 'Add to cart',
  MY_ACCOUNT: 'My account',
  MY_ORDERS: 'My Orders',
  MY_ORDERS_DESC: 'Here you can check the status of your orders.',
  TRACK_ORDER: 'Track my order',
  BAG: 'Bag',
  HELP: 'Help',
  ORDER_BY: 'Order by',
  CLEAR_FILTERS: 'Clear filters',
  SELECTED_FILTERS: 'Selected filters',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  CHOOSE_LOCATION: 'Choose Location',
  /* This is a generic name but in some point we need to consider setting
  the value according to the tenant */
  LOADER_MESSAGE: 'We are processing your request. Please wait a moment',
  SEARCH_WITHOUT_RESULT_TITLE: 'Sorry, we did not find results for',
  SEARCH_WITHOUT_RESULT_DESCRIPTION:
    'Maybe your search was too specific, try to search with a more general term.'
};
