import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withLabels } from '../../../utils/LabelsContext';
import { AvailabilityMobileStyles } from './AvailabilityMobile.style';
import { withApplicationContext } from '../../../utils/ApplicationContext';

const AvailabilityMobileItemDownloadable = ({ labels, appCtx }) => {
  const { isRebrandingEnabled } = appCtx;
  return (
    <Fragment>
      <div className="availability-item">
        <div
          className={`icon downloadAvailable ${isRebrandingEnabled &&
            'rebranded'}`}
        />
        <div className="content-container">
          <div className="content-sub">
            <div id="testId-Availability-Desktop-ModalOpen-downloadAvailable">
              <span className="underline">
                {labels.AVAILABILITY_IMMEDIATE_DELIVERY}{' '}
              </span>
            </div>
            <span className="bold">{`(${
              labels.AVAILABILITY_DOWNLOADABLE
            })`}</span>
          </div>
          <div className="iconCaret" />
        </div>
      </div>
      <style jsx>{AvailabilityMobileStyles}</style>
    </Fragment>
  );
};

AvailabilityMobileItemDownloadable.propTypes = {
  labels: PropTypes.object,
  appCtx: PropTypes.object.isRequired
};

AvailabilityMobileItemDownloadable.defaultProps = {
  labels: {}
};

export default withLabels(
  withApplicationContext(AvailabilityMobileItemDownloadable)
);
