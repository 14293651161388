import React from 'react';
import PropTypes from 'prop-types';
import PDPBasicInfoDesktopStyle from './PDPBasicInfoDesktop.style';
import {
  ProductName,
  BrandName,
  VariantId,
  ProductShareIcon
} from '../../../../../components/PDP';
import { getVariant } from '../../../../../utils/variant';
import Badges from '../../../../../components/Badges/Badges';
import { withRatings } from '../../../../../utils/RatingsContext';
import noop from '../../../../../utils/noop';
import { withApplicationContext } from '../../../../../utils/ApplicationContext';
import _ from '../../../../../utils/LodashImports';
import { RatingAndReviews } from '../../../../../external_dependencies/ratingAndReviews/RatingAndReviews';
import { SKUIdSeller } from '../../../../common/PDPBasicInfoShared';

const PDPBasicInfoDesktop = ({ product, ratings, labels, appCtx }) => {
  const { isOutOfStock } = product;
  const {
    badges = [],
    meatStickers = [],
    offerings = [],
    promotions
  } = getVariant(product.variants, product.currentVariant);
  const reviewsClass = `reviews${badges.length || ratings ? '' : '-hidden'}`; // Don't change this logic, in order to update reviews we need the section to render so data loads
  const rcClass = `reviews-column${ratings ? '' : '-hidden'}`;
  const { isRebrandingEnabled, regionCode } = appCtx;
  const isMeatStickerApplicableInPDP = _.get(
    appCtx,
    'siteConfig.toggles.isMeatStickerApplicableInPDP',
    false
  );
  const freeShippingPromotionFor3p = promotions?.find(
    (promotion) => promotion.campaignName === 'ENVIO GRATIS 3P'
  );

  const hasStickers =
    (isMeatStickerApplicableInPDP && meatStickers.length > 0) ||
    freeShippingPromotionFor3p;

  if (isOutOfStock) {
    return (
      <div
        className={`basic-details-Desktop ${isRebrandingEnabled &&
          'rebranded'} out-of-stock`}
      >
        <div className="corner-details">
          <BrandName brandName={product.brandName} />
        </div>
        <div className="corner-details">
          <ProductName productName={product.name} />
        </div>
        <div className={reviewsClass}>
          <div className={rcClass}>
            <RatingAndReviews />
          </div>
        </div>
        <style jsx>{PDPBasicInfoDesktopStyle}</style>
      </div>
    );
  }

  return (
    <div
      className={`basic-details-Desktop ${isRebrandingEnabled && 'rebranded'}`}
    >
      <div className="corner-details">
        <BrandName brandName={product.brandName} />
        <div className="variant-seller-ids">
          <VariantId
            variantId={product.currentVariant}
            labels={labels}
            isSoCom={appCtx.store === 'so_com'}
          />
          {SKUIdSeller({
            appCtx,
            offerings,
            labels,
            regionCode,
            product,
            customStyle: PDPBasicInfoDesktopStyle
          })}
        </div>
      </div>
      <div className="corner-details">
        <ProductName productName={product.name} />
        <ProductShareIcon
          onClickHandler={noop}
          isRebrandingEnabled={isRebrandingEnabled}
        />
      </div>
      <div className={reviewsClass}>
        <div className={rcClass}>
          <RatingAndReviews />
        </div>
        <div className="reviews-column">
          <Badges
            badgeItems={isMeatStickerApplicableInPDP ? meatStickers : badges}
            deviceType="desktop"
            layout="PDP SOFTLINE"
            isSticker={hasStickers}
            promotionBadge={freeShippingPromotionFor3p?.badge}
          />
        </div>
      </div>
      <style jsx>{PDPBasicInfoDesktopStyle}</style>
    </div>
  );
};

PDPBasicInfoDesktop.propTypes = {
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  ratings: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired
};

export default withRatings(withApplicationContext(PDPBasicInfoDesktop));
