import css from 'styled-jsx/css';
import { colors, icons } from '../../config/styles';
import { svgToDataUri } from '../../utils/styles/svgMixins';

export const SellerRatingsStyles = css`
  .seller-ratings {
    font-size: 14px;
    margin: 24px 16px 16px;
    text-align: center;
    display: flex;
    padding: 12px 12px 16px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid ${colors.gray.shade37};
    @mixin tabletToDesktop {
      margin: 24px 0 16px;
    }
    .bold {
      font-weight: bold;
    }
    .underline {
      text-decoration: underline;
      text-underline-offset: 2px;
    }
    .top-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      .recommended {
        color: ${colors.green.shade26};
      }
      .sold-by-wrapper {
        display: flex;
        gap: 4px;
        .sold-by-text {
          color: ${colors.gray.shade52};
        }
        .seller-name {
          text-transform: capitalize;
        }
      }
      .rating-star {
        height: 16px;
        width: 16px;
        display: inline-block;
        background-repeat: no-repeat;
        &.filled {
          background-image: url("${svgToDataUri(icons.filledStar)}");
        }
        &.empty {
          background-image: url("${svgToDataUri(icons.emptyStar)}");
        }
      }
    }
    .middle-section.ratings {
      display: flex;
      gap: 8px;
      .icon {
        display: block;
        margin: 0 auto;
        width: 44px;
        height: 44px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      } 
      .rating {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      .ontime {
        .high {
           background-image: url("${svgToDataUri(icons.ontimeHigh)}");
        }
        .medium {
          background-image: url("${svgToDataUri(icons.ontimeMedium)}");
        }
        .low {
          background-image: url("${svgToDataUri(icons.ontimeLow)}");
        }
      }

      .cancellations {
        .high {
          background-image: url("${svgToDataUri(icons.cancellationsHigh)}");
        }
        .medium {
          background-image: url("${svgToDataUri(icons.cancellationsMedium)}");
        }
        .low {
          background-image: url("${svgToDataUri(icons.cancellationsLow)}");
        }
      }
      
      .customer {
        .high {
          background-image: url("${svgToDataUri(icons.customerHigh)}");
        }
        .medium {
          background-image: url("${svgToDataUri(icons.customerMedium)}");
        }
        .low {
          background-image: url("${svgToDataUri(icons.customerLow)}");
        }
      }
      
      .text {
        font-size: 12px;
        color: ${colors.gray.shade40};
        font-weight: 400;
        line-height: normal;
        display: block;
      }
    }
  }
`;
