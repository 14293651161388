import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ConnectTabStyles } from './ConnectTab.style';
import WithRouter from '../../../utils/WithRouter';
import { withDigitalData } from '../../../utils/DigitalDataContext';

const ConnectTab = ({ tabData, variant, digitalData }) => {
  const getActiveTabIndex = () => Number(WithRouter('plan') || '0');

  const [activeTab, setActiveTab] = useState(getActiveTabIndex());
  const handleSelectedTab = (tab) => {
    setActiveTab(tab);
  };

  const isLeftTabActive = () => activeTab === 0;
  const isRightTabActive = () => activeTab === 1;

  const leftTabTitle = tabData[0].title(isLeftTabActive());
  const rightTabTitle = tabData[1].title(isRightTabActive());

  const displayLeftTabContent = () => tabData[0].content();
  const displayRightTabContent = () => tabData[1].content();

  digitalData.pdp.setPlansButton(true);

  return (
    <Fragment>
      <div className={`connect-headers-${variant} fa--connect-plan-tabs`}>
        <button
          className={`header-${variant} ${
            isLeftTabActive() ? `active-tab-${variant}` : ''
          }`}
          onClick={handleSelectedTab.bind(null, 0)}
          type="button"
          id="testId-ConnectTab-leftTab"
        >
          {leftTabTitle}
        </button>
        <button
          className={`header-${variant} ${
            isRightTabActive() ? `active-tab-${variant}` : ''
          }`}
          type="button"
          onClick={handleSelectedTab.bind(null, 1)}
          id="testId-ConnectTab-rightTab"
        >
          {rightTabTitle}
        </button>
      </div>
      <div
        className={classNames('tabs-content', {
          'hidden-tab': !isLeftTabActive()
        })}
      >
        {displayLeftTabContent()}
      </div>
      <div
        className={classNames('tabs-content', {
          'hidden-tab': !isRightTabActive()
        })}
      >
        {displayRightTabContent()}
      </div>
      <style jsx>{ConnectTabStyles}</style>
    </Fragment>
  );
};

ConnectTab.defaultProps = {
  tabData: [],
  variant: 'primary'
};

ConnectTab.propTypes = {
  tabData: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.oneOf(['primary', 'secondary', 'rebranding']),
  digitalData: PropTypes.object.isRequired
};

export default withDigitalData(ConnectTab);
