import React from 'react';
import PropTypes from 'prop-types';
import badgesStyles from './MultipurposeBadge.style';
import BodyCopy from '../ui/BodyCopy/BodyCopy';

const MultipurposeBadge = ({ badgeItems, layout, inPdp }) => {
  return (
    <div className={`mtp-pod-badges mtp-pod-badges-${layout}`}>
      {badgeItems &&
        badgeItems.map((badge) => (
          <BodyCopy size="copy5" key={badge.label}>
            {(classes) => (
              <span
                id={`testId-Pod-badges-${badge.label}`}
                className={`${classes} pod-badges-item 
                  ${inPdp ? 'inPdp' : ''}
                `}
                style={{
                  backgroundColor: `${badge.styles.backgroundColor}`,
                  color: `${badge.styles.textColor}`
                }}
              >
                {badge.label}
              </span>
            )}
          </BodyCopy>
        ))}
      <style jsx>{badgesStyles}</style>
    </div>
  );
};

MultipurposeBadge.propTypes = {
  badgeItems: PropTypes.array,
  layout: PropTypes.string,
  inPdp: PropTypes.bool
};

MultipurposeBadge.defaultProps = {
  badgeItems: [],
  layout: '',
  inPdp: false
};

export default MultipurposeBadge;
