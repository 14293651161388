/* stylelint-disable no-descending-specificity */
import css from 'styled-jsx/css';
import { colors } from '../../config/styles';

const TableStyles = css`
  .mobile-table {
    width: 100%;
    td {
      max-width: 144px;
      overflow-wrap: break-word;
    }
    .attr-title-row {
      border-bottom: 1px solid ${colors.gray.shade57};
      position: relative;
      .attr-title {
        position: absolute;
        background: ${colors.white.shade1};
        top: -5px;
        padding: 0 12px;
      }
    }
    .attributes-row {
      border-top: none;
    }
  }
  .desktop-table {
    width: 100%;
  }
  .attributes-row {
    border-top: 1px solid ${colors.gray.shade57};
  }
  .attr-title {
    padding: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: ${colors.gray.shade56};
    @mixin tabletToDesktop {
      color: ${colors.gray.shade58};
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
    }
  }
  .attr-value {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: ${colors.gray.shade58};
    padding: 12px;
    @mixin tabletToDesktop {
      font-size: 17px;
      line-height: 20px;
    }
  }
`;

export { TableStyles };
