/* stylelint-disable selector-pseudo-element-colon-notation */
import css from 'styled-jsx/css';
import colors from '../../../config/styles/colors';

const CarouselStyles = css`
  .carousel-items-container {
    width: 100%;
    background-color: ${colors.white.shade1};
    padding: 0;
    overflow: scroll;
    display: flex;
    flex-shrink: 0;
    position: relative;
    @mixin phabletToDesktop {
      padding: 0 25px 15px;
    }
  }
  .carousel-container {
    background-color: ${colors.white.shade1};
    width: 100%;
    background: white;
    position: relative;
    align-items: center;
    padding: 0 17px 10px;
    @mixin phabletToDesktop {
      padding: 0 34px;
    }
    &-mobile {
      padding: 0;
    }
    &.comparative-table {
      padding: 0 0 7px 11px;
      &.desktop {
        .carousel-items-container {
          overflow: unset;
          padding: 0 25px 15px 120px;
        }
      }
    }
  }

  .carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    position: relative;
  }

  .products-carousel-item-wrapper:not(:first-child)::before {
    content: '';
    display: block;
    position: absolute;
    top: 9.4183%;
    width: 0.0625rem;
    background-color: #f0f0f0;
    height: 71.1911%;
  }
  .fewer-products-carousel-item-wrapper {
    max-width: 240px;
  }

  .divider {
    height: 270px;
    margin: 32px 0 40px 0;
    border-right: 1px solid ${colors.gray.shade14};
  }

  .indicator-wrapper {
    display: flex;
    justify-content: center;
    .indicator-overlay {
      &-left {
        position: absolute;
        top: 5px;
        width: 39%;
        background: #eee;
        height: 15px;
        left: 0;
      }
      &-right {
        position: absolute;
        top: 5px;
        width: 44%;
        background: #eee;
        height: 15px;
        right: 0;
      }
    }
  }
  .products-carousel-items-container {
    width: 100%;
    background-color: ${colors.white.shade1};
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-shrink: 0;
    position: relative;
    @mixin mobileToTablet {
      overflow: scroll;
    }
  }

  .indicator {
    display: flex;
    justify-content: center;
    height: 8px;
    align-items: center;
    margin-top: 20px;

    .dot {
      height: 10px;
      width: 10px;
      outline: none;
      border-radius: 50%;
      margin: 0 7px 0 8px;
      background-color: ${colors.gray.shade33};
      cursor: pointer;
      transition: all 0.2s;
      @mixin phabletToDesktop {
        height: 6px;
        width: 6px;
        margin: 0 3px 0 4px;
      }
      &-active {
        background-color: ${colors.gray.shade40};
      }
    }
  }

  .products-carousel-indicator {
    display: flex;
    height: 15px;
    align-items: center;
    margin-top: 6px;
    @mixin tabletToDesktop {
      justify-content: center;
    }

    .dot {
      height: 0.8rem;
      width: 0.8rem;
      outline: none;
      border-radius: 50%;
      margin: 0 7px 0 8px;
      background-color: ${colors.gray.shade34};
      cursor: pointer;
      transition: all 0.2s;
      @mixin phabletToDesktop {
        margin: 0 3px 0 4px;
      }
      &-active {
        background-color: ${colors.gray.shade53};
      }
      &.products-carousel-dot {
        margin: 0 6px 0 6px;
        @mixin mobileToTablet {
          margin: 0 4px 0 4px;
        }
      }
    }
  }
`;

export { CarouselStyles };
