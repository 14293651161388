import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import noop from '../../../utils/noop';
import { withApplicationContext } from '../../../utils/ApplicationContext';

const MatchMedia = ({ content, appCtx }) => {
  return (
    <Fragment>
      {content[appCtx.deviceType] && content[appCtx.deviceType]()}
    </Fragment>
  );
};

MatchMedia.defaultProps = {
  content: noop
};

MatchMedia.propTypes = {
  appCtx: PropTypes.object.isRequired,
  content: PropTypes.object
};

export default withApplicationContext(MatchMedia);
export { MatchMedia };
