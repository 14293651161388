/* stylelint-disable no-descending-specificity */
import css from 'styled-jsx/css';
import { colors, typography } from '../../../../config/styles';

const SingleChipletSelectionOptionStyles = css`
  .option {
    padding: 7px 7px 8px 12px;
    width: 100%;
    box-sizing: border-box;
    border: 1.1px solid ${colors.gray.shade36};
    background-color: ${colors.gray.shade32};
    border-radius: 3px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    position: relative;
    outline: none;
    color: ${colors.gray.shade40};

    &.mkp {
      height: auto;
    }

    &.background {
      background-color: ${colors.gray.shade3};
      min-height: 38px;
    }
    @mixin tabletToDesktop {
      width: 274px;
    }

    &.option-selected {
      border: 1px solid ${colors.green.shade1};
      background: ${colors.green.shade16};
      color: ${colors.green.shade1};
    }
    &.mkp.additional-option {
      background: ${colors.gray.shade12};
      .checkbox-wrapper {
        margin-right: 4px;
      }
    }
    &.mkp.option-selected {
      border: 1px solid ${colors.gray.shade63};
      background: ${colors.white.shade1};
      color: ${colors.gray.shade40};
      @mixin tabletToDesktop {
        border: 1px solid ${colors.gray.shade63};
      }
    }
    &.mkp.option-not-selected {
      border: 1px solid ${colors.gray.shade46};
    }

    &.pointer {
      cursor: pointer;

      &:disabled {
        cursor: no-drop;
      }
    }

    .checkbox-wrapper {
      margin-right: 10px;
      z-index: 2;

      input[type='checkbox'] {
        opacity: 0;
        cursor: pointer;

        &:disabled {
          cursor: no-drop;
        }
      }
    }

    .selected {
      color: ${colors.green.shade15};
      position: absolute;
      width: 12px;
      height: 12px;
      font-size: ${typography.sm};
      left: 10px;
      top: 10px;
      z-index: 1;
    }

    .label {
      height: auto;
      font-size: ${typography.base};
      line-height: 13px;
      color: inherit;
      font-weight: 700;
      &.mkp {
        text-align: left;
        line-height: 15px;
      }
    }
  }

  .option.medium {
    height: auto;
    width: 100%;
    margin: 10px 0;

    @mixin tabletToDesktop {
      width: 270px;
      margin: 8px 0 0 0;
    }
    @mixin phabletToTablet {
      width: 100%;
    }
    .label {
      font-size: ${typography.sm1};
      line-height: 17px;
    }
    .label.mobile {
      font-size: ${typography.base};
    }
  }
  .option.mobile {
    span {
      line-height: 17px;
    }
  }
  .option.cart-mobile {
    .label {
      font-size: ${typography.base};
    }
  }
`;

export { SingleChipletSelectionOptionStyles };
