import css from 'styled-jsx/css';
import { svgUrl } from '../../../utils/staticUrls';
import { icons, colors } from '../../../config/styles';
import { svgToDataUri } from '../../../utils/styles/svgMixins';
import _ from '../../../utils/LodashImports';

const WarrantySelectStyles = (theme) => {
  const secondaryColor =
    _.get(theme, 'secondary_color', null) || colors.secondary_color;

  return css`div.warrantyOptions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 12px;
    line-height: 22px;
    max-width: 800px;
    width: 660px;
    height: 100%;
    margin-bottom: 15px;
    &.center-options {
      align-items: center;
    }

    &.warrantyOptions-mobile {
      overflow-x: scroll;
    }
  }

  table {
    line-height: 15px;
  }

  th {
    font-weight: bold;
    font-size: 14px;
    padding: 9px 6px 0 6px;
  }

  th > span {
    font-weight: normal;
    font-size: 12px;
  }

  th,
  td {
    border: 1px solid #e0e0e0;
  }

  th.warrantyOptions__right-border,
  td.warrantyOptions__right-border {
    border-right: 1px solid #8fca00;

    &.mkp {
      border-right: 1px solid #4a5867;
    }
  }

  th.warrantyOptions__header {
    position: relative;
    height: 71px;
    width: 140px;
    background-color: #f5f5f5;
  }

  th.warrantyOptions__header > .csicon-shield {
        width: 32px;
        height: 32px;
        background-image: url("${svgToDataUri(
          icons.extendedWarrantyRebranded
        )}");
        background-size: 100%;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &::before {
          content: '';
        }
  }

  td.warrantyOptions__header {
    padding: 6px 10px;
    background-color: #f5f5f5;
    font-size: 12px;
    line-height: 14px;
    color: #333;
  }

  td.warrantyOptions__body {
    position: relative;
    text-align: center;
    width: 130px;

    & > * {
      position: absolute;
      left: 45%;
      top: 37%;
    }
  }

  div.warrantyOptions__cross {
    width: 25px;
    height: 25px;
    background-image: url(${svgUrl('cross.svg')});
    background-repeat: no-repeat;
  }

  div.warrantyOptions__check {
    width: 25px;
    height: 25px;
    background-image: url(${svgUrl('retail-check.svg')});
    background-repeat: no-repeat;

    &.mkp {
      background-image: url(${svgUrl('marketplace-check.svg')});
    }
  }

  td.warrantyOptions__prices {
    font-weight: bold;
  }

  th.warrantyOptions__recommended {
    position: relative;
    border-top: 1px solid #8fca00;
    background-color: #fcfff2;

    & > .warrantyOptions__header-text {
      position: absolute;
      top: 40%;
    }

    &.mkp {
      background-color: #f5f5f5;
      border-top: 1px solid #4a5867;
    }
  }

  p.warrantyOptions__recommended-text {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 20px;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    background-color: #d2ea99;
    margin: 0 14px;

    &.mkp {
      background-color: #febf2d;
    }
  }

  td.warrantyOptions__recommended-last {
    border-bottom: 1px solid #8fca00;
    border-right: 1px solid #8fca00;
    border-left: 1px solid #8fca00;
    background-color: #fcfff2;

    &.mkp {
      background-color: #f5f5f5;
      border-bottom: 1px solid #4a5867;
      border-right: 1px solid #4a5867;
      border-left: 1px solid #4a5867;
    }
  }

  th.warrantyOptions__recommended,
  td.warrantyOptions__recommended {
    border-left: 1px solid #8fca00;
    border-right: 1px solid #8fca00;
    background-color: #fcfff2;

    &.mkp {
      background-color: #f5f5f5;
      border-left: 1px solid #4a5867;
      border-right: 1px solid #4a5867;
    }
  }

  div.warrantyOptions__bottom {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    align-self: flex-end;

    &.warrantyOptions__bottom-mobile {
      display: inline;
    }

    & > * {
      padding: 14px;
    }
  }

  tr.warrantyOptions__bottom {
    td {
      text-align: center;
      vertical-align: bottom;
      border: none;
    }
    div {
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      position: relative;
      margin-top: 16px;
    }
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  div.warrantyOptions__option {
    color: ${colors.gray.shade52};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid ${colors.gray.shade60};
    box-sizing: border-box;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    height: 45px;
    line-height: 16px;
    text-align: center;
    width: 110px;

    & > span {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 95px;
      white-space: nowrap;
    }
  }

  div.warrantyOptions__option--selected {
    border: 1px solid #8fca00;
    color: #8fca00;
    background-color: #fcfff2;

    &.mkp {
      border: 2px solid ${secondaryColor};
      background-color: #fff;
      color: #333;
    }
  }

  div.warrantyOptions__bottom > *:not(:last-child) {
    margin-right: 20px;
    @mixin phabletToDesktop {
      margin-right: 35px;
    }
  }

  div.warrantyOptions__bottom > *:last-child {
    margin-right: 8px;
    @mixin phabletToDesktop {
      margin-right: 15px;
    }
  }
  .warrantyOptions_bottomLabel {
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.171429px;
    color: #495867;
    margin-top: 14px;
  }`;
};
export { WarrantySelectStyles };
