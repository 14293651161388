import getVariant from './getVariant';
import {
  getUniqueSizes,
  isSizesValid,
  getUniqueAvailableSizes
} from './getUniqueSizes';
import { getUniqueOperators } from './getUniqueOperators';
import {
  getUniqueColorVariants,
  getSelectedColorSwatchIndex,
  getUniqueColorVariantsSLP
} from './getUniqueColorVariants';
import sizeOrder from './constants';
import {
  getMatchingVariants,
  getFirstMatchingVariantId,
  getCheapestVariantId
} from './chooseVariant';
import { isAvailable, isNetworkInStock } from './availability';

export {
  getVariant,
  getUniqueSizes,
  isSizesValid,
  getUniqueAvailableSizes,
  getUniqueOperators,
  getUniqueColorVariants,
  getSelectedColorSwatchIndex,
  sizeOrder,
  getMatchingVariants,
  getFirstMatchingVariantId,
  isAvailable,
  isNetworkInStock,
  getCheapestVariantId,
  getUniqueColorVariantsSLP
};
