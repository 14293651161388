import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';

export const selectInputStyles = css`
  .formContainer-item {
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    flex: 1 1 auto;
    margin-right: 2%;

    &:last-child {
      margin-right: 0;
    }

    .dropdown {
      position: relative;
      width: 100%;
      select {
        font-family: inherit;
        color: ${colors.black.shade6};
        font-size: ${typography.base};
        line-height: 17px;
        appearance: none;
        border-radius: 3px;
        background-color: ${colors.white.shade1};
        border: solid ${colors.gray.shade5};
        border-width: 0 0 1px 0;
        max-width: 100%;
        min-width: 100%;
        outline: none;
        padding: 0 40px 10px 0;
        width: 100%;
        cursor: pointer;
        &::-ms-expand {
          display: none;
        }
        &:disabled {
          cursor: no-drop;
          color: ${colors.gray.shade1};
        }
      }
      i {
        pointer-events: none;
        position: absolute;
        color: ${colors.black.shade1};
        top: 25%;
        right: 0;
        line-height: 10px;

        &::before {
          color: ${colors.black.shade1};
          font-size: ${typography.xxs1};
          font-weight: 700;
        }
      }
    }
  }

  .heading {
    display: flex;
    color: ${colors.black.shade7};
    font-weight: bold;
    font-size: ${typography.base};
    line-height: 1.1em;
    margin-top: 25px;
    margin-bottom: 10px;
  }
`;
