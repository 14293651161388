import React from 'react';
import PropTypes from 'prop-types';
import { withLabels } from '../../../../utils/LabelsContext';
import { priceCalendarStyle } from './PriceCalendar.style';
import BodyCopy from '../../../ui/BodyCopy/BodyCopy';
import constants from '../../../../config/constants';
import _ from '../../../../utils/LodashImports';

const getMaxTimeSlot = (slots) => {
  let max = 0;

  slots.forEach(({ customTimeSlots }) => {
    if (customTimeSlots.length > max) {
      max = customTimeSlots.length;
    }
  });

  return max;
};

const normalizeTimeSlots = (maxSlotCount, customTimeSlots) => {
  const dummySlotCount = maxSlotCount - customTimeSlots.length;
  const dummySlots = Array(dummySlotCount)
    .fill(1)
    .map((__, i) => ({
      cost: ' ',
      timeRange: `dummy-${i}`
    }));

  return [...customTimeSlots, ...dummySlots];
};

const PriceCalendar = ({ slots }) => {
  const customTimeSlots = _.get(slots, '[0].customTimeSlots', []);
  const maxSlotCount = getMaxTimeSlot(slots);

  return (
    <div className="calendar">
      <div className="calendar-side-menu">
        <div className="row-1"> </div>
        {customTimeSlots.map(({ timeRange }) => (
          <div className="calender-item" key={timeRange}>
            <BodyCopy size="copy3">{timeRange}</BodyCopy>
          </div>
        ))}
      </div>
      <div className="calendar-date-price">
        {slots.map((slot) => (
          <div
            key={[
              slot.slotDate.day,
              slot.slotDate.date,
              slot.slotDate.month
            ].join(',')}
          >
            <div className="row-1">
              <BodyCopy size="copy3">
                {constants.SPANISH_MONTHS_MAPPING[slot.slotDate.day]}
                {'. '}
                {slot.slotDate.date}
              </BodyCopy>
            </div>

            {normalizeTimeSlots(maxSlotCount, slot.customTimeSlots).map(
              ({ cost, timeRange }) => (
                <div className="calender-item">
                  <BodyCopy
                    size="copy3"
                    isHighlighted
                    key={`${timeRange}-${cost}`}
                  >
                    {cost}
                  </BodyCopy>
                </div>
              )
            )}
          </div>
        ))}
      </div>
      <style jsx>{priceCalendarStyle}</style>
    </div>
  );
};

PriceCalendar.defaultProps = {};

PriceCalendar.propTypes = {
  slots: PropTypes.array.isRequired
};

export { PriceCalendar };
export default withLabels(PriceCalendar);
