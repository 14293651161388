import _ from '../../utils/LodashImports';
import { getVariant } from '../../utils/variant';
import {
  getPriceToNumber,
  formatNumberToPrice
} from '../../utils/PriceHelpers';

const DEFAULT_PRICE_BLOCK = {
  label: '',
  price: [0],
  symbol: '',
  icons: '',
  type: '',
  crossed: ''
};

const mergePriceBlock = (regionCode, p1, p2) => {
  return {
    label: p1.label || p2.label,
    price: [p1.price[0] + getPriceToNumber(p2.price[0], regionCode)],
    symbol: p1.symbol || p2.symbol,
    icons: p1.icons || p2.icons,
    type: p1.type || p2.type,
    crossed: p1.crossed && p2.crossed
  };
};

const getTotalPrice = (prices, regionCode, isForRecos = false) => {
  const calculatedPrice = [];

  let isNormalPriceCrossed = false;
  let maxLength = 0;
  let offerPriceBlock = { ...DEFAULT_PRICE_BLOCK };
  let normalPriceBlock = { ...DEFAULT_PRICE_BLOCK };

  const selectedPrice = prices.filter((price) => {
    if (price.prices && maxLength < price.prices.length) {
      maxLength = price.prices.length;
    }

    return price.isSelected;
  });

  const cmrPriceBlock = selectedPrice.find((price) =>
    _.get(price, `prices[0].icons`, '')
  );

  // CMR Price Block
  if (cmrPriceBlock) {
    let price = 0;

    selectedPrice.forEach((p) => {
      price += getPriceToNumber(_.get(p, `prices[0].price[0]`, ''), regionCode);
    });

    calculatedPrice.push({
      label: cmrPriceBlock.prices[0].label,
      price: [formatNumberToPrice(price, regionCode)],
      symbol: cmrPriceBlock.prices[0].symbol,
      icons: cmrPriceBlock.prices[0].icons,
      type: cmrPriceBlock.prices[0].type,
      crossed: cmrPriceBlock.prices[0].crossed
    });
  }

  selectedPrice.forEach((price) => {
    if (price.prices) {
      const lastPriceIndex = price.prices.length - 1;
      let offerPriceIndex = 0;

      if (_.get(price, `prices[0].icons`, '')) {
        offerPriceIndex = 1;
      }

      if (price.prices[lastPriceIndex].crossed) {
        isNormalPriceCrossed = true;
      }

      offerPriceBlock = mergePriceBlock(
        regionCode,
        offerPriceBlock,
        price.prices[offerPriceIndex]
      );

      normalPriceBlock = mergePriceBlock(
        regionCode,
        normalPriceBlock,
        price.prices[lastPriceIndex]
      );
    }
  });

  if (!cmrPriceBlock || maxLength > 1) {
    calculatedPrice.push({
      ...offerPriceBlock,
      price: [formatNumberToPrice(offerPriceBlock.price[0], regionCode)],
      crossed: maxLength === 3 ? false : offerPriceBlock.crossed
    });
  }

  if (maxLength === 3 || isForRecos) {
    calculatedPrice.push({
      ...normalPriceBlock,
      price: [formatNumberToPrice(normalPriceBlock.price[0], regionCode)],
      crossed: isNormalPriceCrossed
    });
  }

  return {
    selectedPrice,
    calculatedPrice
  };
};

const reConstructProduct = (reConProduct) => {
  const { id, name, brandName, currentVariant, variants } = reConProduct;
  // to keep the BU response and ATG prod response in same structure
  const constructedProduct = {
    id,
    brand: brandName,
    displayName: name,
    isSelected: true,
    prices: getVariant(variants, currentVariant).prices
  };
  return constructedProduct;
};

const canChangeBundle = (changedProduct, bundledProducts) => {
  if (!changedProduct.isSelected) {
    const selectedBundleCount = bundledProducts.reduce((count, item) => {
      return item.isSelected ? count + 1 : count;
    }, 0);

    return selectedBundleCount > 1;
  }
  return true;
};

export { getTotalPrice, reConstructProduct, canChangeBundle };
