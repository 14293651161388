import React, { Fragment } from 'react';
import MatchMedia from '../ui/MatchMedia/MatchMedia';
import SellerInfoDesktop from './Desktop/SellerInfoDesktop';
import SellerInfoMobile from './Mobile/SellerInfoMobile';

const SellerInfo = (props) => (
  <Fragment>
    <MatchMedia
      content={{
        mobile: () => <SellerInfoMobile {...props} />,
        tablet: () => <SellerInfoMobile {...props} />,
        desktop: () => <SellerInfoDesktop {...props} />
      }}
    />
  </Fragment>
);

export default SellerInfo;
