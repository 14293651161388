import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ProductColorSwatches from '../../../../components/ProductColorSwatches/ProductColorSwatches';
import { withProduct } from '../../../../utils/ProductContext';
import { withApplicationContext } from '../../../../utils/ApplicationContext';
import { withLabels } from '../../../../utils/LabelsContext';
import { ProductSpecificationsMobileStyle } from './ProductSpecificationsMobile.style';
import noop from '../../../../utils/noop';
import Size from '../../../../components/Size/Size';
import constants from '../../../../config/constants';
import ProductPrices from '../../../../components/ProductPrices/ProductPrices';
import ProductTopSpecifications from '../../../../components/ProductTopSpecifications/ProductTopSpecifications';
import Operator from '../../../../components/Operator/Operator';
import SellerInfo from '../../../../components/SellerInfo/SellerInfo';
import * as productUtils from '../../../../utils/product/productUtils';
import { getVariant } from '../../../../utils/variant';
import StockQuantity from '../../../../components/StockQuantity/StockQuantity';
import StockTable from '../../../../components/ui/StockTable/StockTable';
import AddToCartButton from '../../../../components/AddToCartButton/Desktop/AddToCartButtonDesktop';
import _ from '../../../../utils/LodashImports';
import AdditionalPDPLabel from '../../../../components/AdditionalPDPLabel/AdditionalPDPLabel';
import FloorCalculator from '../../../../components/FloorCalculator/FloorCalculator';
import MultipurposeBadge from '../../../../components/MultipurposeBadge/MultipurposeBadge';
import AddToCartButtonMobile from '../../../../components/AddToCartButton/Mobile/AddToCartButtonMobile';
import InternationalShipping from '../../../../components/InternationalShipping/InternationalShipping';
import GenericCalculatorContainer from '../../../GenericCalculatorContainer/GenericCalculatorContainer';
import useInput from '../../useInput';
import { VariantId } from '../../../../components/PDP';
import { SKUIdSeller } from '../../../common/PDPBasicInfoShared';
import { ReaconditionedContainer } from '../../../../components/ReaconditionedContainer/ReaconditionedContainer';
import { GetSharedDetails } from '../../shared';

const ProductSpecificationsMobile = ({
  product,
  labels,
  appCtx,
  isPumAvailable,
  pumQuantity,
  primeShipping,
  colorVariants,
  selectedColorSwatchIndex,
  colorSwatchHandler,
  sizes,
  selectedSize,
  sizeChangeHandler,
  handleAddToCart,
  isAddToCartInProgress,
  addToCartButtonVisible,
  showCollectionButtonVisible,
  operators,
  operatorChangeHandler,
  setWarrantyOption,
  setServiceOption,
  isVendorLogin,
  storeSkuData,
  currentTab,
  selectedOperatorId,
  errorAddingToCart,
  requestProductFromStore,
  handleM2QuantityChange,
  m2Quantity,
  m2ResultValue,
  handleM2ResultChange,
  m2Value,
  handleM2ValueChange,
  isExtraM2Checked,
  handleExtraM2Change,
  m2AttributeValue,
  setShowLoginForm,
  showLoginForm,
  setErrorAddingToCart
}) => {
  const isInternationalShipping = _.get(
    product,
    'internationalShipping.applicable',
    false
  );
  const { regionCode, deviceType, isRebrandingEnabled, store } = appCtx;
  const {
    id: productId,
    currentVariant,
    variants,
    additionalPDPLabels,
    isOutOfStock,
    colorMetrics
  } = product;
  const variant = getVariant(variants, currentVariant);
  const maxCartQuantity = _.get(
    variant,
    'maxOnCartAmount',
    constants.MAX_CART_PRODUCT
  );
  const comboData = _.get(variant, 'comboData', null);
  const {
    showQuantityError,
    inputVal,
    handleInputChange,
    setQuantityError,
    setInputVal
  } = useInput(maxCartQuantity);
  const { viewTemplate, pumUnits } = product.attributes;

  const sellerName = _.get(variants, '[0].offerings[0].sellerName', '');
  const is1pSeller = constants.FIRST_PARTY_SELLER_REGEX.test(
    sellerName.toLowerCase()
  );
  const multipurposeBadges = _.get(variant, 'multipurposeBadges', []);
  const { prices, cmrPoints, discountBadge } = variant;
  const type =
    product.productType === constants.PRODUCT_CONSTANTS.CONNECT
      ? constants.PRODUCT_CONSTANTS.CONNECT
      : constants.PRODUCT_CONSTANTS.HARDLINE;
  const isConnectProduct = productUtils.isConnectProduct(product);
  const isMobile = deviceType === constants.DEVICE_MOBILE;

  const shouldRenderSize =
    viewTemplate !== constants.VIEW_TEMPLATE.TYPE_A && sizes.length > 0;

  const isStoreDataVaild = storeSkuData && Object.keys(storeSkuData).length > 0;
  const addToCartLabel = _.get(additionalPDPLabels, 'addToCart.enabled', false)
    ? _.get(additionalPDPLabels, 'addToCart', {})
    : null;

  const isMultipurposeBadgeApplicable = _.get(
    appCtx,
    'siteConfig.toggles.isMultipurposeBadgeApplicable',
    false
  );

  const showStoreAvailability = _.get(
    appCtx,
    'siteConfig.toggles.isStoreAvailabilityVisibleInPDP',
    false
  );

  const additionalWarrantyLabel = _.get(
    additionalPDPLabels,
    'warranty.enabled',
    false
  )
    ? _.get(additionalPDPLabels, 'warranty', {})
    : null;
  const renderColorSwatch = () => {
    return (
      <Fragment>
        <span>{`${labels.COLOR}: `}</span>
        {colorVariants[selectedColorSwatchIndex] &&
          colorVariants[selectedColorSwatchIndex].label}
        <ProductColorSwatches
          colorVariants={colorVariants}
          productId={product.id}
          selectedColorSwatchIndex={selectedColorSwatchIndex}
          colorSwatchHandler={colorSwatchHandler}
          type={type}
          size="large"
          appCtx={appCtx}
          colorMetrics={colorMetrics}
        />
      </Fragment>
    );
  };

  const showCMRCalculator = _.get(
    appCtx,
    'siteConfig.toggles.isCMRCalculatorEnabledPDP',
    false
  );

  const isWarrantyTooltipEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isWarrantyTooltipEnabled',
    false
  );

  const isInternationalBuyVisiblePDP = _.get(
    appCtx,
    'siteConfig.toggles.isInternationalBuyVisiblePDP',
    false
  );

  const isFloorCalculatorEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isFloorcalculatorEnabled',
    true
  );
  const isSoCom = store === 'so_com';
  const isReaconditionedProd = productUtils.isReaconditionedProduct(product);

  if (isOutOfStock) {
    return (
      <div
        className="product-specifications"
        data-backend-category={product.merchantCategoryId}
        data-availability={
          product.attributes && product.attributes.isPrimeAvailable
        }
        data-published={product.isPublished}
        data-variations={variants.length}
      >
        <div className="product-specifications-column">
          {isConnectProduct && renderColorSwatch()}
          <div className={classNames({ flex: !isMobile })}>
            <div>
              {prices && prices.length > 0 && (
                <div className="prices">
                  <ProductPrices
                    isVendorLogin={isVendorLogin}
                    currentTab={currentTab}
                    storeSkuData={storeSkuData}
                    prices={productUtils.getPriceForOutOfStock(prices)}
                    productId={productId}
                    regionCode={regionCode}
                    cmrPoints={cmrPoints}
                    isPumAvailable={isPumAvailable}
                    pumQuantity={pumQuantity}
                    pumUnit={pumUnits}
                    primeShipping={primeShipping}
                    isMobile={isMobile}
                    operators={operators}
                    selectedOperatorId={selectedOperatorId}
                    isConnectProduct={isConnectProduct}
                  />
                </div>
              )}
              <SellerInfo product={product} />
              {isReaconditionedProd && (
                <ReaconditionedContainer
                  appCtx={appCtx}
                  productAttributes={product}
                />
              )}
            </div>
          </div>
        </div>
        <style jsx>{ProductSpecificationsMobileStyle}</style>
      </div>
    );
  }

  return (
    <div
      className="product-specifications"
      data-backend-category={product.merchantCategoryId}
      data-availability={
        product.attributes && product.attributes.isPrimeAvailable
      }
      data-published={product.isPublished}
      data-variations={variants.length}
    >
      <div className="product-specifications-column">
        {isConnectProduct && renderColorSwatch()}
        <div className={classNames({ flex: !isMobile })}>
          <div>
            {prices && prices.length > 0 && (
              <div className="prices">
                <ProductPrices
                  isVendorLogin={isVendorLogin}
                  currentTab={currentTab}
                  storeSkuData={storeSkuData}
                  prices={prices}
                  productId={productId}
                  regionCode={regionCode}
                  cmrPoints={cmrPoints}
                  isPumAvailable={isPumAvailable}
                  pumQuantity={pumQuantity}
                  pumUnit={pumUnits}
                  primeShipping={primeShipping}
                  isMobile={isMobile}
                  operators={operators}
                  selectedOperatorId={selectedOperatorId}
                  isConnectProduct={isConnectProduct}
                  discountBadge={discountBadge}
                />
              </div>
            )}

            {isMultipurposeBadgeApplicable && multipurposeBadges.length > 0 && (
              <div className="multipurpose-badge">
                <MultipurposeBadge
                  badgeItems={multipurposeBadges}
                  deviceType="mobile"
                  inPdp
                />
              </div>
            )}
            <StockQuantity />
            {m2AttributeValue > 0 && isFloorCalculatorEnabled && (
              <div className="floorCalc-pdp-container">
                <FloorCalculator
                  appCtx={appCtx}
                  m2AttributeValue={m2AttributeValue}
                  handleM2QuantityChange={handleM2QuantityChange}
                  handleM2ResultChange={handleM2ResultChange}
                  m2Value={m2Value}
                  m2ResultValue={m2ResultValue}
                  handleM2ValueChange={handleM2ValueChange}
                  isExtraM2Checked={isExtraM2Checked}
                  handleExtraM2Change={handleExtraM2Change}
                />
              </div>
            )}
            <SellerInfo product={product} />
            {isInternationalBuyVisiblePDP && isInternationalShipping && (
              <InternationalShipping />
            )}
            {isReaconditionedProd && (
              <ReaconditionedContainer
                appCtx={appCtx}
                productAttributes={product}
              />
            )}
            {addToCartLabel && (
              <AdditionalPDPLabel
                label={addToCartLabel}
                type="addToCart"
                productType="hardline"
              />
            )}
            <GenericCalculatorContainer handleInputChange={handleInputChange} />
            {colorVariants[selectedColorSwatchIndex] && (
              <div className="color-swatch-container fa--color-swatches__mobile">
                {!isConnectProduct && renderColorSwatch()}
              </div>
            )}
            {isConnectProduct && (
              <Operator
                operators={operators}
                operatorChangeHandler={operatorChangeHandler}
                selectedOperatorId={selectedOperatorId}
                isRebrandingEnabled={appCtx.isRebrandingEnabled}
                isConnectProduct={isConnectProduct}
              />
            )}

            {shouldRenderSize && (
              <Size
                title={labels.SELECTION_SIZE}
                sizes={sizes}
                onSizeChange={sizeChangeHandler}
                selectedSize={selectedSize}
              />
            )}
          </div>
          {isVendorLogin && isStoreDataVaild && (
            <div className="stock-table">
              <StockTable
                stockData={storeSkuData}
                colorVariants={colorVariants}
                selectedColorSwatchIndex={selectedColorSwatchIndex}
                sizes={sizes}
                isMobile={isMobile}
                requestProductFromStore={requestProductFromStore}
                regionCode={regionCode}
              />
            </div>
          )}
        </div>
        {!isConnectProduct && (
          <ProductTopSpecifications
            scollToElementId="productInfoContainer"
            isRebrandingEnabled={isRebrandingEnabled}
          />
        )}
        <div className="variant-seller-ids">
          <VariantId
            variantId={product.currentVariant}
            labels={labels}
            isSoCom={isSoCom}
          />
          {SKUIdSeller({
            appCtx,
            offerings: variant.offerings,
            labels,
            regionCode,
            product
          })}
        </div>
        {addToCartButtonVisible && isVendorLogin && (
          <AddToCartButton
            mobileView
            addToCartButtonVisible={addToCartButtonVisible}
            isAddToCartInProgress={isAddToCartInProgress}
            labels={labels}
            handleAddToCart={handleAddToCart}
            isVendorLogin={isVendorLogin}
            sizes={sizes}
            selectedSize={selectedSize}
            setShowLoginForm={setShowLoginForm}
            showLoginForm={showLoginForm}
            setErrorAddingToCart={setErrorAddingToCart}
            showQuantityError={showQuantityError}
            setQuantityError={setQuantityError}
            inputVal={inputVal}
            setInputVal={setInputVal}
            handleInputChange={handleInputChange}
          />
        )}
        <GetSharedDetails
          sizes={sizes}
          colorVariants={colorVariants}
          regionCode={regionCode}
          store={store}
          is1pSeller={is1pSeller}
          showStoreAvailability={showStoreAvailability}
          showCollectionButtonVisible={showCollectionButtonVisible}
          handleAddToCart={handleAddToCart}
          comboData={comboData}
          product={product}
          labels={labels}
          showCMRCalculator={showCMRCalculator}
          setServiceOption={setServiceOption}
          setWarrantyOption={setWarrantyOption}
          isWarrantyTooltipEnabled={isWarrantyTooltipEnabled}
          additionalWarrantyLabel={additionalWarrantyLabel}
          layout="hardline"
          isSoCom={isSoCom}
          appCtx={appCtx}
        />

        {addToCartButtonVisible && !isVendorLogin && (
          <AddToCartButtonMobile
            maxCartQuantity={maxCartQuantity}
            labels={labels}
            m2Object={{
              m2AttributeValue,
              m2ResultValue,
              m2Quantity,
              isExtraM2Checked,
              handleM2QuantityChange,
              m2Value
            }}
            errorAddingToCart={errorAddingToCart}
            isAddToCartInProgress={isAddToCartInProgress}
            appCtx={appCtx}
            handleAddToCart={handleAddToCart}
            showQuantityError={showQuantityError}
            setQuantityError={setQuantityError}
            inputVal={inputVal}
            setInputVal={setInputVal}
            handleInputChange={handleInputChange}
            sizes={sizes}
            selectedSize={selectedSize}
          />
        )}
      </div>
      <style jsx>{ProductSpecificationsMobileStyle}</style>
    </div>
  );
};

ProductSpecificationsMobile.defaultProps = {
  isPumAvailable: false,
  pumQuantity: 0,
  primeShipping: false,
  colorVariants: [],
  selectedColorSwatchIndex: 0,
  colorSwatchHandler: noop,
  sizes: [],
  selectedSize: '',
  sizeChangeHandler: noop,
  handleAddToCart: noop,
  isAddToCartInProgress: false,
  addToCartButtonVisible: true,
  showCollectionButtonVisible: false,
  operators: [],
  operatorChangeHandler: noop,
  setWarrantyOption: noop,
  setServiceOption: noop,
  requestProductFromStore: noop,
  storeSkuData: {},
  currentTab: '',
  selectedOperatorId: null,
  errorAddingToCart: false,
  handleM2QuantityChange: noop,
  m2Quantity: 1,
  m2ResultValue: 0,
  handleM2ResultChange: noop,
  m2Value: '',
  handleM2ValueChange: noop,
  isExtraM2Checked: true,
  handleExtraM2Change: noop,
  m2AttributeValue: -1,
  showLoginForm: false
};

ProductSpecificationsMobile.propTypes = {
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  isPumAvailable: PropTypes.bool,
  pumQuantity: PropTypes.number,
  primeShipping: PropTypes.bool,
  colorVariants: PropTypes.array,
  selectedColorSwatchIndex: PropTypes.number,
  colorSwatchHandler: PropTypes.func,
  sizes: PropTypes.array,
  selectedSize: PropTypes.string,
  sizeChangeHandler: PropTypes.func,
  handleAddToCart: PropTypes.func,
  isAddToCartInProgress: PropTypes.bool,
  addToCartButtonVisible: PropTypes.bool,
  showCollectionButtonVisible: PropTypes.bool,
  operators: PropTypes.array,
  operatorChangeHandler: PropTypes.func,
  setWarrantyOption: PropTypes.func,
  setServiceOption: PropTypes.func,
  requestProductFromStore: PropTypes.func,
  isVendorLogin: PropTypes.bool.isRequired,
  storeSkuData: PropTypes.object,
  currentTab: PropTypes.string,
  selectedOperatorId: PropTypes.string,
  errorAddingToCart: PropTypes.bool,
  handleM2QuantityChange: PropTypes.func,
  m2Quantity: PropTypes.number,
  m2ResultValue: PropTypes.number,
  handleM2ResultChange: PropTypes.func,
  m2Value: PropTypes.string,
  handleM2ValueChange: PropTypes.func,
  isExtraM2Checked: PropTypes.bool,
  handleExtraM2Change: PropTypes.func,
  m2AttributeValue: PropTypes.number,
  setShowLoginForm: PropTypes.func.isRequired,
  setErrorAddingToCart: PropTypes.func.isRequired,
  showLoginForm: PropTypes.bool
};

export default withProduct(
  withLabels(withApplicationContext(ProductSpecificationsMobile))
);
