import constants from '../../../config/constants';
import _ from '../../../utils/LodashImports';

export const getAdditionalServicesUrl = (
  sellerName,
  regionCode,
  endPoints,
  isSodimacStandAlone = false
) => {
  const urlsMapper = {
    'cl-falabella': _.get(
      endPoints,
      'SERVICES_CL_FALABELLA',
      'https://tienda.falabella.com/falabella-cl/page/servicios-falabella'
    ),
    'cl-sodimac': _.get(
      endPoints,
      'SERVICES_CL_SODIMAC',
      'https://sodimac.falabella.com/sodimac-cl/page/servicios'
    ),
    'cl-tottus': _.get(
      endPoints,
      'SERVICES_CL_TOTTUS',
      'https://tottus.falabella.com/tottus-cl/page/servicios'
    ),
    'pe-falabella': _.get(
      endPoints,
      'SERVICES_PE_FALABELLA',
      'https://tienda.falabella.com.pe/falabella-pe/page/servicios_test'
    ),
    'pe-sodimac': _.get(
      endPoints,
      'SERVICES_PE_SODIMAC',
      'https://sodimac.falabella.com.pe/sodimac-pe/page/servicios-hogar'
    ),
    'pe-tottus': _.get(
      endPoints,
      'SERVICES_PE_TOTTUS',
      'https://tottus.falabella.com.pe/tottus-pe/page/Servicios_test'
    ),
    'co-falabella': _.get(
      endPoints,
      'SERVICES_CO_TIENDA',
      'https://https://tienda.falabella.com/falabella-co/page/page/servicios'
    ),
    'co-sodimac': _.get(
      endPoints,
      'SERVICES_CO_SODIMAC',
      'https://www.homecenter.com.co/homecenter-co/servicios/'
    ),
    'cl-sodimac-so-com': _.get(
      endPoints,
      'SO_COM_SERVICES_CL_SODIMAC',
      'https://www.sodimac.cl/sodimac-cl/content/servicios'
    ),
    'pe-sodimac-so-com': _.get(
      endPoints,
      'SO_COM_SERVICES_PE_SODIMAC',
      'https://www.sodimac.com.pe/sodimac-pe/content/servicios-hogar'
    )
  };
  let sellerClass = '';
  if (constants.FALABELLA_REGEX.test(sellerName)) sellerClass = 'falabella';
  else if (constants.SODIMAC_REGEX.test(sellerName)) sellerClass = 'sodimac';
  else if (constants.TOTUS_REGEX.test(sellerName)) sellerClass = 'tottus';
  else sellerClass = '';
  sellerClass = isSodimacStandAlone ? `${sellerClass}-so-com` : sellerClass;
  return urlsMapper[`${regionCode}-${sellerClass}`];
};
