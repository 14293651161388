import { useEffect, useState } from 'react';
import constants from '../../config/constants';

const myFavsTagname = 'Mis favoritos';

const useMyLists = ({
  product,
  isPDPpage,
  myLists: {
    addTolList,
    myListData = [],
    deleteFromList,
    getAllLists,
    setAddedLists: onSuccessAddToList,
    setUndo,
    setAlertType,
    showMyLists,
    myListMetrics
  },
  setModalVisible,
  setShowLoginForm
}) => {
  const [error, setError] = useState('');
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productIsInAnyList, setProductIsInAnyList] = useState(false);
  const [userSelectedLists, setUserSelectedLists] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  const {
    ALREADY_ADDED,
    NOT_ADDED,
    SAVE_NEW_LIST,
    DO_NOT_SAVE_MYFAVS,
    NOT_SAVE_IN_L2,
    NOT_SAVE_IN_L3,
    NOT_SAVE_IN_L4,
    NOT_SAVE_IN_L5
  } = constants.ML_METRICS.METRICS_DATA;
  const { ATC_SESSION_EXPIRED_CODE } = constants;

  const resetState = () => {
    onSuccessAddToList([]);
    setError('');
  };

  const existanceEvaluation = ({ target, source }) =>
    source.productId === target.productId &&
    (isPDPpage ? source.variantId === target.variantId : true);

  const buildFavoritesList = () => ({
    tagName: myFavsTagname,
    listLines: []
  });

  const checkIfCurrentProductIsInAnyList = () => {
    return myListData.some((list) =>
      list.listLines.some((line) =>
        existanceEvaluation({ source: line, target: product })
      )
    );
  };

  const getCurrentProductLists = (newLists) => {
    return newLists.filter((list) =>
      list.listLines.some((line) =>
        existanceEvaluation({ source: line, target: product })
      )
    );
  };

  const getListWithCheckedFlag = (onlyListsWithCurrentProduct, allLists) => {
    return allLists.map((list) => {
      const checkedList = onlyListsWithCurrentProduct.find(
        (l) => l.tagName === list.tagName
      );
      return {
        ...list,
        checked: !!checkedList
      };
    });
  };

  const sortLists = (listToBeSorted, favList) => {
    const sortedList = listToBeSorted.filter(
      (e) => e.tagName !== myFavsTagname
    );
    sortedList.unshift(favList);
    return sortedList;
  };

  useEffect(() => {
    const listsCopy = [...myListData];
    const favoritesList = listsCopy.find(
      (currentList) => currentList.tagName === myFavsTagname
    );
    const newLists = sortLists(
      listsCopy,
      favoritesList || buildFavoritesList()
    );
    const itsInAnyList = checkIfCurrentProductIsInAnyList();
    setProductIsInAnyList(itsInAnyList);
    if (itsInAnyList) {
      const filteredLists = getCurrentProductLists(newLists);
      const listWithCheckedFlag = getListWithCheckedFlag(
        filteredLists,
        newLists
      );
      setLists(listWithCheckedFlag);
      setUserSelectedLists(filteredLists);
      if (myListMetrics.isEvaluated === false) {
        myListMetrics.setIsAlreadyAdded(ALREADY_ADDED);
      }
    } else {
      setLists(newLists);
      myListMetrics.setIsInMyFavs(DO_NOT_SAVE_MYFAVS);
      myListMetrics.setIsInL2(NOT_SAVE_IN_L2);
      myListMetrics.setIsInL3(NOT_SAVE_IN_L3);
      myListMetrics.setIsInL4(NOT_SAVE_IN_L4);
      myListMetrics.setIsInL5(NOT_SAVE_IN_L5);
      if (myListMetrics.isEvaluated === false) {
        myListMetrics.setIsAlreadyAdded(NOT_ADDED);
      }
    }
  }, [myListData, product.variantId, product.productId]);

  const createList = async (tagName, listType) => {
    setLoading(true);
    resetState();
    myListMetrics.setIsSavedNewList(SAVE_NEW_LIST);
    const resp = await addTolList({
      tagName,
      product,
      listType,
      setShowLoginForm
    });
    if (resp.ok) {
      setAlertType('success');
      if (onSuccessAddToList) {
        onSuccessAddToList([tagName]);
      }
    } else if (
      resp.error_code &&
      resp.error_code === ATC_SESSION_EXPIRED_CODE
    ) {
      setLoading(false);
      setModalVisible(false);
      setShowLoginForm(true);
    } else {
      setError('No hemos podido crear la lista');
    }
    const checkoutResponse = await getAllLists({});
    if (checkoutResponse.ok) {
      myListMetrics.getListTypeSaved([resp], checkoutResponse.data.lists);
    }

    setLoading(false);
    setModalVisible(false);
    setShowForm(false);
  };

  const onListChange = (list, isSelected) => {
    setIsSaveButtonEnabled(true);
    if (isSelected) {
      const newList = [...userSelectedLists, ...[list]];
      setUserSelectedLists(newList);
    } else {
      const newList = userSelectedLists.filter(
        (l) => l.tagName !== list.tagName
      );
      setUserSelectedLists(newList);
    }
  };

  const undo = (undos, updatedLists) => async () => {
    const performUndos = undos.reduce(
      (promises, { tagName, product: listProduct, listType, action }) => {
        if (action === 'add') {
          promises.push(
            addTolList({ tagName, product, listType, setShowLoginForm })
          );
        }
        if (action === 'delete') {
          const list = updatedLists.find((l) => l.tagName === tagName);
          const line = list.listLines.find((l) =>
            existanceEvaluation({
              target: l,
              source: listProduct
            })
          );
          if (line) {
            promises.push(
              deleteFromList(
                list.id,
                line.listLineId,
                list.tagName,
                setShowLoginForm
              )
            );
          }
        }
        return promises;
      },
      []
    );
    await Promise.all(performUndos);
    await getAllLists({});
    setUndo();
  };
  const getActions = () =>
    lists.reduce(
      (actions, currentlist) => {
        const isSelected = userSelectedLists.find(
          (userSelectedList) => userSelectedList.tagName === currentlist.tagName
        );
        const list = lists.find((l) => l.tagName === currentlist.tagName);
        const line = list.listLines.find((l) =>
          existanceEvaluation({
            target: l,
            source: product
          })
        );
        if (isSelected && !list.checked) {
          actions.dos.push(
            addTolList({
              tagName: currentlist.tagName,
              product,
              listType: currentlist.type
            })
          );
          actions.undos.push({
            tagName: list.tagName,
            listType: list.type,
            product,
            action: 'delete'
          });
        }
        if (!isSelected && list.checked) {
          if (line) {
            actions.dos.push(
              deleteFromList(
                list.id,
                line.listLineId,
                list.tagName,
                setShowLoginForm
              )
            );
            actions.undos.push({
              tagName: list.tagName,
              listType: list.type,
              product,
              action: 'add'
            });
          }
        }
        return actions;
      },
      { dos: [], undos: [] }
    );

  const onSaveLists = async () => {
    setLoading(true);
    resetState();
    const actions = getActions();
    const response = await Promise.all(actions.dos);
    const allResponsesSuccessful = response.every((resp) => resp.ok);
    const parsedResponse = response.reduce(
      (acc, currentResponse) => {
        if (currentResponse.ok) {
          return {
            ...acc,
            succeeded: [...acc.succeeded, ...[currentResponse.tagName]]
          };
        }
        if (
          currentResponse.error_code &&
          currentResponse.error_code === ATC_SESSION_EXPIRED_CODE
        ) {
          setLoading(false);
          setModalVisible(false);
          setShowLoginForm(true);
        }
        return {
          ...acc,
          errored: [...acc.errored, ...[currentResponse.tagName]]
        };
      },
      {
        succeeded: [],
        errored: []
      }
    );
    const deleteHasBeenPerformed = actions.undos.some((element) => {
      return element.action === 'add';
    });
    setAlertType(deleteHasBeenPerformed ? 'warning' : 'success');
    onSuccessAddToList(parsedResponse.succeeded);
    const checkoutResponse = await getAllLists({});
    if (checkoutResponse.ok) {
      const cb = undo(actions.undos, checkoutResponse.data.lists);
      setUndo(() => cb);
      if (allResponsesSuccessful) {
        myListMetrics.getListTypeSaved(response, checkoutResponse.data.lists);
      }
    }
    setLoading(false);
    setModalVisible(false);
  };

  const validateList = (newTagName) => {
    const existingList = lists.find(
      (l) => l.tagName.toLowerCase() === newTagName.toLowerCase()
    );
    if (existingList) {
      setError('Ya creaste una lista con este nombre');
    } else {
      setError('');
    }
  };

  return {
    createList,
    lists,
    productIsInAnyList,
    setModalVisible,
    onListChange,
    onSaveLists,
    loading,
    userSelectedLists,
    error,
    validateList,
    showMyLists,
    onSuccessAddToList,
    showForm,
    setShowForm,
    isSaveButtonEnabled,
    getActions,
    undo
  };
};

export default useMyLists;
