import { useState, useEffect } from 'react';

function usePdpTags() {
  const [extProdInfo, setExtProdInfo] = useState(
    'not_available|not_available|not_available'
  );
  const [prodInfo, setProdInfo] = useState(
    'no_desc|not_available|no_color|no_img|no_img'
  );
  const [postSale, setPostSale] = useState(
    'not_available|not_available|not_available'
  );
  const [displayButtons, setDisplayButtons] = useState('no_desc|no_planes');
  const [updateCounter, setUpdateCounter] = useState(0);

  const addTags = () => {
    const pdp =
      window.digitalData &&
      window.digitalData.tag &&
      window.digitalData.tag.pdp;
    window.digitalData.tag = {
      ...window.digitalData.tag,
      pdp: {
        ...pdp,
        extprodinfo: extProdInfo,
        prodinfo: prodInfo,
        postsale: postSale,
        display_buttons: displayButtons
      }
    };
  };

  const replaceValueAt = (originalString = '', position = 0, value = '') => {
    const valueList = originalString.slice().split('|');
    valueList[position] = value;
    return valueList.join('|');
  };

  useEffect(() => {
    setUpdateCounter((cv) => cv + 1);
  }, [extProdInfo, prodInfo, postSale]);

  const setSoldBy = (sellerName = '') => {
    const soldBy =
      sellerName !== '' ? sellerName.replace('|', '') : 'not_available';
    setExtProdInfo((cv) => replaceValueAt(cv, 0, soldBy));
  };

  const setHomeDelivery = (isHomeDeliveryAvailable = false) => {
    const homeDelivery = isHomeDeliveryAvailable
      ? 'available'
      : 'not_available';
    setExtProdInfo((cv) => replaceValueAt(cv, 1, homeDelivery));
  };

  const setStorePickup = (isStorePickupAvailable = false) => {
    const storePickup = isStorePickupAvailable ? 'available' : 'not_available';
    setExtProdInfo((cv) => replaceValueAt(cv, 2, storePickup));
  };

  const setProductFeatures = (productFeatures = '') => {
    let features = 'no_desc';
    if (productFeatures === '1') {
      features = 'model_sizes';
    } else if (productFeatures === '2') {
      features = 'top_features';
    }
    setProdInfo((cv) => replaceValueAt(cv, 0, features));
  };

  const setProductSizeTools = (productSizeTools = '') => {
    let sizeTools = '';
    if (productSizeTools === '1') {
      sizeTools = 'size_chart';
    } else if (productSizeTools === '2') {
      sizeTools = 'fit_finder';
    }
    setProdInfo((cv) => replaceValueAt(cv, 1, sizeTools));
  };

  const setProductColorsQty = (qty = 0) => {
    setProdInfo((cv) => replaceValueAt(cv, 2, !qty ? 'no_color' : qty));
  };

  const setProductBigImagesQty = (qty = 0) => {
    setProdInfo((cv) => replaceValueAt(cv, 3, !qty ? 'no_img' : qty));
  };

  const setProductSmallImagesQty = (qty = 0) => {
    setProdInfo((cv) => replaceValueAt(cv, 4, !qty ? 'no_img' : qty));
  };

  const setExtendedWarrantyOptsQty = (qty = 0) => {
    setPostSale((cv) => replaceValueAt(cv, 0, !qty ? 'not_available' : qty));
  };

  const setAdditionalServicesAvailableQty = (qty = 0) => {
    setPostSale((cv) => replaceValueAt(cv, 1, !qty ? 'not_available' : qty));
  };

  const setFreeReturns = (available = false) => {
    setPostSale((cv) =>
      replaceValueAt(cv, 2, available ? 'available' : 'not_available')
    );
  };

  const setViewMoreButton = (isVisible = false) => {
    setDisplayButtons((cv) =>
      replaceValueAt(cv, 0, isVisible ? 'ver_mas' : 'no_desc')
    );
  };

  const setPlansButton = (isVisible = false) => {
    setDisplayButtons((cv) =>
      replaceValueAt(cv, 1, isVisible ? 'planes' : 'no_planes')
    );
  };

  return {
    addTags,
    updateCounter,
    setSoldBy,
    setHomeDelivery,
    setStorePickup,
    setProductFeatures,
    setProductSizeTools,
    setProductColorsQty,
    setProductBigImagesQty,
    setProductSmallImagesQty,
    setExtendedWarrantyOptsQty,
    setAdditionalServicesAvailableQty,
    setFreeReturns,
    setViewMoreButton,
    setPlansButton
  };
}

export { usePdpTags };
