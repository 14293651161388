import React from 'react';
import PropTypes from 'prop-types';
import MatchMedia from '../../../components/ui/MatchMedia/MatchMedia';
import ProductSpecificationsMobile from './Mobile/ProductSpecificationMobile';
import ProductSpecificationsDesktop from './Desktop/ProductSpecificationsDesktop';
import { withProduct } from '../../../utils/ProductContext';
import { withLabels } from '../../../utils/LabelsContext';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import noop from '../../../utils/noop';

const SoftlineProductSpecifications = ({
  isPumAvailable,
  pumQuantity,
  primeShipping,
  colorVariants,
  selectedColorSwatchIndex,
  colorSwatchHandler,
  sizes,
  selectedSize,
  sizeChangeHandler,
  handleAddToCart,
  isAddToCartInProgress,
  addToCartButtonVisible,
  showCollectionButtonVisible,
  isVendorLogin,
  storeSkuData,
  currentTab,
  errorAddingToCart,
  requestProductFromStore,
  setShowLoginForm,
  showLoginForm,
  setErrorAddingToCart
}) => {
  const specificationProps = {
    isPumAvailable,
    pumQuantity,
    primeShipping,
    colorVariants,
    selectedColorSwatchIndex,
    colorSwatchHandler,
    selectedSize,
    sizes,
    sizeChangeHandler,
    handleAddToCart,
    isAddToCartInProgress,
    addToCartButtonVisible,
    showCollectionButtonVisible,
    isVendorLogin,
    storeSkuData,
    currentTab,
    errorAddingToCart,
    requestProductFromStore,
    setShowLoginForm,
    showLoginForm,
    setErrorAddingToCart
  };

  return (
    <MatchMedia
      content={{
        desktop: () => <ProductSpecificationsDesktop {...specificationProps} />,
        mobile: () => <ProductSpecificationsMobile {...specificationProps} />,
        tablet: () => <ProductSpecificationsMobile {...specificationProps} />
      }}
    />
  );
};

SoftlineProductSpecifications.defaultProps = {
  isPumAvailable: false,
  pumQuantity: 0,
  primeShipping: false,
  colorVariants: [],
  selectedColorSwatchIndex: 0,
  colorSwatchHandler: noop,
  sizes: [],
  selectedSize: undefined,
  sizeChangeHandler: noop,
  handleAddToCart: noop,
  requestProductFromStore: noop,
  isAddToCartInProgress: false,
  addToCartButtonVisible: true,
  showCollectionButtonVisible: false,
  currentTab: '',
  errorAddingToCart: false,
  showLoginForm: false
};

SoftlineProductSpecifications.propTypes = {
  isPumAvailable: PropTypes.bool,
  pumQuantity: PropTypes.number,
  primeShipping: PropTypes.bool,
  colorVariants: PropTypes.array,
  selectedColorSwatchIndex: PropTypes.number,
  colorSwatchHandler: PropTypes.func,
  sizes: PropTypes.array,
  selectedSize: PropTypes.string,
  sizeChangeHandler: PropTypes.func,
  requestProductFromStore: PropTypes.func,
  handleAddToCart: PropTypes.func,
  isAddToCartInProgress: PropTypes.bool,
  addToCartButtonVisible: PropTypes.bool,
  showCollectionButtonVisible: PropTypes.bool,
  isVendorLogin: PropTypes.bool.isRequired,
  storeSkuData: PropTypes.object.isRequired,
  currentTab: PropTypes.string,
  errorAddingToCart: PropTypes.bool,
  setShowLoginForm: PropTypes.func.isRequired,
  setErrorAddingToCart: PropTypes.func.isRequired,
  showLoginForm: PropTypes.bool
};

export default withProduct(
  withLabels(withApplicationContext(SoftlineProductSpecifications))
);
