import debounce from 'lodash.debounce';
import get from 'lodash.get';
import merge from 'lodash.merge';
import find from 'lodash.find';
import deburr from 'lodash.deburr';
import { lowerCase, isNumber } from 'lodash';
import set from 'lodash.set';

const _ = {
  debounce,
  get,
  merge,
  find,
  deburr,
  lowerCase,
  isNumber,
  set
};

export default _;
